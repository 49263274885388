import * as React from 'react';

const LineWithCircleIcon: React.FC = () => {
  return (
    <svg
      width="8"
      height="85"
      viewBox="0 0 8 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="45.5" r="3" fill="#D9D9D9" />
      <line x1="4" x2="4" y2="93" stroke="#E5E1DA" strokeWidth="2" />
    </svg>
  );
};

export default LineWithCircleIcon;
