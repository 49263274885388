import React from 'react';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {IconButton, CircularProgress} from '@mui/material';
import {VerticalAlignBottom} from '@mui/icons-material';
import {useAppSelector} from 'redux/hooks';
import {selectSerializedFilters} from 'redux/filters';
import {formatDate} from 'utilities/date';
import {buildQueries} from 'queries/AlertQueries';
import {fetchInventory, fetchVessels} from 'api/alerts';
import {selectAlertById} from 'redux/alerts';
import {
  formatDetectGeoJson,
  formatImageStripsGeoJson,
  formatODSGeoJson
} from './GeoJsonFormatters';
import {Vessel, OilSpill} from 'types/Alerts';

interface DownloadButtonProps {
  alertId: string;
}

const IMAGE_CHIP_ZIP_DIRECTORY = 'image_chips';
const VESSEL_DETECT_FILENAME = 'detected_vessels.json';
const SPILL_DETECT_FILENAME = 'detected_spills.json';
const IMAGE_STRIP_FILENAME = 'image_strips.json';
const SAR_SCENE_FILENAME = 'scene.json';

export const DownloadButton: React.FC<DownloadButtonProps> = ({alertId}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const filters = useAppSelector(selectSerializedFilters);
  const alertRecord = useAppSelector((state) => selectAlertById(state, alertId));

  const buildODSZip = async (
    zip: JSZip,
    spills: OilSpill[],
    imageStripGeoJson: any,
    oilSpillGeoJson: any
  ) => {
    const imgFolder = zip.folder(IMAGE_CHIP_ZIP_DIRECTORY);
    const imagePromises = spills.map(async (spill) => {
      try {
        const response = await fetch(spill.metadata.chipUrlJpg, {mode: 'cors'});
        const blob = await response.blob();
        if (!imgFolder) return;
        imgFolder.file(`${spill.oilSpillDetails.oilSpillId}.jpg`, blob);
      } catch (err) {
        // Ignore missing images
      }
    });
    await Promise.all(imagePromises);
    zip.file(SPILL_DETECT_FILENAME, JSON.stringify(oilSpillGeoJson));
    zip.file(SAR_SCENE_FILENAME, JSON.stringify(imageStripGeoJson));
    return zip;
  };

  const buildZip = async (
    zip: JSZip,
    alertSource: string,
    vessels: Vessel[],
    detectGeoJson: any,
    imageStripGeoJson: any
  ) => {
    // Ignore RF as no image chips are available
    if (alertSource !== 'RF') {
      const imgFolder = zip.folder(IMAGE_CHIP_ZIP_DIRECTORY);
      const imagePromises = vessels.map(async (vessel) => {
        try {
          const response = await fetch(vessel.chipUrlJpg, {mode: 'cors'});
          const blob = await response.blob();
          if (!imgFolder) return;
          imgFolder.file(`${vessel.vesselId}.jpg`, blob);
        } catch (err) {
          // Ignore missing images
        }
      });
      await Promise.all(imagePromises);
    }

    zip.file(VESSEL_DETECT_FILENAME, JSON.stringify(detectGeoJson));
    zip.file(
      alertSource === 'EO' ? IMAGE_STRIP_FILENAME : SAR_SCENE_FILENAME,
      JSON.stringify(imageStripGeoJson)
    );

    return zip;
  };

  const getZipFileName = () =>
    `${formatDate(alertRecord.properties.meanDatetime, 'yyyy-MM-dd')}_${
      alertRecord.source
    }_${
      alertRecord.properties.scid === '' || alertRecord.properties.scid === undefined
        ? ''
        : alertRecord.properties.scid
    }_${alertRecord.properties.mission}.zip`;

  const downloadAlert = async () => {
    setLoading(true);
    try {
      const {allAlertVesselsQuery} = buildQueries(
        filters.selectedTimeFrame.start,
        filters.selectedTimeFrame.end,
        !filters.filtersEnabled,
        filters.hideZeroDetects,
        filters.filters,
        filters.filters.missions,
        alertId
      );
      const vessels = await fetchVessels(allAlertVesselsQuery);
      const inventory = await fetchInventory(alertId);
      const vesselDetectGeoJson = formatDetectGeoJson(vessels);
      const inventoryGeoJson = formatImageStripsGeoJson(inventory);
      const oilSpillGeoJson = formatODSGeoJson(alertRecord.spills, alertRecord.sources);

      const zip = new JSZip();

      if (alertRecord.source === 'ODS') {
        await buildODSZip(zip, alertRecord.spills, inventoryGeoJson, oilSpillGeoJson);
      } else {
        await buildZip(
          zip,
          alertRecord.source,
          vessels,
          vesselDetectGeoJson,
          inventoryGeoJson
        );
      }

      const content = await zip.generateAsync({type: 'blob'});
      saveAs(content, getZipFileName());
    } catch (err: any) {
      alert(`Download failed: ${err.toLocaleString()}`);
    }

    setLoading(false);
  };

  return (
    <IconButton
      sx={(theme) => ({
        height: '25px',
        width: '36px',
        pointerEvents: 'auto',
        color: theme.palette.secondary.contrastText
      })}
      disabled={loading}
      onClick={downloadAlert}
    >
      {loading ? <CircularProgress size={18} /> : <VerticalAlignBottom />}
    </IconButton>
  );
};
