import {RootState} from '../store';

export const selectMapMensurationEnabled = (state: RootState) =>
  state.mapMensuration.enabled;
export const selectMapMensurationDocked = (state: RootState) =>
  state.mapMensuration.docked;
export const selectMapMensurationTool = (state: RootState) => state.mapMensuration.tool;
export const selectMapMensurationUnit = (state: RootState) => state.mapMensuration.unit;
export const selectMapMensurationLabels = (state: RootState) =>
  state.mapMensuration.labels;
export const selectMapMensurationClearCount = (state: RootState) =>
  state.mapMensuration.cleared;
