export type TickPoints = {
  start: CompassPoint;
  end: CompassPoint;
};

export type TextBox = {
  x: number;
  y: number;
  max: number;
};

export type CompassPoint = {
  x: number;
  y: number;
};

export interface CompassConfig {
  backgroundColor: string; // dark blue
  backgroundAlpha: number;
  enableBackground: boolean;
  alphaReset: number;
  neutralColor: string; // Grey
  rotationColor: string; // Orange
  tickFill: string; // white
  tickTotal: number;
  backgroundRadius: number;
  tickLengthAdjustment: number;
  majorTickEvery: number;
  topPadding: number;
  rightPadding: number;
  tickInnerRadius: number; // inner circle of compass (tick start point)
  degreePerTick: () => number;
  minorTickLength: () => number;
  majorTickLength: () => number;
  northTickLength: () => number;
  centerPoint: (
    drawContext: CanvasRenderingContext2D,
    deviceScaleRatio: number
  ) => CompassPoint;
  convertDegreesToRadians: (angle: number) => number;
  getArcPointMinor: (
    tickDegree: number,
    drawContext: CanvasRenderingContext2D,
    rotationDegree: number,
    deviceScaleRatio: number
  ) => TickPoints;
  getArcPointMajor: (
    tickDegree: number,
    drawContext: CanvasRenderingContext2D,
    rotationDegree: number,
    deviceScaleRatio: number
  ) => TickPoints;
  getArcPointNorth: (
    drawContext: CanvasRenderingContext2D,
    rotationDegree: number,
    deviceScaleRatio: number
  ) => TickPoints;
  getLabelTextBoxMax: (
    drawContext: CanvasRenderingContext2D,
    deviceScaleRatio: number
  ) => TextBox;
}

export class Compass implements CompassConfig {
  backgroundColor: string; // dark blue
  backgroundAlpha: number;
  enableBackground: boolean;
  alphaReset: number;
  neutralColor: string; // Grey
  rotationColor: string; // Orange
  tickFill: string; // white
  tickTotal: number;
  backgroundRadius: number;
  tickLengthAdjustment: number;
  majorTickEvery: number;
  topPadding: number;
  rightPadding: number;
  tickInnerRadiusPadding: number;
  tickInnerRadius: number; // inner circle of compass (tick start point)
  constructor(
    backgroundColor: string,
    backgroundAlpha: number,
    enableBackground: boolean,
    alphaReset: number,
    neutralColor: string,
    rotationColor: string,
    tickFill: string,
    tickTotal: number,
    backgroundRadius: number,
    tickLengthAdjustment: number,
    majorTickEvery: number,
    topPadding: number,
    rightPadding: number,
    tickInnerRadiusPadding: number,
    tickInnerRadius: number
  ) {
    this.backgroundColor = backgroundColor;
    this.backgroundAlpha = backgroundAlpha;
    this.enableBackground = enableBackground;
    this.alphaReset = alphaReset;
    this.neutralColor = neutralColor;
    this.rotationColor = rotationColor;
    this.tickFill = tickFill;
    this.tickTotal = tickTotal;
    this.backgroundRadius = backgroundRadius;
    this.tickLengthAdjustment = tickLengthAdjustment;
    this.majorTickEvery = majorTickEvery;
    this.topPadding = topPadding;
    this.rightPadding = rightPadding;
    this.tickInnerRadiusPadding = tickInnerRadiusPadding;
    this.tickInnerRadius = tickInnerRadius;
  }

  degreePerTick = () => {
    return 360 / this.tickTotal;
  };
  minorTickLength = () => {
    return (
      this.backgroundRadius -
      this.tickInnerRadiusPadding -
      this.tickLengthAdjustment -
      this.tickInnerRadius
    );
  };
  majorTickLength = () => {
    return this.backgroundRadius - this.tickInnerRadiusPadding - this.tickInnerRadius;
  };
  northTickLength = () => {
    return this.backgroundRadius - this.tickInnerRadius;
  };
  centerPoint = (drawContext: CanvasRenderingContext2D, deviceScaleRatio: number) => {
    return {
      x:
        drawContext.canvas.width / deviceScaleRatio -
        (this.backgroundRadius + this.rightPadding),
      y: this.backgroundRadius + this.topPadding
    };
  };
  convertDegreesToRadians = (angle: number) => {
    return (Math.PI / 180) * angle;
  };
  getArcPointMinor = (
    tickDegree: number,
    drawContext: CanvasRenderingContext2D,
    rotationDegree: number,
    deviceScaleRatio: number
  ) => {
    const origin = this.centerPoint(drawContext, deviceScaleRatio);
    const tickRad = this.convertDegreesToRadians(tickDegree);
    const rotationRad = this.convertDegreesToRadians(rotationDegree);
    const radius = this.minorTickLength() + this.tickInnerRadius;
    return {
      start: {
        x: origin.x + this.tickInnerRadius * Math.cos(tickRad + rotationRad),
        y: origin.y + this.tickInnerRadius * Math.sin(tickRad + rotationRad)
      },
      end: {
        x: origin.x + radius * Math.cos(tickRad + rotationRad),
        y: origin.y + radius * Math.sin(tickRad + rotationRad)
      }
    };
  };
  getArcPointMajor = (
    tickDegree: number,
    drawContext: CanvasRenderingContext2D,
    rotationDegree: number,
    deviceScaleRatio: number
  ) => {
    const origin = this.centerPoint(drawContext, deviceScaleRatio);
    const tickRad = this.convertDegreesToRadians(tickDegree);
    const rotationRad = this.convertDegreesToRadians(rotationDegree);
    const radius = this.majorTickLength() + this.tickInnerRadius;
    return {
      start: {
        x: origin.x + this.tickInnerRadius * Math.cos(tickRad + rotationRad),
        y: origin.y + this.tickInnerRadius * Math.sin(tickRad + rotationRad)
      },
      end: {
        x: origin.x + radius * Math.cos(tickRad + rotationRad),
        y: origin.y + radius * Math.sin(tickRad + rotationRad)
      }
    };
  };
  getArcPointNorth = (
    drawContext: CanvasRenderingContext2D,
    rotationDegree: number,
    deviceScaleRatio: number
  ) => {
    const origin = this.centerPoint(drawContext, deviceScaleRatio);
    const tickRad = this.convertDegreesToRadians(0);
    const rotationRad = this.convertDegreesToRadians(rotationDegree);
    const radius = this.northTickLength() + this.tickInnerRadius;
    return {
      start: {
        x: origin.x + this.tickInnerRadius * Math.cos(tickRad + rotationRad),
        y: origin.y + this.tickInnerRadius * Math.sin(tickRad + rotationRad)
      },
      end: {
        x: origin.x + radius * Math.cos(tickRad + rotationRad),
        y: origin.y + radius * Math.sin(tickRad + rotationRad)
      }
    };
  };
  getLabelTextBoxMax = (
    drawContext: CanvasRenderingContext2D,
    deviceScaleRatio: number
  ) => {
    const origin = this.centerPoint(drawContext, deviceScaleRatio);
    return {
      x: origin.x - (this.tickInnerRadius - 3),
      y: origin.y + (this.tickInnerRadius - 3),
      max: this.tickInnerRadius + 4
    };
  };
}
