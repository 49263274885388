import React from 'react';
import {Box, InputAdornment, TextField, Typography} from '@mui/material';
import {MeasurementBlock} from 'types/Filters';
import {useAppSelector} from 'redux/hooks';
import {selectMeasurementBlock, selectFiltersDisabled} from 'redux/filters';
import {ErrorOutlineOutlined} from '@mui/icons-material';

const LengthFilterPanel: React.FC<{
  idx: string;
  setMeasurementValue: (value: MeasurementBlock, idx: string) => void;
  triggerClear: boolean;
}> = ({idx, setMeasurementValue, triggerClear}) => {
  const [errorStateMin, setErrorStateMin] = React.useState(false);
  const [errorStateMax, setErrorStateMax] = React.useState(false);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);
  const measurementValue = useAppSelector((state) => selectMeasurementBlock(state, idx));

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(+e.target.value)) {
      setErrorStateMin(true);
    } else setErrorStateMin(false);

    const measurement = {
      ...measurementValue.vesselLength,
      from: e.target.value
    };
    const measurementBlock = {
      ...measurementValue
    };
    measurementBlock.vesselLength = measurement;
    setMeasurementValue(measurementBlock, idx);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(+e.target.value)) {
      setErrorStateMax(true);
    } else setErrorStateMax(false);

    const measurement = {
      ...measurementValue.vesselLength,
      to: e.target.value
    };
    const measurementBlock = {
      ...measurementValue
    };
    measurementBlock.vesselLength = measurement;
    setMeasurementValue(measurementBlock, idx);
  };

  const handleClearErrorState = React.useCallback(() => {
    if (!isNaN(+measurementValue.vesselLength.from)) {
      setErrorStateMin(false);
    } else setErrorStateMin(true);

    if (!isNaN(+measurementValue.vesselLength.to)) {
      setErrorStateMax(false);
    } else {
      setErrorStateMax(true);
    }

  }, [measurementValue.vesselLength.from, measurementValue.vesselLength.to]);

  React.useEffect(() => {
    handleClearErrorState();
  }, [triggerClear, handleClearErrorState]);

  return (
    <Box sx={{paddingLeft: '13px'}}>
      <Typography>Vessel Length</Typography>
      <Box
        sx={{
          display: 'flex',
          paddingBottom: '8px',
          paddingRight: '16px',
          height: '60px'
        }}
      >
        <TextField
          id="mmsi-collection-filter"
          label="Min"
          variant="standard"
          sx={{width: '207px'}}
          color="primary"
          disabled={filtersDisabled}
          value={measurementValue.vesselLength.from}
          size="small"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMinChange(e)}
          error={errorStateMin}
          helperText={errorStateMin ? 'Please use a valid number' : ''}
          InputProps={
            errorStateMin
              ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      <ErrorOutlineOutlined fontSize="small" color="error" />
                    </InputAdornment>
                  )
                }
              : {}
          }
        />
        <Box sx={{paddingTop: '20px', color: '#656360'}}>m</Box>
        <Box sx={{padding: '15px'}}>-</Box>
        <TextField
          id="mmsi-collection-filter"
          label="Max"
          variant="standard"
          color="primary"
          sx={{width: '207px'}}
          size="small"
          disabled={filtersDisabled}
          value={measurementValue.vesselLength.to}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxChange(e)}
          error={errorStateMax}
          helperText={errorStateMax ? 'Please use a valid number' : ''}
          InputProps={
            errorStateMax
              ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      <ErrorOutlineOutlined fontSize="small" color="error" />
                    </InputAdornment>
                  )
                }
              : {}
          }
        />
        <Box sx={{paddingTop: '20px', marginLeft: '8px', color: '#656360'}}>m</Box>
      </Box>
    </Box>
  );
};

export default LengthFilterPanel;
