import React, {useState} from 'react';
import {Box, Skeleton, Stack, Tab, Tabs, Typography} from '@mui/material';
import TabWrapper from 'components/VesselHistory/TabWrapper';
import VesselHistoryContainer from 'components/VesselHistory/VesselHistoryContainer';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {setMapCenter} from 'redux/map';
import {
  selectVesselHistory,
  selectVesselHistoryLoading,
  loadVesselHistory,
  selectSelectedVessel,
  setFocusedTab
} from 'redux/vesselHistory';
import {loadAisVesselTrack, selectAisDatePeriod} from 'redux/ais';
import {VesselFingerprinting} from 'components/VesselFingerprinting/index';
import {
  loadFingerprintVessels,
  selectFingerprintVessels,
  selectFocusedFpVessel,
  selectVfpVesselsLoading
} from 'redux/vesselFingerprint';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabPanel: React.FC<TabPanelProps> = ({children, index, value, ...other}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 0}}>{children}</Box>}
    </Box>
  );
};

export const HistoryTabPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const vesselHistory = useAppSelector(selectVesselHistory);
  const focusedTab = useAppSelector((store) => store.vesselHistory.focusedTab);

  const vesselHistoryLoading = useAppSelector(selectVesselHistoryLoading);
  const selectedVessel = useAppSelector(selectSelectedVessel);
  const selectedFpVessel = useAppSelector(selectFocusedFpVessel);
  const aisDatePeriod = useAppSelector(selectAisDatePeriod);

  const vfpVessels = useAppSelector(selectFingerprintVessels);
  const isVfpVesselsLoading = useAppSelector(selectVfpVesselsLoading);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      dispatch(setFocusedTab('history'));
    } else if (newValue === 1) {
      dispatch(setFocusedTab('similar'));
    }
  };

  const disableSimilar =
    selectedVessel?.properties.source === 'RF' ||
    selectedVessel?.properties.source === 'ODS';

  React.useEffect(() => {
    dispatch(loadAisVesselTrack());
  }, [selectedVessel, aisDatePeriod, dispatch]);

  React.useEffect(() => {
    if (selectedVessel) {
      dispatch(
        setMapCenter({
          lat: selectedVessel.lat,
          lon: selectedVessel.lon,
          zoom: 9,
          animate: false
        })
      );
    }
    if (selectedFpVessel) {
      dispatch(
        setMapCenter({
          lat: selectedFpVessel.lat,
          lon: selectedFpVessel.lon,
          zoom: 5,
          animate: false
        })
      );
    }
  }, [selectedVessel, selectedFpVessel, dispatch]);

  React.useEffect(() => {
    dispatch(loadVesselHistory());
  }, [dispatch]);

  React.useEffect(() => {
    if (selectedVessel) {
      dispatch(
        loadFingerprintVessels({
          alertId: selectedVessel.alertId,
          vesselId: selectedVessel.vesselId
        })
      );
    }
  }, [selectedVessel, dispatch]);

  React.useEffect(() => {
    if (focusedTab === 'similar') {
      setValue(1);
    }
  }, [focusedTab, dispatch]);

  return (
    <>
      <Tabs
        value={value}
        variant="fullWidth"
        sx={(theme) => ({
          height: '24px !important',
          '& button:focus': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.dark
          },
          '& button:active': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.dark
          },
          '&.MuiTabs-root': {
            maxHeight: '24px',
            minWidth: '158px',
            display: 'flex',
            alignItems: 'center'
          }
        })}
        onChange={handleChange}
        TabIndicatorProps={{
          sx: {
            top: 0,
            backgroundColor: '#fcaf17'
          }
        }}
        aria-label="Vessel Details"
      >
        <Tab
          sx={(theme) => ({
            '&.MuiButtonBase-root.MuiTab-root': {
              color:
                value === 0
                  ? theme.palette.primary.contrastText
                  : theme.palette.primary.main
            },
            backgroundColor:
              value === 0
                ? theme.palette.secondary.dark
                : theme.palette.background.default,
            paddingTop: 0,
            paddingBottom: 0,
            width: '158px'
          })}
          value={0}
          label="Vessel History"
        />
        <Tab
          sx={(theme) => ({
            color: theme.palette.primary.main,
            paddingTop: 0,
            paddingBottom: 0,
            width: '158px',
            '&.Mui-disabled': {
              color: '#6B6965'
            }
          })}
          value={1}
          label="Similar Vessels"
          disabled={disableSimilar}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <TabWrapper>
          {vesselHistoryLoading ? (
            <Stack spacing={1}>
              <Skeleton variant="rectangular" height={40} animation="wave" />
              <Skeleton variant="rectangular" height={40} animation="wave" />
              <Skeleton variant="rectangular" height={40} animation="wave" />
              <Skeleton variant="rectangular" height={40} animation="wave" />
              <Skeleton variant="rectangular" height={40} animation="wave" />
              <Skeleton variant="rectangular" height={40} animation="wave" />
              <Skeleton variant="rectangular" height={40} animation="wave" />
            </Stack>
          ) : (
            <VesselHistoryContainer vessels={vesselHistory} />
          )}
        </TabWrapper>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TabWrapper>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, minmax(0, 0.7fr))',
              rowGap: '1em',
              columnGap: '0.15em',
              padding: '1em'
            }}
          >
            {isVfpVesselsLoading ? (
              <>
                <Skeleton variant="rectangular" height={450} animation="wave" />
                <Skeleton variant="rectangular" height={450} animation="wave" />
                <Skeleton variant="rectangular" height={450} animation="wave" />
              </>
            ) : !isVfpVesselsLoading && vfpVessels.length === 0 ? (
              <>
                <Box></Box>
                <Box sx={{padding: '30px'}}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: 18,
                      fontWeight: 500,
                      color: '#E5E1DA'
                    }}
                  >
                    No Similar Vessels
                  </Typography>
                </Box>
                <Box></Box>
              </>
            ) : (
              <VesselFingerprinting vessels={vfpVessels} />
            )}
          </Box>
        </TabWrapper>
      </TabPanel>
    </>
  );
};
