import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import axiosRetry from 'axios-retry';
import {updateToken} from './middleware';

const isProd = window.location.href.includes('crowsnest');
const API_BASE_URL = !isProd
  ? process.env.REACT_APP_PROXY_HOST
  : 'https://api.maxar.com/crowsnest/v1';

interface MAAAxiosRequestConfig extends Omit<AxiosRequestConfig, 'headers'> {
  headers?: any; // this was "any" at v0.21.1 but now broken between 0.21.4 >= 0.27.2
  // Lets make it any again to make it work again.
}

async function attachToken(config: MAAAxiosRequestConfig) {
  const token = await updateToken();
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`
    }
  };
}

export default class ProxyClient {
  private static instance: ProxyClient = new ProxyClient();

  api: AxiosInstance;

  static getInstance(): ProxyClient {
    return ProxyClient.instance;
  }

  static getApi(): AxiosInstance {
    return ProxyClient.instance.api;
  }

  private constructor() {
    const instance = axios.create({
      baseURL: API_BASE_URL
    });
    axiosRetry(instance, {
      retries: 3,
      retryCondition: (error) => {
        if (error.response) {
          return error.response.status === 503;
        }
        return false;
      }
    });
    instance.interceptors.request.use(attachToken, function (error) {
      return Promise.reject(error);
    });
    this.api = instance;
  }
}

export class MaxarClient {
  private static instance: MaxarClient = new MaxarClient();

  api: AxiosInstance;

  static getInstance(): ProxyClient {
    return MaxarClient.instance;
  }

  static getApi(): AxiosInstance {
    return MaxarClient.instance.api;
  }

  private constructor() {
    const isProd = window.location.href.includes('crowsnest');
    const WMS_BASE_URL = !isProd ? 'https://api.beta.maxar.com' : 'https://api.maxar.com';
    const instance = axios.create({
      baseURL: WMS_BASE_URL
    });
    axiosRetry(instance, {
      retries: 3,
      retryCondition: (error) => {
        if (error.response) {
          return error.response.status === 503;
        }
        return false;
      }
    });
    instance.interceptors.request.use(attachToken, function (error) {
      return Promise.reject(error);
    });
    this.api = instance;
  }
}

export class MausClient {
  private static instance: MausClient = new MausClient();

  api: AxiosInstance;

  static getInstance(): MausClient {
    return MausClient.instance;
  }

  static getApi(): AxiosInstance {
    return MausClient.instance.api;
  }

  private constructor() {
    const MAUS_BASE_URL = !isProd
      ? process.env.REACT_APP_MAUS_URL
      : 'https://api.maxar.com/crowsnest/v1/maus/api/v1';

    const instance = axios.create({baseURL: MAUS_BASE_URL});

    axiosRetry(instance, {
      retries: 3,
      retryCondition: (error) => {
        if (error.response) {
          return error.response.status === 503;
        }
        return false;
      }
    });
    instance.interceptors.request.use(attachToken, function (error) {
      return Promise.reject(error);
    });
    this.api = instance;
  }
}
