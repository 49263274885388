import React from 'react';
import {AppBar, Toolbar, Typography, SvgIcon, Divider, Button, Box} from '@mui/material';
import SettingsMenu from 'components/Navigation/menus/SettingsMenu';
import {HelpOutlineSharp} from '@mui/icons-material';

const HeaderBar: React.FC = () => {
  const title = process.env.REACT_APP_TITLE;
  return (
    <AppBar
      position="sticky"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        borderLeft: `5px solid ${theme.palette.primary.contrastText}`,
        zIndex: 1
      })}
    >
      <Toolbar disableGutters variant="dense">
        <SvgIcon
          sx={(theme) => ({
            color: theme.palette.primary.contrastText,
            display: {xs: 'flex'},
            mr: 2,
            ml: 4
          })}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 0H0V24H24V0ZM14.3994 16.16H16.16V14.3939L9.9205 8.15995H8.15995V9.9205L10.3966 12.1572L8.15995 14.3939V16.16H9.9205L12.1572 13.9177L14.3994 16.16ZM16.1545 8.15999H14.0341V10.2804H16.1545V8.15999Z"
          />
        </SvgIcon>
        <Typography
          variant="h6"
          noWrap
          sx={{
            mr: 2
            // flexGrow: 1
          }}
        >
          {title}
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={(theme) => ({
            borderColor: theme.palette.secondary.main
          })}
          variant="middle"
        />
        <Box sx={{flexGrow: 1}}></Box>
        <Button
          startIcon={<HelpOutlineSharp />}
          href="https://docs.crowsnest.maxar.com"
          target="_blank"
          sx={{textTransform: 'none'}}
        >
          Help
        </Button>
        <Divider
          orientation="vertical"
          flexItem
          sx={(theme) => ({
            borderColor: theme.palette.secondary.main,
            marginRight: '22px',
            marginLeft: '22px'
          })}
          variant="middle"
        />
        <SettingsMenu />
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
