import React from 'react';
import {FingerprintVessel} from 'types/Alerts';
import {VesselPrintCard} from './VesselPrintCard';

type Props = {vessels: FingerprintVessel[]};

export const VesselFingerprinting: React.FC<Props> = ({vessels}) => {
  return (
    <>
      {vessels.map((vessel) => {
        return (
          <VesselPrintCard
            key={vessel.id}
            id={vessel.id}
            name={vessel.name}
            image={vessel.image}
            latitude={vessel.lat}
            longitude={vessel.lon}
            date={vessel.date}
            length={vessel.length}
            width={vessel.width}
            confidence={vessel.confidence}
            geometry={vessel.geometry}
            heading={vessel.heading}
          />
        );
      })}
    </>
  );
};
