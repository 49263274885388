import React from 'react';
import {Box, FormControlLabel, Switch, Typography} from '@mui/material';
import {OilSpill, OilSpillSource, Vessel} from 'types/Alerts';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {selectAlertStateById, setAlertFilteredVessels} from 'redux/alerts';
import {styled} from '@mui/material/styles';
import {RFCard} from './components/RFCard';
import {EOSARCard} from './components/EOSARCard';

import {ODSCard} from './components/ODSCard';

interface AlertChipsContainerProps {
  vessels: Vessel[];
  filteredVessels: Vessel[];
  spills: OilSpill[];
  spillSources: OilSpillSource[];
  alertId: string;
  source: string;
}

const FilterEnable = styled(Switch)(({theme}) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: 'transparent',
    border: '1px solid #606060',
    height: 'auto',
    opacity: 1,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 22,
      height: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  },
  '& .MuiSwitch-switchBase': {
    '&:not(.Mui-checked)': {
      color: '#606060'
    },
    '&.Mui-checked + .MuiSwitch-track': {
      borderColor: theme.palette.primary.contrastText,
      backgroundColor: 'transparent'
    },
    '&.Mui-checked': {
      color: theme.palette.primary.contrastText
    }
  }
}));
export const PanelFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  color: 'rgba(178, 175, 170, 1)',
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  },
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '19px',
  letterSpacing: '0.30000001192092896px'
}));

export const AlertChipsContainer: React.FC<AlertChipsContainerProps> = ({
  alertId,
  vessels,
  filteredVessels,
  spills,
  spillSources,
  source
}) => {
  const dispatch = useAppDispatch();
  const alertState = useAppSelector((state) => selectAlertStateById(state, alertId));
  const toggle = (value: boolean) => {
    dispatch(setAlertFilteredVessels({id: alertId, value}));
  };
  const noVesselDetects =
    filteredVessels.length === 0 && vessels.length === 0 && (spills?.length || 0) === 0;

  const paramVesselId = useAppSelector((store) => store.filters.filters.vesselId);

  return (
    <>
      {filteredVessels.length !== vessels.length && alertState && (
        <Box
          sx={{
            marginLeft: '15px',
            marginTop: '10px',
            display: 'flex'
          }}
        >
          <PanelFormControlLabel
            control={
              <FilterEnable
                checked={alertState.filtered}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  toggle(e.target.checked);
                }}
              />
            }
            label={`Filter Results`}
          />
          <Typography sx={{alignSelf: 'center'}}>{`Showing ${
            alertState.filtered ? filteredVessels.length : vessels.length
          } of ${vessels.length} vessels`}</Typography>
        </Box>
      )}
      {noVesselDetects && (
        <Box sx={{padding: '30px'}}>
          <p style={{textAlign: 'center', fontSize: 18, fontWeight: 500}}>
            {source === 'ODS' ? 'No Oil Spill Detects' : 'No Vessel Detects'}
          </p>
        </Box>
      )}
      {source === 'ODS' && (
        <Box sx={{display: 'flex', flexWrap: 'wrap', padding: '10px'}}>
          <>
            {spills.map((row) => {
              return (
                <ODSCard
                  key={row.oilSpillDetails.oilSpillId}
                  spillSources={spillSources}
                  spill={row}
                />
              );
            })}
          </>
        </Box>
      )}

      {source === 'RF' && (
        <Box sx={{display: 'flex', flexWrap: 'wrap', padding: '10px'}}>
          {alertState.filtered ? (
            <>
              {filteredVessels.map((row) => {
                return <RFCard key={row.id} vessel={row} />;
              })}
            </>
          ) : (
            <>
              {vessels.map((row) => {
                return <RFCard key={row.id} vessel={row} />;
              })}
            </>
          )}
        </Box>
      )}
      {(source === 'EO' || source === 'SAR') && (
        <Box sx={{display: 'flex', flexWrap: 'wrap', padding: '10px'}}>
          {alertState.filtered ? (
            <>
              {filteredVessels.map((row) => {
                if (!paramVesselId) {
                  return <EOSARCard key={row.id} vessel={row} />;
                }

                if (paramVesselId === row.vesselId) {
                  return <EOSARCard key={row.id} vessel={row} />;
                }
              })}
            </>
          ) : (
            <>
              {vessels.map((row) => {
                return <EOSARCard key={row.id} vessel={row} />;
              })}
            </>
          )}
        </Box>
      )}
    </>
  );
};
