import React from 'react';
import {formatDate} from 'utilities/date';
import {Box, styled} from '@mui/material';
import {Vessel} from 'types/Alerts';
import {getVesselPointIcon} from 'utilities/map';
import LineWithCircleIcon from 'components/Icons/LineWithCircleIcon';
import {useAppDispatch} from 'redux/hooks';
import {setSelectedVessel} from 'redux/vesselHistory';
import {resetState} from 'redux/vesselChipMensuration';
import RFIcon from '../../assets/cards/RFIcon.png';
const RowWrapper = styled('div')(({theme}) => ({
  width: '100%',
  background: theme.palette.secondary.dark,
  color: theme.palette.primary.main,
  display: 'flex',
  height: '85px',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  alignItems: 'center'
}));

interface VesselHistoryProps {
  selected: boolean;
  vessel: Vessel;
}

const VesselChipHeadingIndicator = styled('img')({
  width: '34px'
});

const VesselRowHistoryWrapper: React.FC<VesselHistoryProps> = ({vessel, selected}) => {
  const dispatch = useAppDispatch();
  const [hover, setHover] = React.useState(false);

  const vesselClick = () => {
    dispatch(setSelectedVessel(vessel));
    dispatch(resetState());
  };

  return (
    <RowWrapper
      id={vessel.vesselId}
      key={vessel.id}
      sx={(theme) => ({
        border: selected
          ? `1px solid ${theme.palette.primary.contrastText}`
          : `1px solid transparent`,
        '&:hover': {
          backgroundColor: hover ? '#626161' : ''
        }
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={vesselClick}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '80px 98px 16px 44px 1fr 1fr auto',
          alignItems: 'center'
        }}
      >
        <Box sx={{marginLeft: '10px'}}>
          <Box sx={{fontWeight: 700}}>{`${formatDate(
            vessel.properties.meanDatetime,
            'yyyy-MM-dd'
          )}`}</Box>
          <Box>{`${formatDate(vessel.properties.meanDatetime, 'HH:mm:ss')}`}</Box>
        </Box>
        <Box
          component="div"
          sx={{
            marginLeft: '10px'
          }}
        >
          {vessel.properties.source === 'RF' ? (
            <img src={RFIcon} style={{margin: 'auto'}} />
          ) : (
            <img
              src={vessel.chipUrlJpg}
              alt="vessel image"
              style={{
                width: '75px',
                backgroundSize: 'cover'
              }}
            />
          )}
        </Box>
        <Box>
          <LineWithCircleIcon />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '-7px',
            height: '20px',
            width: '20px'
          }}
        >
          <VesselChipHeadingIndicator
            src={getVesselPointIcon(vessel)}
            sx={{margin: 'auto'}}
          />
        </Box>
        <Box>
          <Box>
            <Box component="span" sx={{fontWeight: '700'}}>
              Length:
            </Box>
            <Box component="span"> {vessel.properties.length} (m)</Box>
          </Box>
          <Box>
            <Box component="span" sx={{fontWeight: '700'}}>
              Width:
            </Box>
            <Box component="span"> {vessel.properties.width} (m)</Box>
          </Box>
        </Box>
        <Box sx={{paddingLeft: '10px'}}>
          <Box>
            <Box component="span" sx={{fontWeight: '700'}}>
              Lat:
            </Box>
            <Box component="span"> {vessel.lat}°</Box>
          </Box>
          <Box>
            <Box component="span" sx={{fontWeight: '700'}}>
              Lon:
            </Box>
            <Box component="span"> {vessel.lon}°</Box>
          </Box>
        </Box>
        <Box sx={{paddingLeft: '20px'}}>
          <Box>
            <Box component="span" sx={{fontWeight: '700'}}>
              Speed:
            </Box>
            <Box component="span"> {vessel.properties.speed || '0'} (kts)</Box>
          </Box>
          <Box>
            <Box component="span" sx={{fontWeight: '700'}}>
              Heading:
            </Box>
            <Box component="span"> {vessel.properties.heading}°</Box>
          </Box>
        </Box>
      </Box>
    </RowWrapper>
  );
};

export default VesselRowHistoryWrapper;
