import {createContext} from 'react';

export type AccordionDOMRef = {[key: string]: HTMLDivElement | null};

type AccordionControlContextType = {
  accordionRefs: React.MutableRefObject<AccordionDOMRef>;
  setExpanded: React.Dispatch<React.SetStateAction<string | boolean>>;
  expanded: string | boolean;
};
export const LatencyControlContext = createContext<AccordionControlContextType | null>(
  null
);
