import Dark_TipPoint_NoH_1x from 'assets/map/Dark_TipPoint_NoH_1x.png';
import Lit_TipPoint_NoH_1x from 'assets/map/Lit_TipPoint_NoH_1x.png';
import Lit_TipPoint_SemiH_1x from 'assets/map/Lit_TipPoint_SemiH_1x.png';
import Lit_Untipped_Detect_SemiH_1x from 'assets/map/Lit_Untipped_Detect_SemiH_1x.png';
import Dark_Untipped_Detect_KnownH_1x from 'assets/map/Dark_Untipped_Detect_KnownH_1x.png';
import Lit_Tipped_Detect_SemiH_1x from 'assets/map/Lit_Tipped_Detect_SemiH_1x.png';
import Dark_TipPoint_KnownH_1x from 'assets/map/Dark_TipPoint_KnownH_1x.png';
import Dark_TipPoint_SemiH_1x from 'assets/map/Dark_TipPoint_SemiH_1x.png';
import Lit_TipPoint_KnownH_1x from 'assets/map/Lit_TipPoint_KnownH_1x.png';
import Dark_Tipped_Detect_KnownH_1x from 'assets/map/Dark_Tipped_Detect_KnownH_1x.png';
import Dark_Tipped_Detect_NoH_1x from 'assets/map/Dark_Tipped_Detect_NoH_1x.png';
import Dark_Tipped_Detect_SemiH_1x from 'assets/map/Dark_Tipped_Detect_SemiH_1x.png';
import Dark_Untipped_Detect_NoH_1x from 'assets/map/Dark_Untipped_Detect_NoH_1x.png';
import Dark_Untipped_Detect_SemiH_1x from 'assets/map/Dark_Untipped_Detect_SemiH_1x.png';
import Lit_TipEstimate_1x from 'assets/map/Lit_TipEstimate_1x.png';
import Lit_Tipped_Detect_KnownH_1x from 'assets/map/Lit_Tipped_Detect_KnownH_1x.png';
import Lit_Tipped_Detect_NoH_1x from 'assets/map/Lit_Tipped_Detect_NoH_1x.png';
import Lit_Untipped_Detect_KnownH_1x from 'assets/map/Lit_Untipped_Detect_KnownH_1x.png';
import Lit_Untipped_Detect_NoH_1x from 'assets/map/Lit_Untipped_Detect_NoH_1x.png';
import UnCorrAIS_Map_1x from 'assets/map/UnCorrAIS_Map_1x.png';
import Selected_Vessel_Indicator_1x from 'assets/map/Selected_Vessel_Indicator_1x.png';
import WatchBoxCombinator from 'assets/map/WatchBoxCombinator.svg';
import WatchBoxCombinatorSelected from 'assets/map/WatchBoxCombinatorSelected.svg';
import OilSpill_1x from 'assets/cards/ODS.png';

export const Selected_Vessel_Indicator = Selected_Vessel_Indicator_1x;
export const Watchbox_Marker = WatchBoxCombinator;
export const Watchbox_Marker_Selected = WatchBoxCombinatorSelected;
export const Dark_TipPoint_NoH = Dark_TipPoint_NoH_1x;
export const Dark_TipPoint_KnownH = Dark_TipPoint_KnownH_1x;
export const Dark_TipPoint_SemiH = Dark_TipPoint_SemiH_1x;
export const Lit_TipPoint_SemiH = Lit_TipPoint_SemiH_1x;
export const Lit_TipPoint_KnownH = Lit_TipPoint_KnownH_1x;
export const Lit_TipPoint_NoH = Lit_TipPoint_NoH_1x;
export const Dark_Tipped_Detect_KnownH = Dark_Tipped_Detect_KnownH_1x;
export const Dark_Tipped_Detect_NoH = Dark_Tipped_Detect_NoH_1x;
export const Dark_Tipped_Detect_SemiH = Dark_Tipped_Detect_SemiH_1x;
export const Dark_Untipped_Detect_NoH = Dark_Untipped_Detect_NoH_1x;
export const Dark_Untipped_Detect_SemiH = Dark_Untipped_Detect_SemiH_1x;
export const Lit_TipEstimate = Lit_TipEstimate_1x;
export const Lit_Tipped_Detect_KnownH = Lit_Tipped_Detect_KnownH_1x;
export const Lit_Tipped_Detect_SemiH = Lit_Tipped_Detect_SemiH_1x;
export const Lit_Tipped_Detect_NoH = Lit_Tipped_Detect_NoH_1x;
export const Lit_Untipped_Detect_KnownH = Lit_Untipped_Detect_KnownH_1x;
export const Lit_Untipped_Detect_NoH = Lit_Untipped_Detect_NoH_1x;
export const Lit_Untipped_Detect_SemiH = Lit_Untipped_Detect_SemiH_1x;
export const Dark_Untipped_Detect_KnownH = Dark_Untipped_Detect_KnownH_1x;
export const OilSpillMarker = OilSpill_1x;
export const Uncorrelated_AIS = UnCorrAIS_Map_1x;
export const OILSPILL_GREEN = '#00b77d';
export const LIT_GREEN = '#11aa11';
export const DARK_RED = '#e3664d';
export const WATCHBOX_ORANGE = '#FCAF17';
export const EEZ_PINK = '#CD61AE';
export const EEZ_GRAY = '#7F7D79';
export const SEARCH_AREA_GRAY = '#7F7D7999';
export const INVENTORY_YELLOW = '#B5A83DB2';
export const INVENTORY_HIGHLIGHT = '#E5E1DA';
