import {MaxarClient} from './index';

import axios from 'axios';
const isProd = window.location.href.includes('crowsnest');
const WMS_BASE_URL = !isProd ? 'https://api.beta.maxar.com' : 'https://api.maxar.com';

interface AuthTokenResponse {
  apiToken: string;
  createDate: string;
  description: string;
  id: string;
  name: string;
  updateDate: string;
}

export const listAuthTokens = async (): Promise<AuthTokenResponse[]> => {
  const api = MaxarClient.getApi();
  const {data} = await api.get(`/token-service/api/v1/token`);
  return data;
};

export const removeAuthTokens = async (
  tokenIds: string[],
  previousToken: string
): Promise<AuthTokenResponse> => {
  // const api = MaxarClient.getApi();
  const {data} = await axios.delete(`${WMS_BASE_URL}/token-service/api/v1/token`, {
    data: {ids: tokenIds},
    headers: {Authorization: `${previousToken}`}
  });

  return data;
};

export const generateAuthTokens = async (): Promise<AuthTokenResponse> => {
  const api = MaxarClient.getApi();
  const {data} = await api.post(`/token-service/api/v1/token`, {
    name: 'crowsnest-maa-token',
    description: 'API token used for streaming WMS'
  });
  return data;
};
