import React from 'react';
import {
  AlertBlockHeader,
  AlertDetailCard,
  AlertDetailContentBody,
  AlertDetailContentSubHeading,
  AlertDetailDataTable,
  AlertDetailDataTableCell,
  AlertDetailDataTableCellLong,
  AlertDetailDataTableHeader,
  AlertDetailDataTableHeaderLong,
  AlertDetailLinkAnchor,
  AlertHorizontalDivider,
  AlertSectionHeader
} from '../Styled';
import {formatDate, parseDate} from 'utilities/date';
import {Divider} from '@mui/material';
import {EOAlertResponse} from 'types/Alerts';
import {group} from 'radash';
import LatencyTimeline from 'components/ContentExplorer/ContentExplorerContent/Timeline';

interface SARRFSummaryProps {
  alert: EOAlertResponse;
}

export const SARRFSummary: React.FC<SARRFSummaryProps> = ({alert}) => {
  const vesselBuckets = alert.vessels.map((v) => {
    const length = +v.properties.length;
    let bucket = '';
    if (length > 0 && length < 25) {
      bucket = 'one';
    } else if (length >= 25 && length < 50) {
      bucket = 'two';
    } else if (length >= 50 && length < 100) {
      bucket = 'three';
    } else if (length >= 100 && length < 200) {
      bucket = 'four';
    } else if (length >= 200) {
      bucket = 'five';
    }
    return {
      ...v,
      bucket
    };
  });

  const groupedByVesselBucket = group(vesselBuckets, (v) => v.bucket || '');

  const sarVesselStats = Object.keys(groupedByVesselBucket).map((key) => {
    const vesselList = groupedByVesselBucket[key] || [];
    const correlated = vesselList.filter((v) => v.properties.correlated);
    const bucketTranslation: Record<string, string> = {
      one: '0 - 24.99',
      two: '25 - 49.99',
      three: '50 - 99.99',
      four: '100 - 199.99',
      five: '200+'
    };
    return {
      bucket: bucketTranslation[key],
      total: vesselList.length,
      dark: vesselList.length - correlated.length,
      correlated: correlated.length
    };
  });

  const correlatedTotal = alert.vessels.filter((v) => v.properties.correlated);
  const totals = {
    correlated: correlatedTotal.length,
    dark: alert.vessels.length - correlatedTotal.length,
    total: alert.vessels.length
  };

  const observationDate = alert.properties.periodStopDatetime;
  const processingDate = alert.sarStates
    ?.filter((sarState) => sarState.state === 'Scene_Completed')
    .pop()?.creationDatetime;

  return (
    <AlertDetailCard>
      <AlertSectionHeader>Collection Summary</AlertSectionHeader>
      <AlertDetailContentSubHeading>
        <AlertDetailLinkAnchor
          href={alert.sarDownloadUrl === '' ? alert.sarDownloadUrl : '#'}
        >
          Download
        </AlertDetailLinkAnchor>{' '}
        zip file of vessel detect metadata
      </AlertDetailContentSubHeading>
      <AlertDetailContentBody>
        <b>Mission: </b>
        {alert.properties.mission}
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        <b>Collect Date & Time:</b>
        {` ${formatDate(alert.properties.meanDatetime, 'yyyy-MM-dd')} | ${formatDate(
          alert.properties.meanDatetime,
          'HH:mm:ss'
        )}Z`.toUpperCase()}
      </AlertDetailContentBody>
      <AlertHorizontalDivider />
      <AlertSectionHeader>Vessel Detections</AlertSectionHeader>

      <AlertDetailDataTable>
        <tbody>
          <tr>
            <AlertDetailDataTableHeaderLong>
              VESSEL LENGTH (M)
            </AlertDetailDataTableHeaderLong>
            <AlertDetailDataTableHeader>CORRELATED</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>DARK</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>TOTAL</AlertDetailDataTableHeader>
          </tr>

          {sarVesselStats.map((stat) => {
            return (
              <tr key={stat.bucket}>
                <AlertDetailDataTableCellLong>{stat.bucket}</AlertDetailDataTableCellLong>
                <AlertDetailDataTableCell>{stat.correlated}</AlertDetailDataTableCell>
                <AlertDetailDataTableCell>{stat.dark}</AlertDetailDataTableCell>
                <AlertDetailDataTableCell>{stat.total}</AlertDetailDataTableCell>
              </tr>
            );
          })}

          <tr>
            <AlertDetailDataTableHeaderLong>TOTALS:</AlertDetailDataTableHeaderLong>
            <AlertDetailDataTableHeader>{totals.correlated}</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>{totals.dark}</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>{totals.total}</AlertDetailDataTableHeader>
          </tr>
        </tbody>
      </AlertDetailDataTable>

      <AlertHorizontalDivider />
      <AlertSectionHeader>Latency Details</AlertSectionHeader>
      <LatencyTimeline
        observationDate={parseDate(observationDate)}
        processingDate={parseDate(processingDate ?? '')}
        source={alert.source}
      />

      <Divider />
      <AlertBlockHeader>MAXAR Maritime Service Desk</AlertBlockHeader>
      <AlertDetailContentBody>
        <AlertDetailLinkAnchor
          href="mailto:maritime_support@maxar.com"
          target="_blank"
          rel="noreferrer"
        >
          maritime_support@maxar.com
        </AlertDetailLinkAnchor>
      </AlertDetailContentBody>
    </AlertDetailCard>
  );
};
