import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';
import {styled} from '@mui/system';
import Blender from 'theme/fonts/BlenderFont/Blender';

/** Sizing variables */
export const customAppBarHeight = '48px';

export const contentExplorerSizingBar = '22px';

export const SideContainerController = styled('section')({
  minWidth: contentExplorerSizingBar,
  maxWidth: contentExplorerSizingBar,
  minHeight: `calc(100vh - ${customAppBarHeight})`
});

declare module '@mui/material/styles' {
  interface PaletteColor {
    label?: string;
    labelDisabled?: string;
  }

  interface SimplePaletteColorOptions {
    label?: string;
    labelDisabled?: string;
  }
}

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ['Blender', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',')
  },
  palette: {
    background: {
      /** Off black */
      default: '#212121'
    },
    primary: {
      // primaryText soft gray / off white
      main: '#E5E1DA',
      // primaryAccent yellow text color
      contrastText: '#fcaf17',
      label: 'rgba(178, 175, 170, 1)'
    },
    secondary: {
      // // /** Desaturated White */
      // secondaryText
      main: '#4C4B48',
      // /** Grey */
      // desaturatedText
      contrastText: '#7f7d79',
      dark: '#333231'
    },
    error: {
      main: red.A400
    },
    success: {
      main: '#85D460'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [...Blender]
      }
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true
      }
    },
    MuiList: {
      styleOverrides: {
        root: ({theme}) => ({
          backgroundColor: '#1a1918',
          color: theme.palette.primary.main,
          border: '1px solid #333231'
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '0px'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({theme}) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.main
        })
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: '20px',
          width: '20px'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({theme}) => ({
          dense: {
            borderLeft: 5,
            borderLeftStyle: 'solid',
            borderColor: theme.palette.primary.contrastText,
            height: 48,
            minHeight: 48
          }
        })
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({theme}) => ({
          '& label': {
            color: 'rgba(178, 175, 170, 1)'
          },
          '& label.Mui-disabled': {
            color: theme.palette.secondary.contrastText
          },
          '& label.Mui-focused': {
            color: theme.palette.primary.main
          },
          '& p': {
            color: 'rgba(178, 175, 170, 1)'
          },
          '& p.Mui-disabled': {
            color: theme.palette.secondary.contrastText
          },
          '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(178, 175, 170, 1)'
          },
          '& .MuiInput-input': {
            color: theme.palette.primary.main,
            '&:hover': {
              borderBottomColor: theme.palette.primary.main
            }
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.main
          },
          '& .MuiInputBase-root:hover': {
            '& > fieldset': {
              borderBottomColor: theme.palette.primary.main
            }
          }
        })
      }
    },
    MuiInput: {
      styleOverrides: {
        root: ({theme}) => ({
          '& label': {
            color: 'rgba(178, 175, 170, 1)'
          },
          '& label.Mui-disabled': {
            color: theme.palette.secondary.contrastText
          },
          '& label.Mui-focused': {
            color: theme.palette.primary.main
          },
          '& .MuiSvgIcon-colorError': {
            color: '#ff1744 !important'
          },
          '& p': {
            color: 'rgba(178, 175, 170, 1)'
          },
          '& p.Mui-disabled': {
            color: theme.palette.secondary.contrastText
          },
          '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(178, 175, 170, 1)'
          },
          '& .MuiInput-input': {
            color: theme.palette.primary.main,
            '&:hover': {
              borderBottomColor: theme.palette.primary.main
            }
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.main
          },
          '& .MuiInputBase-root:hover': {
            '& > fieldset': {
              borderBottomColor: theme.palette.primary.main
            }
          }
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({theme}) => ({
          color: theme.palette.secondary.contrastText
        })
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({theme}) => ({
          '& label': {
            color: theme.palette.secondary.contrastText
          },
          '& .MuiInput-underline:before': {
            borderBottomColor: theme.palette.secondary.contrastText
          },
          '& svg': {
            color: theme.palette.secondary.contrastText,
            '&.Mui-checked': {
              color: theme.palette.primary.contrastText
            }
          }
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({theme}) => ({
          color: theme.palette.primary.main,
          borderRadius: '0px !important',
          '&.MuiButton-contained': {
            color: theme.palette.background.default,
            backgroundColor: theme.palette.primary.contrastText
          }
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: () => ({
          '& .MuiMenu-list': {
            paddingTop: '0px',
            paddingBottom: '0px'
          },
          '& .MuiMenu-paper': {
            borderRadius: '0px'
          }
        })
      }
    }
  }
});

export default theme;
