import {RootState} from '../store';
import {unique} from 'radash';
import {createSelector} from '@reduxjs/toolkit';
const selectAlertIds = (state: RootState) =>
  unique(state.alerts.selectedAlertIds, (v) => v);

export const selectSelectedAlertIds = createSelector(
  selectAlertIds,
  (alertIds) => alertIds
);
export const selectAlerts = (state: RootState) => state.alerts.alerts;
export const selectFocusedVessel = (state: RootState) => state.alerts.focusedVessel;
export const selectInitialActionsComplete = (state: RootState) =>
  state.alerts.initialActionsComplete;
export const selectAlertById = (state: RootState, alertId: string) =>
  state.alerts.alertsById[alertId] || null;
export const selectAlertStateById = (state: RootState, alertId: string) =>
  state.alerts.alertStates[alertId] || null;
export const selectAlertsLoading = (state: RootState) =>
  state.alerts.status === 'loading';
export const selectAlertsFailed = (state: RootState) => state.alerts.status === 'failed';
export const selectAlertLoadError = (state: RootState) => state.alerts.error;
