import React, {useState} from 'react';
import {DndContext, closestCenter, DragEndEvent} from '@dnd-kit/core';
import {restrictToWindowEdges} from '@dnd-kit/modifiers';
import {DraggableControlContainer} from 'components/Map/Controls/components/DraggableControlContainer';
import {MensurationPanelContent} from './MensurationPanelContent';
import './MensurationControl.scss';
import StraightenSharpIcon from '@mui/icons-material/StraightenSharp';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  setMensurationManagerDocked,
  toggleMensurationEnabled
} from 'redux/mapMensuration';
import {
  selectCursorLine,
  setActive,
  setCursorLine,
  setDblClick,
  undo
} from 'redux/vesselChipMensuration';

export const MensurationControl: React.FC = () => {
  const [delta, setDelta] = useState({x: 320, y: 112});
  const cursorLine = useAppSelector(selectCursorLine);

  const handleDragEnd = (event: DragEndEvent) => {
    const x = delta.x - event.delta.x;
    const y = delta.y + event.delta.y;
    setDelta({x: x, y: y});
  };
  const dispatch = useAppDispatch();
  const onDock = () => {
    dispatch(setMensurationManagerDocked(true));
  };
  const onClose = () => {
    dispatch(toggleMensurationEnabled());
    dispatch(setActive(false));
    if (cursorLine) {
      dispatch(setCursorLine(null));
      dispatch(undo());
      dispatch(setDblClick(false));
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToWindowEdges]}
      onDragEnd={handleDragEnd}
    >
      <DraggableControlContainer
        x={delta.x}
        y={delta.y}
        width={268}
        onDock={onDock}
        onClose={onClose}
        icon={
          <StraightenSharpIcon
            fontSize="small"
            sx={{
              rotate: '-45deg',
              fontSize: '17px',
              marginLeft: '3px !important',
              marginRight: '5px',
              marginTop: '5px !important'
            }}
          />
        }
        title="Measure"
        id="measure-control"
      >
        <MensurationPanelContent />
      </DraggableControlContainer>
    </DndContext>
  );
};
