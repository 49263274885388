import {IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';

export const TimelineCollapseContainer = styled('div')({
  textAlign: 'right',
  position: 'absolute',
  top: '-2px',
  right: '5px',
  zIndex: '1'
});

export const AISTimeLineContainer = styled('div')({
  position: 'relative',
  marginTop: '0px',
  backgroundColor: '#1a1918'
});

export const AISBackButton = styled(IconButton)({
  position: 'absolute',
  top: '20px',
  zIndex: '3',
  left: '0'
});

export const AISForwardButton = styled(IconButton)({
  position: 'absolute',
  top: '50px',
  right: '0'
});

export const AISTimelineMain = styled('div')({
  width: '100%',
  height: '90px',
  paddingLeft: '5px',
  paddingRight: '5px'
});
