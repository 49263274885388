// import {
//   FormControl,
//   FormControlLabel,
//   Radio,
//   RadioGroup
// } from '@mui/material';
import React, {useState} from 'react';
import StripImage from 'components/ContentExplorer/ContentExplorerContent/AlertTabPanel/AlertStripCard/StripImage';
import {EOAlertResponse} from 'types/Alerts';
import {
  AlertStripCardCollectionCount,
  AlertStripCardContainer,
  // AlertStripCardHeader,
  AlertStripCardSubHeader
  // AlertStripCardNoApply
} from 'components/ContentExplorer/ContentExplorerContent/AlertTabPanel/AlertStripCard/Styled';

interface AlertStripCardProps {
  alert: EOAlertResponse;
}

export const AlertStripCard: React.FC<AlertStripCardProps> = ({alert}) => {
  const [imageStripsApply, setImageStripsApply] = useState('no-image');
  // const handleImageStripsNoApply = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setImageStripsApply(event.target.value);
  // };

  const handleImageStripChange = (value: string) => {
    setImageStripsApply(value);
  };

  return (
    <>
      <AlertStripCardContainer>
        {/* <AlertStripCardHeader>Select an Image to View on Map</AlertStripCardHeader> */}
        <AlertStripCardSubHeader>
          {/* <AlertStripCardNoApply>
            <FormControl>
              <RadioGroup>
                <FormControlLabel
                  checked={imageStripsApply === 'no-image'}
                  control={
                    <Radio
                      checked={imageStripsApply === 'no-image'}
                      onChange={handleImageStripsNoApply}
                      name="no-apply-imagery"
                      value={'no-image'}
                      aria-label="No Image Strips Applied"
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        '&.Mui-checked': {
                          color: theme.palette.primary.contrastText
                        }
                      })}
                    />
                  }
                  label="No Image Strips Applied"
                />
              </RadioGroup>
            </FormControl>
          </AlertStripCardNoApply> */}
          <AlertStripCardCollectionCount>{`${alert.inventoryItems.length} Total Collections for Alert`}</AlertStripCardCollectionCount>
        </AlertStripCardSubHeader>
      </AlertStripCardContainer>
      {alert.inventoryItems.map((item) => {
        const vessels = alert.vessels.filter(
          (v) => v.inventorySummary.inventoryId === item.inventoryId
        );
        return (
          <StripImage
            key={item.inventoryId}
            item={item}
            vessels={vessels}
            selected={imageStripsApply}
            onChange={handleImageStripChange}
          />
        );
      })}
    </>
  );
};
