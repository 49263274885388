import {Alert, Snackbar} from '@mui/material';
import React from 'react';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectCanvasHeight,
  selectCanvasWidth,
  selectDeviceRatio,
  selectVesselChipRotation,
  selectVesselChipRotationActive,
  selectVesselMensurationEnlarged
} from 'redux/vesselChipMensuration/selectors';
import {setImageDimensions} from 'redux/vesselChipMensuration/slice';
import {selectSelectedVessel} from 'redux/vesselHistory/selectors';

export const BackgroundCanvas: React.FC = () => {
  const backgroundCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const renderRotation = React.useRef<number>(0);
  const [systemError, setSystemError] = React.useState<null | string>(null);
  const [open, setOpen] = React.useState(false);

  const dispatch = useAppDispatch();

  const selectedVessel = useAppSelector(selectSelectedVessel);
  const selectedWidth = useAppSelector(selectCanvasWidth);
  const selectedHeight = useAppSelector(selectCanvasHeight);
  const selectedDeviceRatio = useAppSelector(selectDeviceRatio);
  const selectedMensurationEnlarged = useAppSelector(selectVesselMensurationEnlarged);
  const selectedRotation = useAppSelector(selectVesselChipRotation);
  const rotationActive = useAppSelector(selectVesselChipRotationActive);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleScalingImage = React.useCallback(
    (drawContext: CanvasRenderingContext2D, img: HTMLImageElement) => {
      if (img.complete) {
        drawContext.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          drawContext.canvas.width / selectedDeviceRatio[2],
          drawContext.canvas.height / selectedDeviceRatio[2]
        );
      } else {
        img.onload = () => {
          drawContext.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            0,
            0,
            drawContext.canvas.width / selectedDeviceRatio[2],
            drawContext.canvas.height / selectedDeviceRatio[2]
          );
        };
      }
      return img;
    },
    [selectedDeviceRatio]
  );

  const handleForwardsRotation = React.useCallback(
    (drawContext: CanvasRenderingContext2D, rotation: number) => {
      const scaling = 2 * selectedDeviceRatio[2];
      drawContext.translate(
        selectedDeviceRatio[0] / scaling,
        selectedDeviceRatio[1] / scaling
      );
      drawContext.rotate((Math.PI / 180) * rotation);
      drawContext.translate(
        -selectedDeviceRatio[0] / scaling,
        -selectedDeviceRatio[1] / scaling
      );
    },
    [selectedDeviceRatio]
  );

  const handleBackwardsRotation = React.useCallback(
    (drawContext: CanvasRenderingContext2D, rotation: number) => {
      const scaling = 2 * selectedDeviceRatio[2];
      drawContext.translate(
        selectedDeviceRatio[0] / scaling,
        selectedDeviceRatio[1] / scaling
      );
      drawContext.rotate((Math.PI / 180) * -rotation);
      drawContext.translate(
        -selectedDeviceRatio[0] / scaling,
        -selectedDeviceRatio[1] / scaling
      );
    },
    [selectedDeviceRatio]
  );

  const scaleCanvas = React.useCallback(
    (drawContext: CanvasRenderingContext2D) => {
      if (!selectedMensurationEnlarged) {
        drawContext.canvas.style.width = `${selectedWidth}px`;
        drawContext.canvas.style.height = `${selectedHeight}px`;
        drawContext.scale(selectedDeviceRatio[2], selectedDeviceRatio[2]);
      } else {
        drawContext.canvas.style.width = `${
          drawContext.canvas.width / selectedDeviceRatio[2]
        }px`;
        drawContext.canvas.style.height = `${
          drawContext.canvas.height / selectedDeviceRatio[2]
        }px`;
        drawContext.scale(selectedDeviceRatio[2], selectedDeviceRatio[2]);
      }
    },
    [selectedWidth, selectedHeight, selectedDeviceRatio, selectedMensurationEnlarged]
  );

  const backgroundRender = React.useCallback(
    (drawContext: CanvasRenderingContext2D) => {
      if (!selectedVessel) {
        return;
      }
      let chipUrl = '';
      chipUrl = selectedVessel.chipUrlJpg;

      const img = new Image();
      img.src = chipUrl;

      const dimensions = {
        height: img.naturalHeight,
        width: img.naturalWidth
      };
      dispatch(setImageDimensions(dimensions));
      handleScalingImage(drawContext, img);
    },
    [selectedVessel, dispatch, handleScalingImage]
  );

  // Get a canvas -- If fail to get canvas the console.err
  const retrieveValidCanvasContext = React.useCallback(() => {
    const canvas = backgroundCanvasRef.current;
    if (!canvas) {
      setSystemError('Failed to retrieve canvas.');
      return;
    }
    const drawContext = canvas.getContext('2d');
    if (!drawContext) {
      setSystemError('Failed to retrieve canvas 2d draw engine.');
      return;
    }

    return drawContext;
  }, []);

  React.useEffect(() => {
    // Think like a game engine while loop
    const drawContext = retrieveValidCanvasContext();
    if (!drawContext) {
      return;
    }
    // Scale the canvas first then do the rendering
    scaleCanvas(drawContext);
    // Do the render
    backgroundRender(drawContext);

    // Anytime we do a render call prevent a rotate call
    renderRotation.current = 0;
  }, [retrieveValidCanvasContext, backgroundRender, scaleCanvas]);

  React.useEffect(() => {
    // if first call to rotate then set rotation state to false
    const drawContext = retrieveValidCanvasContext();
    if (!drawContext) {
      return;
    }

    if (selectedRotation === 0) {
      return;
    }
    // Clear the canvas
    drawContext.clearRect(0, 0, drawContext.canvas.width, drawContext.canvas.height);
    // Handle circle cycles and negative angles
    const rotation = ((selectedRotation % 360) + 360) % 360;
    if (renderRotation.current !== rotation && rotationActive) {
      // Fire when user has actually rotated the canvas at least once
      renderRotation.current = rotation;
      handleForwardsRotation(drawContext, rotation);
    } else if (renderRotation.current === rotation && !rotationActive) {
      renderRotation.current = 0;
      handleBackwardsRotation(drawContext, rotation);
    }

    // Do the render
    backgroundRender(drawContext);
  }, [
    handleBackwardsRotation,
    selectedRotation,
    handleForwardsRotation,
    retrieveValidCanvasContext,
    rotationActive,
    backgroundRender,
    selectedMensurationEnlarged
  ]);
  return (
    <>
      {systemError ? (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
            {systemError}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      <canvas
        className="background-canvas"
        ref={backgroundCanvasRef}
        width={selectedDeviceRatio[0]}
        height={selectedDeviceRatio[1]}
      ></canvas>
    </>
  );
};
