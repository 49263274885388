import React from 'react';
import {RLayerVector, useOL} from 'rlayers';
import {useAppSelector} from 'redux/hooks';
import {selectWatchboxes} from 'redux/filters';
import {WatchboxMarker} from './WatchboxMarker';
import {WatchboxPolygon} from './WatchboxPolygon';
import {WatchboxMultiPolygon} from './WatchboxMultiPolygon';
import {selectMapView} from 'redux/map';
import {MAX_WATCHBOX_ZO0M} from 'constants/constants';
import {selectWatchboxesEnabled} from 'redux/layers';

export const Watchbox: React.FC = () => {
  const watchboxes = useAppSelector(selectWatchboxes);
  const currentZoom = useAppSelector(selectMapView).zoom;
  const watchboxesEnabled = useAppSelector(selectWatchboxesEnabled);
  const {map} = useOL();
  React.useEffect(() => {
    map.on('pointermove', (evt) => {
      if (!evt.dragging) {
        const features = map.getFeaturesAtPixel(map.getEventPixel(evt.originalEvent), {
          hitTolerance: 3
        });
        const isWatchbox = features.find((feature) => {
          const {FeatureType} = feature.getProperties();
          return FeatureType === 'WatchboxPolygon';
        });

        map.getViewport().style.cursor = isWatchbox ? 'pointer' : '';
      }
    });
    // eslint-disable-next-line
  }, []);
  const renderWatchboxMarkers = () => {
    if (currentZoom >= MAX_WATCHBOX_ZO0M) return null;
    return watchboxes.map((wb) => {
      return <WatchboxMarker key={wb.key} watchbox={wb} />;
    });
  };

  const renderWatchboxPolygons = () => {
    if (currentZoom < MAX_WATCHBOX_ZO0M) return null;
    return watchboxes.map((wb) => {
      return <WatchboxPolygon key={wb.key} watchbox={wb} />;
    });
  };

  const renderWatchboxMultiPolygons = () => {
    if (currentZoom < MAX_WATCHBOX_ZO0M) return null;
    return watchboxes.map((wb) => {
      return <WatchboxMultiPolygon key={wb.key} watchbox={wb} />;
    });
  };

  if (!watchboxesEnabled) return null;
  return (
    <RLayerVector zIndex={10} wrapX={false}>
      {renderWatchboxMarkers()}
      {renderWatchboxPolygons()}
      {renderWatchboxMultiPolygons()}
    </RLayerVector>
  );
};
