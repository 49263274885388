import {RootState} from '../store';
import {format} from 'date-fns';
import {Measurements} from '../../types/Filters';

export const selectFilters = (state: RootState) => state.filters;
export const selectFilterInputs = (state: RootState) => state.filters.filters;
export const selectSerializedFilters = (state: RootState) => {
  return {
    ...state.filters,
    selectedTimeFrame: {
      start: format(state.filters.selectedTimeFrame.start, 'yyyy-MM-dd'),
      end: format(state.filters.selectedTimeFrame.end, 'yyyy-MM-dd')
    }
  };
};
export const selectSelectedTimeFrame = (state: RootState) =>
  state.filters.selectedTimeFrame;
export const selectFiltersEnabled = (state: RootState) => state.filters.filtersEnabled;
export const selectFiltersDisabled = (state: RootState) => !state.filters.filtersEnabled;
export const selectSources = (state: RootState) => state.filters.filters.sources;
export const selectMissions = (state: RootState) => state.filters.filters.missions;
export const selectWatchboxes = (state: RootState) => state.filters.filters.watchboxes;
export const selectWatchboxesLoading = (state: RootState) =>
  state.filters.loadingWatchboxes;
export const selectAlertIds = (state: RootState) => state.filters.filters.alertIds;
export const selectDetectionTypes = (state: RootState) =>
  state.filters.filters.detectionTypes;
export const selectMmsis = (state: RootState) => state.filters.filters.mmsis;
export const selectFlags = (state: RootState) => state.filters.filters.flags;
export const selectCategories = (state: RootState) => state.filters.filters.categories;
export const selectMeasurements = (state: RootState) =>
  state.filters.filters.measurements;
export const selectMeasurementBlock = (state: RootState, idx: string) =>
  state.filters.filters.measurements[idx];
export const selectGeoFiltering = (state: RootState) =>
  state.filters.filters.isGeoFiltering;
export const selectDrawingClear = (state: RootState) => state.filters.filters.geoClear;
export const selectDrawnWatchbox = (state: RootState) =>
  state.filters.filters.drawnWatchbox;

export const selectPanelsActive = (state: RootState) => {
  const activeFilters: any = selectActiveFilters(state);
  let isActive = false;
  Object.keys(activeFilters).forEach((key) => {
    if (isActive) return;
    const value = activeFilters[key];
    if (value) {
      isActive = true;
    }
  });
  return isActive;
};

export const selectActiveFilters = (state: RootState) => {
  return {
    mmsis: state.filters.filters.mmsis.length > 0,
    alertIds: state.filters.filters.alertIds.length > 0,
    flags: state.filters.filters.flags.length > 0,
    measurements: getMeasurementsActive(state.filters.filters.measurements),
    detectionTypes:
      state.filters.filters.detectionTypes.correlated ||
      state.filters.filters.detectionTypes.dark,
    sources: state.filters.filters.sources.filter((obj) => obj.value === true).length,
    categories: state.filters.filters.categories.filter((obj) => obj.value === true)
      .length,
    missions: state.filters.filters.missions.filter((obj) => obj.value === true).length
  };
};

const getMeasurementsActive = (measurements: Measurements) => {
  let hasValue = false;
  Object.keys(measurements).forEach((key) => {
    if (hasValue) return;
    const value = measurements[key];
    hasValue =
      value.speed.from !== '' ||
      value.speed.to !== '' ||
      value.vesselLength.from !== '' ||
      value.vesselLength.to !== '' ||
      value.vesselWidth.from !== '' ||
      value.vesselWidth.to !== '';
  });
  return hasValue;
};
