import React from 'react';
import {ContentExplorerHeader} from 'components/ContentExplorer/ContentExplorerHeader';
import {ContentExplorerContent} from 'components/ContentExplorer/ContentExplorerContent';
import {useAppSelector} from 'redux/hooks';
import {selectSelectedVessel} from 'redux/vesselHistory';
import './ContentExplorer.scss';
import {customAppBarHeight} from 'theme/theme';
import {styled} from '@mui/material';
import VesselHistory from 'components/VesselHistory';

/** Custom Layout Components */
export const SideContainer = styled('section')({
  minWidth: '575px',
  height: `calc(100vh - ${customAppBarHeight})`,
  display: 'flex',
  flexDirection: 'column'
});

export const ContentExplorer: React.FC = () => {
  const selectedVessel = useAppSelector(selectSelectedVessel);

  return (
    <SideContainer>
      {!selectedVessel ? (
        <>
          {/*TODO: re-enable when "My Vessel Records" feature is defined
            <HeaderTabs />
          */}
          <ContentExplorerHeader />
          <ContentExplorerContent />
        </>
      ) : (
        <VesselHistory />
      )}
    </SideContainer>
  );
};
