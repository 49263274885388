import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'redux/store';
import {getVesselHistoryFromES} from 'api/alerts';
import {Vessel} from 'types/Alerts';
import {selectSelectedVessel} from './selectors';
import {buildMMSIQuery} from 'queries/AlertQueries';

export interface VesselHistoryState {
  selectedVessel: Vessel | null;
  history: Vessel[];
  status: 'idle' | 'loading' | 'failed';
  focusedTab: 'history' | 'similar';
}

const initialState: VesselHistoryState = {
  selectedVessel: null,
  history: [],
  status: 'loading',
  focusedTab: 'history'
};

export const loadVesselHistory = createAsyncThunk(
  'alerts/fetchVesselHistory',
  async (_, {getState}) => {
    const selectedVessel = selectSelectedVessel(getState() as RootState);
    if (!selectedVessel) return [];
    const vesselHistoryQuery = buildMMSIQuery(
      selectedVessel.properties.mmsi,
      selectedVessel.vesselId
    );
    return await getVesselHistoryFromES(vesselHistoryQuery);
  }
);

export const vesselHistorySlice = createSlice({
  name: 'vesselHistory',
  initialState,
  reducers: {
    setSelectedVessel: (state, action: PayloadAction<Vessel | null>) => {
      state.selectedVessel = action.payload;
    },
    setVesselHistory: (state, action: PayloadAction<Vessel[]>) => {
      state.history = action.payload;
    },
    setFocusedTab: (state, action: PayloadAction<'history' | 'similar'>) => {
      state.focusedTab = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadVesselHistory.pending, (state) => {
        state.status = 'loading';
        state.history = [];
      })
      .addCase(loadVesselHistory.fulfilled, (state, action) => {
        state.history = action.payload;
        state.status = 'idle';
      })
      .addCase(loadVesselHistory.rejected, (state) => {
        // Ignore cancelled requests
        state.status = 'failed';
        state.history = [];
      });
  }
});

export const {setVesselHistory, setSelectedVessel, setFocusedTab} =
  vesselHistorySlice.actions;

export default vesselHistorySlice.reducer;
