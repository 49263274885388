import {Coordinate} from 'ol/coordinate';
import {transform} from 'ol/proj';
import {Map as OlMap} from 'ol';
import {Extent} from 'ol/extent';
import {Vessel} from '../types/Alerts';
import {
  Dark_Tipped_Detect_KnownH,
  Dark_Tipped_Detect_NoH,
  Dark_Tipped_Detect_SemiH,
  Dark_TipPoint_KnownH,
  Dark_TipPoint_NoH,
  Dark_TipPoint_SemiH,
  Dark_Untipped_Detect_KnownH,
  Dark_Untipped_Detect_NoH,
  Dark_Untipped_Detect_SemiH,
  Lit_Tipped_Detect_KnownH,
  Lit_Tipped_Detect_NoH,
  Lit_Tipped_Detect_SemiH,
  Lit_TipPoint_KnownH,
  Lit_TipPoint_NoH,
  Lit_TipPoint_SemiH,
  Lit_Untipped_Detect_KnownH,
  Lit_Untipped_Detect_NoH,
  Lit_Untipped_Detect_SemiH
} from '../styles/alert_icons';
import {asArray, asString} from 'ol/color';
import {Geometry} from 'ol/geom';
import {getArea, getLength} from 'ol/sphere';
import * as turf from '@turf/turf';

export function getPolygonLabelCoordinates(coordinates: turf.helpers.Position[][]) {
  const poly = turf.polygon(coordinates);
  const bbox = turf.bbox(poly);
  const bboxPolygon = turf.bboxPolygon(bbox);
  const point1 = turf.point(
    bboxPolygon.geometry.coordinates[0][0],
    bboxPolygon.geometry.coordinates[0][1]
  );
  const point2 = turf.point(
    bboxPolygon.geometry.coordinates[0][1],
    bboxPolygon.geometry.coordinates[0][2]
  );
  const midpoint = turf.midpoint(point1, point2);
  return midpoint.geometry.coordinates;
}

export function transformSinglePolygonCoord(a: Coordinate) {
  return transform(a, 'EPSG:3857', 'EPSG:4326');
}

export function transformInversePolygonCoords(a: Coordinate[][]) {
  return a.map(function (aa) {
    return aa.map(function (coords) {
      return transform(coords, 'EPSG:3857', 'EPSG:4326');
    });
  });
}

export function transformPolygonCoords(a: Coordinate[][]) {
  return a.map(function (aa) {
    return aa.map(function (coords) {
      return transform(coords, 'EPSG:4326', 'EPSG:3857');
    });
  });
}

export function transformMultiPolygonCoords(a: Coordinate[][][]) {
  return a.map(function (aa) {
    return aa.map(function (aaa) {
      return aaa.map(function (coords) {
        return transform(coords, 'EPSG:4326', 'EPSG:3857');
      });
    });
  });
}

export function calculateScale(resolution: number) {
  const scale = 2 / Math.pow(resolution, 1 / 6);
  return scale < 1 ? 1 : scale;
}

export function zoomToExtent(
  map: OlMap,
  extent: Extent,
  zoom?: number,
  callback?: () => void
) {
  map.getView().fit(extent, {
    duration: 1000,
    maxZoom: zoom,
    nearest: true,
    padding: [10, 10, 10, 10],
    callback
  });
}

export function displayCoords(coord: Coordinate) {
  const coordinate = transform(coord, 'EPSG:3857', 'EPSG:4326');
  return `${coordinate[1].toFixed(6)}, ${coordinate[0].toFixed(6)}`;
}

export function getVesselPointIcon(vessel: Vessel) {
  const isDark = vessel.properties.dark;
  const isTipped = vessel.properties.tipped;
  const course = vessel.properties.course;
  const heading = vessel.properties.heading;
  const rotation = heading || course || 0;
  const containsHeadingAmbiguity = Boolean(vessel.properties.containsHeadingAmbiguity);
  if (isTipped) {
    if (rotation === 0) {
      return isDark ? Dark_Tipped_Detect_NoH : Lit_Tipped_Detect_NoH;
    }
    if (containsHeadingAmbiguity) {
      return isDark ? Dark_Tipped_Detect_SemiH : Lit_Tipped_Detect_SemiH;
    } else {
      return isDark ? Dark_Tipped_Detect_KnownH : Lit_Tipped_Detect_KnownH;
    }
  } else {
    if (rotation === 0) {
      return isDark ? Dark_Untipped_Detect_NoH : Lit_Untipped_Detect_NoH;
    }
    if (containsHeadingAmbiguity) {
      return isDark ? Dark_Untipped_Detect_SemiH : Lit_Untipped_Detect_SemiH;
    } else {
      return isDark ? Dark_Untipped_Detect_KnownH : Lit_Untipped_Detect_KnownH;
    }
  }
}

export function getTipIcon(
  isDark: boolean,
  containsHeadingAmbiguity: boolean,
  heading: number
) {
  if (heading === 0) {
    return isDark ? Dark_TipPoint_NoH : Lit_TipPoint_NoH;
  }
  if (containsHeadingAmbiguity) {
    return isDark ? Dark_TipPoint_SemiH : Lit_TipPoint_SemiH;
  } else {
    return isDark ? Dark_TipPoint_KnownH : Lit_TipPoint_KnownH;
  }
}

export function getRotation(heading: number) {
  return (heading * Math.PI) / 180;
}

export function colorWithAlpha(color: string, alpha: number) {
  const [r, g, b] = Array.from(asArray(color));
  return asString([r, g, b, alpha]);
}

export function numberWithCommas(x: number) {
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatLength = function (line: Geometry) {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = numberWithCommas(Math.round((length / 1000) * 100) / 100) + ' km';
  } else {
    output = numberWithCommas(Math.round(length * 100) / 100) + ' m';
  }
  return output;
};

export const formatArea = function (polygon: Geometry) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = numberWithCommas(Math.round((area / 1000000) * 100) / 100) + ' km\xB2';
  } else {
    output = numberWithCommas(Math.round(area * 100) / 100) + ' m\xB2';
  }
  return output;
};

export function degreesToCardinal(degrees: number): string {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];
  degrees = (degrees + 360) % 360;
  const index = Math.floor((degrees + 22.5) / 45);
  return directions[index];
}
