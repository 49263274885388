import {Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

export const TimelineDate = styled(Typography)(({theme}) => ({
  color: theme.palette.secondary.light
}));

export const TotalDetectionContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: '1em'
});
