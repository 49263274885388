import {createSelector} from '@reduxjs/toolkit';
import {EOAlertResponse} from 'types/Alerts';
import {RootState} from '../store';

export const getAccessHistory = (state: RootState) => state.accessHistory.accessHistory;
export const getAlerts = (state: RootState) => state.alerts.alerts;

export const getUpdatedAlerts = createSelector(
  [getAccessHistory, getAlerts],
  (accessHistory, alerts) => {
    const updatedAlerts: EOAlertResponse[] = [];

    accessHistory.forEach((historyItem) => {
      const foundAlert = alerts.find(
        (alertItem) => alertItem.id === historyItem.alertIdViewed
      );

      if (foundAlert && foundAlert.counts.total > historyItem.vesselCount) {
        updatedAlerts.push(foundAlert);
      }
    });

    return updatedAlerts;
  }
);
