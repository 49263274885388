import React, {useContext} from 'react';
import {parseDate} from 'utilities/date';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography
} from '@mui/material';
import LatencyTimeline from 'components/ContentExplorer/ContentExplorerContent/Timeline';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {LatencyControlContext} from './LatencyContext';
import type {
  AlertProperty,
  CollectionSources,
  ImageryMethod,
  InventoryItem,
  SearchArea,
  Tip
} from 'types/Alerts';
import {point, polygon, centroid, distance, Feature, Point, Properties} from '@turf/turf';

export const LatencyLink: React.FC<{targetId: string; children: React.ReactNode}> = ({
  targetId,
  children
}) => {
  const context = React.useContext(LatencyControlContext);

  if (!context) {
    throw new Error('Missing context for ControlButton');
  }

  const {accordionRefs, setExpanded} = context;

  const handleClick = () => {
    accordionRefs.current[targetId]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
    setExpanded(targetId);
  };

  return (
    <Link
      sx={(theme) => ({color: theme.palette.primary.contrastText})}
      component="button"
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

type AccordionListProps = {
  inventoryItems: InventoryItem[];
  tips: Tip[];
  vesselSearchAreas: SearchArea[];
  source: CollectionSources;
  properties: AlertProperty;
};

type EOLatencyDetail = {
  id: string;
  observationDate: Date;
  captureDate?: Date | null;
  processingDate: Date | null;
  source: CollectionSources;
  imageryMethod: ImageryMethod;
};

export function LatencyAccordionList({
  inventoryItems,
  tips,
  vesselSearchAreas,
  properties,
  source
}: AccordionListProps) {
  const context = useContext(LatencyControlContext);

  if (!context) {
    throw new Error('Missing context in AccordionList');
  }
  const {accordionRefs, setExpanded, expanded} = context;

  const handleChange = (panel: string) => (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const latencyDetails: Array<EOLatencyDetail | null> = inventoryItems.map((item) => {
    const imageryMethod: ImageryMethod = tips.length > 0 ? 'tipped' : 'watchbox';

    if (imageryMethod === 'tipped') {
      const searchAreas = tippedSearchAreas(vesselSearchAreas, [item]);

      const filteredTip = tips.find((tip) => {
        return searchAreas.find((area) => area.id === tip.tipIdentifier);
      });

      const startDate: string | null =
        filteredTip?.observation?.observationDatetime ?? null;

      if (!startDate) {
        return null;
      }
      return {
        id: item.inventoryId,
        source: source,
        imageryMethod,
        captureDate: parseDate(item.stripDatetime),
        observationDate: parseDate(startDate),
        processingDate: parseDate(item.vesselDetectCompletionDatetime)
      };
    } else {
      const startDate = properties.periodStartDatetime;

      return {
        id: item.inventoryId,
        source: source,
        imageryMethod,
        observationDate: parseDate(startDate),
        processingDate: parseDate(item.vesselDetectCompletionDatetime)
      };
    }
  });

  return (
    <React.Fragment>
      {latencyDetails.map((item) => {
        if (!item) {
          return null;
        }

        return (
          <Accordion
            key={item.id}
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
            ref={(el) => (accordionRefs.current[item.id] = el)}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.background.default
            })}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore sx={(theme) => ({color: theme.palette.primary.main})} />
              }
            >
              <Typography>
                <strong>IMAGE STRIP ID:</strong> {item.id}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <LatencyTimeline
                key={item.id}
                source={item.source}
                captureDate={item.captureDate}
                processingDate={item.processingDate}
                observationDate={item.observationDate}
                imageryMethod={item.imageryMethod}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
}

type AreaCentroid = {
  id: string;
  centroid: Feature<Point, Properties>;
};

function tippedSearchAreas(
  vesselSearchAreas: SearchArea[],
  inventoryItems: InventoryItem[]
): AreaCentroid[] {
  const searchAreasCentroids: AreaCentroid[] = vesselSearchAreas.map((searchItem) => {
    const searchPolygon = polygon(searchItem.geometry.coordinates);
    const searchCentroid = centroid(searchPolygon);

    return {centroid: searchCentroid, id: searchItem.drivingTipIdentifier};
  });

  const imageStripCentriods: AreaCentroid[] = inventoryItems.map((item) => {
    const stripPolygon = polygon(item.stripGeometry.coordinates);
    const stripCentroid = centroid(stripPolygon);

    return {centroid: stripCentroid, id: item.inventoryId};
  });

  return findNearestDistance(imageStripCentriods, searchAreasCentroids);
}

function findNearestDistance(listA: AreaCentroid[], listB: AreaCentroid[]) {
  return listA.map((a) => {
    const pointA = point(a.centroid.geometry.coordinates);
    let closestPoint = listB[0];
    let shortestDistance = distance(
      pointA,
      point(listB[0].centroid.geometry.coordinates)
    );

    listB.forEach((b) => {
      const pointB = point(b.centroid.geometry.coordinates);
      const dist = distance(pointA, pointB);

      if (dist < shortestDistance) {
        shortestDistance = dist;
        closestPoint = b;
      }
    });
    return closestPoint;
  });
}
