import BlenderMedium from './blender-medium.woff2';
import BlenderBold from './blender-bold.woff2';
import BlenderBook from './blender-book.woff2';
import BlenderHeavy from './blender-heavy.woff2';
import BlenderThin from './blender-thin.woff2';

const blender = {
  fontFamily: 'Blender',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
    local('BlenderMedium'),
    url(${BlenderMedium}) format('woff2')
  `
};

const blenderBold = {
  fontFamily: 'Blender',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
        local('BlenderBold),
        url(${BlenderBold}) format('woff2')
    `
};

const blenderBook = {
  fontFamily: 'Blender',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
        local('BlenderBook),
        url(${BlenderBook}) format('woff2')
    `
};
const blenderHeavy = {
  fontFamily: 'Blender',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
        local('BlenderHeavy),
        url(${BlenderHeavy}) format('woff2')
    `
};
const blenderThin = {
  fontFamily: 'Blender',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
        local('BlenderThin),
        url(${BlenderThin}) format('woff2')
    `
};
// only the first one matters for the baseline override.
export default [blender, blenderBold, blenderBook, blenderHeavy, blenderThin];
