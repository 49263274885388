import {Point} from 'ol/geom';
import {fromLonLat} from 'ol/proj';
import {Icon, Style} from 'ol/style';
import {calculateScale, getRotation} from 'utilities/map';
import {Dark_Tipped_Detect_KnownH, Lit_Tipped_Detect_KnownH} from 'styles/alert_icons';
import {RFeature} from 'rlayers';
import React from 'react';
import {OilSpillSource} from 'types/Alerts';

interface SourceVesselFeatureProps {
  vessel: OilSpillSource;
}

export const SourceVesselFeature: React.FC<SourceVesselFeatureProps> = ({vessel}) => {
  if (!vessel.geometry) return null;
  return (
    <RFeature
      geometry={new Point(fromLonLat(vessel.geometry.coordinates))}
      style={(item, resolution) => {
        const icon = new Icon({
          rotation: vessel?.correlationMetadata
            ? getRotation(
                vessel?.correlationMetadata.course ||
                  vessel?.correlationMetadata.headingDeg ||
                  0
              )
            : 0,
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          rotateWithView: true,
          src: vessel.isCorrelated ? Lit_Tipped_Detect_KnownH : Dark_Tipped_Detect_KnownH
        });
        if (resolution) {
          icon.setScale(calculateScale(resolution));
        }
        return new Style({
          image: icon
        });
      }}
    />
  );
};
