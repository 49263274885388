// import {ArrowForwardSharp} from '@mui/icons-material';
import {Box, styled} from '@mui/material';
import React from 'react';
import {WatchboxArea} from 'types/Alerts';

export const PopupCardBase = styled(Box)(() => ({
  backgroundColor: 'rgba(60, 59, 57, 1)',
  height: '100%',
  width: 'auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

export const PopupCardTitle = styled(Box)(() => ({
  // Needs font family IBM-Plex
  padding: '0px 10px 0px 10px'
}));

export const PopupCardWatchboxName = styled(Box)(() => ({
  // Needs font family IBM-plex
  padding: '0px 10px 0px 10px',
  width: 'auto',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textAlign: 'left'
}));

export const MissionName = styled(Box)(() => ({
  padding: '0px 10px 0px 10px',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '22px',
  letterSpacing: '0.5px',
  textAlign: 'left',
  height: '22px',
  textOverflow: 'ellipsis',
  color: 'rgba(178, 175, 170, 1)'
}));

interface PopupLabelFeatures {
  watchbox: WatchboxArea;
}

export const PopupLabel: React.FC<PopupLabelFeatures> = ({watchbox}) => {
  return (
    <PopupCardBase sx={(theme) => ({color: theme.palette.primary.main})}>
      <PopupCardTitle>Watchbox</PopupCardTitle>
      <Box>
        <PopupCardWatchboxName>{watchbox.name}</PopupCardWatchboxName>
        <MissionName>{watchbox.mission}</MissionName>
        {/*<ArrowForwardSharp />*/}
      </Box>
    </PopupCardBase>
  );
};
