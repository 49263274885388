import {RootState} from '../store';
import {addHours, subHours} from 'date-fns';

export const selectVesselTrack = (state: RootState) => state.ais.track;
export const selectAisDatePeriod = (state: RootState) => state.ais.aisPeriod;
export const selectAisDateRange = (state: RootState) => {
  if (!state.vesselHistory.selectedVessel) {
    return {
      start: new Date(),
      end: new Date()
    };
  }
  return {
    start: subHours(
      new Date(state.vesselHistory.selectedVessel.properties.meanDatetime),
      12 * Number(state.ais.aisPeriod)
    ),
    end: addHours(
      new Date(state.vesselHistory.selectedVessel.properties.meanDatetime),
      12 * Number(state.ais.aisPeriod)
    )
  };
};
export const selectAisLoading = (state: RootState) => state.ais.status === 'loading';
