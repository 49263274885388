import React from 'react';
import {Box, Button, Divider, IconButton, styled} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  LengthFilterPanel,
  SpeedFilterPanel,
  WidthFilterPanel
} from 'components/ContentExplorer/ContentExplorerHeader/filterpanels';
import {Spacer} from 'components/Styled/UtilityComponents';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectMeasurements, setMeasurements} from 'redux/filters';
import {defaultMeasureValue} from 'redux/filters/defaults';
import {MeasurementBlock} from 'types/Filters';

export const OrRule = styled(Box)(() => ({
  height: '55px',
  paddingLeft: '20px',
  paddingRight: '24px',
  display: 'flex',
  paddingTop: '10px'
}));

const MeasurementFilterPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const measurements = useAppSelector(selectMeasurements);
  const [triggerClear, setTriggerClear] = React.useState(false);

  const addMeasurementBlock = () => {
    const keys = Object.keys(measurements);
    const newKey = +keys[keys.length - 1] + 1;
    const record = {...measurements};
    record[`${newKey}`] = {
      ...defaultMeasureValue
    };
    dispatch(setMeasurements(record));
  };

  const removeMeasurementBlock = (idx: string) => {
    const record = {...measurements};
    const keys = Object.keys(record);
    setTriggerClear(!triggerClear);
    // If there is a single record and its key is 0 reset 0 key to blank
    if (keys.length === 1 && keys[0] === '0') {
      record['0'] = {
        ...defaultMeasureValue
      };
    }

    // If there are two records remove the 0 key and set to blank remove the old record
    if (keys.length === 2) {
      if (idx === keys[0]) {
        const value = record[keys[1]];
        delete record[keys[0]];
        record['0'] = value;
        delete record[keys[1]];
      } else {
        delete record[keys[1]];
      }
    }

    // If there are multiple records just remove the key
    if (keys.length > 2) {
      delete record[idx];
    }
    dispatch(setMeasurements(record));
  };

  const setMeasurementValue = (value: MeasurementBlock, idx: string) => {
    const v = {...measurements};
    v[idx] = value;
    dispatch(setMeasurements(v));
  };

  return (
    <>
      {Object.entries(measurements).map((entry) => {
        return (
          <Box
            key={entry[0]}
            sx={{
              backgroundColor: 'rgba(42, 41, 40, 0.7)',
              width: '358px',
              marginLeft: '20px',
              paddingBottom: '10px',
              marginTop: entry[0] !== '0' ? '5px' : '0px'
            }}
          >
            <Box sx={{display: 'flex', paddingTop: '5px', paddingRight: '5px'}}>
              <Spacer />
              <IconButton
                aria-label={`close-measurement-${entry[0]}`}
                onClick={() => removeMeasurementBlock(entry[0])}
              >
                <CloseIcon sx={{color: 'rgba(163, 163, 163, 1)'}} />
              </IconButton>
            </Box>
            <LengthFilterPanel
              idx={entry[0]}
              triggerClear={triggerClear}
              setMeasurementValue={setMeasurementValue}
            />
            <WidthFilterPanel
              idx={entry[0]}
              triggerClear={triggerClear}
              setMeasurementValue={setMeasurementValue}
            />
            <SpeedFilterPanel
              idx={entry[0]}
              triggerClear={triggerClear}
              setMeasurementValue={setMeasurementValue}
            />
          </Box>
        );
      })}

      <OrRule>
        <Box sx={{width: '100%', marginBottom: '16px'}}>
          <Divider
            sx={(theme) => ({
              '&::before, &::after': {
                borderColor: theme.palette.secondary.contrastText
              }
            })}
          >
            <Button size="small" variant="outlined" onClick={addMeasurementBlock}>
              OR
            </Button>
          </Divider>
        </Box>
      </OrRule>
    </>
  );
};

export default MeasurementFilterPanel;
