import React from 'react';
import {Alert, AlertTitle, Box, Typography, styled} from '@mui/material';
import {SerializedError} from '@reduxjs/toolkit/dist/createAsyncThunk';
import {AlertDetailLinkAnchor} from './ContentExplorerContent/AlertTabPanel/AlertDetailsCard/Styled';
import {ErrorOutline} from '@mui/icons-material';
import {DOCKYARD_ERROR_ICON} from 'styles/error_icons';

export const ErrorDockyardIcon = styled('img')({
  width: 500
});

type RequestErrorOptions = {
  error: SerializedError | null;
};

export const ApiRequestError: React.FC<RequestErrorOptions> = ({error}) => {
  const [errorVal, setErrorVal] = React.useState<SerializedError>({} as SerializedError);
  React.useEffect(() => {
    if (error) {
      setErrorVal(error);
    }
  }, [error]);
  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          paddingLeft: '90px',
          paddingRight: '90px',
          paddingBottom: '10px',
          paddingTop: '10px'
        }}
      >
        <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
          <ErrorOutline sx={(theme) => ({color: theme.palette.error.main})} />
          <Typography sx={{marginBottom: '5px', marginLeft: '5px'}} color="primary">
            System Error
          </Typography>
        </Box>

        <Typography color="primary">
          The system was unable to load collection data after multiple failed attempts.
        </Typography>
        <Typography color="primary">
          {'Please try refreshing your browser or contacting our 24/7 support team.'}
        </Typography>
        <Typography color="primary" sx={{marginTop: 5}}>
          {'Technical Support Contact Phone: 1-800-496-1225, then select option 3'}
        </Typography>
        <Typography color="primary">
          Email:{' '}
          <AlertDetailLinkAnchor
            href="mailto:maritime_support@maxar.com"
            target="_blank"
            rel="noreferrer"
          >
            maritime_support@maxar.com
          </AlertDetailLinkAnchor>
        </Typography>
        <Alert
          variant="outlined"
          severity="error"
          icon={false}
          sx={(theme) => ({marginTop: 5, color: theme.palette.primary.main})}
        >
          <AlertTitle>
            {errorVal.code} - {errorVal.name}
          </AlertTitle>

          <Typography>{errorVal.message}</Typography>
          <Typography>{errorVal.stack}</Typography>
        </Alert>
        <Typography variant="caption" color="primary">
          *Reference details for support team
        </Typography>
      </Box>
      <ErrorDockyardIcon src={DOCKYARD_ERROR_ICON} />
    </>
  );
};
