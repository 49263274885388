import {RootState} from '../store';

export const selectMapView = (state: RootState) => state.map.view;
export const selectMapExtent = (state: RootState) => state.map.extent;
export const selectMapCenter = (state: RootState) => state.map.center;
export const selectLayerManagerOpen = (state: RootState) => state.map.layerManager.open;
export const selectLayerManagerDocked = (state: RootState) =>
  state.map.layerManager.docked;
export const selectMapMode = (state: RootState) => state.map.mapMode;
export const selectDrawingTool = (state: RootState) => state.map.isDrawing;
export const selectIsMapModeLight = (state: RootState) => state.map.mapMode === 'light';
export const selectMaxarApiToken = (state: RootState) => state.map.maxarApiToken;
