import {unique, alphabetical} from 'radash';
import {points, center} from '@turf/turf';
import {Buffer} from 'buffer';
import ProxyClient, {MaxarClient} from '../api';
import axios from 'axios';
import {
  EOAlertResponse,
  VesselSource,
  AlertSource,
  Vessel,
  WatchboxArea,
  WatchboxResponse,
  InventoryItem
} from 'types/Alerts';
import {Mission} from 'types/Filters';
import {AxiosRequestConfig} from 'axios/index';
import polylabel from 'polylabel';
import {nanoid} from 'nanoid';
const ALERT_DATA_INDEX = 'alert_data_v5';
const VESSEL_DATA_INDEX = 'alert_data_vessels_v5';
const INVENTORY_ITEMS_DATA_INDEX = 'alert_inventory_items_v5';
const REQUEST_REGISTRY: Record<string, AbortController> = {};

export const fetchAlerts = async (
  query: unknown,
  tag?: string
): Promise<EOAlertResponse[]> => {
  const api = ProxyClient.getApi();
  const requestOptions: Omit<AxiosRequestConfig, 'headers'> = {};
  if (tag) {
    if (REQUEST_REGISTRY[tag]) {
      REQUEST_REGISTRY[tag].abort();
      delete REQUEST_REGISTRY[tag];
    }
    const controller = new AbortController();
    requestOptions.signal = controller.signal;
    REQUEST_REGISTRY[tag] = controller;
  }

  const {data} = await api.post(
    `/es/_search?elasticIndex=${ALERT_DATA_INDEX}`,
    query,
    requestOptions
  );
  if (tag) {
    delete REQUEST_REGISTRY[tag];
  }
  return data.hits.hits.map((hit: AlertSource) => {
    return {
      ...hit._source,
      vessels: [],
      filteredVessels: []
    };
  });
};

export const fetchVessels = async (query: unknown, tag?: string): Promise<Vessel[]> => {
  if (!query) return [];
  const api = ProxyClient.getApi();
  const requestOptions: Omit<AxiosRequestConfig, 'headers'> = {};
  if (tag) {
    if (REQUEST_REGISTRY[tag]) {
      REQUEST_REGISTRY[tag].abort();
      delete REQUEST_REGISTRY[tag];
    }
    const controller = new AbortController();
    requestOptions.signal = controller.signal;
    REQUEST_REGISTRY[tag] = controller;
  }
  const {data} = await api.post(
    `/es/_search?elasticIndex=${VESSEL_DATA_INDEX}`,
    query,
    requestOptions
  );
  if (tag) {
    delete REQUEST_REGISTRY[tag];
  }
  return data.hits.hits.map((hit: VesselSource) => {
    return hit._source;
  });
};

export const fetchInventory = async (
  alertId: string,
  tag?: string
): Promise<InventoryItem[]> => {
  const api = ProxyClient.getApi();
  const requestOptions: Omit<AxiosRequestConfig, 'headers'> = {};
  if (tag) {
    if (REQUEST_REGISTRY[tag]) {
      REQUEST_REGISTRY[tag].abort();
      delete REQUEST_REGISTRY[tag];
    }
    const controller = new AbortController();
    requestOptions.signal = controller.signal;
    REQUEST_REGISTRY[tag] = controller;
  }

  const query = {
    size: 10000,
    query: {
      bool: {
        filter: [
          {
            bool: {
              should: {
                term: {_id: alertId}
              }
            }
          }
        ]
      }
    }
  };

  const {data} = await api.post(
    `/es/_search?elasticIndex=${INVENTORY_ITEMS_DATA_INDEX}`,
    query,
    requestOptions
  );
  if (tag) {
    delete REQUEST_REGISTRY[tag];
  }
  return data.hits.hits[0]?._source.inventoryItems || [];
};

export const getVesselHistoryFromES = async (query: unknown): Promise<Vessel[]> => {
  if (!query) return [];
  const data = await fetchVessels(query);
  const uniqueVessels = unique(data, (item) => item.vesselId);
  return unique(uniqueVessels, (item) => item.alertId);
};

export const getMissionList = async (): Promise<Mission[]> => {
  const api = ProxyClient.getApi();
  const {data} = await api.get('/get-customers-and-missions');
  const result: string[] = [];
  Object.keys(data).map((key) => {
    const customerData = data[key] as string[];
    customerData.forEach((mission) => result.push(mission));
  });
  return alphabetical(result, (m) => m, 'asc').map((mission) => {
    return {id: mission, label: mission, value: false};
  });
};

export const getWatchboxList = async (): Promise<WatchboxArea[]> => {
  const api = ProxyClient.getApi();
  const {data} = await api.get('/get-watchboxes');
  return data.watchboxes.map((wb: WatchboxResponse) => {
    if (wb.geometry.type === 'Polygon') {
      const [x, y] = polylabel(wb.geometry.coordinates, 1.0);
      const markerLocation = [x, y];
      return {
        ...wb,
        key: nanoid(5),
        active: false,
        markerLocation
      };
    } else {
      const centroids = wb.geometry.coordinates.map((c: any) => {
        const [x, y] = polylabel(c, 1.0);
        return [x, y];
      });
      // const [x, y] = polylabel(centroids, 1.0);
      // const markerLocation = [x, y];
      const features = points(centroids);
      const markerLocation = center(features);
      return {
        ...wb,
        key: nanoid(5),
        active: false,
        markerLocation: markerLocation.geometry.coordinates
      };
    }
  });
};

export const getCustomerArtifacts = async (): Promise<any> => {
  const api = ProxyClient.getApi();
  const {data} = await api.get(`/get-artifacts`);
  return data;
};

export const downloadImageStrip = async (id: string): Promise<string> => {
  const api = MaxarClient.getApi();
  try {
    // Images won't be available in the beta environment.  If the request fails, use the placeholder image
    const {data} = await api.get(
      `/browse-archive/v1/browse/show?image_id=${id}&image_format=png`,
      {
        responseType: 'arraybuffer'
      }
    );
    const d = Buffer.from(data).toString('base64');
    return `data:image/png;base64, ${d}`;
  } catch (err) {
    const data = await axios.get('https://placehold.co/400x600/png?text=Unavailable', {
      responseType: 'arraybuffer'
    });
    const d = Buffer.from(data.data, 'binary').toString('base64');
    return `data:image/png;base64, ${d}`;
  }
};
