import React from 'react';
import {Box, Typography} from '@mui/material';
import FishNetIcon from 'components/Icons/FishNetIcon';

export const NoAlerts: React.FC = () => {
  return (
    <Box sx={{textAlign: 'center', padding: '90px'}}>
      <Typography sx={{marginBottom: '20px'}} color="primary">
        You appear to have drifted into empty waters. No items were found matching the
        selected filters.
      </Typography>

      <Typography color="primary">
        Consider adjusting your sails and perhaps a vessel will appear upon the high seas
        of your interest.
      </Typography>
      <FishNetIcon />
    </Box>
  );
};
