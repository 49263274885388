import {Box, Link, Typography, styled} from '@mui/material';
import HeaderBar from 'components/Navigation/HeaderBar';
import React from 'react';
import {customAppBarHeight} from 'theme/theme';

export const MainContainer = styled(Box)(({theme}) => ({
  height: `calc(100vh - ${customAppBarHeight})`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.primary.main
}));

export const ContainerWrap = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center'
});

const ErrorLayout: React.FC = () => {
  return (
    <div className="layout_root">
      <HeaderBar />
      <ContainerWrap>
        <MainContainer>
          <Typography>404-Error - Oh No!</Typography>
          <Typography>
            {
              "You've navigated into treacherous waters like a ship without a compass. Don't swim with the sharks "
            }
            <Link href="/">return to safety!</Link>
          </Typography>
        </MainContainer>
      </ContainerWrap>
    </div>
  );
};

export default ErrorLayout;
