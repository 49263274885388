import React from 'react';
import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  // Tooltip,
  Typography,
  styled
} from '@mui/material';
import {
  FlagFilterPanel,
  DataSourcePanel,
  DetectionTypePanel,
  MeasurementFilterPanel,
  MissionFilterPanel,
  VesselTypeFilterPanel,
  MMSIPanel
} from 'components/ContentExplorer/ContentExplorerHeader/filterpanels';

import MdiCircle from '@mui/icons-material/Circle';
import AlertIdPanel from '../filterpanels/AlertIdPanel';
import {useAppSelector} from 'redux/hooks';
import {selectFiltersDisabled, selectActiveFilters} from 'redux/filters';
import GeoSpatialPanel from '../filterpanels/GeoSpatialPanel';

export const CollectionFilterCard = styled(Card)(({theme}) => ({
  width: '430px',
  backgroundColor: '#1a1918',
  color: theme.palette.primary.main,
  boxShadow: 'none',
  overflowY: 'auto'
}));

export const AccordionTitle = styled(Typography)(() => ({
  fontWeight: '700',
  fontSize: '16px',
  letterSpacing: '0.1em',
  color: 'rgba(178, 175, 170, 1)'
}));

export const ExpansionIcon = styled(ExpandMore)(() => ({
  color: 'rgba(178, 175, 170, 1)',
  width: '20px',
  height: '20px'
}));

export const ToolTipHeader = styled(Typography)({
  fontWeight: '700',
  fontSize: '14px',
  marginBttom: '5px'
});
export const ToolTipBody = styled(Typography)({
  fontWeight: '500',
  fontSize: '14px',
  marginBottom: '15px'
});

export const FilterAccordion = styled(Accordion)(() => ({
  borderTop: '1px solid rgba(62, 58, 58, 1)',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

export const FilterAccordionSummary = styled(AccordionSummary)(({theme}) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
    transition: 'all 400ms'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transition: 'all 200ms'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    alignItems: 'center',
    marginTop: '0px',
    marginBottom: '0px',
    transition: 'all 200ms'
  },
  '& .Mui-expanded': {
    marginTop: '15px',
    marginBottom: '8px',
    transition: 'all 400ms'
  },
  backgroundColor: '#1a1918',
  color: theme.palette.primary.main,
  minHeight: '30px',
  paddingLeft: '7px'
}));

export const FilterAccordionDetails = styled(AccordionDetails)(({theme}) => ({
  backgroundColor: '#1a1918',
  color: theme.palette.primary.main,
  paddingTop: '0px',
  paddingBottom: '0px'
}));

const FilterPanel: React.FC = () => {
  const filtersDisabled = useAppSelector(selectFiltersDisabled);
  const activePanels = useAppSelector(selectActiveFilters);
  return (
    <CollectionFilterCard square>
      <FilterAccordion disableGutters elevation={1} square defaultExpanded>
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="data-source-panel-content"
          id="data-source-panel-header"
        >
          <AccordionTitle>IMAGE TYPE</AccordionTitle>
          {/* <Tooltip
            title={
              <>
                <ToolTipHeader>EO - Electro Optical</ToolTipHeader>
                <ToolTipBody>
                  High resolution imagery that sets Maxar apart in the industry. Objects
                  can be viewed in crisp, photographic detail, depending on the cloud
                  coverage during the time of collection.
                </ToolTipBody>
                <ToolTipHeader>SAR - Synthetic Aperture Radar</ToolTipHeader>
                <ToolTipBody>
                  Detect change through inclement ground conditions and at night with
                  radar imagery. This type of imagery can penetrate clouds and
                  darkness—delivering uniquely reliable monitoring capabilities.
                </ToolTipBody>
              </>
            }
            placement="right-start"
            arrow
            sx={{backgroundColor: 'rgba(37, 36, 35, 1)'}}
          >
          <InfoOutlined
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
              width: '20px',
              height: '20px',
              marginLeft: '5px'
            })}
          />
          </Tooltip> */}
          {activePanels.sources ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <DataSourcePanel />
        </FilterAccordionDetails>
      </FilterAccordion>
      <FilterAccordion disableGutters elevation={1} square defaultExpanded>
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="mission-panel-content"
          id="mission-panel-header"
        >
          <AccordionTitle>MISSION</AccordionTitle>
          {activePanels.missions ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <MissionFilterPanel />
        </FilterAccordionDetails>
      </FilterAccordion>
      <FilterAccordion disableGutters elevation={1} square defaultExpanded>
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="mmsi-panel-content"
          id="mmsi-panel-header"
        >
          <AccordionTitle>ALERT ID</AccordionTitle>
          {/* <Tooltip
            title={
              <>
                <ToolTipHeader>Alert ID</ToolTipHeader>
                <ToolTipBody>
                  A unique identifier for an alert found in the header details of an HTML
                  Alert or within the property elements of the .geojson file. It is
                  represented as a string of alphanumeric characters connected by hyphens.
                </ToolTipBody>
                <ToolTipBody>Example: 4t58134x-dd72-44am-a2a2-73a83b7347f5</ToolTipBody>
              </>
            }
            placement="right-start"
            arrow
            sx={{backgroundColor: 'rgba(37, 36, 35, 1)'}}
          >
          <InfoOutlined
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
              width: '20px',
              height: '20px',
              marginLeft: '5px'
            })}
          />
          </Tooltip> */}
          {activePanels.alertIds ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <AlertIdPanel />
        </FilterAccordionDetails>
      </FilterAccordion>
      <FilterAccordion disableGutters elevation={1} square defaultExpanded>
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="detection-type-panel-content"
          id="detection-type-panel-header"
        >
          <AccordionTitle>DETECTION TYPE</AccordionTitle>
          {/* <Tooltip
            title={
              <>
                <ToolTipHeader>Correlated Detections</ToolTipHeader>
                <ToolTipBody>
                  When the system has found an additional data source to strongly
                  associate the location and identity of the vessel at the time the
                  imagery was collected.
                </ToolTipBody>
                <ToolTipHeader>Uncorrelated Detections</ToolTipHeader>
                <ToolTipBody>
                  Also known as “Dark” detections. The system has found no other available
                  data (ie: AIS, RF) to determine identity details about the detection.
                </ToolTipBody>
              </>
            }
            placement="right-start"
            arrow
            sx={{backgroundColor: 'rgba(37, 36, 35, 1)'}}
          >
          <InfoOutlined
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
              width: '20px',
              height: '20px',
              marginLeft: '5px'
            })}
          />
          </Tooltip> */}
          {activePanels.detectionTypes ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <DetectionTypePanel />
        </FilterAccordionDetails>
      </FilterAccordion>
      <FilterAccordion disableGutters elevation={1} square defaultExpanded>
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="mmsi-panel-content"
          id="mmsi-panel-header"
        >
          <AccordionTitle>MMSI</AccordionTitle>
          {activePanels.mmsis ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <MMSIPanel />
        </FilterAccordionDetails>
      </FilterAccordion>
      <FilterAccordion disableGutters elevation={1} square defaultExpanded>
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="measurements-panel-content"
          id="measurements-panel-header"
        >
          <AccordionTitle>VESSEL MEASUREMENTS</AccordionTitle>
          {activePanels.measurements ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <MeasurementFilterPanel />
        </FilterAccordionDetails>
      </FilterAccordion>
      <FilterAccordion
        disableGutters
        elevation={0}
        square
        defaultExpanded
        sx={{marginBottom: '10px'}}
      >
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="other-panel-content"
          id="other-panel-header"
        >
          <AccordionTitle>OTHER VESSEL DETAILS</AccordionTitle>
          {activePanels.flags ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <FlagFilterPanel />
        </FilterAccordionDetails>
      </FilterAccordion>
      <FilterAccordion disableGutters elevation={1} square defaultExpanded>
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="vessel-type-panel-content"
          id="vessel-type-panel-header"
        >
          <AccordionTitle>VESSEL TYPE</AccordionTitle>
          {activePanels.categories ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <VesselTypeFilterPanel />
        </FilterAccordionDetails>
      </FilterAccordion>
      <FilterAccordion
        disableGutters
        elevation={0}
        square
        defaultExpanded
        sx={{marginBottom: '10px'}}
      >
        <FilterAccordionSummary
          expandIcon={<ExpansionIcon />}
          aria-controls="geofilter-panel-content"
          id="geofilter-panel-header"
        >
          <AccordionTitle>CUSTOM GEO FILTER</AccordionTitle>
          {activePanels.flags ? (
            <MdiCircle
              sx={(theme) => ({
                width: '10px',
                height: '10px',
                alignSelf: 'center',
                marginLeft: '5px',
                color: theme.palette.primary.contrastText,
                ...(filtersDisabled && {
                  color: theme.palette.secondary.contrastText
                })
              })}
            />
          ) : (
            ''
          )}
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <GeoSpatialPanel />
        </FilterAccordionDetails>
      </FilterAccordion>
    </CollectionFilterCard>
  );
};

export default FilterPanel;
