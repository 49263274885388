import React from 'react';
import {RLayerVector} from 'rlayers';
import {useAppSelector} from 'redux/hooks';
import {selectVesselTrack, selectAisDateRange, selectAisLoading} from 'redux/ais';
import {formatDate} from 'utilities/date';
import {UNIQUE_HOUR_DATEKEY_FORMAT} from 'constants/constants';
import {fromLonLat} from 'ol/proj';
import {selectSelectedVessel} from 'redux/vesselHistory';
import {generateColorMap} from 'utilities/colors';
import {PingFeature} from './PingFeature';
import {LineFeature} from './LineFeature';

export const AISTrack: React.FC = () => {
  const selectedVessel = useAppSelector(selectSelectedVessel);
  const vesselTrack = useAppSelector(selectVesselTrack);
  const dateRange = useAppSelector(selectAisDateRange);
  const aisLoading = useAppSelector(selectAisLoading);
  if (!selectedVessel || aisLoading) return null;
  const colorMap = generateColorMap(dateRange.start, dateRange.end);
  const renderAISPings = () => {
    return vesselTrack.map((hit, idx) => {
      const nextVessel = vesselTrack[idx + 1];
      const vessel = hit.last.last;
      const recordDate = new Date(vessel.event_ts);
      const colorKey = formatDate(recordDate, UNIQUE_HOUR_DATEKEY_FORMAT);
      const vesselCoords = vessel.geometry?.coordinates || [vessel.lon, vessel.lat];
      const result = [];

      if (nextVessel) {
        const nextRecord = nextVessel.last.last;
        const nextVesselCoords = nextRecord.geometry?.coordinates || [
          nextRecord.lat,
          nextRecord.lon
        ];
        result.push(
          <LineFeature
            key={hit.event_ts + 1}
            coordinates={[fromLonLat(nextVesselCoords), fromLonLat(vesselCoords)]}
            color={colorMap[colorKey]}
          />
        );
      }
      result.push(
        <PingFeature
          key={hit.event_ts}
          timestamp={hit.event_ts}
          coordinate={vesselCoords}
          color={colorMap[colorKey]}
        />
      );
      return result;
    });
  };

  return (
    <RLayerVector zIndex={10} wrapX={false}>
      {renderAISPings()}
    </RLayerVector>
  );
};
