import React from 'react';
import {RFeature} from 'rlayers';
import {RStyle, RStroke, RFill} from 'rlayers/style';
import {MultiPolygon, Polygon} from 'ol/geom';
import {OilSpill} from 'types/Alerts';
import {
  transformPolygonCoords,
  colorWithAlpha,
  transformMultiPolygonCoords
} from 'utilities/map';
import {OILSPILL_GREEN} from 'styles/alert_icons';

interface ODSVesselFeatureProps {
  spill: OilSpill;
  hideFootprint?: boolean;
}

export const ODSVesselFeature: React.FC<ODSVesselFeatureProps> = ({
  spill,
  hideFootprint = false
}) => {
  const color = OILSPILL_GREEN;
  const geometry =
    spill.geometry.type === 'Polygon'
      ? new Polygon(transformPolygonCoords(spill.geometry.coordinates))
      : new MultiPolygon(transformMultiPolygonCoords(spill.geometry.coordinates));

  return (
    <>
      {spill.geometry && !hideFootprint && (
        <RFeature geometry={geometry}>
          <RStyle>
            <RStroke color={color} width={2} />
            <RFill color={colorWithAlpha(color, 0.2)} />
          </RStyle>
        </RFeature>
      )}
    </>
  );
};
