import React from 'react';
import {
  AlertBlockHeader,
  AlertDetailCard,
  AlertDetailContentBody,
  AlertDetailContentSubHeading,
  AlertDetailDataTable,
  AlertDetailDataTableCellLong,
  AlertDetailDataTableHeader,
  AlertDetailDataTableHeaderLong,
  AlertDetailLinkAnchor,
  AlertHorizontalDivider,
  AlertSectionHeader
} from '../Styled';
import {formatDate, parseDate} from 'utilities/date';
import {Divider} from '@mui/material';
import {EOAlertResponse} from 'types/Alerts';
import LatencyTimeline from 'components/ContentExplorer/ContentExplorerContent/Timeline';

interface ODSSummaryProps {
  alert: EOAlertResponse;
}

export const ODSSummary: React.FC<ODSSummaryProps> = ({alert}) => {
  const observationDate = alert.properties.periodStopDatetime;
  const processingDate = alert.sarStates
    ?.filter((sarState) => sarState.state === 'Scene_Completed')
    .pop()?.creationDatetime;

  return (
    <AlertDetailCard>
      <AlertSectionHeader>Collection Summary</AlertSectionHeader>
      <AlertDetailContentSubHeading>
        <AlertDetailLinkAnchor
          href={alert.sarDownloadUrl !== '' ? alert.sarDownloadUrl : '#'}
        >
          Download
        </AlertDetailLinkAnchor>{' '}
        zip file of vessel detect metadata
      </AlertDetailContentSubHeading>
      <AlertDetailContentBody>
        <b>Mission: </b>
        {alert.properties.mission}
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        <b>Collect Date & Time:</b>
        {` ${formatDate(alert.properties.meanDatetime, 'yyyy-MM-dd')} | ${formatDate(
          alert.properties.meanDatetime,
          'HH:mm:ss'
        )}Z`.toUpperCase()}
      </AlertDetailContentBody>
      <AlertHorizontalDivider />
      <AlertSectionHeader>Oil Spill Detections</AlertSectionHeader>

      <AlertDetailDataTable>
        <tbody>
          <tr>
            <AlertDetailDataTableHeaderLong>Spill</AlertDetailDataTableHeaderLong>
            <AlertDetailDataTableHeader>Data Source</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>Classification</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>Area KM2</AlertDetailDataTableHeader>
          </tr>

          {alert.spills.map((spill) => {
            return (
              <tr key={spill.oilSpillDetails.oilSpillId}>
                <AlertDetailDataTableCellLong>
                  {spill.oilSpillDetails.oilSpillId}
                </AlertDetailDataTableCellLong>
                <AlertDetailDataTableCellLong>
                  {spill.oilSpillDetails.dataSource}
                </AlertDetailDataTableCellLong>
                <AlertDetailDataTableCellLong>
                  {spill.oilSpillDetails.classification}
                </AlertDetailDataTableCellLong>
                <AlertDetailDataTableCellLong>
                  {spill.oilSpillDetails.areaKm2
                    ? spill.oilSpillDetails.areaKm2.toFixed(2)
                    : '--'}
                  km
                  <sup>2</sup>
                </AlertDetailDataTableCellLong>
              </tr>
            );
          })}
        </tbody>
      </AlertDetailDataTable>

      <AlertSectionHeader>Oil Spill Sources</AlertSectionHeader>
      <AlertDetailDataTable>
        <tbody>
          <tr>
            <AlertDetailDataTableHeaderLong>Source</AlertDetailDataTableHeaderLong>
            <AlertDetailDataTableHeader>MMSI</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>Oil Spill Id</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>Confidence</AlertDetailDataTableHeader>
          </tr>

          {alert.sources.map((source) => {
            return (
              <tr key={source.oilSourceDetails.oilSourceId}>
                <AlertDetailDataTableCellLong>
                  {source.oilSourceDetails.oilSourceId}
                </AlertDetailDataTableCellLong>
                <AlertDetailDataTableCellLong>
                  {source.correlationVesselMetadata?.mmsi || '--'}
                </AlertDetailDataTableCellLong>
                <AlertDetailDataTableCellLong>
                  {source.associatedOilSpillIds[0] || '--'}
                </AlertDetailDataTableCellLong>
                <AlertDetailDataTableCellLong>
                  {source.oilSourceDetails.sourceConfidence}
                </AlertDetailDataTableCellLong>
              </tr>
            );
          })}
        </tbody>
      </AlertDetailDataTable>

      <AlertHorizontalDivider />
      <AlertSectionHeader>Latency Details</AlertSectionHeader>
      <LatencyTimeline
        observationDate={parseDate(observationDate)}
        processingDate={parseDate(processingDate ?? '')}
        source={alert.source}
      />

      <Divider />
      <AlertBlockHeader>MAXAR Maritime Service Desk</AlertBlockHeader>
      <AlertDetailContentBody>
        <AlertDetailLinkAnchor
          href="mailto:maritime_support@maxar.com"
          target="_blank"
          rel="noreferrer"
        >
          maritime_support@maxar.com
        </AlertDetailLinkAnchor>
      </AlertDetailContentBody>
    </AlertDetailCard>
  );
};
