import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getEOVessel, getFingerprintVessels} from 'api/vessel-fingerprint';
import {centroid} from '@turf/turf';
import {FingerprintVessel, PartialFingerprintVessel} from 'types/Alerts';

export interface VesselFingerprintState {
  fingerprintVessels: FingerprintVessel[];
  status: 'idle' | 'loading' | 'failed';
  focusedFpVessel: PartialFingerprintVessel | null;
}

const initialState: VesselFingerprintState = {
  fingerprintVessels: [],
  status: 'loading',
  focusedFpVessel: null
};

export const loadFingerprintVessels = createAsyncThunk(
  'fetchFingerprintVessels',
  async (params: {alertId: string; vesselId: string}) => {
    const data = await getEOVessel(params);

    const detectionCaptureRecordId = data.properties.detectionMetadata.captureRecordId;
    const metadataCaptureRecordId = data.properties.vesselMetadatas.find(
      (metadata) => metadata.metadataType === 'EO_VD'
    )?.metadata.captureRecordId;

    const targetVesselId =
      data.properties.captureRecordId ??
      detectionCaptureRecordId ??
      metadataCaptureRecordId;

    if (!targetVesselId) {
      throw Error('MissingIdentifier: provided targetVesselId is not valid');
    }

    return await getFingerprintVessels(targetVesselId);
  }
);

export const vesselFingerprintSlice = createSlice({
  name: 'vesselFingerprint',
  initialState,
  reducers: {
    setFingerprintVessels: (state, action: PayloadAction<FingerprintVessel[]>) => {
      state.fingerprintVessels = action.payload;
    },
    setFocusedFpVessel: (
      state,
      action: PayloadAction<PartialFingerprintVessel | null>
    ) => {
      state.focusedFpVessel = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadFingerprintVessels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadFingerprintVessels.fulfilled, (state, action) => {
        const data: FingerprintVessel[] = [];

        for (const match of action.payload.matches) {
          const chipMetadata = match.captureRecord.chipMetadatas.find(
            (chip) => chip.purpose === 'BOW_UP' && chip.chipType === 'jpg'
          );

          const matchCentroid = chipMetadata?.chipGeometry
            ? centroid(chipMetadata.chipGeometry)
            : null;

          const eoVessel = match.captureRecord.vesselMetadatas.find(
            (vessel) => vessel.metadataType === 'EO_VD'
          );

          const aisVessel = match.captureRecord.vesselMetadatas.find(
            (vessel) => vessel.metadataType === 'AIS'
          );

          const calculatedConfidence = 100 - (match.matchDistance / 2) * 100;

          const length = eoVessel?.vesselDetails.vesselLengthMeters;
          const width = eoVessel?.vesselDetails.vesselWidthMeters;
          const heading = eoVessel?.vesselDetails.headingDeg;
          const name =
            aisVessel?.vesselDetails.vesselName ||
            aisVessel?.vesselDetails.mmsi ||
            'none';

          if (chipMetadata && matchCentroid && length && width && heading) {
            data.push({
              id: match.captureRecord.captureRecordId,
              confidence: calculatedConfidence,
              date: match.captureRecord.captureDatetime,
              length,
              width,
              name,
              heading,
              image: chipMetadata.chipUrl,
              lat: matchCentroid.geometry.coordinates[1],
              lon: matchCentroid.geometry.coordinates[0],
              geometry: chipMetadata.chipGeometry
            });
          }
        }

        state.fingerprintVessels = data;
        state.status = 'idle';
      })
      .addCase(loadFingerprintVessels.rejected, (state) => {
        state.status = 'failed';
        state.fingerprintVessels = [];
      });
  }
});

export const {setFingerprintVessels, setFocusedFpVessel} = vesselFingerprintSlice.actions;

export default vesselFingerprintSlice.reducer;
