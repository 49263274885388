import * as React from 'react';
import {SvgIcon} from '@mui/material';

const BinocularIconLoader: React.FC = () => {
  return (
    <SvgIcon
      sx={{
        width: '16px',
        height: '16px',
        fontSize: '16px',
        marginTop: '-2px',
        marginRight: '5px'
      }}
    >
      <path d="M11,6H13V13H11V6M9,20A1,1 0 0,1 8,21H5A1,1 0 0,1 4,20V15L6,6H10V13A1,1 0 0,1 9,14V20M10,5H7V3H10V5M15,20V14A1,1 0 0,1 14,13V6H18L20,15V20A1,1 0 0,1 19,21H16A1,1 0 0,1 15,20M14,5V3H17V5H14Z" />
    </SvgIcon>
  );
};

export default BinocularIconLoader;
