import React, {PropsWithChildren} from 'react';
import {useDraggable} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  Accordion as MuiAccordian,
  AccordionDetails as MuiAccordianDetails
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DockMenu} from '../DockMenu';

export const accordionHeight = '36px !important';

export const Accordian = styled(MuiAccordian)(({theme}) => ({
  position: 'absolute',
  width: '230px',
  color: theme.palette.primary.main,
  backgroundColor: '#333231',
  boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`,
  border: '1px solid #333231',
  borderRadius: 0,
  right: -1,
  zIndex: 6
}));

export const AccordionSummary = styled('div')(({theme}) => ({
  color: theme.palette.primary.main,
  backgroundColor: '#4C4B48',
  minHeight: accordionHeight,
  height: accordionHeight,
  paddingLeft: '0px',
  paddingRight: '10px',
  borderRadius: 0,
  margin: 0,
  marginTop: '0px',
  cursor: 'default'
}));

interface Transition extends PropsWithChildren {
  id: string;
  title: string;
  onDock: () => void;
  onClose: () => void;
  icon: React.ReactNode;
  width?: number;
  x: number;
  y: number;
}

interface Style {
  transform: string | undefined;
  width: number;
  right: number;
  top: number;
  borderRadius: number;
}

export const DraggableControlContainer: React.FC<Transition> = ({
  x,
  y,
  id,
  title,
  onDock,
  onClose,
  width = 230,
  icon,
  children
}) => {
  const {transform, setNodeRef, attributes, listeners} = useDraggable({id});
  const [expanded, setExpanded] = React.useState(true);

  const style = {
    transform: CSS.Transform.toString(transform),
    width,
    borderRadius: 0
  } as Style;

  style.right = Math.abs(x);
  style.top = y;

  const togglePanel = () => {
    setExpanded((prevState) => !prevState);
  };
  return (
    <Accordian
      id={id}
      style={style}
      {...attributes}
      ref={setNodeRef}
      expanded={expanded}
      onChange={togglePanel}
    >
      <AccordionSummary>
        <Stack direction="row" spacing={2} style={{padding: '5px'}}>
          <ExpandMoreIcon
            onClick={togglePanel}
            sx={(theme) => ({
              pointerEvents: 'auto',
              color: theme.palette.primary.main,
              height: '27px',
              fontSize: '20px',
              rotate: expanded ? 0 : '270deg',
              cursor: 'pointer'
            })}
          />
          <div {...listeners} style={{cursor: 'move', marginLeft: 0}}>
            {icon}
          </div>
          <Typography
            fontSize={16}
            style={{marginTop: 2, marginLeft: 0, cursor: 'move', width: '100%'}}
            {...listeners}
          >
            {title}
          </Typography>
          <div>
            <DockMenu
              onDock={onDock}
              topOffset={4}
              onClose={onClose}
              onCloseText={expanded ? 'Close' : 'Open'}
            />
          </div>
        </Stack>
      </AccordionSummary>

      <MuiAccordianDetails sx={{padding: '5px', backgroundColor: '#333231'}}>
        {children}
      </MuiAccordianDetails>
    </Accordian>
  );
};
