import React from 'react';
import {useOL} from 'rlayers';
import {useAppSelector} from 'redux/hooks';
import {selectMapCenter, selectMapExtent} from 'redux/map';
import {fromLonLat} from 'ol/proj';
import {zoomToExtent} from 'utilities/map';

export const ViewManager: React.FC = () => {
  const {map} = useOL();
  const extent = useAppSelector(selectMapExtent);
  const center = useAppSelector(selectMapCenter);

  React.useEffect(() => {
    if (center) {
      const coordMin = fromLonLat([center.lon, center.lat], 'EPSG:3857');
      const coordMax = fromLonLat([center.lon, center.lat], 'EPSG:3857');
      const extent = [coordMin[0], coordMin[1], coordMax[0], coordMax[1]];
      if (center.animate) {
        const tmpCoords = map.getView().getCenter();
        if (tmpCoords) {
          zoomToExtent(
            map,
            [tmpCoords[0], tmpCoords[1], tmpCoords[0], tmpCoords[1]],
            7,
            () => zoomToExtent(map, extent, center.zoom || 11)
          );
        }
      } else {
        zoomToExtent(map, extent, center.zoom || 11);
      }
    }
  }, [center, map]);

  React.useEffect(() => {
    if (extent) {
      zoomToExtent(map, extent);
    }
  }, [extent, map]);

  return <></>;
};
