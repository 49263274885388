import React, {useState} from 'react';
import {DndContext, closestCenter, DragEndEvent} from '@dnd-kit/core';
import {restrictToWindowEdges} from '@dnd-kit/modifiers';
import {DraggableControlContainer} from 'components/Map/Controls/components/DraggableControlContainer';
import {LayersControlContent} from './LayersControlContent';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import {useAppDispatch} from 'redux/hooks';
import {setLayerManagerDocked, setLayerManagerOpen} from 'redux/map';

export const LayersControl: React.FC = () => {
  const [delta, setDelta] = useState({x: 320, y: 155});

  const handleDragEnd = (event: DragEndEvent) => {
    const x = delta.x - event.delta.x;
    const y = delta.y + event.delta.y;
    setDelta({x: x, y: y});
  };

  const dispatch = useAppDispatch();

  const onDock = () => {
    dispatch(setLayerManagerDocked(true));
  };
  const onClose = () => {
    dispatch(setLayerManagerOpen(false));
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToWindowEdges]}
      onDragEnd={handleDragEnd}
    >
      <DraggableControlContainer
        x={delta.x}
        y={delta.y}
        width={270}
        onDock={onDock}
        onClose={onClose}
        icon={
          <LayersOutlinedIcon
            fontSize="small"
            sx={{
              fontSize: '18px',
              marginLeft: '2px !important',
              marginRight: '5px',
              marginTop: '5px !important'
            }}
          />
        }
        title="Layers"
        id="layer-control"
      >
        <LayersControlContent />
      </DraggableControlContainer>
    </DndContext>
  );
};
