import {RootState} from '../store';

export const selectFingerprintVessels = (state: RootState) =>
  state.vesselFingerprint.fingerprintVessels;

export const selectVfpVesselsLoading = (state: RootState) =>
  state.vesselFingerprint.status === 'loading';

export const selectFocusedFpVessel = (state: RootState) =>
  state.vesselFingerprint.focusedFpVessel;
