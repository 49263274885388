import {InventoryItem, OilSpill, OilSpillSource, Vessel} from 'types/Alerts';

export const formatODSGeoJson = (spills?: OilSpill[], sources?: OilSpillSource[]) => {
  if (!spills || !sources) {
    return {
      type: 'FeatureCollection',
      features: []
    };
  }
  const spillFeatures = spills.map((spill) => {
    const properties = {
      ...spill.oilSpillDetails,
      image: `image_chips/${spill.oilSpillDetails.oilSpillId}.jpg`
    };
    return {
      type: 'Feature',
      properties,
      geometry: spill.geometry
    };
  });
  const sourceFeatures = sources.map((sources) => {
    const properties = {
      ...sources.oilSourceDetails
    };
    return {
      type: 'Feature',
      properties,
      geometry: sources.geometry
    };
  });

  return {
    type: 'FeatureCollection',
    features: [...spillFeatures, ...sourceFeatures]
  };
};

export const formatDetectGeoJson = (vessels: Vessel[]) => {
  const vesselFeatures = vessels.map((vessel) => {
    const properties = {
      ...vessel.properties,
      vesselId: vessel.vesselId,
      image: `image_chips/${vessel.vesselId}`
    };
    const geometry = vessel.geometry || vessel.sarGeometry;
    return {
      type: 'Feature',
      properties,
      geometry
    };
  });

  return {
    type: 'FeatureCollection',
    features: vesselFeatures
  };
};

export const formatImageStripsGeoJson = (inventoryItems: InventoryItem[]) => {
  const features = inventoryItems.map((item) => {
    const properties = {
      stripId: item.inventoryId
    };
    const geometry = item.stripGeometry;
    return {
      type: 'Feature',
      properties,
      geometry
    };
  });

  return {
    type: 'FeatureCollection',
    features
  };
};
