import React from 'react';
import {RFeature} from 'rlayers';
import {fromLonLat} from 'ol/proj';
import {Point} from 'ol/geom';
import {Vessel, OilSpill, PartialFingerprintVessel} from 'types/Alerts';
import {Icon, Style} from 'ol/style';
import {calculateScale} from 'utilities/map';
import {Selected_Vessel_Indicator} from 'styles/alert_icons';

interface SelectedVesselFeatureProps {
  vessel: Vessel | OilSpill | PartialFingerprintVessel;
}

export const SelectedVesselFeature: React.FC<SelectedVesselFeatureProps> = ({vessel}) => {
  return (
    <RFeature
      geometry={new Point(fromLonLat([vessel.lon, vessel.lat]))}
      style={(item, resolution) => {
        const icon = new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          rotateWithView: true,
          src: Selected_Vessel_Indicator
        });
        if (resolution) {
          icon.setScale(calculateScale(resolution));
        }
        return new Style({
          image: icon
        });
      }}
    />
  );
};
