import React from 'react';
import {
  Card,
  CardHeader as MuiCardHeader,
  IconButton,
  IconButtonProps,
  Collapse,
  Typography,
  CardContent as MuiCardContent,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableCell as MuiTableCell,
  TableHead,
  TableBody,
  CardActions
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Vessel} from 'types/Alerts';
import {styled} from '@mui/material/styles';
import {CardMenu} from '../CardMenu';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getVesselPointIcon} from 'utilities/map';
import {getDetectConfidenceLabel, getDetectConfidenceColor} from 'utilities/vessels';
import {title} from 'radash';
import {useAppSelector} from 'redux/hooks';
import {selectFocusedVessel} from 'redux/alerts';
import './index.scss';

interface EOSARCardProps {
  vessel: Vessel;
}

export const CardHeader = styled(MuiCardHeader)(({theme}) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  height: '39px',
  '.MuiCardHeader-title': {
    fontSize: '16px'
  }
}));

export const CardContent = styled(MuiCardContent)(({theme}) => ({
  backgroundColor: '#1A1918',
  paddingTop: '10px',
  paddingLeft: '10px',
  color: theme.palette.primary.main,
  height: '185px'
}));

export const CardColumn = styled('div')(({theme}) => ({
  backgroundColor: '#404040',
  color: theme.palette.primary.main,
  height: '39px'
}));

export const TableCell = styled(MuiTableCell)(({theme}) => ({
  backgroundColor: '#404040',
  color: theme.palette.primary.main,
  height: '28px',
  width: '120px',
  fontSize: '12px',
  padding: 0,
  paddingLeft: '10px',
  borderTop: '1px solid #5B5B5B',
  borderLeft: '1px solid #5B5B5B',
  borderBottom: '1px solid #5B5B5B'
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {expand, ...other} = props;
  return <IconButton {...other} />;
})(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  color: theme.palette.primary.main,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const ValueTableCell: React.FC<{
  value: string | number;
  large?: boolean;
  secondaryValue?: React.ReactNode;
}> = ({value, large, secondaryValue}) => {
  if (large)
    return (
      <TableCell sx={{backgroundColor: '#363636', width: '146px'}}>
        {value} {secondaryValue}
      </TableCell>
    );
  return (
    <TableCell sx={{backgroundColor: '#363636'}}>
      {value} {secondaryValue}
    </TableCell>
  );
};

const formatVesselType = (vesselData: string | null) => {
  let correctedTitle;
  if (vesselData?.includes('_')) {
    correctedTitle = title(vesselData?.replace(' ', '_'));
    return correctedTitle;
  } else {
    correctedTitle = vesselData
      ? vesselData.charAt(0).toUpperCase() + vesselData.slice(1)
      : '- -';
    return correctedTitle;
  }
};

export const EOSARCard: React.FC<EOSARCardProps> = ({vessel}) => {
  const [expanded, setExpanded] = React.useState(false);
  const focusedVessel = useAppSelector(selectFocusedVessel);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const isFocused = focusedVessel && focusedVessel.vesselId === vessel.vesselId;

  return (
    <Card
      id={vessel.vesselId}
      sx={{
        width: '600px',
        marginBottom: '10px',
        borderRadius: 0,
        borderTop: `4px solid ${vessel.properties.dark ? '#C12F0D' : '#11AA11'}`,
        borderRight: `1px solid ${isFocused ? '#999792' : '#6B6965'}`,
        borderBottom: `1px solid ${isFocused ? '#999792' : '#6B6965'}`,
        borderLeft: `1px solid ${isFocused ? '#999792' : '#6B6965'}`
      }}
    >
      <CardHeader
        avatar={
          <div
            style={{
              backgroundColor: '#000',
              height: '24px',
              width: '24px',
              paddingTop: '2px'
            }}
          >
            <img
              src={getVesselPointIcon(vessel)}
              style={{margin: 'auto', height: '18px'}}
            />
          </div>
        }
        sx={{
          backgroundColor: isFocused ? '#B2AFAA' : '#4C4B48',
          color: isFocused ? '#1A1918' : '#fff'
        }}
        action={<CardMenu vessel={vessel} />}
        title={`${vessel.properties.source} Detect - ${
          vessel.properties.dark ? 'Dark' : 'Correlated'
        }`}
      />
      <CardContent sx={{backgroundColor: isFocused ? '#6B6965' : '#1A1918'}}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={0}
        >
          <CardColumn
            sx={{
              display: 'flex',
              marginRight: '10px',
              height: '168px',
              width: '168px',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <div className={`vessel-chip-container ${isFocused && 'vessel-active'}`}>
              <LazyLoadImage
                effect="opacity"
                wrapperClassName="vessel-chip"
                className={`vessel-chip`}
                style={{
                  height: '168px',
                  width: '168px'
                }}
                src={vessel.chipUrlJpg}
              />
            </div>
          </CardColumn>
          <CardColumn sx={{marginRight: '20px', width: '360px', height: '168px'}}>
            <TableContainer component="div">
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{vessel.properties.source}</TableCell>
                    <TableCell>AIS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      TYPE
                    </TableCell>
                    <ValueTableCell
                      value={formatVesselType(vessel.properties.vesselType)}
                    />
                    <ValueTableCell
                      value={vessel.correlationMetadata?.vesselType || '- -'}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      LENGTH (m)
                    </TableCell>
                    <ValueTableCell value={vessel.properties.length ?? '- -'} />
                    <ValueTableCell
                      value={
                        vessel.correlationMetadata?.length
                          ? `${vessel.correlationMetadata?.length} m`
                          : '- -'
                      }
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      WIDTH (m)
                    </TableCell>
                    <ValueTableCell value={vessel.properties.width ?? '- -'} />
                    <ValueTableCell
                      value={
                        vessel.correlationMetadata?.width
                          ? `${vessel.correlationMetadata?.width} m`
                          : '- -'
                      }
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      SPEED (kts)
                    </TableCell>
                    <ValueTableCell value={vessel.properties.speed ?? '- -'} />
                    <ValueTableCell
                      value={
                        vessel.correlationMetadata?.speedKnots
                          ? `${Math.round(
                              (vessel.correlationMetadata?.speedKnots * 100) / 100
                            )} (kts)`
                          : '- -'
                      }
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      HEADING
                    </TableCell>
                    <ValueTableCell value={`${vessel.properties.heading}°` ?? '- -'} />
                    <ValueTableCell
                      value={
                        vessel.correlationMetadata?.headingDeg
                          ? `${Math.round(
                              (vessel.correlationMetadata?.headingDeg * 100) / 100
                            )}°`
                          : '- -'
                      }
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardColumn>
        </Stack>
      </CardContent>
      <CardActions
        disableSpacing
        sx={(theme) => {
          return {
            backgroundColor: isFocused ? '#6B6965' : '#1A1918',
            color: theme.palette.primary.main
          };
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon sx={{color: isFocused ? '#1A1918' : '#fff'}} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MuiCardContent
          sx={(theme) => {
            return {
              backgroundColor: isFocused ? '#6B6965' : '#1A1918',
              color: theme.palette.primary.main,
              padding: '10px !important',
              paddingLeft: '70px!important'
            };
          }}
        >
          {/*if correlated add table*/}
          {vessel.properties.correlated && (
            <TableContainer component="div" sx={{width: '290px', marginBottom: '30px'}}>
              <Typography>Vessel ID</Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>AIS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      VESSEL NAME
                    </TableCell>
                    <ValueTableCell
                      value={vessel.correlationMetadata?.vesselName || '- -'}
                      large
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CALL SIGN
                    </TableCell>
                    <ValueTableCell
                      value={vessel.correlationMetadata?.callSign || '- -'}
                      large
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      MMSI
                    </TableCell>
                    <ValueTableCell
                      value={vessel.correlationMetadata?.mmsi || '- -'}
                      large
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      IMO
                    </TableCell>
                    <ValueTableCell
                      value={vessel.correlationMetadata?.imo || '- -'}
                      large
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      FLAG(S)
                    </TableCell>
                    <ValueTableCell
                      value={vessel.correlationMetadata?.flag || '- -'}
                      large
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Typography component="div">Summary Details</Typography>
          <Typography component="div">
            Lat/Lon: {vessel.lat}, {vessel.lon}
          </Typography>
          {vessel.properties.source === 'SAR' && (
            <Typography component="div">
              Detect Confidence:{' '}
              {vessel.vesselProperties?.detectionMetadata?.detectConfidence}{' '}
              <div
                style={{
                  backgroundColor: getDetectConfidenceColor(
                    getDetectConfidenceLabel(
                      vessel.vesselProperties?.detectionMetadata?.detectConfidence
                    )
                  ),
                  width: '64px',
                  height: '16px',
                  display: 'inline-block',
                  textAlign: 'center',
                  fontWeight: '900',
                  color: '#1A1918',
                  fontSize: '12px',
                  marginLeft: '10px'
                  // padding: '2px'
                }}
              >
                {getDetectConfidenceLabel(
                  vessel.vesselProperties?.detectionMetadata?.detectConfidence
                )}
              </div>
            </Typography>
          )}

          {vessel.properties.correlated && (
            <>
              <Typography component="div">
                AIS Age (min):{' '}
                {vessel.vesselProperties?.correlationMetadata?.aisAgeSeconds}
              </Typography>
              <Typography component="div">
                AIS Timestamp:{' '}
                {vessel.vesselProperties?.detectionVesselMetadata.recordDatetime}
              </Typography>
            </>
          )}
          <Typography component="div">
            Panchromatic GSD (m): {vessel.inventorySummary.gsdMeters}
          </Typography>

          <Typography component="div">
            Inventory ID: {vessel.inventorySummary.inventoryId}
          </Typography>
        </MuiCardContent>
      </Collapse>
    </Card>
  );
};
