import * as React from 'react';

const FishNetIcon: React.FC = () => {
  return (
    <svg
      width="117"
      height="82"
      viewBox="0 0 117 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 22.5H10.5V23V29.5V30H11H34C35.3807 30 36.5 28.8807 36.5 27.5V25C36.5 23.6193 35.3807 22.5 34 22.5H11Z"
        stroke="#E5E1DA"
      />
      <rect x="36.5" y="23.5" width="12" height="5" stroke="#E5E1DA" />
      <path
        d="M100.5 26C100.5 29.2934 97.7641 32.4153 93.0181 34.7435C88.3048 37.0557 81.7583 38.5 74.5 38.5C67.2417 38.5 60.6952 37.0557 55.9819 34.7435C51.2359 32.4153 48.5 29.2934 48.5 26C48.5 22.7066 51.2359 19.5847 55.9819 17.2565C60.6952 14.9443 67.2417 13.5 74.5 13.5C81.7583 13.5 88.3048 14.9443 93.0181 17.2565C97.7641 19.5847 100.5 22.7066 100.5 26Z"
        stroke="#E5E1DA"
      />
      <path
        d="M97.5 26C97.5 28.7287 95.1177 31.3534 90.905 33.3253C86.7325 35.2785 80.9329 36.5 74.5 36.5C68.0671 36.5 62.2675 35.2785 58.095 33.3253C53.8823 31.3534 51.5 28.7287 51.5 26C51.5 23.2713 53.8823 20.6466 58.095 18.6747C62.2675 16.7215 68.0671 15.5 74.5 15.5C80.9329 15.5 86.7325 16.7215 90.905 18.6747C95.1177 20.6466 97.5 23.2713 97.5 26Z"
        stroke="#E5E1DA"
      />
      <path
        d="M49.4815 28.3652C49.407 28.0993 49.1311 27.9441 48.8652 28.0185C48.5993 28.093 48.4441 28.3689 48.5185 28.6348L49.4815 28.3652ZM59.5 66L59.0185 66.1348L59.0728 66.3288L59.2465 66.431L59.5 66ZM89 66L89.4 66.3L89.4543 66.2276L89.4798 66.1407L89 66ZM65.5 65L65.4019 64.5097L65.5 65ZM73.5 69.5L73.5083 69.9999L73.5 69.5ZM80.5 66L80.438 65.5039L80.4007 65.5085L80.3644 65.5187L80.5 66ZM48.5185 28.6348L59.0185 66.1348L59.9815 65.8652L49.4815 28.3652L48.5185 28.6348ZM89.4798 66.1407L100.48 28.6407L99.5202 28.3593L88.5202 65.8593L89.4798 66.1407ZM59.2465 66.431C59.8926 66.811 60.4863 66.9822 61.0618 66.994C61.6321 67.0057 62.1454 66.8597 62.6253 66.6671C63.0804 66.4844 63.5882 66.2214 64.0424 66.0162C64.5224 65.7993 65.0252 65.6049 65.5981 65.4903L65.4019 64.5097C64.7248 64.6451 64.1447 64.8726 63.6306 65.1049C63.0907 65.3489 62.693 65.5624 62.2528 65.7391C61.8373 65.9059 61.4655 66.0021 61.0823 65.9942C60.7042 65.9865 60.2761 65.8765 59.7535 65.569L59.2465 66.431ZM65.5981 65.4903C66.0236 65.4052 66.5362 65.5507 67.169 65.9315C67.7952 66.3082 68.4526 66.8614 69.1483 67.4612C69.8293 68.0484 70.5482 68.6814 71.2551 69.1598C71.954 69.6328 72.7225 70.0129 73.5083 69.9999L73.4917 69.0001C73.0238 69.0078 72.4704 68.7748 71.8155 68.3316C71.1687 67.8939 70.5018 67.3079 69.8013 66.7038C69.1154 66.1125 68.3955 65.5024 67.6846 65.0746C66.9804 64.6509 66.1965 64.3508 65.4019 64.5097L65.5981 65.4903ZM73.5083 69.9999C75.1645 69.9726 76.3732 69.1964 77.4403 68.4083C78.5366 67.5985 79.4478 66.8158 80.6356 66.4813L80.3644 65.5187C78.9528 65.9163 77.8546 66.859 76.8462 67.6039C75.8084 68.3704 74.8167 68.9782 73.4917 69.0001L73.5083 69.9999ZM80.562 66.4961C82.4567 66.2593 84.1062 66.7304 85.5813 67.0511C86.2976 67.2068 87.0087 67.3343 87.6506 67.271C88.3267 67.2044 88.9305 66.9259 89.4 66.3L88.6 65.7C88.3195 66.0741 87.9858 66.2331 87.5525 66.2758C87.0851 66.3219 86.5149 66.2307 85.7937 66.0739C84.3938 65.7696 82.5433 65.2407 80.438 65.5039L80.562 66.4961Z"
        fill="#E5E1DA"
      />
      <path d="M88.6514 41L82.1914 47.46" stroke="#E5E1DA" />
      <path d="M91.3116 44.0391L84.8516 50.4991" stroke="#E5E1DA" />
      <path d="M91.5 47.6553L85.04 41.1953" stroke="#E5E1DA" />
      <path d="M88.4609 50.3116L82.0009 43.8516" stroke="#E5E1DA" />
    </svg>
  );
};

export default FishNetIcon;
