import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type MapMensurationUnitOption =
  | 'kilometer'
  | 'meter'
  | 'mile'
  | 'feet'
  | 'nautical_mile';
export type MapMensurationToolOption = 'select' | 'line' | 'polygon';
export type MapMensurationTypeOption = 'area' | 'distance';

export const MapMensurationOptionLookup = {
  kilometer: 'km',
  meter: 'm',
  mile: 'mi',
  feet: 'ft',
  nautical_mile: 'nm'
};

export interface MapMensurationState {
  enabled: boolean;
  unit: MapMensurationUnitOption;
  labels: {
    area: boolean;
    distance: boolean;
  };
  docked: boolean;
  tool: MapMensurationToolOption;
  cleared: number;
}

const initialState: MapMensurationState = {
  enabled: false,
  unit: 'kilometer',
  labels: {
    area: true,
    distance: true
  },
  docked: true,
  tool: 'line',
  cleared: 0
};

export const mapMensurationSlice = createSlice({
  name: 'mensuration',
  initialState,
  reducers: {
    setMensurationManagerDocked: (state, action: PayloadAction<boolean>) => {
      state.docked = action.payload;
    },
    clearMensurationDrawings: (state) => {
      state.cleared = state.cleared + 1;
    },
    toggleMensurationEnabled: (state) => {
      state.enabled = !state.enabled;
    },
    setMensurationEnabled: (state, action: PayloadAction<boolean>) => {
      state.enabled = action.payload;
    },
    setMensurationLabels: (
      state,
      action: PayloadAction<{label: MapMensurationTypeOption; value: boolean}>
    ) => {
      state.labels = {
        ...state.labels,
        [action.payload.label]: action.payload.value
      };
    },
    setMensurationTool: (state, action: PayloadAction<MapMensurationToolOption>) => {
      state.tool = action.payload;
    },
    setMensurationUnit: (state, action: PayloadAction<MapMensurationUnitOption>) => {
      state.unit = action.payload;
    }
  }
});

export const {
  toggleMensurationEnabled,
  setMensurationUnit,
  setMensurationTool,
  setMensurationLabels,
  clearMensurationDrawings,
  setMensurationEnabled,
  setMensurationManagerDocked
} = mapMensurationSlice.actions;

export default mapMensurationSlice.reducer;
