import tipPointIcon from 'assets/legend/TipPoint_2x.png';
import estimatedVesselLocationIcon from 'assets/legend/TipEstimate_2x.png';
import detectionFromTipIcon from 'assets/legend/TippedDetect_2x.png';
import untippedDetectionIcon from 'assets/legend/UntippedDetect_2x.png';
import detectionFootprintIcon from 'assets/legend/DetectFootprint_2x.png';
import aisTracksIcon from 'assets/legend/AISTracks_2x.png';
import searchAreaIcon from 'assets/legend/SearchedArea_2x.png';
import watchboxIcon from 'assets/legend/CustomerWatchbox.png';
import geofilterIcon from 'assets/GeofilterBox.svg';
import eezIcon from 'assets/legend/EEZ-NewOpt1_2x.png';
import uncorrelatedAISIcon from 'assets/legend/UnCorrAIS_Legend_2x.png';
import imageFootprintIcon from 'assets/legend/ImageFootprint_2x.png';
import watchboxMarker from 'assets/legend/WatchboxMarker.png';
import rfIcon from 'assets/legend/RFDetect_LegendIcons_2x.png';
import {MAX_WATCHBOX_ZO0M} from 'constants/constants';

export enum AlertLayerNames {
  untippedVesselDetectPoints = 'untippedVesselDetectPoints',
  tippedVesselDetectPoints = 'tippedVesselDetectPoints',
  sarLitVesselDetections = 'sarLitVesselDetections',
  sarDarkVesselDetections = 'sarDarkVesselDetections',
  darkTippingPoints = 'darkTippingPoints',
  litTippingPoints = 'litTippingPoints',
  estimatedAisLocations = 'estimatedAisLocations',
  darkVesselDetections = 'darkVesselDetections',
  litVesselDetections = 'litVesselDetections',
  imageFootprints = 'imageFootprints',
  cloudCover = 'cloudCover',
  searchAreas = 'searchAreas',
  watchboxes = 'watchboxes',
  watchboxesMulti = 'watchboxesMulti',
  inventoryHighlight = 'inventoryHighlight'
}
export const TIP_POINT_ICON = tipPointIcon;
export const ESTIMATED_VESSEL_ICON = estimatedVesselLocationIcon;
export const TIPPED_DETECTION_ICON = detectionFromTipIcon;
export const UNTIPPED_DETECTION_ICON = untippedDetectionIcon;
export const DETECTION_FOOTPRINT_ICON = detectionFootprintIcon;
export const AIS_TRACKS_ICON = aisTracksIcon;
export const UNCORRELATED_AIS_ICON = uncorrelatedAISIcon;
export const SEARCH_AREA_ICON = searchAreaIcon;
export const WATCHBOX_ICON = watchboxIcon;
export const GEOFILTER_ICON = geofilterIcon;
export const EEZ_ICON = eezIcon;
export const IMAGE_FOOTPRINT_ICON = imageFootprintIcon;
export const WATCHBOX_MARKER = watchboxMarker;
export const RF_ICON = rfIcon;

// TODO: Move these types
export enum AISLayerNames {
  // latestPingsTipped = "latestPingsTipped",
  // latestPingsUntipped = "latestPingsUntipped",
  pings = 'pings',
  paths = 'paths'
}
export enum UncorrelatedAISLayerNames {
  uncorrelatedTipped = 'uncorrelatedTipped',
  uncorrelatedUntipped = 'uncorrelatedUntipped'
}

export interface BaseLegendControlOptions {
  id: string;
  label: string;
  icon: string;
  lightModeIcon?: string;
  legend_category: string;
  secondaryIcon?: string;
  secondaryIconZoom?: number;
  secondaryIconHeight?: number;
  secondaryIconWidth?: number;
}

export interface AlertLegendControlOption extends BaseLegendControlOptions {
  category: 'alert';
  layers: AlertLayerNames[];
}

export interface AISAlertLegendControlOption extends BaseLegendControlOptions {
  category: 'ais';
  layers: AISLayerNames[];
}

// export interface UncorrelatedAISAlertLegendControlOption
//   extends BaseLegendControlOptions {
//   category: 'uncorrelated_ais';
//   layers: UncorrelatedAISLayerNames[];
// }

export type LegendControlOption = AlertLegendControlOption | AISAlertLegendControlOption;
// | UncorrelatedAISAlertLegendControlOption;

export const defaultLegendOptions: LegendControlOption[] = [
  {
    id: 'tip_point',
    label: 'Tip Point',
    icon: TIP_POINT_ICON,
    category: 'alert',
    legend_category: 'vessel_detections',
    layers: [AlertLayerNames.darkTippingPoints, AlertLayerNames.litTippingPoints]
  },
  {
    id: 'estimated_vessel_location',
    label: 'Estimated Vessel Location',
    icon: ESTIMATED_VESSEL_ICON,
    legend_category: 'vessel_detections',
    category: 'alert',
    layers: [AlertLayerNames.estimatedAisLocations]
  },
  {
    id: 'detection_from_tip',
    label: 'Vessel Detected from Tip',
    legend_category: 'vessel_detections',
    icon: TIPPED_DETECTION_ICON,
    category: 'alert',
    layers: [AlertLayerNames.tippedVesselDetectPoints]
  },
  {
    id: 'detection_not_from_tip',
    label: 'Vessel Detected NOT from Tip',
    legend_category: 'vessel_detections',
    icon: UNTIPPED_DETECTION_ICON,
    category: 'alert',
    layers: [AlertLayerNames.untippedVesselDetectPoints]
  },
  {
    id: 'detection_FOOTPRINT',
    label: 'Vessel Detect Footprint',
    icon: DETECTION_FOOTPRINT_ICON,
    legend_category: 'vessel_detections',
    category: 'alert',
    layers: [AlertLayerNames.darkVesselDetections, AlertLayerNames.litVesselDetections]
  },
  {
    id: 'detection_RF',
    label: 'RF Emitter (w/ Accuracy Halo)',
    icon: RF_ICON,
    legend_category: 'vessel_detections',
    category: 'alert',
    layers: [AlertLayerNames.darkVesselDetections, AlertLayerNames.litVesselDetections]
  },
  // {
  //   id: 'uncorrelated_ais',
  //   label: 'Uncorrelated AIS',
  //   icon: UNCORRELATED_AIS_ICON,
  //   legend_category: 'ais',
  //   category: 'uncorrelated_ais',
  //   layers: [
  //     UncorrelatedAISLayerNames.uncorrelatedUntipped,
  //     UncorrelatedAISLayerNames.uncorrelatedTipped
  //   ]
  // },
  {
    id: 'ais_vessel_track',
    label: 'AIS Tracks (heat map)',
    icon: AIS_TRACKS_ICON,
    legend_category: 'ais',
    category: 'ais',
    layers: [AISLayerNames.pings, AISLayerNames.paths]
  },
  {
    id: 'vessel_search_area',
    label: 'Vessel Search Area',
    legend_category: 'boundaries',
    icon: SEARCH_AREA_ICON,
    category: 'alert',
    layers: [AlertLayerNames.searchAreas]
  },
  {
    id: 'ordered_watch_box',
    label: 'Watch Box',
    icon: WATCHBOX_ICON,
    secondaryIcon: WATCHBOX_MARKER,
    secondaryIconZoom: MAX_WATCHBOX_ZO0M,
    secondaryIconHeight: 24,
    secondaryIconWidth: 24,
    legend_category: 'boundaries',
    category: 'alert',
    layers: [AlertLayerNames.watchboxesMulti, AlertLayerNames.watchboxes]
  },
  {
    id: 'geo_filter',
    label: 'Geo Filter',
    icon: GEOFILTER_ICON,
    legend_category: 'boundaries',
    category: 'alert',
    layers: []
  },
  {
    id: 'eez',
    label: 'Exclusive Economic Zone (EEZ)',
    icon: EEZ_ICON,
    legend_category: 'boundaries',
    category: 'alert',
    layers: []
  },
  {
    id: 'image_footprints',
    label: 'Image Footprints (+Cloud Cover)',
    icon: IMAGE_FOOTPRINT_ICON,
    legend_category: 'boundaries',
    category: 'alert',
    layers: [AlertLayerNames.imageFootprints, AlertLayerNames.cloudCover]
  }
];
