import React from 'react';
import {RFeature} from 'rlayers';
import {transformMultiPolygonCoords} from 'utilities/map';
import {MultiPolygon, MultiLineString, LineString} from 'ol/geom';
import {WatchboxArea} from 'types/Alerts';
import {PolygonLabel} from './PolygonLabel';
import {
  PolygonDefaultStyle,
  LightPolygonHoverStyle,
  PolygonLightStyle,
  DarkPolygonHoverStyle
} from './Styles';
import {useDispatch} from 'react-redux';
import {toggleWatchbox} from 'redux/filters';
import {useAppSelector} from 'redux/hooks';
import {selectMapMensurationEnabled} from 'redux/mapMensuration';
import {selectIsMapModeLight} from 'redux/map';

interface WatchboxMultiPolygonProps {
  watchbox: WatchboxArea;
}

export const WatchboxMultiPolygon: React.FC<WatchboxMultiPolygonProps> = ({watchbox}) => {
  const dispatch = useDispatch();
  const mensurationEnabled = useAppSelector(selectMapMensurationEnabled);
  const isLightMode = useAppSelector(selectIsMapModeLight);
  const [hover, setHover] = React.useState(false);
  const onPolygonClicked = () => {
    if (!mensurationEnabled) {
      dispatch(toggleWatchbox(watchbox.name));
    }
  };

  const lineStringGeometry = React.useMemo(() => {
    if (watchbox.geometry.type !== 'MultiPolygon') return null;
    const tmpPolygon = new MultiPolygon(
      transformMultiPolygonCoords(watchbox.geometry.coordinates)
    );
    const lineStrings: LineString[] = [];
    tmpPolygon.getPolygons().forEach(function (polygon) {
      // Extract the exterior ring of each Polygon and create a LineString
      const exteriorRing = polygon.getLinearRing(0);
      if (exteriorRing) {
        const lineString = new LineString(exteriorRing.getCoordinates());
        lineStrings.push(lineString);
      } // Assuming the exterior ring is the first one
    });
    return lineStrings;
  }, [watchbox]);

  if (watchbox.geometry.type === 'MultiPolygon') {
    if (!lineStringGeometry) return null;
    return (
      <>
        <RFeature
          geometry={new MultiLineString(lineStringGeometry)}
          properties={{FeatureType: 'WatchboxPolygon'}}
          onClick={onPolygonClicked}
          style={isLightMode ? PolygonLightStyle : PolygonDefaultStyle}
          onPointerEnter={() => {
            setHover(true);
          }}
          onPointerLeave={() => {
            setHover(false);
          }}
        />

        {hover && (
          <RFeature
            geometry={
              new MultiPolygon(transformMultiPolygonCoords(watchbox.geometry.coordinates))
            }
            style={isLightMode ? LightPolygonHoverStyle : DarkPolygonHoverStyle}
          />
        )}

        <PolygonLabel
          coordinates={watchbox.geometry.coordinates[0]}
          watchboxName={watchbox.name}
          watchboxActive={watchbox.active}
          hoverActive={hover}
          onPointerEnter={() => {
            setHover(true);
          }}
          onPointerLeave={() => {
            setHover(false);
          }}
        />
      </>
    );
  }
  return null;
};
