// import {ExpandMore} from '@mui/icons-material';
import React, {useState} from 'react';
import {useAppSelector} from 'redux/hooks';
import {selectSelectedVessel, selectVesselHistoryLoading} from 'redux/vesselHistory';
import {TimelineCollapseContainer} from './Styled';
import {AISTimeline} from 'components/TimelineContainer/AISTimeline';

export const TimelineContainer: React.FC = () => {
  const selectedVessel = useAppSelector(selectSelectedVessel);
  const vesselHistoryLoading = useAppSelector(selectVesselHistoryLoading);
  const [collapse] = useState(false);
  if (!selectedVessel || vesselHistoryLoading) return null;
  return (
    <div className={'timeline_root ' + (collapse ? 'collapsed' : '')}>
      <TimelineCollapseContainer>
        {/*<ExpandMore*/}
        {/*  className={collapse ? '' : 'rotate'}*/}
        {/*  onClick={() => setCollapse(!collapse)}*/}
        {/*  sx={(theme) => ({*/}
        {/*    fontSize: '24px',*/}
        {/*    color: theme.palette.primary.main,*/}
        {/*    cursor: 'pointer'*/}
        {/*  })}*/}
        {/*/>*/}
      </TimelineCollapseContainer>
      <AISTimeline />
    </div>
  );
};
