import React, {useState} from 'react';
import {customAppBarHeight} from 'theme/theme';
import {styled} from '@mui/material';
import {Allotment} from 'allotment';
import 'allotment/dist/style.css';
import {useSearchParams} from 'react-router-dom';
import {DndContext, DragEndEvent, closestCenter} from '@dnd-kit/core';
import {LegendControl} from 'components/Map/Controls/LegendControl';
import HeaderBar from 'components/Navigation/HeaderBar';
import {ContentExplorer} from 'components/ContentExplorer';
import {TimelineContainer} from 'components/TimelineContainer';
// import {PrivacyPolicy} from 'components/PrivacyPolicy';
import {loadAlerts} from 'redux/alerts';
import {
  loadMissionOptions,
  loadWatchboxOptions,
  selectFilters,
  setInitialSearchParams
} from 'redux/filters';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {Map} from 'components/Map';
import {selectVesselMensurationEnlarged} from 'redux/vesselChipMensuration';
import {
  selectMapMensurationEnabled,
  selectMapMensurationDocked
} from 'redux/mapMensuration';
import {
  selectLayerManagerOpen,
  selectLayerManagerDocked,
  setupUserMapTokens
} from 'redux/map';
import {MensurationControl} from 'components/Map/Controls/MensurationControl';
import {LayersControl} from 'components/Map/Controls/LayersControl';
import {loadCustomerArtifacts} from 'redux/artifacts';
import {selectLegendDocked, selectLegendOpen} from 'redux/legend';
import {createLoginRecord} from 'redux/alertAccess';

export const MainContainer = styled('section')({
  minHeight: `calc(100vh - ${customAppBarHeight})`,
  height: `calc(100vh - ${customAppBarHeight})`,
  display: 'flex',
  flexDirection: 'column'
});

const initialContentPanelWidth = (637 / window.innerWidth) * 100;
const enlargedResize = (580 / window.innerWidth) * 100;
const HomeLayout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const allotmentRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);
  const mensurationEnlarged = useAppSelector(selectVesselMensurationEnlarged);
  const legendDocked = useAppSelector(selectLegendDocked);
  const legendOpen = useAppSelector(selectLegendOpen);
  const mensurationOpen = useAppSelector(selectMapMensurationEnabled);
  const mensurationDocked = useAppSelector(selectMapMensurationDocked);
  const layersOpen = useAppSelector(selectLayerManagerOpen);
  const layersDocked = useAppSelector(selectLayerManagerDocked);
  const [delta, setDelta] = useState({x: 0, y: 0});

  const handleDragEnd = (event: DragEndEvent) => {
    const x = delta.x + event.delta.x;
    const y = delta.y + event.delta.y;
    setDelta({x: x, y: y});
  };

  React.useEffect(() => {
    const alertId = searchParams.get('alertId');
    const alertDate = searchParams.get('alertDate');
    const vesselId = searchParams.get('vesselId');
    dispatch(setInitialSearchParams({alertId, alertDate, vesselId}));
  }, [searchParams, dispatch]);

  React.useEffect(() => {
    dispatch(loadMissionOptions());
    dispatch(loadCustomerArtifacts());
    dispatch(loadWatchboxOptions());
    dispatch(setupUserMapTokens());
    dispatch(createLoginRecord());
  }, [dispatch]);

  React.useEffect(() => {
    // Wait for mission list to load before initial query for alerts
    if (filters.filters.missions.length > 0) {
      dispatch(loadAlerts({avoidLoadingState: false}));
    }
  }, [dispatch, filters]);

  React.useEffect(() => {
    if (mensurationEnlarged && allotmentRef.current) {
      allotmentRef.current.resize([enlargedResize, 100 - enlargedResize]);
    } else if (allotmentRef.current) {
      allotmentRef.current.resize([
        initialContentPanelWidth,
        100 - initialContentPanelWidth
      ]);
    }
  }, [mensurationEnlarged]);

  return (
    <div className="layout_root">
      <HeaderBar />
      {!legendDocked && legendOpen && <LegendControl />}
      {!mensurationDocked && mensurationOpen && <MensurationControl />}
      {!layersDocked && layersOpen && <LayersControl />}
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <main style={{height: 'calc(100vh - 50px', display: 'flex'}}>
          <Allotment
            defaultSizes={[initialContentPanelWidth, 100 - initialContentPanelWidth]}
            ref={allotmentRef}
          >
            <Allotment.Pane>
              <ContentExplorer />
            </Allotment.Pane>
            <Allotment.Pane>
              <MainContainer>
                {/*<MapBase />*/}
                <Map />
                <TimelineContainer />
                {/*<PrivacyPolicy />*/}
              </MainContainer>
            </Allotment.Pane>
          </Allotment>
        </main>
      </DndContext>
    </div>
  );
};

export default HomeLayout;
