import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  DefaultCanvasSize,
  LargeCanvasSize
} from 'components/VesselHistory/IconButtonsOnVesselChip';
import {Label, Vertex, Line} from 'types/Mensuration';

export type Dimension = {
  height: number;
  width: number;
};

export interface VCMState {
  active: boolean;
  enlarged: boolean;
  chipRotation: boolean;
  rotationDegrees: number;
  // Mixed Object Type
  past: Array<Vertex | Line | Label>;
  present: Vertex | Line | Label | null;
  // Mixed Object Type
  future: Array<Vertex | Line | Label>;
  width: number;
  height: number;
  deviceRatio: number[];
  disableRedoActionHistory: boolean;
  disableUndoActionHistory: boolean;
  collisionTolerance: number;
  imageNaturalDimensions: Dimension;
  cursorLine: Line | null;
  dblClick: boolean;
}

const initialState: VCMState = {
  active: false,
  enlarged: false,
  chipRotation: false,
  rotationDegrees: 0,
  past: [],
  present: null,
  future: [],
  width: 208,
  height: 208,
  deviceRatio: [
    208 * window.devicePixelRatio,
    208 * window.devicePixelRatio,
    window.devicePixelRatio
  ],
  disableRedoActionHistory: true,
  disableUndoActionHistory: true,
  collisionTolerance: 5,
  imageNaturalDimensions: {
    height: 0,
    width: 0
  },
  cursorLine: null,
  dblClick: false
};

export const VCMSlice = createSlice({
  name: 'vesselChipMensuration',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Vertex | Line | Label>) => {
      if (state.present) {
        state.past.push(state.present);
      }
      state.future = [];
      state.present = action.payload;
      state.disableUndoActionHistory = false;
    },
    undo: (state) => {
      if (state.past.length > 0 && state.present) {
        const previous = state.past[state.past.length - 1];

        state.past = state.past.slice(0, state.past.length - 1);

        state.future = [state.present, ...state.future];

        state.present = previous;

        state.disableRedoActionHistory = false;

        if (state.past.length === 0 && !state.present) {
          state.disableUndoActionHistory = true;
        }
      } else if (state.past.length === 0 && state.present) {
        state.future = [state.present, ...state.future];
        state.present = null;
        state.disableUndoActionHistory = true;
      }
    },
    redo: (state) => {
      if (state.future.length > 0 && state.present) {
        const next = state.future[0];

        state.future = state.future.slice(1);

        state.past = [...state.past, state.present];

        state.present = next;

        state.disableUndoActionHistory = false;

        if (state.future.length === 0) {
          state.disableRedoActionHistory = true;
        }
      }
      if (state.future.length > 0 && !state.present) {
        const next = state.future[0];
        state.future = state.future.slice(1);
        state.present = next;
        state.disableUndoActionHistory = false;
        if (state.future.length === 0) {
          state.disableRedoActionHistory = true;
        }
      }
    },
    setPast: (state, action: PayloadAction<Array<any>>) => {
      state.past = action.payload;
    },

    setFuture: (state, action: PayloadAction<Array<any>>) => {
      state.future = action.payload;
    },
    setChipRotation: (state, action: PayloadAction<boolean>) => {
      state.chipRotation = action.payload;
    },
    setChipRotationDegrees: (state, action: PayloadAction<number>) => {
      state.rotationDegrees = action.payload;
    },
    setPresent: (state, action: PayloadAction<Vertex | Line | Label>) => {
      state.present = action.payload;
    },
    setCursorLine: (state, action: PayloadAction<Line | null>) => {
      state.cursorLine = action.payload;
    },
    setDblClick: (state, action: PayloadAction<boolean>) => {
      state.dblClick = action.payload;
    },
    setRedoActionHistory: (state, action: PayloadAction<boolean>) => {
      state.disableRedoActionHistory = action.payload;
    },
    setUndoActionHistory: (state, action: PayloadAction<boolean>) => {
      state.disableUndoActionHistory = action.payload;
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
    setEnlarged: (state, action: PayloadAction<boolean>) => {
      state.enlarged = action.payload;
    },

    setDeviceRatio: (state, action: PayloadAction<number[]>) => {
      state.deviceRatio = action.payload;
    },

    setHeight: (state, action: PayloadAction<number>) => {
      state.height = action.payload;
    },
    setWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
    },
    setImageDimensions: (state, action: PayloadAction<Dimension>) => {
      state.imageNaturalDimensions.height = action.payload.height;
      state.imageNaturalDimensions.width = action.payload.width;
    },

    resetState: (state) => {
      state.active = false;
      state.chipRotation = false;
      state.rotationDegrees = 0;
      state.past = [];
      state.present = null;
      state.future = [];

      if (state.enlarged) {
        state.width = LargeCanvasSize.width;
        state.height = LargeCanvasSize.height;
      } else {
        state.width = DefaultCanvasSize.width;
        state.height = DefaultCanvasSize.height;
      }

      state.deviceRatio = [state.width * 1, state.height * 1, 1];
      state.disableRedoActionHistory = true;
      state.disableUndoActionHistory = true;
      state.imageNaturalDimensions = {height: 0, width: 0};
      state.cursorLine = null;
      state.dblClick = false;
    }
  }
});

export const {
  add,
  undo,
  redo,
  setRedoActionHistory,
  setUndoActionHistory,
  resetState,
  setActive,
  setEnlarged,
  setChipRotation,
  setChipRotationDegrees,
  setDeviceRatio,
  setHeight,
  setWidth,
  setPast,
  setPresent,
  setFuture,
  setImageDimensions,
  setCursorLine,
  setDblClick
} = VCMSlice.actions;

export default VCMSlice.reducer;
