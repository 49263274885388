import React from 'react';
import {Chip} from '@mui/material';
// import {Category, Mission} from 'types/Filters';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectFilterInputs,
  setAlertIds,
  setFlags,
  setMmsis,
  toggleSource,
  toggleMission,
  toggleCategory,
  toggleDetectionType,
  setMeasurements,
  toggleWatchbox
} from 'redux/filters';
import {defaultMeasureValue} from 'redux/filters/defaults';

type FilterChip = {
  type: string;
  label: string;
  value: string;
  id?: string;
};

interface FilterChipProps {
  chip: FilterChip;
}
export const FilterChips: React.FC<FilterChipProps> = ({chip}) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilterInputs);

  const removeMeasurementBlock = (idx: string) => {
    const record = {...filters.measurements};
    const keys = Object.keys(record);
    // If there is a single record and its key is 0 reset 0 key to blank
    if (keys.length === 1 && keys[0] === '0') {
      record['0'] = {
        ...defaultMeasureValue
      };
    }

    // If there are two records remove the 0 key and set to blank remove the old record
    if (keys.length === 2) {
      if (idx === keys[0]) {
        const value = record[keys[1]];
        delete record[keys[0]];
        record['0'] = value;
        delete record[keys[1]];
      } else {
        delete record[keys[1]];
      }
    }

    // If there are multiple records just remove the key
    if (keys.length > 2) {
      delete record[idx];
    }
    dispatch(setMeasurements(record));
  };
  const handleDelete = (chip: FilterChip) => {
    // let idx: number;
    // let category: Category;
    // let missions: Array<Mission>;
    // let mission: Mission;
    switch (chip.type) {
      case 'mmsi':
        dispatch(setMmsis(filters.mmsis.filter((value) => value !== chip.value)));
        break;
      case 'alertId':
        dispatch(setAlertIds(filters.alertIds.filter((value) => value !== chip.value)));
        break;
      case 'flag':
        dispatch(setFlags(filters.flags.filter((value) => value !== chip.value)));
        break;
      case 'source':
        if (chip.value === 'EO') {
          dispatch(toggleSource('EO'));
        }
        if (chip.value === 'SAR') {
          dispatch(toggleSource('SAR'));
        }
        if (chip.value === 'ODS') {
          dispatch(toggleSource('ODS'));
        }
        if (chip.value === 'RF') {
          dispatch(toggleSource('RF'));
        }
        break;
      case 'mission':
        dispatch(toggleMission(chip.value));
        break;
      case 'watchbox':
        dispatch(toggleWatchbox(chip.value));
        break;
      case 'category':
        if (chip.id) {
          dispatch(toggleCategory(chip.id));
        }

        break;
      case 'detection':
        if (chip.value === 'Dark') {
          dispatch(toggleDetectionType('dark'));
        }
        if (chip.value === 'Correlated') {
          dispatch(toggleDetectionType('correlated'));
        }
        break;
      case 'measurement':
        if (chip.id !== undefined) {
          removeMeasurementBlock(chip.id);
        }
        break;
    }
  };
  return (
    <Chip
      id={`${chip.label}-${chip.value}`}
      label={`${chip.label}: ${chip.value}`}
      onDelete={() => handleDelete(chip)}
      variant="outlined"
      color="primary"
      sx={{
        marginRight: '8px',
        marginBottom: '4px',
        backgroundColor: '#2A2928',
        borderRadius: '3px'
      }}
    />
  );
};
