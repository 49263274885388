import React from 'react';
import LegendItem from 'components/Map/Controls/LegendControl/LegendItem';
import {useAppSelector} from 'redux/hooks';
import {selectLegendItems} from 'redux/legend';
import './MapLegend.scss';

export const LegendContent: React.FC = () => {
  const legendItems = useAppSelector(selectLegendItems);
  const renderItems = (category: string) => {
    return legendItems.map((item) => {
      if (item.legend_category !== category) return null;
      return <LegendItem item={item} key={item.id} isVisible />;
    });
  };
  return (
    <div style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 10}}>
      <p className="legend-section-title">Vessel Detections</p>
      {renderItems('vessel_detections')}
      {renderItems('ais')}
      <p className="legend-section-title">Boundaries</p>
      {renderItems('boundaries')}
    </div>
  );
};
