import React from 'react';
import {RFeature} from 'rlayers';
import {RStroke, RStyle} from 'rlayers/style';
import {LineString} from 'ol/geom';
import {Coordinate} from 'ol/coordinate';

interface LineFeatureProps {
  coordinates: Coordinate[];
  color: string;
}

export const LineFeature: React.FC<LineFeatureProps> = ({coordinates, color}) => {
  return (
    <RFeature geometry={new LineString(coordinates)}>
      <RStyle>
        <RStroke color={color} width={2} />
      </RStyle>
    </RFeature>
  );
};
