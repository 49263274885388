import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  styled
} from '@mui/material';
import {Category} from 'types/Filters';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectFiltersDisabled, selectCategories, toggleCategory} from 'redux/filters';
import {ToolTipHeader} from '../menus/FilterPanel';

export const PanelCheckbox = styled(Checkbox)(({theme}) => ({
  color: theme.palette.primary.label,
  '&.Mui-checked': {
    color: theme.palette.primary.contrastText
  },
  '&.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

export const PanelFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  color: theme.palette.primary.label,
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

const VesselTypeFilterPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectCategories);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);
  const onChangeHandler = (vessel: Category) => {
    dispatch(toggleCategory(vessel.id));
  };

  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 16px',
        flex: '1 1 auto',
        maxWidth: '100%'
      }}
    >
      <Grid container spacing={2} sx={{marginBottom: '16px'}}>
        {categories.map((vessel) => {
          return (
            <Grid key={vessel.id} item xs={6}>
              <Tooltip
                title={
                  <>
                    <ToolTipHeader>{`AIS Category: ${vessel.aisType}`}</ToolTipHeader>
                  </>
                }
                placement="right-start"
              >
                <PanelFormControlLabel
                  control={
                    <PanelCheckbox
                      disabled={filtersDisabled}
                      onChange={() => {
                        onChangeHandler(vessel);
                      }}
                      checked={vessel.value}
                    />
                  }
                  label={vessel.label}
                />
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </FormGroup>
  );
};

export default VesselTypeFilterPanel;
