import {
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import {getCustomerArtifacts} from 'api/alerts';
import {ArtifactResponse, Feature} from 'types/Artifacts';
export interface ArtifactState {
  artifacts: ArtifactResponse;
  featureByCustomer: Record<string, Feature[]>;
  status: 'idle' | 'loading' | 'failed';
  error: SerializedError | null;
}

const initialState: ArtifactState = {
  artifacts: {} as ArtifactResponse,
  featureByCustomer: {},
  status: 'loading',
  error: null
};

export const loadCustomerArtifacts = createAsyncThunk(
  'artifacts/loadCustomerArtifacts',
  async () => {
    return await getCustomerArtifacts();
  }
);

export const artifactSlice = createSlice({
  name: 'artifact',
  initialState,
  reducers: {
    setArtifacts: (state, action: PayloadAction<ArtifactResponse>) => {
      state.artifacts = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCustomerArtifacts.pending, (state) => {
        state.status = 'loading';
        state.artifacts = {} as ArtifactResponse;
      })
      .addCase(loadCustomerArtifacts.fulfilled, (state, action) => {
        state.artifacts = action.payload;
        const record = action.payload.features.reduce((acc: any, feature: Feature) => {
          return {
            ...acc,
            [feature.properties.customer]: []
          };
        }, {});

        action.payload.features.map((feature: Feature) => {
          record[feature.properties.customer].push(feature);
        });

        state.featureByCustomer = record;
        state.status = 'idle';
      })
      .addCase(loadCustomerArtifacts.rejected, (state, action) => {
        if (action.payload) {
          state.status = 'failed';
          state.artifacts = {} as ArtifactResponse;
          state.error = action.error;
        }
      });
  }
});

export const {setArtifacts} = artifactSlice.actions;

export default artifactSlice.reducer;
