import React from 'react';
import {RFeature} from 'rlayers';
import {RStyle, RStroke, RFill} from 'rlayers/style';
import {Polygon} from 'ol/geom';
import {SearchArea} from 'types/Alerts';
import {transformPolygonCoords} from 'utilities/map';
import {SEARCH_AREA_GRAY} from 'styles/alert_icons';
import {useAppSelector} from 'redux/hooks';
import {selectSearchAreasEnabled} from 'redux/layers';

interface SearchAreaFeatureProps {
  searchArea: SearchArea;
}

export const SearchAreaFeature: React.FC<SearchAreaFeatureProps> = ({searchArea}) => {
  const searchAreasEnabled = useAppSelector(selectSearchAreasEnabled);
  if (!searchAreasEnabled) return null;
  return (
    <RFeature
      geometry={new Polygon(transformPolygonCoords(searchArea.geometry.coordinates))}
    >
      <RStyle>
        <RStroke color={SEARCH_AREA_GRAY} width={2} />
        <RFill color="transparent" />
      </RStyle>
    </RFeature>
  );
};
