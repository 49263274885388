// .97 - 1.0 = high
// .65 - .96 = med
// 0.01 - .64 = low
export const getDetectConfidenceLabel = (confidence?: number | null) => {
  if (!confidence) return 'N/A';
  if (confidence < 0.64) return 'LOW';
  if (confidence < 0.96) return 'MED';
  return 'HIGH';
};
// >5 = high
// > 15 = med
// > 25 =low
export const getDetectAccuracyLabel = (accuracy?: number) => {
  if (!accuracy) return 'N/A';
  if (accuracy <= 5) return 'HIGH';
  if (accuracy <= 15) return 'MED';
  return 'LOW';
};
export const getDetectConfidenceColor = (label: 'LOW' | 'MED' | 'HIGH' | 'N/A') => {
  if (label === 'N/A') return '#c0c0c0';
  if (label === 'HIGH') return '#00AE4DB2';
  if (label === 'MED') return '#F8941DB2';
  if (label === 'LOW') return '#868686';
};

export const getDetectAccuracyColor = (label: 'LOW' | 'MED' | 'HIGH' | 'N/A') => {
  return getDetectConfidenceColor(label);
};
