import Keycloak, {KeycloakConfig} from 'keycloak-js';
import './pendo';

export interface UserInfo {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  preferred_username: string;
  sub: string;
}

const isProd = window.location.href.includes('crowsnest');
const KEYCLOAK_BASE_URL = !isProd
  ? process.env.REACT_APP_AUTH_URL
  : 'https://account.maxar.com/auth';

const initOptions = {
  url: KEYCLOAK_BASE_URL,
  redirectUri: window.location.origin,
  realm: process.env.REACT_APP_AUTH_REALM,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  pkceMethod: 'S256',
  checkLoginIframe: false,
  responseMode: 'fragment',
  responseType: 'code',
  enableLogging: true
} as KeycloakConfig;

const _keycloak = new Keycloak(initOptions);

const initKeycloak = (
  onAuthenticatedCallback: () => void,
  onErrorCallback: (arg0: string) => void
) => {
  _keycloak
    .init({onLoad: 'login-required'})
    .then(async (auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        onAuthenticatedCallback();
      }
    })
    .catch((error) => {
      onErrorCallback(error.error);
    });
};

const doLogin = _keycloak.login;

const doLogout = _keycloak.logout;

const getToken = () => _keycloak.token;

const isLoggedIn = () => !!_keycloak.token;

const getUsername = () => _keycloak.tokenParsed?.given_name;

const getUserInfo = () => {
  return {
    email: _keycloak.tokenParsed?.email,
    email_verified: _keycloak.tokenParsed?.email_verified,
    family_name: _keycloak.tokenParsed?.family_name,
    given_name: _keycloak.tokenParsed?.given_name,
    name: _keycloak.tokenParsed?.name,
    preferred_username: _keycloak.tokenParsed?.preferred_username,
    sub: _keycloak.tokenParsed?.sub
  } as UserInfo;
};

const hasRole = (roles: string[]) =>
  roles.some((role: string) => _keycloak.hasRealmRole(role));

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  getUsername,
  hasRole,
  getUserInfo,
  _keycloak
};
export default KeycloakService;
