import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import {Check, FindReplace, TravelExplore} from '@mui/icons-material';
import {formatDate} from 'utilities/date';
import {intervalToDuration, formatDuration} from 'date-fns';
import {TimelineDate, TotalDetectionContent} from './Styles';

export const EOTippedTimeline = (props: {
  observationDate: Date;
  captureDate: Date | null;
  processingDate: Date | null;
  dateFormat: string;
}): React.ReactElement => {
  const {observationDate, captureDate, processingDate, dateFormat} = props;

  //#region formatted dates
  const observedDate = formatDate(observationDate, dateFormat);

  let capturedDate;
  if (captureDate) {
    capturedDate = formatDate(captureDate, dateFormat);
  }

  let processedDate;
  if (processingDate) {
    processedDate = formatDate(processingDate, dateFormat);
  }
  //#endregion

  //#region durations
  let firstDuration;
  if (captureDate) {
    firstDuration = intervalToDuration({start: observationDate, end: captureDate});
  }
  const fmtObservationToCapture = firstDuration
    ? formatDuration(firstDuration)
    : 'Observing...';

  let secondDuration;
  if (captureDate && processingDate) {
    secondDuration = intervalToDuration({start: captureDate, end: processingDate});
  }
  const fmtCaptureToProcessed = secondDuration
    ? formatDuration(secondDuration)
    : 'Capturing...';

  let finalDuration;
  if (processingDate) {
    finalDuration = intervalToDuration({start: observationDate, end: processingDate});
  }
  const fmtCompletion = finalDuration ? formatDuration(finalDuration) : 'Processing...';
  //#endregion

  return (
    <>
      <TotalDetectionContent>
        <Typography variant="h6" component="span">
          Total Detection Cycle
        </Typography>
        <Typography sx={{fontWeight: 'bold'}}>{fmtCompletion}</Typography>
      </TotalDetectionContent>

      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent sx={{m: 'auto 0'}} align="right" variant="body2">
            <Typography variant="h6" component="span">
              Observation Time
            </Typography>

            <TimelineDate>{observedDate}</TimelineDate>
          </TimelineOppositeContent>

          <IconConnector icon={<TravelExplore />} />

          <TimelineContent sx={{py: '12px', px: 2, position: 'relative', top: '3em'}}>
            <div>
              <Typography variant="h6" component="span">
                Observation to Capture
              </Typography>
              <Typography>{fmtObservationToCapture}</Typography>
            </div>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{m: 'auto 0', position: 'relative', top: '3em'}}
            align="right"
            variant="body2"
          >
            <div>
              <Typography variant="h6" component="span">
                Capture to Delivery
              </Typography>
              <Typography>{fmtCaptureToProcessed}</Typography>
            </div>
          </TimelineOppositeContent>

          <IconConnector icon={<FindReplace />} />

          <TimelineContent sx={{py: '12px', px: 2}}>
            <Typography variant="h6" component="span">
              Capture Time
            </Typography>

            <TimelineDate>{capturedDate}</TimelineDate>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{m: 'auto 0'}} align="right" variant="body2">
            <Typography variant="h6" component="span">
              Delivery Time
            </Typography>

            <TimelineDate>{processedDate}</TimelineDate>
          </TimelineOppositeContent>

          <IconConnector icon={<Check />} />

          <TimelineContent sx={{py: '12px', px: 2, my: '2em'}} />
        </TimelineItem>
      </Timeline>
    </>
  );
};

export function IconConnector({icon}: {icon: React.ReactNode}) {
  return (
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot variant="outlined">{icon}</TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
  );
}
