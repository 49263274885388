import React from 'react';
import {Box, styled} from '@mui/material';
import {Typography} from '@mui/material';

const VesselDetailTypography = styled(Typography)(() => ({
  lineHeight: 1,
  fontWeight: 500,
  textTransform: 'uppercase'
}));

// EO or AIS Data Headers
const VesselDetailHeaders: React.FC = () => {
  return (
    <Box>
      <VesselDetailTypography> Ship Type</VesselDetailTypography>
      <VesselDetailTypography> Length </VesselDetailTypography>
      <VesselDetailTypography>Width </VesselDetailTypography>
      <VesselDetailTypography> Vessel Name</VesselDetailTypography>
      <VesselDetailTypography> Call Sign</VesselDetailTypography>
      <VesselDetailTypography> Flag</VesselDetailTypography>
      <VesselDetailTypography> IMO</VesselDetailTypography>
    </Box>
  );
};

export default VesselDetailHeaders;
