import React from 'react';
import {RFeature} from 'rlayers';
import {fromLonLat} from 'ol/proj';
import {Point, Polygon} from 'ol/geom';
import {
  calculateScale,
  colorWithAlpha,
  getRotation,
  transformPolygonCoords
} from 'utilities/map';
import {Icon, Style} from 'ol/style';
import {PartialFingerprintVessel} from 'types/Alerts';
import {LIT_GREEN, Lit_Tipped_Detect_KnownH} from 'styles/alert_icons';
import {RFill, RStroke, RStyle} from 'rlayers/style';

interface Props {
  onClick: (vessel: PartialFingerprintVessel) => void;
  vessel: PartialFingerprintVessel;
}

export const FingerprintVesselFeature: React.FC<Props> = ({vessel, onClick}) => {
  const pointRef = React.useRef<RFeature | null>(null);

  const color = LIT_GREEN;

  const onFeatureClick = () => {
    onClick(vessel);
  };

  return (
    <>
      <RFeature
        onClick={onFeatureClick}
        properties={{FeatureType: 'VesselDetect'}}
        geometry={new Point(fromLonLat([vessel.lon, vessel.lat]))}
        ref={pointRef}
        style={(_, resolution) => {
          const icon = new Icon({
            rotation: getRotation(vessel.heading),
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            rotateWithView: true,
            src: Lit_Tipped_Detect_KnownH
          });
          if (resolution) {
            icon.setScale(calculateScale(resolution));
          }
          return new Style({
            image: icon
          });
        }}
      />
      <RFeature
        geometry={new Polygon(transformPolygonCoords(vessel.geometry.coordinates))}
      >
        <RStyle>
          <RStroke color={color} width={2} />
          <RFill color={colorWithAlpha(color, 0.2)} />
        </RStyle>
      </RFeature>
    </>
  );
};
