import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';

const textStyle = new Text({
  font: '12px Blender,sans-serif',
  fill: new Fill({
    color: '#1A1918'
  }),
  backgroundFill: new Fill({
    color: '#E5E1DA'
  }),
  backgroundStroke: new Stroke({
    width: 1,
    color: '#1A1918'
  }),
  padding: [2, 5, 2, 5],
  textBaseline: 'bottom',
  offsetY: -12
});

export const segmentStyle = new Style({
  text: textStyle.clone()
});

export const segmentStyles = [segmentStyle];

const lightStroke = new Stroke({
  color: '#000',
  lineDash: [4, 4],
  width: 2,
  lineCap: 'square',
  lineJoin: 'round'
});

// const darkStroke = new Stroke({
//   color: '#fff',
//   lineDash: [4, 4],
//   width: 1,
//   lineCap: 'square',
//   lineJoin: 'round'
// });

export const lightPointStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.1)'
  }),
  stroke: lightStroke,
  image: new CircleStyle({
    radius: 3,
    stroke: new Stroke({
      color: '#000000'
    }),
    fill: new Fill({
      color: '#fff'
    })
  })
});

export const pointStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.1)'
  }),
  stroke: new Stroke({
    color: '#fff',
    lineDash: [4, 4],
    width: 1,
    lineCap: 'square',
    lineJoin: 'round'
  }),
  image: new CircleStyle({
    radius: 3,
    stroke: new Stroke({
      color: '#000000'
    }),
    fill: new Fill({
      color: '#fff'
    })
  })
});

export const selectPointStyle = new Style({
  fill: new Fill({
    color: 'rgb(27, 130, 130, 0.1)'
  }),
  stroke: new Stroke({
    color: '#1B8282',
    lineDash: [4, 4],
    width: 2,
    lineCap: 'square',
    lineJoin: 'round'
  })
});

export const labelStyle = new Style({
  text: textStyle.clone()
});
