import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import KCService, {UserInfo} from 'services/keycloak';
import HomeLayout from 'views/HomeLayout';
import App from './App';
import theme from 'theme/theme';
import reportWebVitals from './reportWebVitals';
import './App.scss';
import './services/pendo';
import ErrorLayout from 'views/ErrorLayout';

import {Provider} from 'react-redux';
import {store} from 'redux/store';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
KCService.initKeycloak(instantiateApp, instantiateUnAuthorizedApp);

function instantiateUnAuthorizedApp(error: string) {
  root.render(
    <React.StrictMode>
      <App error={error} />
    </React.StrictMode>
  );
  setNewInterval();
}

async function instantiateApp() {
  const user = KCService.getUserInfo() as UserInfo;
  (window as any).pendo.initialize({
    visitor: {
      id: user.sub,
      name: `${user.given_name} ${user.family_name}`,
      email: user.email,
      role: 'BASE_USER'
    },
    account: {
      id: 'maritime_analytics',
      name: 'Maritime Analytics Application'
    },
    apiKey: '02b98740-ec7a-4949-622b-305d67eeb9cd'
  });
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<HomeLayout />} />
              <Route path="*" element={<ErrorLayout />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
  /** Uncomment when keycloak is enabled */
  setNewInterval();
}

function setNewInterval() {
  setInterval(async () => {
    try {
      const refreshed = await KCService._keycloak.updateToken(30);
      if (refreshed) {
        // eslint-disable-next-line no-console
        console.debug('Token refreshed' + refreshed);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to refresh token', err);
    }
  }, 20000);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default KCService;
