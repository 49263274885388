export const buildAISQuery = (
  mmsi: string | null | undefined,
  startDate: Date,
  endDate: Date
) => {
  if (!mmsi) return null;
  return {
    size: 10000,
    query: {
      bool: {
        filter: [
          {
            range: {
              event_ts: {
                format: 'strict_date_optional_time',
                gte: startDate.toISOString(),
                lte: endDate.toISOString()
              }
            }
          },
          {
            term: {
              'last.last.mmsi': mmsi
            }
          }
        ]
      }
    },
    sort: [
      {
        event_ts: {
          order: 'desc'
        }
      }
    ]
  };
};
