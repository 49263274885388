import {IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';

export const EyeToggleButton = styled(IconButton)(({theme}) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '3px',
  padding: '4px'
}));

export const Spacer = styled('div')({
  flexGrow: 1
});
