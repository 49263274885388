import React from 'react';
import {Box, Button, styled} from '@mui/material';
import {setSelectedVessel, setFocusedTab} from 'redux/vesselHistory';
import {setFocusedVessel} from 'redux/alerts';
import {Vessel} from 'types/Alerts';
import {useAppDispatch} from 'redux/hooks';
import {setMapCenter} from 'redux/map';

interface CardMenuProps {
  vessel: Vessel;
}

const MenuButton = styled(Button)(({theme}) => ({
  padding: '0 10px',
  color: theme.palette.primary.main,
  backgroundColor: '#1A1918',
  '&:hover': {
    backgroundColor: '#6B6965'
  },
  textTransform: 'capitalize',
  '&.Mui-disabled': {
    color: '#6B6965'
  }
}));

export const CardMenu: React.FC<CardMenuProps> = ({vessel}) => {
  const dispatch = useAppDispatch();

  const focusToMap = React.useCallback(() => {
    dispatch(setFocusedVessel(vessel));
    dispatch(
      setMapCenter({
        lat: vessel.lat,
        lon: vessel.lon,
        zoom: 10
      })
    );
  }, [dispatch, vessel]);

  const navigateToVesselHistory = () => {
    dispatch(setSelectedVessel(vessel));
    dispatch(setFocusedVessel(vessel));
    dispatch(setFocusedTab('history'));
  };

  const navigateToSimilarVessel = () => {
    dispatch(setSelectedVessel(vessel));
    dispatch(setFocusedVessel(vessel));
    dispatch(setFocusedTab('similar'));
  };

  const disableSimilar =
    vessel.properties.source === 'RF' || vessel.properties.source === 'ODS';

  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        height: '25px',
        justifyContent: 'center',
        bottom: '5px'
      }}
    >
      <MenuButton
        variant="outlined"
        onClick={navigateToSimilarVessel}
        disabled={disableSimilar}
      >
        Show Similar
      </MenuButton>
      <MenuButton variant="outlined" onClick={focusToMap}>
        Focus Vessel
      </MenuButton>
      <MenuButton variant="outlined" onClick={navigateToVesselHistory}>
        Vessel History
      </MenuButton>
    </Box>
  );
};
