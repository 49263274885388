import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface LayerState {
  watchboxesEnabled: boolean;
  eezsEnabled: boolean;
  searchAreasEnabled: boolean;
  rfHalos: boolean;
}

const initialState: LayerState = {
  watchboxesEnabled: true,
  eezsEnabled: true,
  searchAreasEnabled: true,
  rfHalos: true
};

export const layersSlice = createSlice({
  name: 'layers',
  initialState,
  reducers: {
    setLayerEnabled: (
      state,
      action: PayloadAction<{
        layer: 'watchboxes' | 'eezs' | 'searchAreas' | 'rfHalos';
        value: boolean;
      }>
    ) => {
      const {layer, value} = action.payload;
      if (layer === 'watchboxes') state.watchboxesEnabled = value;
      if (layer === 'eezs') state.eezsEnabled = value;
      if (layer === 'searchAreas') state.searchAreasEnabled = value;
      if (layer === 'rfHalos') state.rfHalos = value;
    },
    setWatchboxesEnabled: (state, action: PayloadAction<boolean>) => {
      state.watchboxesEnabled = action.payload;
    },
    setEezsEnabled: (state, action: PayloadAction<boolean>) => {
      state.eezsEnabled = action.payload;
    },
    setSearchAreasEnabled: (state, action: PayloadAction<boolean>) => {
      state.searchAreasEnabled = action.payload;
    },
    setRFHalosEnabled: (state, action: PayloadAction<boolean>) => {
      state.rfHalos = action.payload;
    }
  }
});

export const {
  setLayerEnabled,
  setWatchboxesEnabled,
  setEezsEnabled,
  setSearchAreasEnabled,
  setRFHalosEnabled
} = layersSlice.actions;

export default layersSlice.reducer;
