import React from 'react';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {LegendControlOption} from 'styles/legend_icons';
import './MapLegend.scss';
import {Spacer} from 'components/Styled/UtilityComponents';
import {useAppSelector} from 'redux/hooks';
import {selectMapView, selectIsMapModeLight} from 'redux/map';
import {MAX_WATCHBOX_ZO0M} from 'constants/constants';

export const accordionHeight = '36px !important';

export const LegendAccordionDetailRow = styled('div')({
  marginTop: 0,
  paddingLeft: 0,
  display: 'flex',
  alignItems: 'center'
});

export const LegendAccordionDetailRowLabel = styled('div')({
  paddingRight: 0,
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingLeft: '5px',
  textAlign: 'center'
  // cursor: 'pointer'
});

export const LegendAccordionDetailRowIconContainer = styled('div')({
  paddingLeft: '0',
  paddingTop: '0',
  paddingBottom: '0'
  // cursor: 'pointer'
});

export const LegendAccordionDetailRowIcon = styled('img')({
  fontSize: '12px',
  width: 28
});

interface LegendItemProps {
  item: LegendControlOption;
  isVisible: boolean;
}

const LegendItem: React.FC<LegendItemProps> = ({item, isVisible}) => {
  const currentZoom = useAppSelector(selectMapView).zoom;
  const isLightMode = useAppSelector(selectIsMapModeLight);

  return (
    <LegendAccordionDetailRow
      className={`legend-row-item ${!isVisible && 'hidden-layer-row'}`}
      key={item.id}
    >
      <LegendAccordionDetailRowIconContainer>
        {item.secondaryIcon && item.secondaryIconZoom ? (
          <>
            {currentZoom < MAX_WATCHBOX_ZO0M ? (
              <LegendAccordionDetailRowIcon
                src={item.secondaryIcon}
                // height={item.secondaryIconHeight}
                width={item.secondaryIconWidth}
                style={{padding: 3}}
              />
            ) : (
              <LegendAccordionDetailRowIcon src={item.icon} />
            )}
          </>
        ) : (
          <LegendAccordionDetailRowIcon
            src={isLightMode && item.lightModeIcon ? item.lightModeIcon : item.icon}
          />
        )}
      </LegendAccordionDetailRowIconContainer>
      <LegendAccordionDetailRowLabel>
        <Typography fontSize={14}>{item.label}</Typography>
      </LegendAccordionDetailRowLabel>
      <Spacer />
      {/* <EyeToggleButton onClick={() => toggleLegendLayer()}>
        {isVisible ? (
          <Visibility
            sx={(theme) => ({
              fontSize: '16px',
              color: theme.palette.primary.main
            })}
          />
        ) : (
          <VisibilityOff
            sx={(theme) => ({
              fontSize: '16px',
              color: theme.palette.primary.main
            })}
          />
        )}
      </EyeToggleButton> */}
    </LegendAccordionDetailRow>
  );
};

export default LegendItem;
