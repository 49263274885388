import {styled} from '@mui/material/styles';
export const AlertStripCardContainer = styled('div')({
  paddingLeft: '12px',
  paddingRight: '12px'
});

export const AlertStripCardHeader = styled('p')(({theme}) => ({
  color: theme.palette.secondary.contrastText,
  marginBottom: '12px'
}));

export const AlertStripCardSubHeader = styled('div')({
  display: 'flex',
  marginBottom: '0px',
  height: '40px'
});

export const AlertStripCardNoApply = styled('div')(() => ({
  width: '100%'
}));

export const AlertStripCardCollectionCount = styled('div')({
  width: '100%',
  marginTop: '1px',
  textAlign: 'right',
  paddingTop: '8px'
});

export const ImageStripCardContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start'
});

export const ImageStripAlertDetails = styled('div')(({theme}) => ({
  position: 'relative',
  backgroundColor: '#333231',
  color: theme.palette.primary.main,
  borderTop: '1px solid #151414',
  width: '100%',
  zIndex: '1',
  display: 'flex',
  overflowWrap: 'break-word',
  transitionDuration: '0.28s',
  transitionProperty: 'box-shadow, opacity, background',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  // '&:hover': {
  //   backgroundColor: '#4C4B48',
  //   borderColor: '#4FC3F7'
  // },
  margin: '0px 8px 8px 0px'
}));

export const StripImageContainer = styled('div')({
  height: '150px',
  width: '150px',
  margin: '10px',
  position: 'relative',
  backgroundColor: '#110f0e',
  // '&:hover': {
  //   backgroundColor: ''
  // },
  cursor: 'pointer'
});

export const ResponsiveOverlay = styled('div')({
  display: 'flex',
  maxHeight: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
  position: 'relative',
  height: '100%'
});

export const StripImagePreview = styled('div')({
  backgroundColor: '#000',
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000'
  }
});

export const StripImagePreviewTextDecorator = styled('span')({
  textDecoration: 'underline'
});

export const StripRadioContainer = styled('div')({
  display: 'flex',
  alignItems: 'start'
});

export const StripDetailCard = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const StripDetailCardTitle = styled('div')({
  width: '100%',
  marginTop: '6px',
  fontSize: '16px',
  marginBottom: '6px'
});

export const StripDetailCardBody = styled('div')({
  marginBottom: '6px',
  width: '100%',
  fontSize: '16px'
});
