import React from 'react';
import {Checkbox, FormControlLabel, FormGroup, Grid, styled} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectDetectionTypes,
  selectFiltersDisabled,
  setDetectionTypes
} from 'redux/filters';
import {Detection} from 'types/Filters';

export const PanelCheckbox = styled(Checkbox)(({theme}) => ({
  color: theme.palette.primary.label,
  '&.Mui-checked': {
    color: theme.palette.primary.contrastText
  },
  '&.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

export const PanelFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  color: theme.palette.primary.label,
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

const DetectionTypePanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const detectionTypes = useAppSelector(selectDetectionTypes);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);

  const toggleDetectionType = (key: keyof Detection) => {
    const updatedValue = {
      ...detectionTypes,
      [key]: !detectionTypes[key]
    };
    dispatch(setDetectionTypes(updatedValue));
  };
  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 16px',
        flex: '1 1 auto',
        maxWidth: '100%'
      }}
    >
      <Grid container spacing={2} sx={{marginBottom: '16px'}}>
        <Grid item xs={6}>
          <PanelFormControlLabel
            control={
              <PanelCheckbox
                onChange={() => {
                  toggleDetectionType('correlated');
                }}
                disabled={filtersDisabled}
                checked={detectionTypes.correlated}
              />
            }
            label="Correlated"
          />
        </Grid>
        <Grid item xs={6}>
          <PanelFormControlLabel
            control={
              <PanelCheckbox
                onChange={() => {
                  toggleDetectionType('dark');
                }}
                disabled={filtersDisabled}
                checked={detectionTypes.dark}
              />
            }
            label="Uncorrelated (Dark)"
          />
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default DetectionTypePanel;
