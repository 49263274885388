import React from 'react';

import {styled} from '@mui/material';

const VesselWrapper = styled('div')(() => ({
  width: '100%',
  height: 'calc(100vh - 369px)',
  overflowX: 'hidden',
  overflowY: 'auto',
  minWidth: '410px'
}));

interface VesselHistoryProps {
  children?: React.ReactNode;
}

const TabWrapper: React.FC<VesselHistoryProps> = ({children}) => {
  return <VesselWrapper>{children}</VesselWrapper>;
};

export default TabWrapper;
