/* eslint react-hooks/exhaustive-deps: 0 */
import {Box, Paper, Popper, Typography, styled, Menu, MenuItem} from '@mui/material';
import React from 'react';
import {selectAlertById} from 'redux/alerts/selectors';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {
  selectCanvasHeight,
  selectCanvasWidth,
  selectDeviceRatio,
  selectDisableRedoActionHistory,
  selectDisableUndoActionHistory,
  selectFuture,
  selectPast,
  selectPresent,
  selectVesselMensurationActive,
  selectTabIndex,
  selectVesselMensurationEnlarged,
  selectCollisionTolerance,
  selectRenderedLines,
  selectRenderedLabels,
  selectRenderedElements,
  selectRenderedVertices,
  selectImageNaturalHeight,
  selectCursorLine,
  selectDoubleClick,
  selectVesselChipRotation,
  selectVesselChipRotationActive
} from 'redux/vesselChipMensuration/selectors';
import {
  add,
  redo,
  undo,
  resetState,
  setActive,
  setPast,
  setFuture,
  setPresent,
  setCursorLine,
  setDblClick
} from 'redux/vesselChipMensuration/slice';
import {selectSelectedVessel} from 'redux/vesselHistory/selectors';
import {
  ADS_CLICK_ICON,
  DELETE_OUTLINE_ICON,
  LEFT_CLICK_ICON
} from 'styles/mensuration_icons';
import {Label, Line, Vector, Vertex} from 'types/Mensuration';
import {DefaultCanvasSize, LargeCanvasSize} from './IconButtonsOnVesselChip';
import {BackgroundCanvas} from './BackgroundChipCanvas';
import {CompassLayer} from './CompassLayer';

export const ToastIcon = styled('img')({
  fontSize: '12px',
  width: 24,
  marginRight: 2
});

const Mensuration: React.FC = () => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  // For timeout logic
  const [timeoutArray, setTimeoutArray] = React.useState([] as Array<NodeJS.Timeout>);
  const [deactivationCount, setDeactivationCount] = React.useState(0);

  const [layerCount, setLayerCount] = React.useState(0);

  // For single click popover
  const [anchorElClick, setAnchorElClick] = React.useState<HTMLCanvasElement | null>(
    null
  );
  const open = Boolean(anchorElClick);
  const id = open ? 'click-popper' : undefined;

  // For double click popover
  const [anchorElDblClick, setAnchorElDblClick] =
    React.useState<HTMLCanvasElement | null>(null);
  const openDbl = Boolean(anchorElDblClick);
  const idDbl = openDbl ? 'dbl-click-popper' : undefined;

  // For delete hotkey popover
  const [anchorElDelete, setAnchorElDelete] = React.useState<HTMLCanvasElement | null>(
    null
  );
  const openDelete = Boolean(anchorElDelete);
  const idDelete = openDelete ? 'delete-popper' : undefined;

  // Right click context menu
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const selectedVessel = useAppSelector(selectSelectedVessel);
  const dispatch = useAppDispatch();
  // Ternary to ensure non-null, cannot branch useAppSelector
  const selectedAlert = useAppSelector((state) =>
    selectAlertById(state, selectedVessel ? selectedVessel.alertId : '')
  );
  const selectedWidth = useAppSelector(selectCanvasWidth);
  const selectedHeight = useAppSelector(selectCanvasHeight);
  const selectedDeviceRatio = useAppSelector(selectDeviceRatio);
  const selectedPresent = useAppSelector(selectPresent);
  const selectedPast = useAppSelector(selectPast);
  const selectedFuture = useAppSelector(selectFuture);
  const mensurationActive = useAppSelector(selectVesselMensurationActive);
  const selectedDisableUndo = useAppSelector(selectDisableUndoActionHistory);
  const selectedDisableRedo = useAppSelector(selectDisableRedoActionHistory);
  const selectedMensurationEnlarged = useAppSelector(selectVesselMensurationEnlarged);
  const selectedTabIndex = useAppSelector(selectTabIndex);
  const selectedCollisionTolerance = useAppSelector(selectCollisionTolerance);
  const renderedLines = useAppSelector(selectRenderedLines);
  const renderedLabels = useAppSelector(selectRenderedLabels);
  const renderedVertices = useAppSelector(selectRenderedVertices);
  const renderedElements = useAppSelector(selectRenderedElements);
  const imageNaturalHeight = useAppSelector(selectImageNaturalHeight);
  const cursorLine = useAppSelector(selectCursorLine);
  const dblClick = useAppSelector(selectDoubleClick);
  const selectedRotation = useAppSelector(selectVesselChipRotation);
  const rotationActive = useAppSelector(selectVesselChipRotationActive);

  const clearAllTimeouts = () => {
    // Remove popover
    timeoutArray.forEach((timeout) => {
      clearTimeout(timeout);
    });
  };
  const createDeleteToastTimeout = () => {
    setAnchorElDelete(canvasRef.current);
    const timeout = setTimeout(() => {
      setAnchorElDelete(null);
    }, 2000);
    setTimeoutArray([...timeoutArray, timeout]);
  };
  const createOnClickToastTimeout = () => {
    setAnchorElClick(canvasRef.current);
    const timeout = setTimeout(() => {
      setAnchorElClick(null);
    }, 2000);
    setTimeoutArray([...timeoutArray, timeout]);
  };
  const createOnDblClickToastTimeout = () => {
    setAnchorElDblClick(canvasRef.current);
    const timeout = setTimeout(() => {
      setAnchorElDblClick(null);
    }, 2000);
    setTimeoutArray([...timeoutArray, timeout]);
  };
  // const rotateCoords = (x: number, y: number, angleRadians: number) => {
  //   const rotatedX = Math.floor(x * Math.cos(angleRadians) - y * Math.sin(angleRadians));
  //   const rotatedY = Math.floor(x * Math.sin(angleRadians) + y * Math.cos(angleRadians));
  //   return {x: rotatedX, y: rotatedY};
  // };
  const mouseCoords = (clientX: number, clientY: number) => {
    if (!canvasRef.current) {
      return {x: 0, y: 0};
    }
    const x = clientX;
    const y = clientY;
    const rect = canvasRef.current.getBoundingClientRect();

    return {x: x - rect.left, y: y - rect.top};
  };
  const mouseHoverLine = (line: Line, x: number, y: number) => {
    const dx = line.vertices[1].xPixel - line.vertices[0].xPixel;
    const dy = line.vertices[1].yPixel - line.vertices[0].yPixel;
    const t =
      ((x - line.vertices[0].xPixel) * dx + (y - line.vertices[0].yPixel) * dy) /
      (dx * dx + dy * dy);
    const lineX = lerp(line.vertices[0].xPixel, line.vertices[1].xPixel, t);
    const lineY = lerp(line.vertices[0].yPixel, line.vertices[1].yPixel, t);
    return {x: lineX, y: lineY};
  };
  const lineHovering = (
    distance: number,
    tolerance: number,
    axis: 'x' | 'y',
    mouse: {
      x: number;
      y: number;
    },
    line: Line
  ) => {
    if (axis === 'x') {
      return (
        distance < selectedCollisionTolerance &&
        ((mouse.x > line.vertices[0].xPixel && mouse.x < line.vertices[1].xPixel) ||
          (mouse.x < line.vertices[0].xPixel && mouse.x > line.vertices[1].xPixel))
      );
    } else if (axis === 'y') {
      return (
        distance < selectedCollisionTolerance &&
        ((mouse.y > line.vertices[0].yPixel && mouse.y < line.vertices[1].yPixel) ||
          (mouse.y < line.vertices[0].yPixel && mouse.y > line.vertices[1].yPixel))
      );
    }
  };
  const mouseHoverLabel = (label: Label, x: number, y: number) => {
    let inside = false;

    const poly = [];
    const topLeft = [
      label.xPixel - selectedCollisionTolerance,
      label.yPixel - selectedCollisionTolerance
    ];
    const topRight = [
      label.xPixel + label.width + label.lineWidth + selectedCollisionTolerance,
      label.yPixel - selectedCollisionTolerance
    ];
    const bottomRight = [
      label.xPixel + label.width + label.lineWidth + selectedCollisionTolerance,
      label.yPixel + label.height + label.lineWidth + selectedCollisionTolerance
    ];
    const bottomLeft = [
      label.xPixel - selectedCollisionTolerance,
      label.yPixel + label.height + label.lineWidth + selectedCollisionTolerance
    ];
    poly.push(topLeft, topRight, bottomRight, bottomLeft, topLeft);

    for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
      const xi = poly[i][0],
        yi = poly[i][1];
      const xj = poly[j][0],
        yj = poly[j][1];
      const intersect = yi > y != yj > y && x < ((xj - xi) * (x - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };
  const onKeypressDownHandler = (e: React.KeyboardEvent<HTMLCanvasElement>) => {
    if (!mensurationActive) {
      return;
    }
    // Browser uses CTRL+Y for alerts so need to disable
    e.preventDefault();
    switch (e.key) {
      case 'z':
        if (e.ctrlKey || e.metaKey) {
          if (!selectedDisableUndo && !dblClick) {
            clearAllTimeouts();
            dispatch(setCursorLine(null));
            setAnchorElClick(null);
            dispatch(undo());
            dispatch(undo());
            dispatch(undo());
            dispatch(setDblClick(!dblClick));
          } else if (!selectedDisableUndo) {
            clearAllTimeouts();
            setAnchorElDblClick(null);
            dispatch(undo());
            dispatch(setDblClick(!dblClick));
          }
        }
        break;
      case 'y':
        if (e.ctrlKey || e.metaKey) {
          if (!selectedDisableRedo && dblClick) {
            clearAllTimeouts();
            setAnchorElDblClick(null);
            dispatch(setCursorLine(null));
            dispatch(redo());
            dispatch(redo());
            dispatch(redo());
            dispatch(setDblClick(!dblClick));
          } else if (!selectedDisableRedo) {
            clearAllTimeouts();
            setAnchorElClick(null);
            dispatch(redo());
            dispatch(setDblClick(!dblClick));
          }
        }
        break;
      case 'Escape':
        // remove last vertex if esc is pressed while one vertex is down for a line
        if (dblClick) {
          clearAllTimeouts();
          dispatch(setCursorLine(null));
          dispatch(undo());
          dispatch(setDblClick(false));
          dispatch(setActive(false));
        } else {
          clearAllTimeouts();
          dispatch(setCursorLine(null));
          dispatch(setActive(false));
        }
        break;

      case 'Backspace':
        if (selectionStateActive()) {
          handleDelete();
        }
    }
  };
  const handleCloseClick = () => {
    setAnchorElClick(null);
  };
  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };
  const handleCloseDblClick = () => {
    setAnchorElDblClick(null);
  };
  const hoverStateActive = () => {
    let elements: Array<Label | Line | Vertex>;
    if (selectedPresent) {
      elements = [...selectedPast, selectedPresent];
    } else {
      elements = [];
    }

    let hoveredElements = [];
    if (elements.length > 0) {
      hoveredElements = elements.filter((x: Line | Label | Vertex) => x.hover === true);
    }

    if (hoveredElements.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const selectionStateActive = () => {
    let elements: Array<Label | Line | Vertex>;
    if (selectedPresent) {
      elements = [...selectedPast, selectedPresent];
    } else {
      elements = [];
    }

    let selectedElements = [];
    if (elements.length > 0) {
      selectedElements = elements.filter(
        (x: Line | Label | Vertex) => x.selected === true
      );
    }

    if (selectedElements.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const onClickHandler = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!mensurationActive) {
      return;
    }

    if (!dblClick) {
      if (selectionStateActive() && !hoverStateActive()) {
        handleDeselection();
      } else if (selectionStateActive() && hoverStateActive()) {
        handleDeselection();
        handleSelection();
        return;
      } else if (hoverStateActive()) {
        handleSelection();
        return;
      }
    }

    setAnchorElClick(null);
    if (!dblClick) {
      const mouse = mouseCoords(e.clientX, e.clientY);
      const vector: Vector = {
        x: mouse.x,
        y: mouse.y
      };
      dispatch(add(new Vertex(vector, layerCount)));
      dispatch(setDblClick(true));
    }
  };
  const onDblClickHandler = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!canvasRef.current || !mensurationActive || selectionStateActive() || !dblClick) {
      return;
    }
    clearAllTimeouts();
    setAnchorElDblClick(null);

    const mouse = mouseCoords(e.clientX, e.clientY);
    const vector: Vector = {
      x: mouse.x,
      y: mouse.y
    };

    const scalingRatio =
      imageNaturalHeight / (canvasRef.current.height / selectedDeviceRatio[2]);

    let gsd = 1;
    if (selectedAlert) {
      gsd =
        selectedAlert.inventoryItems[
          selectedAlert.inventoryItems.findIndex(
            (x) => x.inventoryId === selectedVessel?.inventoryId
          )
        ].gsdMeters;
    } else {
      gsd = selectedVessel?.inventorySummary.gsdMeters as number;
    }
    const newVertex = new Vertex(vector, layerCount);
    const newLine = new Line(
      [newVertex, selectedPresent as Vertex],
      gsd,
      scalingRatio,
      layerCount
    );
    const newLabel = new Label(newLine, layerCount);
    dispatch(add(newVertex));
    dispatch(add(newLine));
    dispatch(add(newLabel));
    setLayerCount(layerCount + 1);
    dispatch(setDblClick(false));
    dispatch(setCursorLine(null));
  };
  const onRightClickHandler = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!mensurationActive || dblClick) {
      return;
    }

    e.preventDefault();
    if (!canvasRef.current) {
      return;
    }

    if (!selectionStateActive()) {
      handleSelection();
    }

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: e.clientX + 2,
            mouseY: e.clientY - 6
          }
        : null
    );
  };
  // Only use this for after a single click is made. Otherwise it will break due to shape stack layering.
  const handleMouseFollow = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    handleHover(e);
    handleLineFollow(e);
  };
  const lerp = (a: number, b: number, x: number) => {
    return a + x * (b - a);
  };
  const handleDelete = () => {
    if (!selectionStateActive() || !selectedPresent || selectedPast.length === 0) {
      return;
    }

    if (deactivationCount < 3) {
      clearAllTimeouts();
      setAnchorElDelete(null);
      createOnClickToastTimeout();
    }

    const elements = [...selectedPast, selectedPresent];
    const selectedElements = elements.filter(
      (x: Line | Label | Vertex) => x.selected === true
    );

    const isInPast =
      selectedPast.indexOf(selectedElements[selectedElements.length - 1]) > 0;
    const isPresent =
      selectedElements[selectedElements.length - 1].id === selectedPresent.id;

    const cpyPast = [...selectedPast];

    if (isInPast) {
      const pastIdx = selectedPast.indexOf(selectedElements[selectedElements.length - 1]);
      // for enabling undo / redo functionality
      // const slicedLayer = cpyPast.splice(pastIdx - 3, 4);
      // slicedLayer.reverse();
      cpyPast.splice(pastIdx - 3, 4);
      dispatch(setFuture([]));
      dispatch(setPast(cpyPast));
    } else if (isPresent) {
      // for enabling undo / redo functionality
      // const slicedLayer = cpyPast.splice(cpyPast.length - 3, 4);
      // slicedLayer.reverse();
      cpyPast.splice(cpyPast.length - 3, 4);
      const next = cpyPast.splice(cpyPast.length - 1, 1);
      dispatch(setFuture([]));
      dispatch(setPresent(next[0]));
      dispatch(setPast(cpyPast));
    }
    if (contextMenu) {
      setContextMenu(null);
    }
  };
  const handleSelection = () => {
    if (
      !hoverStateActive() ||
      !canvasRef.current ||
      !selectedPresent ||
      selectedPast.length === 0
    ) {
      return;
    }
    if (deactivationCount < 3) {
      clearAllTimeouts();
      setAnchorElClick(null);
      createDeleteToastTimeout();
    }
    const renderedElementsCpy = [...renderedElements];
    renderedElementsCpy.forEach((element) => {
      if (element && element.hover === true) {
        element.setSelected(true);
        element.setHover(false);
      }
    });
    const present = renderedElementsCpy[renderedElementsCpy.length - 1];
    const elementsPast = renderedElementsCpy.slice(0, renderedElementsCpy.length - 1);
    if (present) {
      dispatch(setPresent(present));
    }
    dispatch(setPast(elementsPast));
  };
  const handleDeselection = () => {
    if (
      !selectionStateActive() ||
      !canvasRef.current ||
      !selectedPresent ||
      selectedPast.length === 0
    ) {
      return;
    }

    if (deactivationCount < 3) {
      clearAllTimeouts();
      setAnchorElDelete(null);
      createOnClickToastTimeout();
    }

    const renderedElementsCpy = [...renderedElements];

    renderedElementsCpy.forEach((element) => {
      if (element && element.hover === true) {
        element.setSelected(false);
        element.setHover(true);
      } else if (element && element.selected === true) {
        element.setSelected(false);
      }
    });

    const present = renderedElementsCpy[renderedElementsCpy.length - 1];
    const elementsPast = renderedElementsCpy.slice(0, renderedElementsCpy.length - 1);
    if (present) {
      dispatch(setPresent(present));
    }
    dispatch(setPast(elementsPast));
  };
  const handleHover = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (
      !mensurationActive ||
      !canvasRef.current ||
      (selectedPast.length === 0 && !selectedPresent)
    ) {
      return;
    }
    const mouse = mouseCoords(e.clientX, e.clientY);
    // for each line determine if mouse is intersecting the tolerance
    let layerCount = 0;
    const renderedElementsCpy = [...renderedElements];

    const sortedRenderLines = renderedLines.sort((a: Line, b: Line) => b.layer - a.layer);
    const sortedRenderLabels = renderedLabels.sort(
      (a: Label, b: Label) => b.layer - a.layer
    );
    // Check labels for collision first then lines
    sortedRenderLabels.forEach((label) => {
      if (mouseHoverLabel(label, mouse.x, mouse.y) && layerCount === 0) {
        renderedElementsCpy.forEach((element) => {
          if (element && element.layer === label.layer) {
            element.setHover(true);
          }
          layerCount = 1;
        });
      } else {
        renderedElementsCpy.forEach((element) => {
          if (element && element.layer === label.layer) {
            element.setHover(false);
          }
        });
      }
    });

    if (layerCount === 0 && !hoverStateActive()) {
      sortedRenderLines.forEach((line) => {
        const nearestPoint = mouseHoverLine(line, mouse.x, mouse.y);
        const dx = mouse.x - nearestPoint.x;
        const dy = mouse.y - nearestPoint.y;
        const distance = Math.abs(Math.sqrt(dx * dx + dy * dy));
        const hovering = lineHovering(
          distance,
          selectedCollisionTolerance,
          'x',
          mouse,
          line
        );
        if (renderedLabels.length > 0 && hovering && layerCount === 0) {
          renderedElementsCpy.forEach((element) => {
            if (element && element.layer === line.layer) {
              element.setHover(true);
            }
            layerCount = 1;
          });
        } else if (renderedLabels.length > 0) {
          renderedElementsCpy.forEach((element) => {
            if (element && element.layer === line.layer) {
              element.setHover(false);
            }
          });
        }
      });
    }
    const present = renderedElementsCpy[renderedElementsCpy.length - 1];
    const elementsPast = renderedElementsCpy.slice(0, renderedElementsCpy.length - 1);
    if (present) {
      dispatch(setPresent(present));
    }
    dispatch(setPast(elementsPast));
  };
  const handleLineFollow = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!mensurationActive) {
      return;
    }

    if (!dblClick) {
      return;
    }
    const mouse = mouseCoords(e.clientX, e.clientY);
    const currentVertex = selectedPresent as Vertex;
    const vector: Vector = {
      x: mouse.x,
      y: mouse.y
    };
    const newVertex = new Vertex(vector, -1);
    const line = new Line([currentVertex, newVertex], 0, 0, -1);
    dispatch(setCursorLine(line));
    render();
  };
  const handleObjectScalingTranslation = () => {
    if (!selectedPast && !selectedPresent && !selectedFuture) {
      return;
    }

    const pastElements = [...selectedPast];
    const futureElements = [...selectedFuture];
    const translationScale = DefaultCanvasSize.width / LargeCanvasSize.width;

    pastElements.forEach((shape: Line | Vertex | Label) => {
      if (shape && selectedMensurationEnlarged) {
        shape.translateByRatio(1 / translationScale);
      } else if (shape) {
        shape.translateByRatio(translationScale);
      }
    });
    futureElements.forEach((shape: Line | Vertex | Label) => {
      if (shape && selectedMensurationEnlarged) {
        shape.translateByRatio(1 / translationScale);
      } else if (shape) {
        shape.translateByRatio(translationScale);
      }
    });

    if (selectedPresent) {
      const presentElement = [selectedPresent];
      presentElement.forEach((shape: Line | Vertex | Label) => {
        if (shape && selectedMensurationEnlarged) {
          shape.translateByRatio(1 / translationScale);
        } else if (shape) {
          shape.translateByRatio(translationScale);
        }
      });
      setPresent(presentElement[0]);
    }

    setPast(pastElements);
    setFuture(futureElements);
  };
  const render = () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const drawContext = canvas.getContext('2d');
    if (!drawContext || !selectedVessel) {
      return;
    }

    if (!selectedVessel) {
      return;
    }
    drawContext.clearRect(0, 0, selectedWidth, selectedHeight);
    // Draw cursor line
    if (cursorLine && dblClick && mensurationActive) {
      cursorLine.draw(drawContext);
    }
    // Draw all lines
    renderedLines.forEach((line: Line) => {
      line.draw(drawContext);
    });
    // Draw all vertices
    renderedVertices.forEach((vertex: Vertex) => {
      vertex.draw(drawContext);
    });

    // Draw all Labels
    renderedLabels.forEach((label: Label) => {
      label.draw(drawContext);
    });
  };

  React.useEffect(() => {
    // Called on component will unmount for cleanup
    return () => {
      dispatch(resetState());
    };
  }, []);
  React.useEffect(() => {
    if (deactivationCount >= 2) {
      return;
    }
    if (mensurationActive && !selectedPresent) {
      createOnClickToastTimeout();
    }
    if (mensurationActive && !dblClick && selectedPresent) {
      setDeactivationCount(deactivationCount + 1);
      createOnClickToastTimeout();
    } else if (mensurationActive && dblClick && selectedPresent) {
      createOnDblClickToastTimeout();
    }
  }, [mensurationActive, dblClick]);
  // Render The Canvas
  React.useEffect(() => {
    render();
  }, [selectedPast, selectedFuture, selectedPresent]);
  React.useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    canvas.style.width = `${selectedWidth}px`;
    canvas.style.height = `${selectedHeight}px`;
    ctx.scale(selectedDeviceRatio[2], selectedDeviceRatio[2]);
    handleObjectScalingTranslation();
    render();
  }, [selectedMensurationEnlarged]);

  React.useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const rotation = ((selectedRotation % 360) + 360) % 360;
    const canvas = canvasRef.current;
    const scaling = 2 * selectedDeviceRatio[2];
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    if (rotationActive) {
      ctx.translate(selectedDeviceRatio[0] / scaling, selectedDeviceRatio[1] / scaling);
      ctx.rotate((Math.PI / 180) * rotation);
      ctx.translate(-selectedDeviceRatio[0] / scaling, -selectedDeviceRatio[1] / scaling);
      if (dblClick) {
        dispatch(setCursorLine(null));
        dispatch(undo());
        dispatch(setDblClick(false));
        dispatch(setActive(false));
      } else {
        dispatch(setCursorLine(null));
        dispatch(setActive(false));
      }
    } else if (!rotationActive) {
      ctx.translate(selectedDeviceRatio[0] / scaling, selectedDeviceRatio[1] / scaling);
      ctx.rotate((Math.PI / 180) * -rotation);
      ctx.translate(-selectedDeviceRatio[0] / scaling, -selectedDeviceRatio[1] / scaling);
    }
    render();
  }, [selectedRotation, rotationActive]);

  return (
    <>
      <Box sx={{position: 'relative'}}>
        <BackgroundCanvas key="background-canvas" />
        <CompassLayer key="compass-canvas" />
        <canvas
          ref={canvasRef}
          className={
            mensurationActive
              ? 'cursor-crosshair no-focus foreground-canvas'
              : 'no-focus foreground-canvas'
          }
          tabIndex={selectedTabIndex}
          width={selectedDeviceRatio[0]}
          height={selectedDeviceRatio[1]}
          onClick={(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) =>
            onClickHandler(e)
          }
          onKeyDown={(e: React.KeyboardEvent<HTMLCanvasElement>) =>
            onKeypressDownHandler(e)
          }
          onDoubleClick={(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) =>
            onDblClickHandler(e)
          }
          onMouseMove={(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) =>
            handleMouseFollow(e)
          }
          onContextMenu={(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) =>
            onRightClickHandler(e)
          }
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
              : undefined
          }
        >
          <MenuItem onClick={handleDelete}>Delete Selection</MenuItem>
        </Menu>
      </Box>
      <Popper
        id={id}
        open={open}
        sx={{zIndex: 4}}
        anchorEl={anchorElClick}
        onClick={handleCloseClick}
        placement={'bottom'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: selectedMensurationEnlarged ? [0, -535] : [0, -200]
            }
          }
        ]}
      >
        <Paper
          sx={{
            width: '173px',
            height: '34px',
            borderRadius: 0,
            backgroundColor: 'rgba(76, 75, 72, 0.85)',
            border: '1px solid rgba(127, 125, 121, 1)'
          }}
        >
          <Box
            sx={(theme) => ({
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.primary.main
            })}
          >
            <ToastIcon src={LEFT_CLICK_ICON} />
            <Typography>Click to begin a line</Typography>
          </Box>
        </Paper>
      </Popper>
      <Popper
        id={idDbl}
        open={openDbl}
        anchorEl={anchorElDblClick}
        onClick={handleCloseDblClick}
        placement={'bottom'}
        sx={{zIndex: 4}}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: selectedMensurationEnlarged ? [0, -535] : [0, -200]
            }
          }
        ]}
      >
        <Paper
          sx={{
            width: '198px',
            height: '34px',
            borderRadius: 0,
            backgroundColor: 'rgba(76, 75, 72, 0.85)',
            border: '1px solid rgba(127, 125, 121, 1)'
          }}
        >
          <Box
            sx={(theme) => ({
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.primary.main
            })}
          >
            <ToastIcon src={ADS_CLICK_ICON}></ToastIcon>
            <Typography>Double click to end line</Typography>
          </Box>
        </Paper>
      </Popper>
      <Popper
        id={idDelete}
        open={openDelete}
        anchorEl={anchorElDelete}
        onClick={handleCloseDelete}
        sx={{zIndex: 4}}
        placement={'bottom'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: selectedMensurationEnlarged ? [0, -535] : [0, -200]
            }
          }
        ]}
      >
        <Paper
          sx={{
            width: '198px',
            height: '34px',
            borderRadius: 0,
            backgroundColor: 'rgba(76, 75, 72, 0.85)',
            border: '1px solid rgba(127, 125, 121, 1)'
          }}
        >
          <Box
            sx={(theme) => ({
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.primary.main
            })}
          >
            <ToastIcon src={DELETE_OUTLINE_ICON} />
            <Typography>Backspace to remove</Typography>
          </Box>
        </Paper>
      </Popper>
    </>
  );
};

export default Mensuration;
