import {styled} from '@mui/system';
import {Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';

export const alertAccordionHeight = '36px !important';

export const Alert = styled(Accordion)(({theme}) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  paddingLeft: '0px',
  paddingRight: '0px'
}));

export const AlertSummary = styled(AccordionSummary)(({theme}) => ({
  pointerEvents: 'none',
  flexDirection: 'row-reverse',
  minHeight: alertAccordionHeight,
  height: alertAccordionHeight,
  borderBottom: `1px solid ${theme.palette.secondary.contrastText}`,
  paddingLeft: '6px',
  width: '100%',
  display: 'inline-flex',
  paddingRight: '0px'
}));

export const AlertDetails = styled(AccordionDetails)({
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingBottom: '0px',
  paddingTop: '0px'
}) as typeof AccordionDetails;

export const AlertSummaryContent = styled(Typography)({
  width: '100%',
  paddingRight: '20px',
  paddingTop: '2px',
  fontSize: '14px'
});

export const AlertSummaryVesselCount = styled(Typography)({
  whiteSpace: 'nowrap',
  fontSize: '14px',
  paddingTop: '2px',
  minWidth: '150px',
  textAlign: 'right'
});
