import {Button, FormControlLabel, FormGroup, Grid, Switch, styled} from '@mui/material';
import React from 'react';
import {
  resetDrawnWatchbox,
  selectFiltersDisabled,
  selectGeoFiltering,
  toggleGeoFiltering
} from 'redux/filters';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {PanelCheckbox} from '../menus/CollectionFilter';
import {selectDrawingTool, toggleDrawingEnabled} from 'redux/map';

const DrawingEnable = styled(Switch)(({theme}) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: 'transparent',
    border: '1px solid #606060',
    height: 'auto',
    opacity: 1,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 22,
      height: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  },
  '& .MuiSwitch-switchBase': {
    '&:not(.Mui-checked)': {
      color: '#606060'
    },
    '&.Mui-checked + .MuiSwitch-track': {
      borderColor: theme.palette.primary.contrastText,
      backgroundColor: 'transparent'
    },
    '&.Mui-checked': {
      color: theme.palette.primary.contrastText
    }
  }
}));

export const PanelFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  color: theme.palette.primary.label,
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

const GeoSpatialPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const canDraw = useAppSelector(selectDrawingTool);
  const geoFilterEnabled = useAppSelector(selectGeoFiltering);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);

  const clearDrawings = () => {
    dispatch(resetDrawnWatchbox(true));
  };

  const handleEnableGeoFiltering = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleGeoFiltering({value: e.target.checked}));
  };
  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 16px',
        flex: '1 1 auto',
        maxWidth: '100%'
      }}
    >
      <Grid container spacing={2} sx={{marginBottom: '16px'}}>
        <Grid item xs={6}>
          <PanelFormControlLabel
            control={
              <PanelCheckbox
                onChange={() => {
                  dispatch(toggleDrawingEnabled(canDraw));
                }}
                disabled={filtersDisabled}
                checked={canDraw}
              />
            }
            label="Draw On Map"
          />
        </Grid>
        <Grid item xs={6}>
          <PanelFormControlLabel
            control={
              <DrawingEnable
                value={geoFilterEnabled}
                checked={geoFilterEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleEnableGeoFiltering(e);
                }}
              />
            }
            label={`Geo Filtering ${geoFilterEnabled ? 'ON' : 'OFF'}`}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="error"
            disabled={filtersDisabled}
            onClick={() => {
              clearDrawings();
            }}
          >
            CLEAR
          </Button>
        </Grid>
      </Grid>
    </FormGroup>
  );
};
export default GeoSpatialPanel;
