export const vesselTypeMap = new Map<string, string | Array<number>>([
  ['AC-M', 'Aircraft_Carrier'],
  ['NC-A-59', [59]],
  ['AP-A-54', [54]],
  ['P-A-6+60-69', [6, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69]],
  ['B-M', 'Barge'],
  ['PV-A-50', [50]],
  ['CO-M', 'Container'],
  ['PC-A-37', [37]],
  ['CA-M', 'Cargo'],
  ['PT-A-53', [53]],
  ['DO-A-34', [34]],
  ['S-M', 'Sailing'],
  ['D-M', 'Dredging'],
  ['SR-A-51', [51]],
  ['F-M', 'Fishing'],
  ['S-A-56+57', [56, 57]],
  ['G-M', 'Government'],
  ['T-M', 'Tanker'],
  ['HSC-A-40-49', [40, 41, 42, 43, 44, 45, 46, 47, 48, 49]],
  ['T-A-31+32', [31, 32]],
  ['LE-A-55', [55]],
  ['TU-M', 'Tug'],
  ['M-A-58', [58]],
  ['WIG-A-2+20-29', [2, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]],
  ['MO-A-35', [35]],
  ['U-A-9+90-99', [9, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99]]
]);
