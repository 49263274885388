import React from 'react';
import {Checkbox, FormControlLabel, FormGroup, Grid, styled} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectFiltersDisabled, selectSources, toggleSource} from 'redux/filters';

export const PanelCheckbox = styled(Checkbox)(({theme}) => ({
  color: theme.palette.primary.label,
  '&.Mui-checked': {
    color: theme.palette.primary.contrastText
  },
  '&.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

export const PanelFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  color: theme.palette.primary.label,
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

const DataSourcePanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const sources = useAppSelector(selectSources);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);

  const renderSourceOptions = () => {
    return sources.map((source) => {
      return (
        <Grid key={source.id} item xs={3}>
          <PanelFormControlLabel
            control={
              <PanelCheckbox
                onChange={() => {
                  dispatch(toggleSource(source.id));
                }}
                checked={source.value}
                disabled={filtersDisabled}
              />
            }
            label={source.label}
          />
        </Grid>
      );
    });
  };
  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 16px',
        flex: '1 1 auto',
        maxWidth: '100%'
      }}
    >
      <Grid container spacing={2} sx={{marginBottom: '16px'}}>
        {renderSourceOptions()}
      </Grid>
    </FormGroup>
  );
};

export default DataSourcePanel;
