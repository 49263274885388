import ProxyClient from 'api';
import {EOVesselResponse} from 'types/EOAlertsVessel';
import {VesselFingerprintResponse} from 'types/VesselFingerprint';

export const getFingerprintVessels = async (
  targetVesselId: string
): Promise<VesselFingerprintResponse> => {
  const api = ProxyClient.getApi();

  const {data} = await api.post('/fingerprint-vessel/' + targetVesselId);

  return data;
};

export const getEOVessel = async (params: {
  alertId: string;
  vesselId: string;
}): Promise<EOVesselResponse> => {
  const api = ProxyClient.getApi();

  const {data} = await api.get(`eo-alerts/${params.alertId}/vessels/${params.vesselId}`);

  return data;
};
