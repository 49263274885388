import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  AccessHistoryBody,
  getAccessHistory,
  newLoginRecord,
  updateAlertAccessHistory
} from 'api/user-analytics';
import {defaultStartDate, today} from 'redux/filters/defaults';
import {AlertAccessHistory} from 'types/UserPreferences';
import {formatDate} from 'utilities/date';
import {startOfDay} from 'date-fns';

export interface AccessHistoryState {
  accessHistory: AlertAccessHistory[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AccessHistoryState = {
  accessHistory: [],
  status: 'loading'
};

export const fetchAccessHistory = createAsyncThunk(
  'maus/accessHistory',
  async (params: {dateStart?: Date; dateEnd?: Date} = {}) => {
    if (!params.dateStart) {
      params.dateStart = defaultStartDate;
    }

    if (!params.dateEnd) {
      params.dateEnd = startOfDay(today);
    }

    const data = await getAccessHistory({
      dateStart: formatDate(params.dateStart, 'yyyy-MM-dd'),
      dateEnd: formatDate(params.dateEnd, 'yyyy-MM-dd')
    });

    return data;
  }
);

export const updateAccessHistory = createAsyncThunk(
  'maus/accessHistory/update',
  async (body: AccessHistoryBody & {queryParams: {dateStart?: Date; dateEnd?: Date}}) => {
    const {alertId, lastVesselId, vesselCount} = body;

    if (!body.queryParams.dateStart) {
      body.queryParams.dateStart = defaultStartDate;
    }

    if (!body.queryParams.dateEnd) {
      body.queryParams.dateEnd = startOfDay(today);
    }

    const data = await updateAlertAccessHistory(
      {alertId, lastVesselId, vesselCount},
      {
        dateStart: formatDate(body.queryParams.dateStart, 'yyyy-MM-dd'),
        dateEnd: formatDate(body.queryParams.dateEnd, 'yyyy-MM-dd')
      }
    );

    return data;
  }
);

export const createLoginRecord = createAsyncThunk('maus/createLoginRecord', async () => {
  return await newLoginRecord();
});

export const accessHistorySlice = createSlice({
  name: 'accessHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccessHistory.fulfilled, (state, action) => {
        state.status = 'idle';
        state.accessHistory = action.payload.accessHistory;
      })
      .addCase(fetchAccessHistory.rejected, (state) => {
        state.status = 'failed';
        state.accessHistory = [];
      });
  }
});

export default accessHistorySlice.reducer;
