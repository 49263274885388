import React from 'react';
import {useAppSelector} from 'redux/hooks';
import {
  selectCanvasHeight,
  selectCanvasWidth,
  selectDeviceRatio,
  selectVesselChipRotation,
  selectVesselChipRotationActive,
  selectVesselMensurationEnlarged
} from 'redux/vesselChipMensuration';
import {Compass, TickPoints} from 'utilities/compass';

export const CompassLayer: React.FC = () => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const selectedWidth = useAppSelector(selectCanvasWidth);
  const selectedHeight = useAppSelector(selectCanvasHeight);
  const selectedDeviceRatio = useAppSelector(selectDeviceRatio);
  const selectedMensurationEnlarged = useAppSelector(selectVesselMensurationEnlarged);
  const selectedRotation = useAppSelector(selectVesselChipRotation);
  const rotationActive = useAppSelector(selectVesselChipRotationActive);

  const scaleCanvas = React.useCallback(
    (drawContext: CanvasRenderingContext2D) => {
      if (!selectedMensurationEnlarged) {
        drawContext.canvas.style.width = `${selectedWidth}px`;
        drawContext.canvas.style.height = `${selectedHeight}px`;
        drawContext.scale(selectedDeviceRatio[2], selectedDeviceRatio[2]);
      } else {
        drawContext.canvas.style.width = `${
          drawContext.canvas.width / selectedDeviceRatio[2]
        }px`;
        drawContext.canvas.style.height = `${
          drawContext.canvas.height / selectedDeviceRatio[2]
        }px`;
        drawContext.scale(selectedDeviceRatio[2], selectedDeviceRatio[2]);
      }
    },
    [selectedWidth, selectedHeight, selectedDeviceRatio, selectedMensurationEnlarged]
  );

  // Get a canvas -- If fail to get canvas the console.err
  const retrieveValidCanvasContext = React.useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const drawContext = canvas.getContext('2d');
    if (!drawContext) {
      return;
    }

    return drawContext;
  }, []);

  const drawCompass = React.useCallback(
    (
      drawContext: CanvasRenderingContext2D,
      rotationDegrees: number,
      rotationActive: boolean
    ) => {
      const compass = new Compass(
        '#191936', // background color
        0.5, // background alpha
        true, // enable background -- if enabled then a transparent blue background will be displayed
        1, // alpha reset value (most likely 1)
        '#898486', // neutral color -- what the
        '#FCAF17', // rotation color -- what the rotational color fill should be
        'ffffff', // tick fill color -- what the tick color should be
        16, // total amount of ticks on the canvas including large ticks
        16, // background radius -- should always be set to how large the north tick should be
        // DefaultCanvasSize.width / LargeCanvasSize.width, // background radius scale factor -- specified when you need to swap between scaling
        3, // tick length adjustment -- specifies how the length difference between a major and minor tick NOTE: north tick ignores this property
        4, // major tick every -- specifies how often a major tick should occur (typically 4 for cardinal or 8 for partial cardinal i.e. NE SE SW NW) NOTE: should be a factor of total tick amount
        2, // top padding -- padding that the background radius follows from the parent canvas height (backgroundRadius + padding top)
        2, // right padding -- padding that the background radius follows from the parent canvas width (width - (backgroundRadius + padding right))
        2, // tick inner radius padding -- padding that all ticks follow from the background radius, north tick ignores this property
        6 // tick inner radius -- specifies inner circle such that no ticks are present and surrounds the label text (N)
      );
      // start at the top of the circle
      const startAngleDeg = 270;
      // remove negative and over 360 deg angles so always a positive rotation
      const angleConversion = (((rotationDegrees + startAngleDeg) % 360) + 360) % 360;
      // convert angle to radians
      const angleRadians = compass.convertDegreesToRadians(angleConversion);
      // get the compass center point (origin)
      const centerPoint = compass.centerPoint(drawContext, selectedDeviceRatio[2]);
      // get the center text box for the label
      const compassTextBox = compass.getLabelTextBoxMax(
        drawContext,
        selectedDeviceRatio[2]
      );

      // color for tick marks and rotation
      let stateColor;
      !rotationActive
        ? (stateColor = compass.neutralColor)
        : (stateColor = compass.rotationColor);
      // clear the canvas
      drawContext.clearRect(0, 0, drawContext.canvas.width, drawContext.canvas.height);
      if (compass.enableBackground) {
        drawContext.fillStyle = compass.backgroundColor;
        drawContext.globalAlpha = compass.backgroundAlpha;
        drawContext.beginPath();
        drawContext.arc(
          centerPoint.x,
          centerPoint.y,
          compass.backgroundRadius,
          0,
          2 * Math.PI,
          false
        );
        drawContext.fill();
        drawContext.closePath();
        drawContext.globalAlpha = compass.alphaReset;
      }

      // draw ticks
      for (let i = 0; i < compass.tickTotal; i++) {
        drawContext.beginPath();
        drawContext.lineWidth = 1;
        drawContext.strokeStyle = stateColor;
        const currentTickDegree = compass.degreePerTick() * i;
        if (i > 0) {
          drawContext.strokeStyle = compass.neutralColor;
        }
        let tickPoints = compass.getArcPointMinor(
          currentTickDegree,
          drawContext,
          angleConversion,
          selectedDeviceRatio[2]
        ) as TickPoints;
        if (i === 0) {
          tickPoints = compass.getArcPointNorth(
            drawContext,
            angleConversion,
            selectedDeviceRatio[2]
          );
        } else if (i % compass.majorTickEvery === 0) {
          tickPoints = compass.getArcPointMajor(
            currentTickDegree,
            drawContext,
            angleConversion,
            selectedDeviceRatio[2]
          );
        }
        drawContext.moveTo(tickPoints.start.x, tickPoints.start.y);
        drawContext.lineTo(tickPoints.end.x, tickPoints.end.y);
        drawContext.stroke();
        drawContext.closePath();
      }

      // ### draw arc showing rotation
      drawContext.beginPath();
      drawContext.arc(
        centerPoint.x,
        centerPoint.y,
        compass.backgroundRadius - compass.tickInnerRadiusPadding,
        compass.convertDegreesToRadians(startAngleDeg),
        angleRadians,
        false
      );
      drawContext.strokeStyle = stateColor;
      drawContext.stroke();
      drawContext.closePath();

      drawContext.beginPath();

      drawContext.strokeStyle = stateColor;
      drawContext.fillStyle = stateColor;
      drawContext.font = `${(compass.backgroundRadius * 1) / 1.7}px Blender`;
      drawContext.fillText('N', compassTextBox.x, compassTextBox.y, compassTextBox.max);
      drawContext.closePath();
    },
    [selectedDeviceRatio]
  );

  React.useEffect(() => {
    // Think like a game engine while loop
    const drawContext = retrieveValidCanvasContext();
    if (!drawContext) {
      return;
    }
    // Scale the canvas first then do the rendering
    scaleCanvas(drawContext);
    // unrotatedCompass(drawContext, selectedRotation);
  }, [retrieveValidCanvasContext, scaleCanvas]);

  React.useEffect(() => {
    const drawContext = retrieveValidCanvasContext();
    if (!drawContext) {
      return;
    }
    if (!rotationActive) {
      drawCompass(drawContext, 0, false);
    } else {
      drawCompass(drawContext, selectedRotation, true);
    }
  }, [
    rotationActive,
    drawCompass,
    retrieveValidCanvasContext,
    selectedRotation,
    selectedMensurationEnlarged
  ]);

  return (
    <>
      <canvas
        className="compass-canvas"
        ref={canvasRef}
        width={selectedDeviceRatio[0]}
        height={selectedDeviceRatio[1]}
      ></canvas>
    </>
  );
};
