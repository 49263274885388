import React from 'react';
import {Menu, MenuItem, MenuProps} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SquareIcon from '@mui/icons-material/Square';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectMapMensurationUnit,
  setMensurationUnit,
  MapMensurationOptionLookup,
  MapMensurationUnitOption
} from 'redux/mapMensuration';
import {styled} from '@mui/material/styles';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({theme}) => ({
  boxShadow: 'box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)',
  '& .MuiPaper-root': {
    marginLeft: '-7px',
    '& .MuiMenu-list': {
      width: '65px'
    },

    '& .MuiMenuItem-root': {
      paddingLeft: '5px',
      // paddingRight: '5px',
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1)
      },
      '&:hover': {
        backgroundColor: '#656360'
      },
      '&:active': {
        backgroundColor: '#1A1918'
      }
    }
  }
}));

interface MeasurementOption {
  id: 'kilometer' | 'meter' | 'mile' | 'feet' | 'nautical_mile';
  label: string;
}

const options: MeasurementOption[] = [
  {
    id: 'kilometer',
    label: 'km'
  },
  {
    id: 'meter',
    label: 'm'
  },
  {
    id: 'mile',
    label: 'mi'
  },
  {
    id: 'feet',
    label: 'ft'
  },
  {
    id: 'nautical_mile',
    label: 'nm'
  }
];

export const MeasurementSelect: React.FC = () => {
  const dispatch = useAppDispatch();
  const unit = useAppSelector(selectMapMensurationUnit);
  const setUnit = (value: MapMensurationUnitOption) => {
    dispatch(setMensurationUnit(value));
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderOptionItems = () => {
    return options.map((option) => {
      return (
        <MenuItem
          key={option.id}
          onClick={() => {
            setUnit(option.id);
            handleClose();
          }}
          style={{fontSize: 12}}
        >
          {option.label} / {option.label}
          <sup style={{fontSize: 8, marginTop: -5}}>2</sup>
          {option.id === unit && (
            <SquareIcon
              sx={{color: '#1B8282', marginLeft: '3px', height: 10, width: 10}}
            />
          )}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <button className={`measurement-menu-button`} onClick={handleClick}>
        <span className="fs-12">
          {MapMensurationOptionLookup[unit]} / {MapMensurationOptionLookup[unit]}
        </span>
        <sup style={{fontSize: 8, marginTop: -1}}>2</sup>
        <ArrowDropDownIcon sx={{width: 14, height: 14}} />
      </button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {renderOptionItems()}
      </StyledMenu>
    </>
  );
};
