import React, {useState} from 'react';
import {DndContext, closestCenter, DragEndEvent} from '@dnd-kit/core';
import {restrictToWindowEdges} from '@dnd-kit/modifiers';
import {DraggableControlContainer} from 'components/Map/Controls/components/DraggableControlContainer';
import {LegendContent} from './LegendContent';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import {useAppDispatch} from 'redux/hooks';
import {setLegendDocked, setLegendOpen} from 'redux/legend';
export const LegendControl: React.FC = () => {
  const [delta, setDelta] = useState({x: 320, y: 85});

  const handleDragEnd = (event: DragEndEvent) => {
    const x = delta.x - event.delta.x;
    const y = delta.y + event.delta.y;
    setDelta({x: x, y: y});
  };

  const dispatch = useAppDispatch();
  const onDock = () => {
    dispatch(setLegendDocked(true));
  };
  const onClose = () => {
    dispatch(setLegendOpen(false));
  };
  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToWindowEdges]}
      onDragEnd={handleDragEnd}
    >
      <DraggableControlContainer
        x={delta.x}
        y={delta.y}
        width={268}
        title="Key"
        id="legend"
        onDock={onDock}
        onClose={onClose}
        icon={
          <VpnKeyOutlinedIcon
            fontSize="small"
            sx={{
              fontSize: '17px',
              marginLeft: '5px !important',
              marginRight: '5px !important',
              marginTop: '5px !important'
            }}
          />
        }
      >
        <LegendContent />
      </DraggableControlContainer>
    </DndContext>
  );
};
