import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, Tooltip, Typography} from '@mui/material';
import {ContentExplorerHeaderContainer} from 'components/ContentExplorer/ContentExplorerHeader/Styled';
import CollectionFilter from 'components/ContentExplorer/ContentExplorerHeader/menus/CollectionFilter';
import DateRangeSelector from 'components/ContentExplorer/ContentExplorerHeader/menus/DateRangeSelector';
import {MeasurementBlock} from 'types/Filters';
import {FilterChips} from './FilterChips';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectFilterInputs,
  selectFiltersDisabled,
  resetFilters,
  resetWatchboxes
} from 'redux/filters';
import {loadAlerts, selectAlertsLoading} from 'redux/alerts';
import {fetchAccessHistory} from 'redux/alertAccess';

type FilterChip = {
  type: string;
  label: string;
  value: string;
  id?: string;
};

export const ContentExplorerHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const filtersDisabled = useAppSelector(selectFiltersDisabled);
  const filters = useAppSelector(selectFilterInputs);
  const [enableFilterChips, setEnableFilterChips] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [filterChips, setFilterChips] = useState<Array<FilterChip>>([]);
  const [mapChips, setMapChips] = useState<Array<FilterChip>>([]);

  const areAlertsLoading = useAppSelector(selectAlertsLoading);

  useEffect(() => {
    if (filtersDisabled) {
      setEnableFilterChips(false);
      return;
    }
    // Used to determine if the filter chips should be false after
    // The enableFilterChips value is true
    let flag = false;
    const chips: Array<FilterChip> = [];
    const geoChips: Array<FilterChip> = [];

    // const {mmsis, detectionTypes, alertIds, sources, flags, categories} = filters;
    if (filters.mmsis.length > 0 && filters.mmsis[0] !== '') {
      setEnableFilterChips(true);
      for (let i = 0; i < filters.mmsis.length; i++) {
        chips.push({type: 'mmsi', label: 'MMSI', value: filters.mmsis[i]});
      }
      flag = true;
    }
    if (filters.flags.length > 0) {
      setEnableFilterChips(true);
      for (let i = 0; i < filters.flags.length; i++) {
        chips.push({
          type: 'flag',
          label: 'Vessel Country Of Origin',
          value: filters.flags[i]
        });
      }
      flag = true;
    }
    if (filters.alertIds.length > 0 && filters.alertIds[0] !== '') {
      setEnableFilterChips(true);
      for (let i = 0; i < filters.alertIds.length; i++) {
        chips.push({type: 'alertId', label: 'Alert ID', value: filters.alertIds[i]});
      }
      flag = true;
    }
    const missions = filters.missions.filter((x) => x.value === true);
    if (missions.length > 0) {
      setEnableFilterChips(true);
      for (let i = 0; i < missions.length; i++) {
        chips.push({type: 'mission', label: 'Mission', value: missions[i].label});
      }
      flag = true;
    }

    const watchboxes = filters.watchboxes.filter((x) => x.active === true);
    if (watchboxes.length > 0) {
      setEnableFilterChips(true);
      for (let i = 0; i < watchboxes.length; i++) {
        geoChips.push({
          type: 'watchbox',
          label: 'Watchbox',
          value: watchboxes[i].name
        });
      }
      flag = true;
    }

    const categoryFilter = filters.categories.filter((x) => x.value === true);
    if (categoryFilter.length > 0) {
      setEnableFilterChips(true);
      for (let i = 0; i < categoryFilter.length; i++) {
        chips.push({
          type: 'category',
          label: 'Vessel Type',
          value: categoryFilter[i].label,
          id: categoryFilter[i].id
        });
      }
      flag = true;
    }
    if (filters.detectionTypes.dark || filters.detectionTypes.correlated) {
      setEnableFilterChips(true);
      if (filters.detectionTypes.dark) {
        chips.push({type: 'detection', label: 'Vessel Detection', value: 'Dark'});
      }
      if (filters.detectionTypes.correlated) {
        chips.push({type: 'detection', label: 'Vessel Detection', value: 'Correlated'});
      }
      flag = true;
    }
    const sourcesFilter = filters.sources.filter((x) => x.value === true);
    if (sourcesFilter.length > 0) {
      setEnableFilterChips(true);
      for (let i = 0; i < sourcesFilter.length; i++) {
        chips.push({
          type: 'source',
          label: 'Collection Type',
          value: sourcesFilter[i].label
        });
      }
      flag = true;
    }

    const keys = Object.keys(filters.measurements);
    let value: MeasurementBlock = {} as MeasurementBlock;
    if (keys.length === 1) {
      value = filters.measurements['0'];
      if (
        (value !== undefined && value.speed.from !== '') ||
        value.speed.to !== '' ||
        value.vesselLength.from !== '' ||
        value.vesselLength.to !== '' ||
        value.vesselWidth.from !== '' ||
        value.vesselWidth.to !== ''
      ) {
        setEnableFilterChips(true);
        chips.push({
          type: 'measurement',
          label: 'Vessel Measures',
          value: `${value.speed.from === '' ? 0 : value.speed.from}-${
            value.speed.to === '' ? '∞' : value.speed.to
          }kts ${value.vesselLength.from === '' ? 0 : value.vesselLength.from}-${
            value.vesselLength.to === '' ? '∞' : value.vesselLength.to
          }m L x ${value.vesselWidth.from === '' ? 0 : value.vesselWidth.from}-${
            value.vesselWidth.to === '' ? '∞' : value.vesselWidth.to
          }m W`,
          id: keys[0]
        });
        flag = true;
      }
    } else if (keys.length > 1) {
      for (let i = 0; i < keys.length; i++) {
        value = filters.measurements[keys[i]];
        if (
          (value !== undefined && value.speed.from !== '') ||
          value.speed.to !== '' ||
          value.vesselLength.from !== '' ||
          value.vesselLength.to !== '' ||
          value.vesselWidth.from !== '' ||
          value.vesselWidth.to !== ''
        ) {
          setEnableFilterChips(true);
          chips.push({
            type: 'measurement',
            label: 'Vessel Measures',
            value: `${value.speed.from === '' ? 0 : value.speed.from}-${
              value.speed.to === '' ? '∞' : value.speed.to
            }kts ${value.vesselLength.from === '' ? 0 : value.vesselLength.from}-${
              value.vesselLength.to === '' ? '∞' : value.vesselLength.to
            }m L x ${value.vesselWidth.from === '' ? 0 : value.vesselWidth.from}-${
              value.vesselWidth.to === '' ? '∞' : value.vesselWidth.to
            }m W`,
            id: keys[i]
          });
          flag = true;
        }
      }
    }
    if (!flag) {
      setEnableFilterChips(false);
    } else {
      setFilterChips(chips);
      setMapChips(geoChips);
    }
  }, [
    filters.mmsis,
    filters.detectionTypes,
    filters.missions,
    filtersDisabled,
    filters.sources,
    filters.flags,
    filters.alertIds,
    filters.categories,
    filters.measurements,
    filters.watchboxes
  ]);

  return (
    <ContentExplorerHeaderContainer
      sx={{
        paddingBottom: enableFilterChips ? '' : '12px',
        flexGrow: '0'
      }}
    >
      <Box sx={{display: 'flex', width: '100%'}}>
        <Box
          sx={{
            width: '25%',
            display: 'flex',
            justifyContnet: 'start'
          }}
        >
          <Typography
            sx={(theme) => ({
              fontSize: 18,
              paddingLeft: 2,
              color: theme.palette.secondary.contrastText,
              alignSelf: 'center'
            })}
          >
            Collection Dates
          </Typography>
        </Box>
        <Box sx={{width: '60%'}}>
          <DateRangeSelector />
        </Box>

        <Box sx={{display: 'flex', alignItems: 'center', padding: '0 0.5em'}}>
          <Tooltip title="Refresh Alerts">
            <div>
              <IconButton
                sx={(theme) => ({
                  color: theme.palette.secondary.contrastText
                })}
                onClick={() => {
                  dispatch(loadAlerts({avoidLoadingState: true}));
                  dispatch(fetchAccessHistory());
                }}
                disabled={areAlertsLoading}
              >
                <RefreshIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Box>

        <Box sx={{width: '15%', display: 'flex', justifyContent: 'end'}}>
          <CollectionFilter />
        </Box>
      </Box>
      {enableFilterChips && !collapse && (
        <Box
          sx={{
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '10px',
            width: '100%'
          }}
        >
          {filterChips.length > 0 && (
            <fieldset style={{padding: '5px'}}>
              <legend style={{paddingLeft: '5px', paddingRight: '5px', color: '#E5E1DA'}}>
                Filter Selections
              </legend>
              <Box sx={{padding: '5px'}}>
                {filterChips.map((chip) => {
                  return (
                    <FilterChips
                      key={`${chip.label}-${chip.value}-${chip.id}`}
                      chip={chip}
                    />
                  );
                })}
                <Button
                  sx={{textDecoration: 'underline'}}
                  onClick={() => {
                    dispatch(resetFilters());
                  }}
                >
                  Clear All
                </Button>
              </Box>
            </fieldset>
          )}

          {mapChips.length > 0 && (
            <>
              <fieldset style={{padding: '5px'}}>
                <legend
                  style={{paddingLeft: '5px', paddingRight: '5px', color: '#E5E1DA'}}
                >
                  Map Selections
                </legend>

                <Box sx={{padding: '5px'}}>
                  {mapChips.map((chip) => {
                    return (
                      <FilterChips
                        key={`${chip.label}-${chip.value}-${chip.id}`}
                        chip={chip}
                      />
                    );
                  })}
                  <Button
                    sx={{textDecoration: 'underline'}}
                    onClick={() => {
                      dispatch(resetWatchboxes());
                    }}
                  >
                    Clear All
                  </Button>
                </Box>
              </fieldset>
            </>
          )}

          <Box sx={{display: 'flex', justifyContent: 'end', width: '100%'}}>
            <Button sx={{textDecoration: 'underline'}} onClick={() => setCollapse(true)}>
              Hide Selected Filters
            </Button>
          </Box>
        </Box>
      )}
      {enableFilterChips && collapse && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
            paddingTop: '5px',
            paddingBottom: '5px'
          }}
        >
          <Button sx={{textDecoration: 'underline'}} onClick={() => setCollapse(false)}>
            Show Selected Filters
          </Button>
        </Box>
      )}
    </ContentExplorerHeaderContainer>
  );
};
