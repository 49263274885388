import React from 'react';
import {Box, IconButton, Tooltip, styled} from '@mui/material';
import StraightenSharpIcon from '@mui/icons-material/StraightenSharp';
import OpenInFullSharp from '@mui/icons-material/OpenInFullSharp';
import {
  selectVesselChipRotation,
  selectVesselChipRotationActive,
  selectVesselMensurationActive,
  selectVesselMensurationEnlarged
} from 'redux/vesselChipMensuration/selectors';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  setActive,
  setChipRotation,
  setChipRotationDegrees,
  setDeviceRatio,
  setEnlarged,
  setHeight,
  setWidth
} from 'redux/vesselChipMensuration/slice';
import {selectSelectedVessel, setFocusedTab} from 'redux/vesselHistory';
import {setMensurationEnabled} from 'redux/mapMensuration/slice';
import {Fingerprint, Rotate90DegreesCcwSharp} from '@mui/icons-material';

const IconWrapperContainer = styled('div')(() => ({
  position: 'relative',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  height: '100%',
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'flex-end'
}));

export enum LargeCanvasSize {
  height = 545,
  width = 545
}

export enum DefaultCanvasSize {
  height = 208,
  width = 208
}

const IconButtonsOnVesselChip: React.FC = () => {
  const dispatch = useAppDispatch();
  const mensurationActive = useAppSelector(selectVesselMensurationActive);
  const mensurationEnlarged = useAppSelector(selectVesselMensurationEnlarged);
  const rotationActive = useAppSelector(selectVesselChipRotationActive);
  const selectedRotation = useAppSelector(selectVesselChipRotation);
  const selectedVessel = useAppSelector(selectSelectedVessel);

  const measurementClickHandler = () => {
    if (selectedVessel?.properties.source === 'EO') {
      if (!rotationActive) {
        dispatch(setActive(!mensurationActive));
        dispatch(setMensurationEnabled(false));
      }
    }
  };

  const mensurationToolTip = (theme: any) => {
    if (theme) {
      const tipProps = {
        title:
          selectedVessel?.properties.source === 'EO'
            ? 'Enable Vessel Mensuration'
            : 'Mensuration unavailable for SAR',
        backgroundColor:
          selectedVessel?.properties.source === 'EO'
            ? ''
            : theme.palette.background.default,
        iconColor:
          selectedVessel?.properties.source === 'EO'
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
        iconCursor: selectedVessel?.properties.source === 'EO' ? 'pointer' : 'default'
      };
      if (rotationActive) {
        tipProps.title = 'Mensuration edits unavailable while canvas is rotated';
        tipProps.backgroundColor = theme.palette.background.default;
        tipProps.iconColor = theme.palette.secondary.main;
        tipProps.iconCursor = 'default';
      }
      return tipProps;
    } else {
      const tipProps = {
        title:
          selectedVessel?.properties.source === 'EO'
            ? 'Enable Vessel Mensuration'
            : 'Mensuration unavailable for SAR',
        backgroundColor: '',
        iconColor: '',
        iconCursor: ''
      };

      if (rotationActive) {
        tipProps.title = 'Mensuration edits unavailable while canvas is rotated';
        tipProps.backgroundColor = '';
        tipProps.iconColor = '';
        tipProps.iconCursor = '';
      }

      return tipProps;
    }
  };

  const enlargeClickHandler = () => {
    const largeSize = [
      LargeCanvasSize.height * window.devicePixelRatio,
      LargeCanvasSize.width * window.devicePixelRatio,
      window.devicePixelRatio
    ];
    const defaultSize = [
      DefaultCanvasSize.height * window.devicePixelRatio,
      DefaultCanvasSize.width * window.devicePixelRatio,
      window.devicePixelRatio
    ];
    if (!mensurationEnlarged) {
      dispatch(setDeviceRatio(largeSize));
      dispatch(setHeight(LargeCanvasSize.height));
      dispatch(setWidth(LargeCanvasSize.width));
    } else {
      dispatch(setDeviceRatio(defaultSize));
      dispatch(setHeight(DefaultCanvasSize.height));
      dispatch(setWidth(DefaultCanvasSize.width));
    }
    dispatch(setEnlarged(!mensurationEnlarged));
  };

  const rotationClickHandler = () => {
    dispatch(setChipRotation(!rotationActive));
    dispatch(setActive(false));
    if (selectedRotation === 0 && selectedVessel?.properties.upIsUpRotationDeg) {
      dispatch(setChipRotationDegrees(selectedVessel.properties.upIsUpRotationDeg));
    }
  };

  const navigateToSimilarVessels = () => {
    dispatch(setFocusedTab('similar'));
  };

  return (
    <IconWrapperContainer>
      <Tooltip title="Show Similar Vessels">
        <Box
          sx={{
            marginLeft: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            border: ''
          }}
        >
          <IconButton
            sx={(theme) => ({
              color: theme.palette.primary.main
            })}
            onClick={navigateToSimilarVessels}
          >
            <Fingerprint />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Expand Vessel Chip">
        <Box
          sx={(theme) => ({
            marginLeft: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            border: mensurationEnlarged
              ? `1px solid ${theme.palette.primary.contrastText}`
              : ''
          })}
        >
          <IconButton
            sx={(theme) => ({
              color: theme.palette.primary.main
            })}
            onClick={enlargeClickHandler}
          >
            <OpenInFullSharp />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title={mensurationToolTip(undefined).title}>
        <Box
          sx={(theme) => ({
            marginLeft: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            border: mensurationActive
              ? `1px solid ${theme.palette.primary.contrastText}`
              : '',
            backgroundColor: mensurationToolTip(theme).backgroundColor
          })}
        >
          <IconButton
            sx={(theme) => ({
              color: mensurationToolTip(theme).iconColor,
              cursor: mensurationToolTip(theme).iconCursor
            })}
            className="rotate-45"
            onClick={measurementClickHandler}
          >
            <StraightenSharpIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title={'Optimize Rotation'}>
        <Box
          sx={(theme) => ({
            marginLeft: '5px',
            marginRight: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            border: rotationActive
              ? `1px solid ${theme.palette.primary.contrastText}`
              : ''
          })}
        >
          <IconButton
            sx={(theme) => ({
              color: theme.palette.primary.main
            })}
            onClick={rotationClickHandler}
          >
            <Rotate90DegreesCcwSharp />
          </IconButton>
        </Box>
      </Tooltip>
    </IconWrapperContainer>
  );
};

export default IconButtonsOnVesselChip;
