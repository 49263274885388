import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  setSelectedTimeFrame,
  selectSelectedTimeFrame,
  selectAlertIds
} from 'redux/filters';

const DateRangeSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedTimeFrame = useAppSelector(selectSelectedTimeFrame);
  const filterAlertIds = useAppSelector(selectAlertIds);
  const [dateRange, setDateRange] = React.useState<Array<Date | null>>([
    selectedTimeFrame.start,
    selectedTimeFrame.end
  ]);
  const [startDate, endDate] = dateRange;

  React.useEffect(() => {
    setDateRange([selectedTimeFrame.start, selectedTimeFrame.end]);
  }, [selectedTimeFrame]);

  const handleDateChange = (dateRange: Array<Date | null>) => {
    setDateRange(dateRange);
    const [startDate, endDate] = dateRange;
    if (startDate && endDate) {
      dispatch(setSelectedTimeFrame({start: startDate, end: endDate}));
    }
  };

  const alertIdsPresent = filterAlertIds.length > 0;
  return (
    <DatePicker
      selectsRange
      disabled={alertIdsPresent}
      startDate={alertIdsPresent ? undefined : startDate}
      endDate={alertIdsPresent ? undefined : endDate}
      placeholderText={'All Collection Dates'}
      onChange={(update) => handleDateChange(update)}
      monthsShown={2}
      className="maa-datepicker"
      dateFormat={'yyyy-MM-dd'}
    />
  );
};

export default DateRangeSelector;
