import React from 'react';

export const AreaIcon: React.FC<{
  selected: boolean;
}> = ({selected}) => {
  const color = selected ? '#E5E1DA' : '#B2AFAA';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
    >
      <path
        d="M2.64307 16.0791L18.5082 13.2793V3.49316L2.64307 2.09473V16.0791Z"
        fill={color}
        fillOpacity="0.3"
        stroke={color}
      />
      <ellipse cx="2.64283" cy="2.09765" rx="2.14283" ry="2.09765" fill={color} />
      <ellipse cx="2.64283" cy="16.0811" rx="2.14283" ry="2.09765" fill={color} />
      <ellipse cx="18.3577" cy="13.2832" rx="2.14283" ry="2.09765" fill={color} />
      <ellipse cx="18.3577" cy="3.49707" rx="2.14283" ry="2.09765" fill={color} />
    </svg>
  );
};
