import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ToolbarState {
  showPanel: boolean;
}

const initialState: ToolbarState = {
  showPanel: true
};

export const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    setShowPanel: (state, action: PayloadAction<boolean>) => {
      state.showPanel = action.payload;
    }
  }
});

export const {setShowPanel} = toolbarSlice.actions;

export default toolbarSlice.reducer;
