import {styled} from '@mui/material/styles';
import {Divider} from '@mui/material';

export const AlertDetailCard = styled('div')(({theme}) => ({
  backgroundColor: '#333231',
  color: theme.palette.primary.main,
  padding: '20px 20px 20px 20px',
  borderRadius: '0',
  overflowX: 'auto',
  marginLeft: '8px',
  marginRight: '8px',
  marginBottom: '8px'
}));

export const AlertSectionHeader = styled('h4')({
  marginBottom: '20px',
  display: 'block',
  marginBlockEnd: '1.33em',
  marginInlineStart: '0px',
  marginInlineEnd: '0px',
  fontWeight: 'bold'
});

export const AlertBlockHeader = styled('h5')({
  display: 'block',
  fontSize: '0.83em',
  marginBlockStart: '1.67em',
  marginInlineStart: '0px',
  marginInlineEnd: '0px',
  fontWeight: 'bold'
});

export const AlertDetailContentSubHeading = styled('p')({
  fontSize: '12px',
  marginBottom: '8px'
});

export const AlertDetailContentBody = styled('p')({
  fontSize: '12px'
});

export const AlertHorizontalDivider = styled(Divider)({
  marginTop: '20px',
  marginBottom: '20px',
  borderColor: 'rgba(255, 255, 255, 0.12)'
}) as typeof Divider;

export const AlertDetailLinkAnchor = styled('a')({
  color: '#9E9EFF'
});

export const AlertDetailDataTable = styled('table')({
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '12px'
});

export const AlertDetailDataTableHeaderLong = styled('th')(({theme}) => ({
  minWidth: '100px',
  maxWidth: '100px',
  backgroundColor: '#6b6965',
  color: theme.palette.primary.main,
  height: '16.5px',
  padding: '3px',
  lineHeight: '16.5px',
  textAlign: 'center',
  verticalAlign: 'inherit',
  fontWeight: 'bold'
}));

export const AlertDetailDataTableCellLong = styled('td')(({theme}) => ({
  minWidth: '100px',
  maxWidth: '100px',
  backgroundColor: '#4c4b48',
  color: theme.palette.primary.main,
  height: '16.5px',
  padding: '3px',
  lineHeight: '16.5px',
  textAlign: 'start',
  verticalAlign: 'inherit'
}));

export const AlertDetailDataTableHeader = styled('th')(({theme}) => ({
  minWidth: '85px',
  maxWidth: '85px',
  backgroundColor: '#6b6965',
  color: theme.palette.primary.main,
  height: '16.5px',
  padding: '3px',
  lineHeight: '16.5px',
  textAlign: 'center',
  verticalAlign: 'inherit',
  fontWeight: 'bold'
}));

export const AlertDetailDataTableCell = styled('td')(({theme}) => ({
  minWidth: '85px',
  maxWidth: '85px',
  // could replace this background color here with theme?
  backgroundColor: '#4c4b48',
  color: theme.palette.primary.main,
  height: '16.5px',
  padding: '3px',
  lineHeight: '16.5px',
  textAlign: 'center',
  verticalAlign: 'inherit'
}));
