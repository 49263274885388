import React from 'react';
import {RFeature, ROverlay} from 'rlayers';
import {calculateScale, getPolygonLabelCoordinates} from 'utilities/map';
import {Point} from 'ol/geom';
import {Fill, Stroke, Style} from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import {Box} from '@mui/material';
import {Check} from '@mui/icons-material';
import {fromLonLat} from 'ol/proj';
import * as turf from '@turf/turf';
import {useDispatch} from 'react-redux';
import {toggleWatchbox} from 'redux/filters';
import {useAppSelector} from 'redux/hooks';
import {selectMapMensurationEnabled} from 'redux/mapMensuration';
import {selectIsMapModeLight} from '../../../../redux/map';

interface PolygonLabelProps {
  coordinates: turf.helpers.Position[][];
  watchboxName: string;
  watchboxActive: boolean;
  hoverActive: boolean;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
}

export const PolygonLabel: React.FC<PolygonLabelProps> = ({
  coordinates,
  watchboxName,
  watchboxActive,
  hoverActive,
  onPointerEnter,
  onPointerLeave
}) => {
  const labelXOffset = 3.5 * watchboxName.length * -1;
  const labelCoords = getPolygonLabelCoordinates(coordinates);
  const mensurationEnabled = useAppSelector(selectMapMensurationEnabled);
  const isLightMode = useAppSelector(selectIsMapModeLight);
  const dispatch = useDispatch();
  const onPolygonClicked = () => {
    if (!mensurationEnabled) {
      dispatch(toggleWatchbox(watchboxName));
    }
  };

  return (
    <>
      <RFeature
        geometry={new Point(fromLonLat(labelCoords))}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        style={(item, resolution) => {
          const circle = new CircleStyle({
            radius: 3,
            stroke: new Stroke({
              color: 'rgba(255,255,0,0.0)',
              width: 0.25
            }),
            fill: new Fill({
              color: 'rgba(255,255,0,0.0)'
            })
          });
          if (resolution) {
            circle.setRadius(3 * calculateScale(resolution));
          }
          return new Style({
            image: circle
          });
        }}
      >
        <ROverlay
          className={
            watchboxActive
              ? `watchbox-label-overlay active-border ${isLightMode && 'light-mode'}`
              : `watchbox-label-overlay ${hoverActive && 'watchbox-hover'} ${
                  isLightMode && 'light-mode'
                }`
          }
          offset={[labelXOffset, 25]}
          positioning={'center-center'}
          onClick={onPolygonClicked}
        >
          <div onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: isLightMode ? '#1A1918' : '#b2afaa'
              }}
            >
              {watchboxActive ? (
                <Check sx={{height: 16, width: 16, marginRight: '2px'}} />
              ) : (
                <></>
              )}
              <p>{watchboxName}</p>
            </Box>
          </div>
        </ROverlay>
      </RFeature>
    </>
  );
};
