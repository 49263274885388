import chroma from 'chroma-js';
import {format, addHours, differenceInHours} from 'date-fns';
import {UNIQUE_HOUR_DATEKEY_FORMAT} from 'constants/constants';
import {utcToZonedTime} from 'date-fns-tz';
export type ColorMap = Record<string, string>;

export function getColorOptions(colors: number) {
  return chroma
    .scale([
      '#1B2B66',
      '#432F71',
      '#653177',
      '#853279',
      '#A43377',
      '#BF3871',
      '#D64267',
      '#E9525B',
      '#F6664D',
      '#FE7D3D',
      '#FF952B',
      '#FCAF17'
    ])
    .mode('rgb')
    .colors(colors);
}

export function generateColorMap(start: Date, end: Date) {
  const colorMap: ColorMap = {};
  const startDate = utcToZonedTime(start, 'UTC');
  const endDate = addHours(utcToZonedTime(end, 'UTC'), 2);
  const numOfHours = differenceInHours(endDate, startDate);
  const colorOptions = getColorOptions(numOfHours);
  // Create an array where each index corresponds with a hour long period
  new Array(numOfHours).fill('').forEach((_, idx) => {
    const d = addHours(startDate, idx);
    const key = format(d, UNIQUE_HOUR_DATEKEY_FORMAT);
    colorMap[key] = colorOptions[idx];
  });
  return colorMap;
}
