import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import alertReducer from 'redux/alerts/slice';
import filterReducer from 'redux/filters/slice';
import vesselHistoryReducer from 'redux/vesselHistory/slice';
import aisReducer from 'redux/ais/slice';
import mapReducer from 'redux/map/slice';
import VCMReducer from 'redux/vesselChipMensuration/slice';
import mapMensurationReducer from 'redux/mapMensuration/slice';
import artifactReducer from 'redux/artifacts/slice';
import toolbarReducer from 'redux/toolbar/slice';
import legendReducer from 'redux/legend/slice';
import layersReducer from 'redux/layers/slice';
import accessHistoryReducer from 'redux/alertAccess/slice';
import vesselFingerprintReducer from 'redux/vesselFingerprint/slice';

export const store = configureStore({
  reducer: {
    alerts: alertReducer,
    filters: filterReducer,
    vesselHistory: vesselHistoryReducer,
    ais: aisReducer,
    map: mapReducer,
    vesselChipMensuration: VCMReducer,
    mapMensuration: mapMensurationReducer,
    artifacts: artifactReducer,
    toolbar: toolbarReducer,
    legend: legendReducer,
    layers: layersReducer,
    accessHistory: accessHistoryReducer,
    vesselFingerprint: vesselFingerprintReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
