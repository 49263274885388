import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

export const ContentExplorerHeaderContainer = styled(Box)(({theme}) => ({
  borderBottom: '2px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  paddingRight: '14px',
  paddingTop: '14px'
}));

export const VesselChipContainerWrapper = styled('div')({
  position: 'relative',
  border: '2px solid #4c4b48',
  height: '208px',
  width: '208px',
  margin: '4px',
  padding: '0'
});

export const VesselChipContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  height: '204px',
  width: '204px',
  padding: '0'
});

export const VesselChipMain = styled('canvas')({
  height: '100%',
  width: '100%'
});
