import React from 'react';
import {
  Stack,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  Tooltip
} from '@mui/material';

import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import LinkedCameraOutlinedIcon from '@mui/icons-material/LinkedCameraOutlined';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectWatchboxesEnabled,
  selectEEZsEnabled,
  selectSearchAreasEnabled,
  setLayerEnabled,
  selectRFHalosEnabled
} from 'redux/layers';
import {MapMode, selectMapMode, selectMaxarApiToken, setMapMode} from 'redux/map';
import {ControlSwitch} from '../components/ControlSwitch';
const LayerControlRadioLine: React.FC<{
  label: string;
  icon: React.ReactNode;
  tooltipText: string;
  value: string;
  disabled?: boolean;
}> = ({value, disabled = false, label, icon, tooltipText}) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
      <Box sx={{display: 'flex'}}>
        <Radio
          disabled={disabled}
          value={value}
          sx={{
            color: '#E5E1DA',
            '&.Mui-checked': {
              color: '#0F9797'
            }
          }}
        />{' '}
        {icon}
        <Typography variant="body1" sx={{marginTop: '10px', paddingLeft: '5px'}}>
          {label}
        </Typography>
      </Box>
      <Tooltip title={tooltipText} arrow placement="left-end">
        <InfoOutlinedIcon sx={{marginTop: '10px', fontSize: '22px'}} />
      </Tooltip>
    </Box>
  );
};

export const LayersControlContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const watchboxesEnabled = useAppSelector(selectWatchboxesEnabled);
  const eezsEnabled = useAppSelector(selectEEZsEnabled);
  const searchAreasEnabled = useAppSelector(selectSearchAreasEnabled);
  const rfHalosEnabled = useAppSelector(selectRFHalosEnabled);

  const mapMode = useAppSelector(selectMapMode);
  const maxarApiToken = useAppSelector(selectMaxarApiToken);

  const toggleWatchboxes = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLayerEnabled({layer: 'watchboxes', value: event.target.checked}));
  };
  const toggleEEZs = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLayerEnabled({layer: 'eezs', value: event.target.checked}));
  };
  const toggleSearchAreas = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLayerEnabled({layer: 'searchAreas', value: event.target.checked}));
  };
  const toggleRFHalos = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLayerEnabled({layer: 'rfHalos', value: event.target.checked}));
  };

  const toggleMapMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMapMode(event.target.value as MapMode));
  };

  return (
    <div style={{paddingLeft: 5}}>
      <Stack direction="row" justifyContent="space-between">
        <h2 className="fs-14" style={{color: '#C6C6C6'}}>
          Base Layer
        </h2>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <RadioGroup
          value={mapMode}
          onChange={toggleMapMode}
          sx={{width: '100%', marginLeft: '-10px'}}
        >
          <LayerControlRadioLine
            label="Dark Mode"
            value="dark"
            tooltipText="cartodb_darkmatter"
            icon={
              <BedtimeOutlinedIcon
                sx={{marginTop: '10px', paddingLeft: '0px !important', fontSize: '22px'}}
              />
            }
          />
          <LayerControlRadioLine
            label="Light Mode"
            value="light"
            tooltipText="openstreetmap"
            icon={
              <WbSunnyOutlinedIcon
                sx={{marginTop: '10px', paddingLeft: '0px !important', fontSize: '22px'}}
              />
            }
          />

          <LayerControlRadioLine
            disabled={maxarApiToken === null}
            label="Satellite"
            value="vivid"
            tooltipText="maxar satellite imagery"
            icon={
              <LinkedCameraOutlinedIcon
                sx={{marginTop: '10px', paddingLeft: '0px !important', fontSize: '22px'}}
              />
            }
          />
        </RadioGroup>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <h2 className="fs-14" style={{color: '#C6C6C6', marginTop: 10}}>
          Boundaries
        </h2>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <FormGroup sx={{paddingLeft: '5px'}}>
          <FormControlLabel
            control={
              <ControlSwitch
                size="small"
                checked={watchboxesEnabled}
                onChange={toggleWatchboxes}
              />
            }
            label="Watchboxes"
          />
          <FormControlLabel
            control={
              <ControlSwitch size="small" checked={eezsEnabled} onChange={toggleEEZs} />
            }
            label="Exclusive Economic Zones (EEZ)"
          />
          <FormControlLabel
            control={
              <ControlSwitch
                size="small"
                checked={searchAreasEnabled}
                onChange={toggleSearchAreas}
              />
            }
            label="AIS Search Areas"
          />
        </FormGroup>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <h2 className="fs-14" style={{color: '#C6C6C6', marginTop: 10}}>
          Detection Layers
        </h2>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <FormGroup sx={{paddingLeft: '5px'}}>
          <FormControlLabel
            control={
              <ControlSwitch
                size="small"
                checked={rfHalosEnabled}
                onChange={toggleRFHalos}
              />
            }
            label="RF Accuracy Halos"
          />
        </FormGroup>
      </Stack>
    </div>
  );
};
