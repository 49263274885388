import {v4 as uuidv4} from 'uuid';

type Vector = {
  x: number;
  y: number;
};

export enum SHAPE_TYPES {
  Line = 'Line',
  Vertex = 'Vertex',
  Label = 'Label'
}

export enum COLORS {
  black = '#1A1918',
  white = 'white',
  offwhite = '#B2AFAA',
  hover = '#008697',
  select = '#7AEAEB',
  labelBG = '#B2AFAA',
  labelOutline = '#1A1918'
}

type ShapeLayer = {
  id: string;
  scaling: number;
  selected: boolean;
  hover: boolean;
  type: string;
  layer: number;
  draw: (drawContext: CanvasRenderingContext2D | null) => void;
  translateByRatio: (ratio: number | null) => void;
  copy: () => Label | Vertex | Line;
  setHover: (value: boolean) => void;
  setSelected: (value: boolean) => void;
};

interface Vertex extends ShapeLayer {
  xPixel: number;
  yPixel: number;
  isDragging: boolean;
  line: Line | null;
}

interface Line extends ShapeLayer {
  gsd: number;
  vertices: Array<Vertex>;
  distanceMeters: number;
  midPoint: Array<number>;
  positiveSlope: boolean;
  distancePixels: number;
}

interface Label extends ShapeLayer {
  line: Line;
  xPixel: number;
  yPixel: number;
  width: number;
  height: number;
  lineWidth: number;
}

class Vertex implements ShapeLayer {
  constructor(vector: Vector, layer: number) {
    this.id = uuidv4();
    this.layer = layer;
    this.xPixel = vector.x;
    this.yPixel = vector.y;
    this.isDragging = false;
    this.selected = false;
    this.hover = false;
    this.line = null;
    this.type = SHAPE_TYPES.Vertex;
  }

  translateByRatio(ratio: number | null): void {
    if (ratio) {
      this.xPixel = this.xPixel * ratio;
      this.yPixel = this.yPixel * ratio;
    }
  }

  setHover(value: boolean): void {
    this.hover = value;
  }

  setSelected(value: boolean): void {
    this.selected = value;
  }

  copy(): Vertex {
    return {...this};
  }

  draw(drawContext: CanvasRenderingContext2D | null): void {
    if (drawContext) {
      // if (this.hover) {
      //   drawContext.fillStyle = COLORS.hover;
      // } else
      if (this.selected) {
        drawContext.fillStyle = COLORS.select;
      } else {
        drawContext.fillStyle = COLORS.black;
      }
      drawContext.moveTo(this.xPixel, this.yPixel);
      drawContext.beginPath();
      drawContext.arc(this.xPixel, this.yPixel, 4, 0, 2 * Math.PI);
      drawContext.fill();

      drawContext.fillStyle = COLORS.white;
      drawContext.moveTo(this.xPixel, this.yPixel);
      drawContext.beginPath();
      drawContext.arc(this.xPixel, this.yPixel, 3, 0, 2 * Math.PI);
      drawContext.fill();
    }
  }
}

class Line implements ShapeLayer {
  constructor(vertices: Array<Vertex>, gsd: number, scaling: number, layer: number) {
    this.vertices = vertices;
    this.gsd = gsd;
    this.layer = layer;
    this.scaling = scaling;
    this.id = uuidv4();
    this.midPoint = [];
    this.type = SHAPE_TYPES.Line;
    this.distancePixels = 0;
    this.distanceMeters = 0;
    this.selected = false;
    this.positiveSlope = false;
    this.hover = false;
    this._calculateDistanceMeters();
    this._calculateDistancePixels();
    this.calculateMidPoint();
    this._calculateSlopeDirection();
  }

  private _calculateDistancePixels = () => {
    const x1 = this.vertices[0].xPixel;
    const x2 = this.vertices[1].xPixel;
    const y1 = this.vertices[0].yPixel;
    const y2 = this.vertices[1].yPixel;

    const linePixelsX = x2 - x1;
    const linePixelsY = y2 - y1;

    if (this.scaling >= 1) {
      this.distancePixels =
        Math.sqrt(linePixelsX * linePixelsX + linePixelsY * linePixelsY) / this.scaling;
    } else {
      this.distancePixels =
        Math.sqrt(linePixelsX * linePixelsX + linePixelsY * linePixelsY) * this.scaling;
    }
  };

  private _calculateDistanceMeters = () => {
    const x1 = this.vertices[0].xPixel;
    const x2 = this.vertices[1].xPixel;
    const y1 = this.vertices[0].yPixel;
    const y2 = this.vertices[1].yPixel;

    const lineMetersX = (x2 - x1) * this.gsd;
    const lineMetersY = (y2 - y1) * this.gsd;

    this.distanceMeters =
      Math.sqrt(lineMetersX * lineMetersX + lineMetersY * lineMetersY) * this.scaling;
  };

  private _calculateSlopeDirection = () => {
    const x1 = this.vertices[0].xPixel;
    const x2 = this.vertices[1].xPixel;
    const y1 = this.vertices[0].yPixel;
    const y2 = this.vertices[1].yPixel;

    const denominator = x2 - x1;
    const numerator = y2 - y1;
    let slope = 1;
    if (denominator !== 0) {
      slope = numerator / denominator;
    } else {
      slope = 0;
    }
    if (slope >= 0) {
      this.positiveSlope = true;
    }
  };

  public calculateMidPoint = () => {
    const xMid = (this.vertices[0].xPixel + this.vertices[1].xPixel) / 2;
    const yMid = (this.vertices[0].yPixel + this.vertices[1].yPixel) / 2;
    this.midPoint = [xMid, yMid];
  };

  copy(): Line {
    return {...this};
  }
  setHover(value: boolean): void {
    this.hover = value;
  }

  setSelected(value: boolean): void {
    this.selected = value;
  }
  translateByRatio(ratio: number | null): void {
    if (ratio) {
      this.calculateMidPoint();
    } else {
      this.calculateMidPoint();
    }
  }

  draw(drawContext: CanvasRenderingContext2D | null): void {
    if (drawContext) {
      // if (this.hover) {
      //   drawContext.strokeStyle = COLORS.hover;
      // } else
      if (this.selected) {
        drawContext.strokeStyle = COLORS.select;
      } else {
        drawContext.strokeStyle = COLORS.black;
      }
      drawContext.setLineDash([3, 3]);
      drawContext.lineWidth = 4;
      drawContext.beginPath();
      drawContext.moveTo(this.vertices[0].xPixel, this.vertices[0].yPixel);
      drawContext.lineTo(this.vertices[1].xPixel, this.vertices[1].yPixel);
      drawContext.stroke();
      drawContext.setLineDash([]);
      drawContext.closePath();

      drawContext.strokeStyle = COLORS.white;
      drawContext.setLineDash([3, 3]);
      drawContext.lineWidth = 2;
      // drawContext.lineDashOffset = 24;
      drawContext.beginPath();
      drawContext.moveTo(this.vertices[0].xPixel, this.vertices[0].yPixel);
      drawContext.lineTo(this.vertices[1].xPixel, this.vertices[1].yPixel);
      drawContext.stroke();
      drawContext.setLineDash([]);
      // drawContext.lineDashOffset = 0;
      drawContext.closePath();
    }
  }
}

class Label implements ShapeLayer {
  constructor(line: Line, layer: number) {
    this.line = line;
    this.selected = false;
    this.type = SHAPE_TYPES.Label;
    this.layer = layer;
    this.id = uuidv4();
    this.xPixel = 1;
    this.yPixel = 1;
    this.width = 45;
    this.lineWidth = 2;
    this.height = 20;
    this.hover = false;
    this._calculateStartPixel();
  }

  private _calculateStartPixel = () => {
    if (!this.line.positiveSlope) {
      this.yPixel = this.line.midPoint[1] + 10;
      this.xPixel = this.line.midPoint[0];
    } else {
      this.yPixel = this.line.midPoint[1] - 30;
      this.xPixel = this.line.midPoint[0];
    }
  };

  translateByRatio(ratio: number | null): void {
    if (ratio && !this.line.positiveSlope) {
      this.yPixel = this.line.midPoint[1] + 10;
      this.xPixel = this.line.midPoint[0] + 10;
    } else if (ratio && this.line.positiveSlope) {
      this.yPixel = this.line.midPoint[1] - 30;
      this.xPixel = this.line.midPoint[0];
    }
  }

  copy(): Label {
    return {...this};
  }

  setHover(value: boolean): void {
    this.hover = value;
  }

  setSelected(value: boolean): void {
    this.selected = value;
  }

  draw(drawContext: CanvasRenderingContext2D | null): void {
    if (drawContext) {
      // if (this.hover) {
      //   drawContext.strokeStyle = COLORS.hover;
      // } else
      if (this.selected) {
        drawContext.strokeStyle = COLORS.select;
      } else {
        drawContext.strokeStyle = COLORS.labelOutline;
      }

      drawContext.fillStyle = COLORS.labelBG;
      drawContext.lineWidth = this.lineWidth;
      drawContext.strokeRect(this.xPixel, this.yPixel, this.width, this.height);
      drawContext.fillRect(this.xPixel, this.yPixel, this.width, this.height);

      drawContext.strokeStyle = COLORS.black;
      drawContext.fillStyle = COLORS.black;
      drawContext.font = '12px Blender';
      drawContext.textAlign = 'center';
      drawContext.textBaseline = 'middle';
      const roundValue = (this.line.distanceMeters + Number.EPSILON) * 100;
      const xRect = this.xPixel;
      const yRect = this.yPixel;
      drawContext.fillText(
        `${Math.round(roundValue) / 100}m`,
        xRect + this.width / 2,
        yRect + this.height / 2
      );
      drawContext.closePath();
    }
  }
}

export type {Vector, ShapeLayer};
export {Line, Vertex, Label};
