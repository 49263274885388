import React from 'react';
import {Checkbox, FormControlLabel, FormGroup, Grid, styled} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectFiltersDisabled, selectMissions, toggleMission} from 'redux/filters';

export const PanelCheckbox = styled(Checkbox)(({theme}) => ({
  color: theme.palette.primary.label,
  '&.Mui-checked': {
    color: theme.palette.primary.contrastText
  },
  '&.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

export const PanelFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  color: theme.palette.primary.label,
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

const MissionFilterPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const missions = useAppSelector(selectMissions);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);

  const renderMissions = () => {
    return missions.map((mission) => {
      return (
        <Grid key={mission.id} item xs={mission.label.length > 24 ? 12 : 6}>
          <PanelFormControlLabel
            control={
              <PanelCheckbox
                disabled={filtersDisabled}
                onChange={() => {
                  dispatch(toggleMission(mission.id));
                }}
                checked={mission.value}
              />
            }
            label={mission.label}
          />
        </Grid>
      );
    });
  };
  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '0px',
        flex: '1 1 auto',
        maxWidth: '100%'
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: '16px',
          maxHeight: '300px',
          overflow: 'hidden',
          overflowY: 'scroll'
        }}
      >
        {renderMissions()}
      </Grid>
    </FormGroup>
  );
};

export default MissionFilterPanel;
