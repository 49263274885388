import React from 'react';
import {
  Box,
  Card,
  CardHeader as MuiCardHeader,
  IconButton,
  IconButtonProps,
  Collapse,
  CardContent as MuiCardContent,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableCell as MuiTableCell,
  TableHead,
  TableBody,
  Typography,
  CardActions
} from '@mui/material';
import AirIcon from '@mui/icons-material/Air';
import NorthIcon from '@mui/icons-material/North';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {OilSpill, OilSpillSource} from 'types/Alerts';
import {styled} from '@mui/material/styles';
import {getDetectConfidenceLabel, getDetectConfidenceColor} from 'utilities/vessels';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useAppSelector} from 'redux/hooks';
import {selectFocusedVessel} from 'redux/alerts';
import './index.scss';
import {OilSpillMarker} from 'styles/alert_icons';
import {degreesToCardinal} from 'utilities/map';

interface ODSCardProps {
  spill: OilSpill;
  spillSources: OilSpillSource[];
}

export const CardHeader = styled(MuiCardHeader)(({theme}) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  height: '39px',
  '.MuiCardHeader-title': {
    fontSize: '16px'
  }
}));

export const CardContent = styled(MuiCardContent)(({theme}) => ({
  backgroundColor: '#1A1918',
  paddingTop: '10px',
  paddingLeft: '10px',
  color: theme.palette.primary.main,
  height: '185px'
}));

export const CardColumn = styled('div')(({theme}) => ({
  backgroundColor: '#404040',
  color: theme.palette.primary.main,
  height: '39px'
}));

export const TableCell = styled(MuiTableCell)(({theme}) => ({
  backgroundColor: '#404040',
  color: theme.palette.primary.main,
  height: '28px',
  width: '120px',
  fontSize: '12px',
  padding: 0,
  paddingLeft: '10px',
  borderTop: '1px solid #5B5B5B',
  borderLeft: '1px solid #5B5B5B',
  borderBottom: '1px solid #5B5B5B'
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {expand, ...other} = props;
  return <IconButton {...other} />;
})(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  color: theme.palette.primary.main,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const ValueTableCell: React.FC<{
  value: string | number;
  large?: boolean;
  secondaryValue?: React.ReactNode;
}> = ({value, large, secondaryValue}) => {
  if (large)
    return (
      <TableCell sx={{backgroundColor: '#363636', width: '146px'}}>
        {value} {secondaryValue}
      </TableCell>
    );
  return (
    <TableCell sx={{backgroundColor: '#363636'}}>
      {value} {secondaryValue}
    </TableCell>
  );
};

export const ODSCard: React.FC<ODSCardProps> = ({spill, spillSources}) => {
  const [expanded, setExpanded] = React.useState(false);
  const focusedVessel = useAppSelector(selectFocusedVessel);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // spill.oilSpillId should be replaced with unique spill id
  const isFocused = focusedVessel && focusedVessel.vesselId === spill.vesselId;

  const potentialSources = spillSources.filter(
    (s) => spill.associatedOilSourceIds.indexOf(s.oilSourceDetails.oilSourceId) > -1
  );

  return (
    <Card
      id={spill.vesselId}
      sx={{
        width: '600px',
        marginBottom: '10px',
        borderRadius: 0,
        borderTop: `4px solid #6B6965FF`,
        borderRight: `1px solid ${isFocused ? '#999792' : '#6B6965'}`,
        borderBottom: `1px solid ${isFocused ? '#999792' : '#6B6965'}`,
        borderLeft: `1px solid ${isFocused ? '#999792' : '#6B6965'}`
      }}
    >
      <CardHeader
        avatar={
          <div
            style={{
              backgroundColor: '#363636',
              height: '24px',
              width: '24px',
              paddingTop: '2px'
            }}
          >
            <img src={OilSpillMarker} style={{margin: 'auto', height: '18px'}} />
          </div>
        }
        sx={{
          backgroundColor: isFocused ? '#B2AFAA' : '#4C4B48',
          color: isFocused ? '#1A1918' : '#fff'
        }}
        title={`Oil Spill Detect - ${spill.oilSpillDetails.metadataType}`}
      />
      <CardContent sx={{backgroundColor: isFocused ? '#6B6965' : '#1A1918'}}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={0}
        >
          <CardColumn
            sx={{
              display: 'flex',
              marginRight: '10px',
              height: '196px',
              width: '196px',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <div className={`vessel-chip-container ${isFocused && 'vessel-active'}`}>
              <LazyLoadImage
                effect="opacity"
                wrapperClassName="vessel-chip"
                className={`vessel-chip`}
                style={{
                  height: '180px',
                  width: '180px'
                }}
                src={spill.metadata.chipUrlPng}
              />
            </div>
          </CardColumn>
          <CardColumn sx={{marginRight: '20px', width: '360px', height: '168px'}}>
            <TableContainer component="div">
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>SPILL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      SOURCE TYPE
                    </TableCell>
                    <ValueTableCell value={spill.oilSpillDetails.dataSource} />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      SOURCE NAME
                    </TableCell>
                    <ValueTableCell value={spill.oilSpillDetails.dataSource} />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      AREA
                    </TableCell>
                    <ValueTableCell
                      value={`${spill.oilSpillDetails.areaKm2.toFixed(2)} km2`}
                    />
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      LENGTH
                    </TableCell>
                    <ValueTableCell
                      value={`${spill.oilSpillDetails.lengthKm.toFixed(2)} km`}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      WIDTH
                    </TableCell>
                    <ValueTableCell
                      value={`${spill.oilSpillDetails.widthKm.toFixed(2)} km`}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      VOLUME
                    </TableCell>
                    <ValueTableCell
                      value={
                        spill.oilSpillDetails.volumeKm3
                          ? `${spill.oilSpillDetails.volumeKm3.toFixed(2)} km3 `
                          : '--'
                      }
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardColumn>
          <CardColumn sx={{marginRight: '20px', width: '120px', height: '168px'}}>
            <TableContainer component="div">
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{verticalAlign: 'middle'}}>
                      <AirIcon style={{fontSize: 18, verticalAlign: 'middle'}} /> WIND
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{height: 84}}>
                    <TableCell sx={{backgroundColor: '#363636', pl: 0}}>
                      <div style={{textAlign: 'center'}}>
                        {spill.oilSpillDetails.windSpeedMps
                          ? `${spill.oilSpillDetails.windSpeedMps.toFixed(2)} mph`
                          : 'Unknown'}
                      </div>
                      {spill.oilSpillDetails.windDirectionDeg && (
                        <div style={{textAlign: 'center', marginTop: 10}}>
                          <NorthIcon
                            sx={{
                              rotate: `${spill.oilSpillDetails.windDirectionDeg}deg`
                            }}
                          />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <ValueTableCell
                      value={
                        spill.oilSpillDetails.windSpeedMps
                          ? `${spill.oilSpillDetails.windSpeedMps.toFixed(2)} mph`
                          : '- -'
                      }
                    />
                  </TableRow>
                  <TableRow>
                    <ValueTableCell
                      value={
                        spill.oilSpillDetails.windDirectionDeg
                          ? `${spill.oilSpillDetails.windDirectionDeg.toFixed(2)}° `
                          : '- -'
                      }
                    />
                  </TableRow>
                  <TableRow>
                    <ValueTableCell
                      value={
                        spill.oilSpillDetails.windDirectionDeg
                          ? `${degreesToCardinal(spill.oilSpillDetails.windDirectionDeg)}`
                          : '- -'
                      }
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardColumn>
        </Stack>
      </CardContent>
      <div style={{height: 30, backgroundColor: '#1A1918'}} />
      <CardActions
        disableSpacing
        sx={(theme) => {
          return {
            backgroundColor: isFocused ? '#6B6965' : '#1A1918',
            color: theme.palette.primary.main
          };
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon sx={{color: isFocused ? '#1A1918' : '#fff'}} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MuiCardContent
          sx={(theme) => {
            return {
              backgroundColor: isFocused ? '#6B6965' : '#1A1918',
              color: theme.palette.primary.main,
              padding: '10px !important',
              paddingLeft: '50px!important'
            };
          }}
        >
          {/*if correlated add table*/}

          {potentialSources.length > 0 && (
            <TableContainer
              component="div"
              sx={{width: '500px', marginBottom: '30px', display: 'flex'}}
            >
              {potentialSources.map((spillSource) => {
                return (
                  <>
                    <Box sx={{marginRight: '1em'}}>
                      <Typography>
                        Source {spillSource.oilSourceDetails.oilSourceId}
                      </Typography>

                      <Table
                        size="small"
                        aria-label="a dense table"
                        key={spillSource.oilSourceDetails.oilSourceId}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>Source</TableCell>
                            <TableCell>AIS</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              NAME
                            </TableCell>
                            <ValueTableCell
                              value={spillSource.oilSourceDetails.sourceName || '- -'}
                              large
                            />
                            <ValueTableCell
                              value={
                                spillSource.correlationVesselMetadata?.vesselName || '- -'
                              }
                              large
                            />
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              TYPE
                            </TableCell>
                            <ValueTableCell
                              value={spillSource.oilSourceDetails.sourceType || '- -'}
                              large
                            />
                            <ValueTableCell
                              value={
                                spillSource.correlationVesselMetadata?.vesselType || '- -'
                              }
                              large
                            />
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              CONFIDENCE
                            </TableCell>
                            <ValueTableCell
                              value={
                                spillSource.oilSourceDetails.sourceConfidence || '- -'
                              }
                              large
                            />
                            <ValueTableCell value={'- -'} large />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                    <Box>
                      <Typography>Source Vessel</Typography>

                      <Table
                        size="small"
                        aria-label="a dense table"
                        key={spillSource.oilSourceDetails.oilSourceId}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>AIS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              LENGTH (m)
                            </TableCell>
                            <ValueTableCell
                              value={
                                spillSource.correlationVesselMetadata?.vesselLengthMeters
                                  ? `${spillSource.correlationVesselMetadata?.vesselLengthMeters}`
                                  : '- -'
                              }
                            />
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              WIDTH (m)
                            </TableCell>
                            <ValueTableCell
                              value={
                                spillSource.correlationVesselMetadata?.vesselWidthMeters
                                  ? `${spillSource.correlationVesselMetadata?.vesselWidthMeters}`
                                  : '- -'
                              }
                            />
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              SPEED (kts)
                            </TableCell>
                            <ValueTableCell
                              value={
                                spillSource.correlationVesselMetadata?.speedKnots.toString() ||
                                '- -'
                              }
                            />
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              HEADING
                            </TableCell>
                            <ValueTableCell
                              value={
                                spillSource.correlationVesselMetadata?.headingDeg
                                  ? `${Math.round(
                                      (spillSource.correlationVesselMetadata?.headingDeg *
                                        100) /
                                        100
                                    )}°`
                                  : '- -'
                              }
                            />
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              IMO
                            </TableCell>
                            <ValueTableCell
                              value={spillSource.correlationVesselMetadata?.imo || '- -'}
                              large
                            />
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              MMSI
                            </TableCell>
                            <ValueTableCell
                              value={spillSource.correlationVesselMetadata?.mmsi || '- -'}
                              large
                            />
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              FLAG(S)
                            </TableCell>
                            <ValueTableCell
                              value={spillSource.correlationVesselMetadata?.flag || '- -'}
                              large
                            />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </>
                );
              })}
            </TableContainer>
          )}

          <Typography component="div">Summary Details</Typography>
          <Typography component="div">
            Classification: {spill.oilSpillDetails.classification || 'N/A'}
          </Typography>
          <Typography component="div">
            Timestamp: {spill.oilSpillDetails.recordDatetime}
          </Typography>
          <Typography component="div">
            Detect Confidence: {spill.oilSpillDetails.classificationConfidence}{' '}
            <div
              style={{
                backgroundColor: getDetectConfidenceColor(
                  getDetectConfidenceLabel(spill.oilSpillDetails.classificationConfidence)
                ),
                width: '64px',
                height: '16px',
                display: 'inline-block',
                textAlign: 'center',
                fontWeight: '900',
                color: '#1A1918',
                fontSize: '12px',
                marginLeft: '10px'
                // padding: '2px'
              }}
            >
              {getDetectConfidenceLabel(spill.oilSpillDetails.classificationConfidence)}
            </div>
          </Typography>
        </MuiCardContent>
      </Collapse>
    </Card>
  );
};
