import React from 'react';
// import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Box, Typography, Button, Divider, styled} from '@mui/material';
import {HistoryTabPanel} from 'components/VesselHistory/HistoryorSimilarVesselTabs';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {selectSelectedVessel, setSelectedVessel} from 'redux/vesselHistory';

import VesselDetailsEOAIS from 'components/VesselHistory//VesselDetailsEOAIS';
import VesselDetailHeaders from 'components/VesselHistory/VesselDetailHeaders';
import BackButtonIcon from 'components/Icons/BackButtonIcon';
import Mensuration from './Mensuration';
import IconButtonsOnVesselChip from './IconButtonsOnVesselChip';
import {
  resetState,
  selectVesselMensurationEnlarged,
  setEnlarged
} from 'redux/vesselChipMensuration';
import RFIcon from '../../assets/cards/RFIcon.png';
import {CardColumn} from '../ContentExplorer/ContentExplorerContent/AlertTabPanel/AlertChipsContainer/components/RFCard';

export const VesselDetailHeader = styled(Box)({
  height: '34px',
  minWidth: '560px',
  display: 'flex',
  alignItems: 'center'
});

const VesselCard = styled('div')(({theme}) => ({
  position: 'absolute',
  width: '98%',
  background: theme.palette.background.default,
  marginLeft: '11px'
}));

const VesselDetails = styled(Box)(({theme}) => ({
  width: '100%',
  color: theme.palette.primary.main
}));

const VesselHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedVessel = useAppSelector(selectSelectedVessel);
  const mensurationEnlarged = useAppSelector(selectVesselMensurationEnlarged);
  if (!selectedVessel) return null;
  return (
    <VesselCard>
      <VesselDetailHeader>
        <Box
          onClick={() => {
            dispatch(setSelectedVessel(null));
            dispatch(setEnlarged(false));
            dispatch(resetState());
          }}
          sx={(theme) => ({
            color: theme.palette.primary.main,
            paddingLeft: '0px',
            paddingRight: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
          })}
        >
          <Button variant="text" startIcon={<BackButtonIcon />} sx={{pl: 1, pr: 1}}>
            Back to Alert
          </Button>
        </Box>
      </VesselDetailHeader>
      <Divider
        flexItem
        orientation="horizontal"
        sx={(theme) => ({
          borderColor: theme.palette.primary.contrastText
        })}
      />
      <VesselDetails
        sx={{
          display: 'flex',
          flexDirection: mensurationEnlarged ? 'column' : 'row',
          height: mensurationEnlarged ? '' : '236px',
          flexGrow: mensurationEnlarged ? 1 : ''
        }}
      >
        <Box
          component="div"
          sx={{
            width: mensurationEnlarged ? '545px' : '208px',
            height: mensurationEnlarged ? '545px' : '208px',
            alignSelf: mensurationEnlarged ? 'center' : '',
            marginTop: '8px',
            position: 'relative'
          }}
        >
          <Box
            component="div"
            sx={{
              height: '100%',
              position: 'relative'
            }}
          >
            {selectedVessel?.properties?.source === 'RF' ? (
              <CardColumn
                sx={{
                  display: 'flex',
                  marginRight: '10px',
                  height: '205px',
                  width: '205px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img src={RFIcon} style={{margin: 'auto'}} />
              </CardColumn>
            ) : (
              <>
                {' '}
                <Box
                  sx={{
                    height: '26px',
                    position: 'absolute',
                    zIndex: 5,
                    bottom: 0,
                    right: 0
                  }}
                >
                  <IconButtonsOnVesselChip />
                </Box>
                <Mensuration />
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={() => ({
            flexGrow: 1,
            height: mensurationEnlarged ? '236px' : '',
            marginLeft: '10px'
          })}
        >
          <Typography> Inventory ID: {selectedVessel.inventoryId} </Typography>
          <Typography>MMSI : {selectedVessel?.properties?.mmsi ?? 'Unknown'}</Typography>
          {/* placeholder for where tag will go */}
          <Box sx={{height: '16px'}}> </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '33% 33% 33%'
            }}
          >
            <Box> </Box>
            <Typography>{selectedVessel?.properties?.source ?? 'Unknown'}</Typography>
            <Typography> AIS </Typography>
          </Box>
          <Divider
            flexItem
            orientation="horizontal"
            sx={() => ({
              borderColor: '#4C4B48',
              width: '100%'
            })}
          />
          <Box>
            <Box
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '33% 33% 33%',
                color: theme.palette.primary.main,
                minWidth: '186px',
                marginTop: '12px'
              })}
            >
              <VesselDetailHeaders />
              <VesselDetailsEOAIS vessel={selectedVessel} />
              <VesselDetailsEOAIS vessel={selectedVessel.correlationMetadata} />
            </Box>
          </Box>
        </Box>
      </VesselDetails>
      <HistoryTabPanel />
    </VesselCard>
  );
};

export default VesselHistory;
