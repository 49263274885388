import React from 'react';
import {
  AlertBlockHeader,
  AlertDetailCard,
  AlertDetailContentBody,
  AlertDetailDataTable,
  AlertDetailDataTableCell,
  AlertDetailDataTableCellLong,
  AlertDetailDataTableHeader,
  AlertDetailDataTableHeaderLong,
  AlertDetailLinkAnchor,
  AlertHorizontalDivider,
  AlertSectionHeader
} from '../Styled';
import {formatDate} from 'utilities/date';
import {Divider, Typography} from '@mui/material';
import {EOAlertResponse} from 'types/Alerts';
import {group} from 'radash';
import {ErrorBoundary} from 'react-error-boundary';
import {AccordionDOMRef, LatencyControlContext} from './Latency/LatencyContext';
import {LatencyAccordionList, LatencyLink} from './Latency';

const logError = (error: Error, info: {componentStack: string}) => {
  // TODO: do something with the error, e.g. log to an external API
  // eslint-disable-next-line no-console
  console.log(error, info);
};

interface EOSummaryProps {
  alert: EOAlertResponse;
}

export const EOSummary: React.FC<EOSummaryProps> = ({alert}) => {
  const groupedVessels = group(alert.vessels, (v) => v.properties.vesselType || '');
  const vesselStats = Object.keys(groupedVessels).map((key) => {
    const vesselList = groupedVessels[key] || [];
    const correlated = vesselList.filter((v) => v.properties.correlated);
    return {
      shipType: key,
      total: vesselList.length,
      dark: vesselList.length - correlated.length,
      correlated: correlated.length
    };
  });

  const correlatedTotal = alert.vessels.filter((v) => v.properties.correlated);

  const totals = {
    correlated: correlatedTotal.length,
    dark: alert.vessels.length - correlatedTotal.length,
    total: alert.vessels.length
  };

  const inventoryStats = alert.inventoryItems.map((item) => {
    const matching = alert.vessels.filter(
      (v) => v.inventorySummary.inventoryId === item.inventoryId
    );
    return {
      stripId: item.inventoryId,
      metadataZipDownloadUrl: item.metadataZipDownloadUrl,
      detects: matching.length,
      cloudCoverage: Math.round(item.stripCloudCoverPct),
      status: item.vesselDetectStatus
    };
  });

  const completedInventoryCount = alert.inventoryItems.filter(
    (item) => item.vesselDetectStatus === 'Completed'
  ).length;

  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  const accordionRefs = React.useRef<AccordionDOMRef>({});

  const observationType = alert.tips.length > 0 ? 'Tipped' : 'Watchbox';

  const mmsis = alert.tips
    .map((tip) => tip.tipMetadata.observations.map((ob) => ob.mmsi))
    .join(', ');

  const rulesets = Array.from(
    new Set(alert.tips.map((tip) => tip.tipMetadata.rulesets).flat())
  ).join(', ');

  return (
    <AlertDetailCard className="alert-details-info">
      <AlertSectionHeader>Collection Summary</AlertSectionHeader>
      <AlertDetailContentBody>
        <b>Mission: </b>
        {alert.properties.mission}
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        <b>Collect Date & Time:</b>
        {` ${formatDate(alert.properties.meanDatetime, 'yyyy-MM-dd')} | ${formatDate(
          alert.properties.meanDatetime,
          'HH:mm:ss'
        )}Z`.toUpperCase()}
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        <b>Billable Area Collected:</b>
        {alert.areaCalcs.unique_collected_cloud_free_search_area_km2}km<sup>2</sup>
      </AlertDetailContentBody>
      <AlertHorizontalDivider />
      <AlertSectionHeader>Vessel Detections</AlertSectionHeader>
      <AlertDetailDataTable>
        <tbody>
          <tr>
            <AlertDetailDataTableHeaderLong>VESSEL TYPE</AlertDetailDataTableHeaderLong>
            <AlertDetailDataTableHeader>CORRELATED</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>DARK</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>TOTAL</AlertDetailDataTableHeader>
          </tr>
          {vesselStats.map((stat) => {
            return (
              <tr key={stat.shipType}>
                <AlertDetailDataTableCellLong>
                  {stat.shipType}
                </AlertDetailDataTableCellLong>
                <AlertDetailDataTableCell>{stat.correlated}</AlertDetailDataTableCell>
                <AlertDetailDataTableCell>{stat.dark}</AlertDetailDataTableCell>
                <AlertDetailDataTableCell>{stat.total}</AlertDetailDataTableCell>
              </tr>
            );
          })}
          <tr>
            <AlertDetailDataTableHeaderLong>TOTALS:</AlertDetailDataTableHeaderLong>
            <AlertDetailDataTableHeader>{totals.correlated}</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>{totals.dark}</AlertDetailDataTableHeader>
            <AlertDetailDataTableHeader>{totals.total}</AlertDetailDataTableHeader>
          </tr>
        </tbody>
      </AlertDetailDataTable>
      <AlertHorizontalDivider />
      <AlertSectionHeader>{observationType} Observation Details</AlertSectionHeader>
      <AlertDetailContentBody>
        <b>MMSI(s):</b> {mmsis.length > 0 ? mmsis : 'N/A'}
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        <b>Ruleset(s):</b> {rulesets.length > 0 ? rulesets : 'N/A'}
      </AlertDetailContentBody>

      <LatencyControlContext.Provider value={{accordionRefs, setExpanded, expanded}}>
        <AlertDetailDataTable>
          <tbody>
            <tr>
              <AlertDetailDataTableHeader className="strip-id">
                IMAGE STRIP ID
              </AlertDetailDataTableHeader>
              <AlertDetailDataTableHeader className="detects">
                DETECTS
              </AlertDetailDataTableHeader>
              <AlertDetailDataTableHeader className="cloud-coverage">
                CLOUD COVERAGE
              </AlertDetailDataTableHeader>
              <AlertDetailDataTableHeader className="status">
                STATUS
              </AlertDetailDataTableHeader>
            </tr>

            {inventoryStats.map((stat) => {
              return (
                <tr key={stat.stripId}>
                  <AlertDetailDataTableCellLong className="strip-id">
                    {stat.stripId}
                  </AlertDetailDataTableCellLong>
                  <AlertDetailDataTableCell className="detects">
                    {stat.detects}
                  </AlertDetailDataTableCell>
                  <AlertDetailDataTableCell className="cloud-coverage">
                    {stat.cloudCoverage}%
                  </AlertDetailDataTableCell>
                  <AlertDetailDataTableCell className="status">
                    <LatencyLink targetId={stat.stripId}>{stat.status}</LatencyLink>
                    <span>
                      &nbsp;|&nbsp;
                      <AlertDetailLinkAnchor
                        href={stat.metadataZipDownloadUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </AlertDetailLinkAnchor>
                    </span>
                  </AlertDetailDataTableCell>
                </tr>
              );
            })}

            <tr>
              <AlertDetailDataTableHeader className="collection-total" colSpan={4}>
                TOTAL COLLECTIONS: {completedInventoryCount}/{inventoryStats.length}
              </AlertDetailDataTableHeader>
            </tr>
          </tbody>
        </AlertDetailDataTable>
        <AlertHorizontalDivider />

        <AlertSectionHeader>Latency Details</AlertSectionHeader>
        <ErrorBoundary
          fallback={<Typography>Error fetching latencies</Typography>}
          onError={logError}
        >
          <LatencyAccordionList
            properties={alert.properties}
            tips={alert.tips}
            inventoryItems={alert.inventoryItems}
            vesselSearchAreas={alert.vesselSearchAreas}
            source={alert.source}
          />
        </ErrorBoundary>

        <AlertHorizontalDivider />
      </LatencyControlContext.Provider>

      <AlertSectionHeader>Area Statistics</AlertSectionHeader>
      <AlertDetailContentBody>
        Unique Search Area: &nbsp;{alert.areaCalcs.unique_search_area_km2}km
        <sup>2</sup>
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        Unique Collection Area: &nbsp;{alert.areaCalcs.unique_collect_area_km2}km
        <sup>2</sup>
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        Unique Cloud Free Collected Area: &nbsp;
        {alert.areaCalcs.unique_collected_cloud_free_search_area_km2}km<sup>2</sup>
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        Unique Collected Search Area: &nbsp;
        {alert.areaCalcs.unique_collected_search_area_km2}km<sup>2</sup>
      </AlertDetailContentBody>
      <AlertDetailContentBody>
        Unique Collected Cloud Free Search Area (Billable): &nbsp;
        {alert.areaCalcs.unique_collected_cloud_free_search_area_km2}km<sup>2</sup>
      </AlertDetailContentBody>
      <Divider />
      <AlertBlockHeader>MAXAR Maritime Service Desk</AlertBlockHeader>
      <AlertDetailContentBody>
        <AlertDetailLinkAnchor
          href="mailto:maritime_support@maxar.com"
          target="_blank"
          rel="noreferrer"
        >
          maritime_support@maxar.com
        </AlertDetailLinkAnchor>
      </AlertDetailContentBody>
    </AlertDetailCard>
  );
};
