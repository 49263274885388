import React from 'react';
import {Tooltip, MenuItem, Button, Menu, Typography} from '@mui/material';
import KCService from 'index';

//Initialize the following
const SettingsMenu: React.FC = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const settings = ['Logout'];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  async function logout() {
    sessionStorage.clear();
    return KCService.doLogout({
      redirectUri: window.location.origin
    });
  }

  const handleClickUserMenu = (settingOption: string) => {
    if (settingOption === 'Logout') {
      logout();
    }
    handleCloseUserMenu();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      <Tooltip title="Open user settings">
        <Button variant="text" onClick={handleOpenUserMenu} style={{marginRight: 20}}>
          {KCService.getUserInfo().name}
        </Button>
      </Tooltip>
      <Menu
        sx={{mt: '45px'}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={() => handleClickUserMenu(setting)}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default SettingsMenu;
