import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  FormGroup,
  Menu,
  Switch,
  Stack
} from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import FilterPanel from './FilterPanel';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Spacer} from 'components/Styled/UtilityComponents';
import {styled} from '@mui/material/styles';
import MdiCircle from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';

import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectFiltersEnabled,
  setFiltersEnabled,
  resetFilters,
  setHideZeroDetects,
  selectPanelsActive
} from 'redux/filters';

export const FilterBackground = styled(Box)(() => ({
  width: '440px',
  background: 'rgba(42, 41, 40, 1)'
}));

export const FilterCardTitle = styled(Box)(() => ({
  width: '440px',
  display: 'flex',
  paddingTop: '10px',
  paddingBottom: '7px',
  paddingLeft: '10px',
  paddingRight: '11.5px',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '19px',
  letterSpacing: '0.05em',
  color: 'rgba(163, 163, 163, 1)',
  background: 'rgba(42, 41, 40, 1)'
}));

const FilterEnable = styled(Switch)(({theme}) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: 'transparent',
    border: '1px solid #606060',
    height: 'auto',
    opacity: 1,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 22,
      height: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  },
  '& .MuiSwitch-switchBase': {
    '&:not(.Mui-checked)': {
      color: '#606060'
    },
    '&.Mui-checked + .MuiSwitch-track': {
      borderColor: theme.palette.primary.contrastText,
      backgroundColor: 'transparent'
    },
    '&.Mui-checked': {
      color: theme.palette.primary.contrastText
    }
  }
}));

export const FilterContentContainer = styled(Box)(() => ({
  width: '440px',
  background: 'rgba(26, 25, 24, 1)'
}));

export const FilterCardHeader = styled(Box)(() => ({
  height: '35px',
  borderBottom: '1px solid rgba(42, 41, 40, 1)',
  padding: '0px 7px 0px 9px'
}));

export const FilterCardActions = styled(Box)(() => ({
  maxHeight: '35px',
  display: 'flex'
}));

export const FilterCardFooter = styled(Box)(() => ({
  paddingBottom: '10px',
  paddingRight: '10px',
  paddingLeft: '10px',
  paddingTop: '10px',
  height: '55px',
  backgroundColor: '#1A1918',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
}));

export const PanelCheckbox = styled(Checkbox)(({theme}) => ({
  color: 'rgba(178, 175, 170, 1)',
  '&.Mui-checked': {
    color: theme.palette.primary.contrastText
  },
  '&.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  }
}));

export const PanelFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  color: 'rgba(178, 175, 170, 1)',
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.secondary.contrastText
  },
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '19px',
  letterSpacing: '0.30000001192092896px'
}));

const CollectionFilter: React.FC = () => {
  const [anchorElFilter, setAnchorElFilter] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const filtersEnabled = useAppSelector(selectFiltersEnabled);
  const panelsActive = useAppSelector(selectPanelsActive);
  const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };
  const disableHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFiltersEnabled(e.target.checked));
  };

  const handleClearClick = () => {
    dispatch(resetFilters());
  };

  const handleCancelClick = () => {
    handleCloseFilterMenu();
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={(theme) => ({
          borderRadius: '4px',
          color: theme.palette.secondary.contrastText
        })}
        onClick={handleOpenFilterMenu}
        startIcon={
          <FilterIcon
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText
            })}
          />
        }
      >
        Filters
        {panelsActive ? (
          <MdiCircle
            sx={(theme) => ({
              width: '10px',
              height: '10px',
              alignSelf: 'center',
              marginLeft: '5px',
              color: theme.palette.primary.contrastText,
              ...(!filtersEnabled && {
                color: theme.palette.secondary.contrastText
              })
            })}
          />
        ) : (
          ''
        )}
      </Button>

      <Menu
        sx={{
          mt: '45px',
          maxHeight: 'calc(100vh - 75px)',
          paddingTop: '0px',
          paddingBottom: '0px',
          overflowY: 'hidden !important'
        }}
        marginThreshold={0}
        id="menu-filters"
        anchorEl={anchorElFilter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={Boolean(anchorElFilter)}
        onClose={handleCloseFilterMenu}
      >
        <FilterBackground sx={{borderRadius: 0}}>
          <FilterCardTitle>
            COLLECTION FILTERS
            <Spacer />
            <IconButton aria-label="close-filter-menu" onClick={handleCancelClick}>
              <CloseIcon sx={{color: 'rgba(163, 163, 163, 1)'}} />
            </IconButton>
          </FilterCardTitle>
          <FilterContentContainer>
            <FilterCardHeader>
              <FormGroup>
                <Stack direction="row">
                  <PanelFormControlLabel
                    defaultChecked
                    control={
                      <PanelCheckbox
                        sx={{
                          paddingLeft: '9px',
                          paddingRight: '12px',
                          paddingTop: '0px',
                          paddingBottom: '0px'
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setHideZeroDetects(event.target.checked));
                        }}
                      />
                    }
                    label="Hide (0) Detect Alerts"
                  />

                  <PanelFormControlLabel
                    control={
                      <FilterEnable
                        defaultChecked={filtersEnabled}
                        value={filtersEnabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          disableHandler(e);
                        }}
                      />
                    }
                    label={`Filters ${filtersEnabled ? 'ON' : 'OFF'}`}
                  />
                </Stack>
              </FormGroup>
            </FilterCardHeader>
          </FilterContentContainer>
          <Box
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: 'calc(100vh - 300px)'
            }}
          >
            <FilterPanel />
          </Box>
          <FilterCardFooter>
            <FilterCardActions>
              <Button
                variant="text"
                startIcon={<RefreshIcon />}
                onClick={handleClearClick}
              >
                Clear all
              </Button>
              <Spacer />
              <Button variant="contained" onClick={handleCancelClick}>
                Close
              </Button>
            </FilterCardActions>
          </FilterCardFooter>
        </FilterBackground>
      </Menu>
    </>
  );
};

export default CollectionFilter;
