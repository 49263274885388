import React from 'react';
import {Vessel} from 'types/Alerts';
import {RFeature} from 'rlayers';
import {Circle, Point} from 'ol/geom';
import {fromLonLat} from 'ol/proj';
import {Circle as CircleStyle, Stroke, Fill, Style} from 'ol/style';

import {DARK_RED, LIT_GREEN} from 'styles/alert_icons';
import {useAppSelector} from 'redux/hooks';
import {selectRFHalosEnabled} from 'redux/layers';

interface RFVesselFeatureProps {
  vessel: Vessel;
  onClick: (vessel: Vessel) => void;
}

export const RFVesselFeature: React.FC<RFVesselFeatureProps> = ({vessel, onClick}) => {
  const color = vessel.properties.dark ? DARK_RED : LIT_GREEN;
  const rfHalosEnabled = useAppSelector(selectRFHalosEnabled);
  if (!vessel.vesselProperties) return null;
  const accuracy = vessel.vesselProperties?.detectionVesselMetadata?.accuracyLevelKm || 0;
  return (
    <>
      <RFeature
        onClick={() => onClick(vessel)}
        properties={{FeatureType: 'VesselDetect'}}
        geometry={new Point(fromLonLat([vessel.lon, vessel.lat]))}
        style={() => {
          return new Style({
            image: new CircleStyle({
              radius: 2,
              stroke: new Stroke({
                color: color,
                width: 1
              }),
              fill: new Fill({
                color: `${color}E6`
              })
            })
          });
        }}
      />
      {rfHalosEnabled && (
        <RFeature
          geometry={new Circle(fromLonLat([vessel.lon, vessel.lat]), accuracy * 2000)}
          style={() => {
            return new Style({
              renderer(coordinates, state) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const [[x, y], [x1, y1]] = coordinates;
                const ctx = state.context;
                const dx = x1 - x;
                const dy = y1 - y;
                const radius = Math.sqrt(dx * dx + dy * dy);
                const innerRadius = 0;
                const outerRadius = radius * 1.4;
                const gradient = ctx.createRadialGradient(
                  x,
                  y,
                  innerRadius,
                  x,
                  y,
                  outerRadius
                );
                gradient.addColorStop(0, `${color}40`);
                gradient.addColorStop(0.6, `${color}1A`);
                gradient.addColorStop(1, 'rgba(255,0,0,0)');
                ctx.beginPath();
                ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
                ctx.fillStyle = gradient;
                ctx.fill();

                ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
                ctx.strokeStyle = `${color}33`;
                ctx.stroke();
              }
            });
          }}
        />
      )}
    </>
  );
};
