import React from 'react';
import {LayersControlContent} from './LayersControlContent';
import {SidebarAccordian} from 'components//Map/Controls/components/SidebarAccordian';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {
  selectLayerManagerOpen,
  setLayerManagerDocked,
  setLayerManagerOpen
} from 'redux/map';

export const SidebarLayersControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const layersEnabled = useAppSelector(selectLayerManagerOpen);
  const onUndock = () => {
    dispatch(setLayerManagerDocked(false));
  };
  const onClose = () => {
    dispatch(setLayerManagerOpen(false));
  };
  if (!layersEnabled) return null;
  return (
    <SidebarAccordian
      title="Layers"
      onUndock={onUndock}
      onClose={onClose}
      icon={
        <LayersOutlinedIcon
          fontSize="small"
          sx={{
            fontSize: '17px',
            marginLeft: '0px !important',
            marginRight: '5px',
            marginTop: '5px !important'
          }}
        />
      }
    >
      <LayersControlContent />
    </SidebarAccordian>
  );
};
