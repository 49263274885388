import type {AlertAccessHistory, UserPreferences} from 'types/UserPreferences';
import {MausClient} from '../api';

export const newLoginRecord = async () => {
  // user already logged in, no need to report again
  // particularly to avoid recording on page refreshes
  if (sessionStorage.getItem('hasRecordedLogin')) {
    return;
  }

  const api = MausClient.getApi();

  let appVersion = process.env.REACT_APP_VERSION;

  if (!appVersion || appVersion.split('.').length !== 3) {
    appVersion = '0.1.0';
  }
  const [major, minor, patch] = appVersion.split('.');

  const response = await api.post('/user-access', {
    applicationName: 'Maritime Analytics Application',
    version: {
      major,
      minor,
      patch
    }
  });

  if (response) {
    sessionStorage.setItem('hasRecordedLogin', 'true');
  }

  return response.data;
};

export const getUserPreferences = async (): Promise<UserPreferences> => {
  const api = MausClient.getApi();

  const {data} = await api.get('/user-preferences');

  return data;
};

export type AccessHistoryBody = {
  alertId: string;
  /** Api expects the `vesselCount` to be a number as string */
  vesselCount: string;
  lastVesselId?: string;
};

export const createAccessHistoryLog = async (body: AccessHistoryBody) => {
  const api = MausClient.getApi();

  const response = await api.post('/alert-access', {
    alertIdViewed: body.alertId,
    vesselCount: body.vesselCount,
    lastVesselId: body.lastVesselId
  });

  return response.data;
};

export const updateAlertAccessHistory = async (
  body: AccessHistoryBody,
  queryParams: {dateStart: string; dateEnd: string}
) => {
  const api = MausClient.getApi();

  const params = new URLSearchParams(queryParams);

  return await api.put('/alert-access?' + params, {
    alertIdViewed: body.alertId,
    vesselCount: body.vesselCount,
    lastVesselId: body.lastVesselId
  });
};

export const getAccessHistory = async (queryParams: {
  dateStart: string;
  dateEnd: string;
}) => {
  const api = MausClient.getApi();

  const params = new URLSearchParams(queryParams);

  const {data} = await api.get<{accessHistory: AlertAccessHistory[]}>(
    '/alert-access?' + params
  );

  return data;
};
