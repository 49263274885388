import React from 'react';
import {ExpandMore, Visibility, VisibilityOff} from '@mui/icons-material';
import {LinearProgress, Box, Divider, IconButton, Typography} from '@mui/material';
import {
  Alert,
  AlertDetails,
  AlertSummary,
  AlertSummaryContent,
  AlertSummaryVesselCount
} from 'components/ContentExplorer/ContentExplorerContent/Styled';
import {EyeToggleButton} from 'components/Styled/UtilityComponents';
import CenterFocusWeakSharpIcon from '@mui/icons-material/CenterFocusWeakSharp';
import {AlertTabPanel} from 'components/ContentExplorer/ContentExplorerContent/AlertTabPanel';
import {NoAlerts} from 'components/ContentExplorer/NoAlerts';
import {EOAlertResponse} from 'types/Alerts';
import {
  selectAlertsLoading,
  selectAlerts,
  selectAlertStateById,
  deselectAlert,
  selectAlert,
  expandAlert,
  collapseAlert,
  selectAlertsFailed,
  selectAlertLoadError,
  loadAlerts
} from 'redux/alerts';
import {useAppDispatch, useAppSelector, useInterval} from 'redux/hooks';
import {setMapExtent} from 'redux/map';
import {dateInRange, formatDate} from 'utilities/date';
import {ApiRequestError} from '../ApiRequestRejection';
import {fetchAccessHistory, updateAccessHistory} from 'redux/alertAccess/slice';
import {getUpdatedAlerts} from 'redux/alertAccess';
import {selectSelectedTimeFrame} from 'redux/filters';
import {ONE_MINUTE} from 'constants/constants';
import {DownloadButton} from './DownloadButton';

interface AlertPanelProps {
  alert: EOAlertResponse;
}

const AlertPanel: React.FC<AlertPanelProps> = ({alert}) => {
  const dispatch = useAppDispatch();
  const alertState = useAppSelector((state) => selectAlertStateById(state, alert.id));

  const toggleAlert = () => {
    if (alertState.selected) {
      dispatch(deselectAlert(alert.id));
    } else {
      dispatch(setMapExtent(alert.alertGeometry.coordinates));
      dispatch(selectAlert(alert.id));
    }
  };

  const setExpanded = () => {
    if (alertState.expanded) {
      dispatch(collapseAlert(alert.id));
    } else {
      dispatch(expandAlert(alert.id));
    }
  };

  const zoomToAlert = () => {
    if (alert.alertGeometry) {
      dispatch(setMapExtent(alert.alertGeometry.coordinates));
    }
  };

  const updatedAlerts = useAppSelector(getUpdatedAlerts);
  const dates = useAppSelector(selectSelectedTimeFrame);

  const isTodaysDate = !dateInRange(dates.start, dates.end);
  const alertStatus =
    updatedAlerts.some((updatedAlert) => updatedAlert.id === alert.id) && isTodaysDate
      ? 'new'
      : null;

  React.useEffect(() => {
    if (alertState.expanded) {
      dispatch(
        updateAccessHistory({
          alertId: alert.id,
          lastVesselId: alert.vessels.at(-1)?.id ?? '',
          vesselCount: alert.counts.total.toString(),
          queryParams: {}
        })
      );
    }
  }, [alertState.expanded, alert, alertStatus, dispatch]);

  return (
    <Alert
      id={alert.alertIdentifier}
      square
      TransitionProps={{unmountOnExit: true, timeout: 100}}
      elevation={0}
      expanded={alertState.expanded}
      disableGutters
    >
      <AlertSummary
        sx={(theme: any) => ({
          '& .MuiAccordionSummary-content': {
            width: '100%',
            paddingRight: '0'
          },
          borderColor: alertState.selected ? theme.palette.primary.contrastText : ''
        })}
      >
        {alertStatus && <StatusIndicator status={alertStatus} />}

        <EyeToggleButton
          sx={{pointerEvents: 'auto', height: '25px', width: '25px'}}
          onClick={toggleAlert}
        >
          {alertState.selected ? (
            <Visibility
              sx={(theme) => ({
                fontSize: '20px',
                color: theme.palette.primary.contrastText
              })}
            />
          ) : (
            <VisibilityOff
              sx={(theme) => ({
                fontSize: '20px',
                color: theme.palette.secondary.contrastText
              })}
            />
          )}
        </EyeToggleButton>
        <Box
          sx={{
            pointerEvents: 'auto',
            height: '25px',
            width: '30px',
            minWidth: '30px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
          onClick={setExpanded}
        >
          <ExpandMore
            className={alertState.expanded ? 'rotate' : ''}
            sx={(theme) => ({
              fontSize: '20px',
              color: theme.palette.primary.main
            })}
          />
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={(theme) => ({
            borderColor: theme.palette.secondary.main,
            marginRight: '16px'
          })}
        />

        <AlertSummaryContent noWrap>
          {formatDate(alert.properties.meanDatetime, 'yyyy-MM-dd') +
            ' | ' +
            alert.source +
            ' | ' +
            (alert.properties.scid === '' || alert.properties.scid === undefined
              ? ''
              : `${alert.properties.scid} | `) +
            alert.properties.mission}
        </AlertSummaryContent>
        {alert.source === 'ODS' ? (
          <AlertSummaryVesselCount>
            {alert.spills.length} Oil Spills
          </AlertSummaryVesselCount>
        ) : (
          <AlertSummaryVesselCount>
            {alert.counts.correlated} Correlated | {alert.counts.dark} Dark
          </AlertSummaryVesselCount>
        )}

        <Divider
          orientation="vertical"
          flexItem
          sx={(theme) => ({
            borderColor: theme.palette.secondary.main,
            marginLeft: '16px'
          })}
        />
        <IconButton
          sx={(theme) => ({
            height: '25px',
            width: '36px',
            pointerEvents: 'auto',
            color: theme.palette.secondary.contrastText
          })}
          onClick={zoomToAlert}
        >
          <CenterFocusWeakSharpIcon />
        </IconButton>
        <Divider
          orientation="vertical"
          flexItem
          sx={(theme) => ({
            borderColor: theme.palette.secondary.main
          })}
        />

        <DownloadButton alertId={alert.alertIdentifier} />
      </AlertSummary>
      <AlertDetails>
        {alertState.expanded && <AlertTabPanel alert={alert} />}
      </AlertDetails>
    </Alert>
  );
};

function StatusIndicator({status}: {status: 'new' | 'updated'}): JSX.Element {
  return (
    <Box
      sx={(theme) => ({
        minWidth: '3.5em',
        padding: '0 1em',
        marginRight: '0.5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.text.primary
      })}
    >
      <Typography variant="caption" sx={{textTransform: 'uppercase', fontSize: '0.8em'}}>
        {status}
      </Typography>
    </Box>
  );
}

export const ContentExplorerContent: React.FC = () => {
  const alerts = useAppSelector(selectAlerts);
  const isLoading = useAppSelector(selectAlertsLoading);
  const isRejected = useAppSelector(selectAlertsFailed);
  const error = useAppSelector(selectAlertLoadError);

  const dispatch = useAppDispatch();

  // Polling for refreshing alert's store
  useInterval(() => {
    dispatch(loadAlerts({avoidLoadingState: true}));
    dispatch(fetchAccessHistory());
  }, ONE_MINUTE * 2.5);

  return (
    <Box
      sx={{
        maxWidth: '100%',
        marginRight: '2px',
        overflowX: 'hidden',
        overflowY: 'auto'
      }}
    >
      {isLoading && (
        <LinearProgress
          sx={(theme) => ({backgroundColor: theme.palette.primary.contrastText})}
        />
      )}
      {alerts.length === 0 && !isLoading ? (
        !isRejected ? (
          <NoAlerts />
        ) : (
          <ApiRequestError error={error} />
        )
      ) : (
        <Box>
          {alerts.map((row) => {
            return <AlertPanel key={row.id} alert={row} />;
          })}
        </Box>
      )}
    </Box>
  );
};
