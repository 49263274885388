import React from 'react'; // { useMemo }
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material';
import {ArrowForwardSharp, Search} from '@mui/icons-material';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectFiltersDisabled, selectMmsis, setMmsis} from 'redux/filters';

const MMSIPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const mmsis = useAppSelector(selectMmsis);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);
  const [errorState, setErrorState] = React.useState(false);
  const [text, setText] = React.useState('');

  const onEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    const element = e.currentTarget.children[1] as HTMLInputElement;
    const list = element.value.replaceAll(' ', '').split(',');
    dispatch(setMmsis([...mmsis, ...list]));
    let errorFlag = false;
    list.forEach((mmsi) => {
      if (isNaN(+mmsi)) {
        errorFlag = true;
      }
    });
    if (errorFlag) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
    setText('');
  };

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const element = e.currentTarget.parentElement?.parentElement
      ?.children[1] as HTMLInputElement;
    const list = element.value.replaceAll(' ', '').split(',');
    dispatch(setMmsis([...mmsis, ...list]));
    let errorFlag = false;
    list.forEach((mmsi) => {
      if (isNaN(+mmsi)) {
        errorFlag = true;
      }
    });
    if (errorFlag) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
    setText('');
  };

  const handleDelete = (value: string) => {
    dispatch(setMmsis(mmsis.filter((mmsi) => mmsi !== value)));
  };

  return (
    <Box sx={{paddingLeft: '8px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{width: '100%'}}>
            <InputLabel htmlFor="mmsi-collection-filter">Search by MMSI</InputLabel>
            <Input
              id="mmsi-collection-filter"
              type={'text'}
              value={text}
              aria-describedby="mmsi-collection-filter-helper-text"
              onKeyUpCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
                onEnter(event);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="click enter mmsi" onClick={handleOnClick}>
                    <ArrowForwardSharp />
                  </IconButton>
                </InputAdornment>
              }
              disabled={filtersDisabled}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setText(e.target.value)
              }
              sx={{width: '100%'}}
              color="primary"
            />
            <FormHelperText id="mmsi-collection-filter-helper-text">
              Separate multiples by comma ,
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {mmsis.map((mmsi, index) => {
            return (
              <Chip
                key={`${mmsi}${index}`}
                id={`${mmsi}-${index}`}
                label={mmsi}
                disabled={filtersDisabled}
                onDelete={() => handleDelete(mmsi)}
                variant="outlined"
                color="primary"
                sx={{
                  marginRight: '8px',
                  marginBottom: '16px',
                  backgroundColor: errorState ? 'rgba(239, 83, 80, 0.3)' : '#2A2928',
                  borderColor: errorState ? '#EF5350' : '',
                  borderRadius: '3px'
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MMSIPanel;
