import {Fill, Stroke, Style} from 'ol/style';
import {WATCHBOX_ORANGE} from '../../../../styles/alert_icons';

export const LightPolygonHoverStyle = new Style({
  fill: new Fill({
    color: '#4C4B481A'
  }),
  stroke: new Stroke({
    color: 'transparent',
    lineDash: [5],
    width: 2,
    lineCap: 'square',
    lineJoin: 'round'
  })
});

export const DarkPolygonHoverStyle = new Style({
  fill: new Fill({
    color: '#4C4B481A'
  }),
  stroke: new Stroke({
    color: 'transparent',
    lineDash: [5],
    width: 2,
    lineCap: 'square',
    lineJoin: 'round'
  })
});

export const PolygonDefaultStyle = new Style({
  fill: new Fill({
    color: 'transparent'
  }),
  stroke: new Stroke({
    color: WATCHBOX_ORANGE,
    lineDash: [5],
    width: 2,
    lineCap: 'square',
    lineJoin: 'round'
  })
});

export const PolygonLightStyle = new Style({
  fill: new Fill({
    color: 'transparent'
  }),
  stroke: new Stroke({
    color: WATCHBOX_ORANGE,
    lineDash: [5],
    width: 2,
    lineCap: 'square',
    lineJoin: 'round'
  })
});
