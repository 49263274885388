import React from 'react';
import {RFeature} from 'rlayers';
import {transformPolygonCoords} from 'utilities/map';
import {LineString, Polygon} from 'ol/geom';
import {WatchboxArea} from 'types/Alerts';
import {PolygonLabel} from './PolygonLabel';
import {
  PolygonDefaultStyle,
  PolygonLightStyle,
  LightPolygonHoverStyle,
  DarkPolygonHoverStyle
} from './Styles';
import {useDispatch} from 'react-redux';
import {toggleWatchbox} from 'redux/filters';
import {useAppSelector} from 'redux/hooks';
import {selectMapMensurationEnabled} from 'redux/mapMensuration';
import {selectIsMapModeLight} from 'redux/map';

interface WatchboxPolygonProps {
  watchbox: WatchboxArea;
}

export const WatchboxPolygon: React.FC<WatchboxPolygonProps> = ({watchbox}) => {
  const dispatch = useDispatch();
  const mensurationEnabled = useAppSelector(selectMapMensurationEnabled);
  const isLightMode = useAppSelector(selectIsMapModeLight);
  const onPolygonClicked = () => {
    if (!mensurationEnabled) {
      dispatch(toggleWatchbox(watchbox.name));
    }
  };
  const [hover, setHover] = React.useState(false);
  if (watchbox.geometry.type === 'Polygon') {
    const tmpPolygon = new Polygon(transformPolygonCoords(watchbox.geometry.coordinates));
    const lineString = tmpPolygon.getLinearRing(0)?.getCoordinates();
    if (!lineString) return null;
    return (
      <>
        <RFeature
          geometry={new LineString(lineString)}
          onClick={onPolygonClicked}
          properties={{FeatureType: 'WatchboxPolygon'}}
          style={isLightMode ? PolygonLightStyle : PolygonDefaultStyle}
          onPointerEnter={() => {
            setHover(true);
          }}
          onPointerLeave={() => {
            setHover(false);
          }}
        />
        {hover && (
          <RFeature
            geometry={tmpPolygon}
            style={isLightMode ? LightPolygonHoverStyle : DarkPolygonHoverStyle}
          />
        )}
        <PolygonLabel
          coordinates={watchbox.geometry.coordinates}
          watchboxName={watchbox.name}
          watchboxActive={watchbox.active}
          hoverActive={hover}
          onPointerEnter={() => {
            setHover(true);
          }}
          onPointerLeave={() => {
            setHover(false);
          }}
        />
      </>
    );
  }
  return null;
};
