import React from 'react';
import {RMap, RLayerTile, RControl, RLayerTileWMS, ROSM} from 'rlayers';
import {RView} from 'rlayers/RMap';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {selectMapView, selectMapMode, setMapView, selectMaxarApiToken} from 'redux/map';
import {Alerts} from './Layers/Alerts';
import {AISTrack} from './Layers/AISTrack';
import {VesselHistory} from './Layers/VesselHistory';
import {ViewManager} from './Managers/ViewManager';
import {displayCoords} from 'utilities/map';
import 'ol/ol.css';
import './index.scss';
import {Mensuration} from './Layers/Mensuration';
import {Watchbox} from './Layers/Watchbox';
import {EEZ} from './Layers/EEZ';
import {selectWatchboxesLoading} from '../../redux/filters';
import {ControlSidebar} from './Controls/ControlSidebar';
import {selectToolbarOpen} from 'redux/toolbar';

import GeoFilterLayer from './Layers/GeoFilter';

const isProd = window.location.href.includes('crowsnest');

const WMS_BASE_URL = !isProd ? 'https://api.beta.maxar.com' : 'https://api.maxar.com';

export const Map: React.FC = () => {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectMapView);
  const watchboxesLoading = useAppSelector(selectWatchboxesLoading);
  const mapMode = useAppSelector(selectMapMode);
  const toolbarOpen = useAppSelector(selectToolbarOpen);
  const maxarApiToken = useAppSelector(selectMaxarApiToken);

  const setView = (v: RView) => {
    dispatch(setMapView(v));
  };

  return (
    <>
      <RMap
        className={`map ${toolbarOpen && 'sidebar-open'}`}
        initial={view}
        view={[view, setView]}
        noDefaultControls={true}
      >
        <ROSM visible={mapMode === 'light'} />
        <RLayerTile
          visible={mapMode === 'dark'}
          projection="EPSG:3857"
          attributions='<a href="https://www.openstreetmap.org/copyright/" target="_blank">©OpenStreetMap</a> <a href="https://carto.com/attribution/" target="_blank">©CartoDB</a>'
          url="http://{1-4}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
        />

        <GeoFilterLayer />

        <RLayerTileWMS
          visible={mapMode === 'vivid'}
          url={`${WMS_BASE_URL}/streaming/v1/ogc/wms`}
          params={{
            SERVICE: 'WMS',
            VERSION: '1.1.1',
            LAYERS: 'Maxar:Imagery',
            CRS: 'EPSG:3857',
            REQUEST: 'GetMap',
            FORMAT: 'image/png',
            TRANSPARENT: true,
            HEIGHT: 1000,
            WIDTH: 1000,
            serverType: 'geoserver',
            maxar_api_token: maxarApiToken
          }}
        />
        <ViewManager />
        {/*<DrawingControl />*/}
        <RControl.RScaleLine />
        <RControl.RAttribution collapsible={false} collapsed={false} />
        <RControl.RZoom />
        <RControl.RZoomSlider />
        {watchboxesLoading && (
          <div className="watchbox-loading-indicator">Loading Watchboxes...</div>
        )}

        <div className="control-container">
          <div className="map-zoom">Zoom Level: {Math.round(view.zoom)}</div>
          <div className="map-center">{displayCoords(view.center || [0, 0])}</div>
        </div>
        <Alerts />
        <AISTrack />
        <VesselHistory />
        <Mensuration />
        <EEZ />
        <Watchbox />
      </RMap>
      <ControlSidebar />
    </>
  );
};
