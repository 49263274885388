import {utcToZonedTime, format} from 'date-fns-tz';
import {isSameDay, subDays, startOfDay, parseISO} from 'date-fns';

export const formatDate = (dateString: string | Date, dateFormat = 'dd-MM-yyyy') => {
  return format(utcToZonedTime(dateString, 'UTC'), dateFormat, {timeZone: 'UTC'});
};

export const parseDate = (dateStr: string): Date => {
  return parseISO(dateStr);
};

export function dateInRange(dateStart: Date, dateEnd: Date): boolean {
  const today = startOfDay(new Date());
  const yesterday = startOfDay(subDays(today, 1));

  const differentFromYesterday =
    !isSameDay(dateStart, yesterday) && !isSameDay(dateEnd, yesterday);
  const differentFromToday = !isSameDay(dateStart, today) && !isSameDay(dateEnd, today);

  return differentFromYesterday || differentFromToday;
}
