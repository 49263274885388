import React from 'react';
import {LegendContent} from './LegendContent';
import {SidebarAccordian} from '../components/SidebarAccordian';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {selectLegendOpen, setLegendDocked, setLegendOpen} from 'redux/legend';

export const SidebarLegend: React.FC = () => {
  const dispatch = useAppDispatch();
  const legendEnabled = useAppSelector(selectLegendOpen);
  const onUndock = () => {
    dispatch(setLegendDocked(false));
  };
  const onClose = () => {
    dispatch(setLegendOpen(false));
  };
  if (!legendEnabled) return null;
  return (
    <SidebarAccordian
      title="Key"
      onUndock={onUndock}
      onClose={onClose}
      icon={
        <VpnKeyOutlinedIcon
          fontSize="small"
          sx={{
            fontSize: '17px',
            marginLeft: '0px !important',
            marginRight: '5px',
            marginTop: '5px !important'
          }}
        />
      }
    >
      <LegendContent />
    </SidebarAccordian>
  );
};
