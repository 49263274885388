import React from 'react';
import {IconButton, Menu, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface DockMenuProps {
  onUndock?: () => void;
  onDock?: () => void;
  onClose?: () => void;
  onCloseText?: string;
  topOffset?: number;
}

export const DockMenu: React.FC<DockMenuProps> = ({
  onUndock,
  onDock,
  onClose,
  onCloseText,
  topOffset = 0
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDockUndock = () => {
    if (onUndock) {
      onUndock();
    }
    if (onDock) {
      onDock();
    }
  };

  const selectedSX = {
    marginTop: `${topOffset + 4}px`,
    backgroundColor: '#1A1918',
    borderRadius: 0,
    padding: '5px',
    height: '35px',
    width: '35px',
    position: 'absolute',
    right: '-3px',
    top: '-8px'
  };

  const unselectedSX = {
    marginTop: `${topOffset + 4}px`,
    borderRadius: 0,
    padding: '5px',
    height: '35px',
    width: '35px',
    position: 'absolute',
    right: '-3px',
    top: '-8px'
  };
  return (
    <>
      <IconButton onClick={handleClick} sx={anchorEl ? selectedSX : unselectedSX}>
        <MoreVertIcon
          sx={(theme) => ({
            pointerEvents: 'auto',
            color: theme.palette.primary.main,
            fontSize: '20px'
            // margin: '4px 5px'
          })}
        />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorPosition={{
          left: 300,
          top: 100
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {onUndock && (
          <MenuItem onClick={handleDockUndock} sx={{width: '100px'}}>
            Undock
          </MenuItem>
        )}
        {onDock && (
          <MenuItem onClick={handleDockUndock} sx={{width: '100px'}}>
            Dock
          </MenuItem>
        )}
        {onClose && onCloseText && (
          <MenuItem
            onClick={() => {
              onClose();
              handleClose();
            }}
          >
            {onCloseText}
          </MenuItem>
        )}
        {/**/}
      </Menu>
    </>
  );
};
