import ProxyClient from '../api';
import {AISElasticResponse, AISVesselAggSource, AISVesselPol} from 'types/AIS';
import {AxiosRequestConfig} from 'axios';

const REQUEST_REGISTRY: Record<string, AbortController> = {};

export const queryAis = async (
  index: string,
  query: unknown,
  tag?: string
): Promise<AISVesselPol[]> => {
  if (!query) return [];
  const api = ProxyClient.getApi();
  const requestOptions: Omit<AxiosRequestConfig, 'headers'> = {};
  if (tag) {
    if (REQUEST_REGISTRY[tag]) {
      REQUEST_REGISTRY[tag].abort();
      delete REQUEST_REGISTRY[tag];
    }
    const controller = new AbortController();
    requestOptions.signal = controller.signal;
    REQUEST_REGISTRY[tag] = controller;
  }

  const result = await api.post(
    `/es/_search?elasticIndex=${index}`,
    query,
    requestOptions
  );
  if (tag) {
    delete REQUEST_REGISTRY[tag];
  }
  return result.data.hits.hits.map((hit: AISVesselAggSource) => hit._source);
};

export const getAggData = async (): Promise<AISElasticResponse> => {
  const api = ProxyClient.getApi();
  const {data} = await api.post(`/es/_search?elasticIndex=agg`, {
    size: 1000
  });
  return data;
};
