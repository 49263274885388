import React from 'react';
import {MensurationPanelContent} from './MensurationPanelContent';
import {SidebarAccordian} from 'components//Map/Controls/components/SidebarAccordian';
import StraightenSharpIcon from '@mui/icons-material/StraightenSharp';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {
  selectMapMensurationEnabled,
  setMensurationManagerDocked,
  toggleMensurationEnabled
} from 'redux/mapMensuration';
import {
  selectCursorLine,
  setActive,
  setCursorLine,
  setDblClick,
  undo
} from 'redux/vesselChipMensuration';

export const SidebarMensurationControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const drawingEnabled = useAppSelector(selectMapMensurationEnabled);
  const cursorLine = useAppSelector(selectCursorLine);
  const onUndock = () => {
    dispatch(setMensurationManagerDocked(false));
  };

  const onClose = () => {
    dispatch(toggleMensurationEnabled());
    dispatch(setActive(false));
    if (cursorLine) {
      dispatch(setCursorLine(null));
      dispatch(undo());
      dispatch(setDblClick(false));
    }
  };
  if (!drawingEnabled) return null;
  return (
    <SidebarAccordian
      title="Measure"
      onUndock={onUndock}
      onClose={onClose}
      icon={
        <StraightenSharpIcon
          fontSize="small"
          sx={{
            rotate: '-45deg',
            fontSize: '17px',
            marginLeft: '0px !important',
            marginRight: '5px',
            marginTop: '5px !important'
          }}
        />
      }
    >
      <MensurationPanelContent />
    </SidebarAccordian>
  );
};
