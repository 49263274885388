import React from 'react';
import {RLayerVector} from 'rlayers';
import {useAppSelector} from 'redux/hooks';
import {selectFocusedVessel, selectSelectedAlertIds} from 'redux/alerts';
import {selectSelectedVessel} from 'redux/vesselHistory';
import {AlertLayer} from './AlertLayer';
import {SelectedVesselFeature} from '../VesselHistory/SelectedVesselFeature';

export const Alerts: React.FC = () => {
  const selectedVessel = useAppSelector(selectSelectedVessel);
  const focusedVessel = useAppSelector(selectFocusedVessel);
  const selectedAlertIds = useAppSelector(selectSelectedAlertIds);
  const renderAlertLayers = () => {
    return selectedAlertIds.map((id) => {
      return <AlertLayer key={id} alertId={id} />;
    });
  };

  const renderFocusedVessel = () => {
    if (focusedVessel) {
      return <SelectedVesselFeature vessel={focusedVessel} />;
    }
  };
  if (selectedVessel) return null;

  return (
    <RLayerVector zIndex={10} wrapX={false}>
      {renderAlertLayers()}
      {renderFocusedVessel()}
    </RLayerVector>
  );
};
