import {alpha, styled} from '@mui/material/styles';
import {Switch} from '@mui/material';

export const ControlSwitch = styled(Switch)(({theme}) => ({
  marginTop: '-3px',
  marginRight: '7px',
  marginLeft: '-5px',
  '& .MuiSwitch-switchBase': {
    color: '#656360',
    '&:hover': {
      backgroundColor: alpha('#656360', theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: '#656360'
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#1B8282',
    '&:hover': {
      backgroundColor: alpha('#1B8282', theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#1B8282'
  }
}));
