import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Feature} from 'ol';
import {Coordinate} from 'ol/coordinate';
import {RView} from 'rlayers/RMap';
import {fromLonLat, transform} from 'ol/proj';
import {Polygon} from 'ol/geom';
import {listAuthTokens, generateAuthTokens, removeAuthTokens} from 'api/auth';

export interface MapCenter {
  lat: any;
  lon: any;
  zoom?: number;
  animate?: boolean;
}

export type MapMode = 'dark' | 'light' | 'vivid';

export interface MapState {
  view: RView;
  extent: number[] | null;
  center: MapCenter | null;
  layerManager: {
    docked: boolean;
    open: boolean;
  };
  mapMode: MapMode;
  isDrawing: boolean;
  maxarApiToken: string | null;
}

const defaultView = {center: fromLonLat([0, 0]), zoom: 2};

const initialState: MapState = {
  view: defaultView,
  center: null,
  extent: null,
  layerManager: {
    open: false,
    docked: true
  },
  mapMode: 'dark',
  isDrawing: false,
  maxarApiToken: null
};

export const setupUserMapTokens = createAsyncThunk('map/setupUserMapTokens', async () => {
  const existingTokens = await listAuthTokens();
  const crowsnestTokens = existingTokens
    .filter((t) => t.name === 'crowsnest-maa-token')
    .map((t) => t.id);
  if (crowsnestTokens.length > 0) {
    try {
      await removeAuthTokens(crowsnestTokens, existingTokens[0].apiToken);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error Removing API tokens: ', err);
    }
  }
  const updatedToken = await generateAuthTokens();
  return updatedToken.apiToken;
});

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapMode: (state, action: PayloadAction<MapMode>) => {
      state.mapMode = action.payload;
    },
    setLayerManagerOpen: (state, action: PayloadAction<boolean>) => {
      state.layerManager.open = action.payload;
    },
    setLayerManagerDocked: (state, action: PayloadAction<boolean>) => {
      state.layerManager.docked = action.payload;
    },
    setMapView: (state, action: PayloadAction<RView>) => {
      state.view = action.payload;
    },
    setMapCenter: (state, action: PayloadAction<MapCenter>) => {
      state.center = action.payload;
    },
    setMapExtent: (state, action: PayloadAction<Coordinate[][]>) => {
      const feature = new Feature();
      const coords = action.payload.map(function (aa) {
        return aa.map(function (coords) {
          return transform(coords, 'EPSG:4326', 'EPSG:3857');
        });
      });
      feature.setGeometry(new Polygon(coords));

      const bounds = feature.getGeometry();
      if (bounds) {
        state.extent = bounds.getExtent();
      }
    },
    toggleDrawingEnabled: (state, action: PayloadAction<boolean>) => {
      state.isDrawing = !action.payload;
    },
    setIsDrawing: (state, action: PayloadAction<boolean>) => {
      state.isDrawing = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setupUserMapTokens.fulfilled, (state, action) => {
      state.maxarApiToken = action.payload;
    });
    builder.addCase(setupUserMapTokens.rejected, (state) => {
      state.maxarApiToken = null;
    });
  }
});

export const {
  setMapView,
  setMapExtent,
  setMapCenter,
  setLayerManagerOpen,
  setMapMode,
  setLayerManagerDocked,
  toggleDrawingEnabled,
  setIsDrawing
} = mapSlice.actions;

export default mapSlice.reducer;
