import React from 'react';
import './index.scss';
import {Box, Typography} from '@mui/material';
import StraightenSharpIcon from '@mui/icons-material/StraightenSharp';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectMapMensurationEnabled,
  toggleMensurationEnabled,
  selectMapMensurationDocked
} from 'redux/mapMensuration';
import {
  setLayerManagerOpen,
  selectLayerManagerOpen,
  selectLayerManagerDocked
} from 'redux/map';
import {selectLegendOpen, setLegendOpen, selectLegendDocked} from 'redux/legend';
import {
  selectCursorLine,
  setActive,
  setCursorLine,
  setDblClick,
  undo
} from 'redux/vesselChipMensuration';
import {selectToolbarOpen, setShowPanel} from 'redux/toolbar';
import {SidebarLegend} from 'components/Map/Controls/LegendControl/SidebarLegend';
import {SidebarMensurationControl} from 'components/Map/Controls/MensurationControl/SidebarMensurationControl';
import {SidebarLayersControl} from '../LayersControl/SidebarLayersControl';

export const ControlSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const drawingEnabled = useAppSelector(selectMapMensurationEnabled);
  const legendEnabled = useAppSelector(selectLegendOpen);
  const layersEnabled = useAppSelector(selectLayerManagerOpen);
  const cursorLine = useAppSelector(selectCursorLine);
  const legendDocked = useAppSelector(selectLegendDocked);
  const mensurationDocked = useAppSelector(selectMapMensurationDocked);
  const layersDocked = useAppSelector(selectLayerManagerDocked);
  const toggleDrawing = () => {
    dispatch(toggleMensurationEnabled());
    dispatch(setActive(false));
    if (cursorLine) {
      dispatch(setCursorLine(null));
      dispatch(undo());
      dispatch(setDblClick(false));
    }
  };
  const panelOpen = useAppSelector(selectToolbarOpen);

  const toggleLegend = () => {
    dispatch(setLegendOpen(!legendEnabled));
  };

  const toggleLayers = () => {
    dispatch(setLayerManagerOpen(!layersEnabled));
  };

  const toggleToolbar = () => {
    dispatch(setShowPanel(!panelOpen));
  };

  return (
    <div className={`content-sidebar-wrapper ${panelOpen && 'open'}`}>
      <div className="content-sidebar">
        <button onClick={toggleToolbar} className={`panel-button`}>
          <DoubleArrowOutlinedIcon
            fontSize="small"
            sx={{rotate: panelOpen ? '0deg' : '180deg', fontSize: '16px'}}
          />
        </button>
        <div className="content-sidebar-buttons">
          <button
            onClick={toggleLegend}
            className={`toggle-button ${legendEnabled ? 'enabled' : ''}`}
          >
            <VpnKeyOutlinedIcon fontSize="small" />
          </button>
          <button
            onClick={toggleDrawing}
            className={`toggle-button ${drawingEnabled ? 'enabled' : ''}`}
          >
            <StraightenSharpIcon fontSize="small" sx={{rotate: '-45deg'}} />
          </button>
          <button
            onClick={toggleLayers}
            className={`toggle-button ${layersEnabled ? 'enabled' : ''}`}
          >
            <LayersOutlinedIcon fontSize="small" />
          </button>
        </div>
      </div>
      <div className="content">
        <div className="content-header">
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography component="div" variant="body1">
              Map Tools
            </Typography>
          </Box>
        </div>
        {panelOpen && (
          <div className="content-body">
            {legendDocked && <SidebarLegend />}
            {mensurationDocked && <SidebarMensurationControl />}
            {layersDocked && <SidebarLayersControl />}
          </div>
        )}
      </div>
    </div>
  );
};
