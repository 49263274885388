import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material';
import {ArrowForwardSharp, Search} from '@mui/icons-material';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectAlertIds, selectFiltersDisabled, setAlertIds} from 'redux/filters';

const AlertIdPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const alertIds = useAppSelector(selectAlertIds);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);
  const [text, setText] = React.useState('');

  const onEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    const element = e.currentTarget.children[1] as HTMLInputElement;
    const list = element.value.replaceAll(' ', '').split(',');
    dispatch(setAlertIds([...alertIds, ...list]));
    setText('');
  };

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const element = e.currentTarget.parentElement?.parentElement
      ?.children[1] as HTMLInputElement;
    const list = element.value.replaceAll(' ', '').split(',');
    dispatch(setAlertIds([...alertIds, ...list]));
    setText('');
  };

  const handleDelete = (value: string) => {
    dispatch(setAlertIds(alertIds.filter((alertId) => alertId !== value)));
  };
  return (
    <Box sx={{paddingLeft: '8px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{width: '100%'}}>
            <InputLabel htmlFor="alertid-filter">Search by Alert ID</InputLabel>
            <Input
              id="alertid-filter"
              type={'text'}
              value={text}
              aria-describedby="alertid-filter-helper-text"
              onKeyUpCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
                onEnter(event);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="click enter alert id" onClick={handleOnClick}>
                    <ArrowForwardSharp />
                  </IconButton>
                </InputAdornment>
              }
              disabled={filtersDisabled}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setText(e.target.value)
              }
              sx={{width: '100%'}}
              color="primary"
            />
            <FormHelperText id="alertid-filter-helper-text">
              Separate multiples by comma ,
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {alertIds.map((alertId, index) => {
            return (
              <Chip
                key={`${alertId}${index}`}
                id={`${alertId}-${index}`}
                label={alertId}
                disabled={filtersDisabled}
                onDelete={() => handleDelete(alertId)}
                variant="outlined"
                color="primary"
                sx={{
                  marginRight: '8px',
                  marginBottom: '16px',
                  backgroundColor: '#2A2928',
                  borderRadius: '3px'
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertIdPanel;
