import React from 'react';
import {RLayerVector} from 'rlayers';
import {useAppSelector} from 'redux/hooks';
import {selectFeatureByCustomer} from 'redux/artifacts/selectors';
import {nanoid} from '@reduxjs/toolkit';
import {EEZFeature} from './EEZFeature';
import {Feature} from 'types/Artifacts';
import {selectEEZsEnabled} from 'redux/layers';

export const EEZ: React.FC = () => {
  const featureByCustomer = useAppSelector(selectFeatureByCustomer);
  const eezsEnabled = useAppSelector(selectEEZsEnabled);
  const renderEEZs = () => {
    const features = Object.values(featureByCustomer).flatMap((feature) => {
      return feature;
    });

    return features.map((feature: Feature) => {
      return <EEZFeature key={nanoid(5)} feature={feature} />;
    });
  };
  if (!eezsEnabled) return null;
  return (
    <RLayerVector zIndex={10} wrapX={false}>
      {renderEEZs()}
    </RLayerVector>
  );
};
