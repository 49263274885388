import React from 'react';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  selectAlertById,
  selectAlertStateById,
  selectFocusedVessel,
  setFocusedVessel
} from 'redux/alerts';
import {VesselFeature} from './VesselFeature';
import {TipFeature} from './TipFeature';
import {InventoryFeature} from './InventoryFeature';
import {SearchAreaFeature} from './SearchAreaFeature';
import {RFVesselFeature} from './RFVesselFeature';
import {Vessel, OilSpill} from 'types/Alerts';
import {useOL} from 'rlayers';
import {ODSVesselFeature} from './ODSVesselFeature';
import {SourceVesselFeature} from './SourceVesselFeature';
import {nanoid} from 'nanoid';
import {SarSceneFeature} from './SarSceneFeature';

interface AlertLayerProps {
  alertId: string;
}

export const AlertLayer: React.FC<AlertLayerProps> = ({alertId}) => {
  const dispatch = useAppDispatch();
  const {map} = useOL();
  const focusedVessel = useAppSelector(selectFocusedVessel);
  const alert = useAppSelector((state) => selectAlertById(state, alertId));
  const alertState = useAppSelector((state) => selectAlertStateById(state, alertId));
  const focusVessel = (vessel: Vessel | OilSpill) => {
    dispatch(setFocusedVessel(vessel));
  };

  React.useEffect(() => {
    map.on('pointermove', (evt) => {
      if (!evt.dragging) {
        const features = map.getFeaturesAtPixel(map.getEventPixel(evt.originalEvent), {
          hitTolerance: 3
        });
        const isVesselDetect = features.find((feature) => {
          const {FeatureType} = feature.getProperties();
          return FeatureType === 'VesselDetect';
        });

        map.getViewport().style.cursor = isVesselDetect ? 'pointer' : '';
      }
    });
    // eslint-disable-next-line
  }, []);
  const scrollToVessel = React.useCallback(() => {
    if (focusedVessel) {
      const target = document.getElementById(focusedVessel.vesselId);
      target?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [focusedVessel]);

  React.useEffect(() => {
    scrollToVessel();
  }, [focusedVessel, scrollToVessel]);

  const renderSpills = () => {
    if (alert.source === 'ODS') {
      return alert.spills.map((spill) => {
        return <ODSVesselFeature key={spill.oilSpillDetails.oilSpillId} spill={spill} />;
      });
    }
  };
  const renderSources = () => {
    if (alert.source === 'ODS') {
      return alert.sources.map((source) => {
        return <SourceVesselFeature key={nanoid()} vessel={source} />;
      });
    }
  };
  const renderVessels = () => {
    if (alertState.filtered) {
      if (alert.source === 'RF') {
        return alert.filteredVessels.map((vessel) => {
          return (
            <RFVesselFeature
              key={vessel.vesselId}
              vessel={vessel}
              onClick={focusVessel}
            />
          );
        });
      }
      return alert.filteredVessels.map((vessel) => {
        return (
          <VesselFeature key={vessel.vesselId} vessel={vessel} onClick={focusVessel} />
        );
      });
    }

    if (alert.source === 'EO') {
      return alert.vessels.map((vessel) => {
        return (
          <VesselFeature key={vessel.vesselId} vessel={vessel} onClick={focusVessel} />
        );
      });
    }
    return alert.vessels.map((vessel) => {
      return (
        <RFVesselFeature key={vessel.vesselId} vessel={vessel} onClick={focusVessel} />
      );
    });
  };

  const renderTips = () => {
    return alert.tips.map((tip) => {
      return <TipFeature key={tip.tipIdentifier} tip={tip} />;
    });
  };
  const renderInventoryItems = () => {
    return alert.inventoryItems.map((inventoryItem) => {
      return (
        <InventoryFeature key={inventoryItem.inventoryId} inventoryItem={inventoryItem} />
      );
    });
  };
  const renderSarGeometry = () => {
    if (alert.sarGeometry) {
      return <SarSceneFeature geometry={alert.sarGeometry} />;
    }
  };
  const renderSearchAreas = () => {
    return alert.vesselSearchAreas.map((searchArea) => {
      return (
        <SearchAreaFeature key={searchArea.orderIdentifier} searchArea={searchArea} />
      );
    });
  };

  return (
    <>
      {renderVessels()}
      {renderSpills()}
      {renderSources()}
      {renderTips()}
      {renderInventoryItems()}
      {renderSarGeometry()}
      {renderSearchAreas()}
    </>
  );
};
