import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  LinearProgress,
  Box,
  IconButton,
  Tooltip,
  ButtonBase,
  CardActions
} from '@mui/material';
import Place from '@mui/icons-material/Place';
import {formatDate} from 'utilities/date';
import {BookmarkBorder} from '@mui/icons-material';
import {selectFocusedFpVessel, setFocusedFpVessel} from 'redux/vesselFingerprint';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {PolygonGeometry} from 'types/Alerts';

export const VesselPrintCard: React.FC<{
  geometry: PolygonGeometry;
  heading: number;
  image: string;
  latitude: number;
  longitude: number;
  date: string;
  length: number;
  width: number;
  confidence: number;
  name: string;
  id: string;
}> = ({
  image,
  latitude,
  longitude,
  date,
  length,
  width,
  confidence,
  name,
  geometry,
  heading,
  id
}) => {
  const dispatch = useAppDispatch();

  const selectedVessel = useAppSelector(selectFocusedFpVessel);
  const isSelected = id === selectedVessel?.id;

  const handleCardClick = () => {
    if (isSelected) {
      dispatch(setFocusedFpVessel(null));
    } else {
      dispatch(
        setFocusedFpVessel({id: id, lat: latitude, lon: longitude, geometry, heading})
      );
    }
  };

  // scroll to card effect
  React.useEffect(() => {
    const isFocused = selectedVessel?.id === id;
    if (isFocused) {
      const target = document.getElementById(id);
      target?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [selectedVessel, id]);

  // unmount behavior
  React.useEffect(() => {
    return () => {
      dispatch(setFocusedFpVessel(null));
    };
  }, [dispatch]);

  return (
    <ButtonBase id={id} disableRipple onClick={handleCardClick}>
      <Card
        sx={(theme) => ({
          maxWidth: 500,
          minWidth: 180,
          width: '100%',
          color: theme.palette.primary.main,
          backgroundColor: '#363636',
          marginX: '0.5em',
          border: '1px solid #212121',
          borderColor: isSelected ? theme.palette.primary.contrastText : '',
          '&:hover': {
            cursor: 'pointer'
          }
        })}
      >
        <CardContent sx={{padding: '0.5em !important', textAlign: 'left'}}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" component="div">
              <strong>{name}</strong>
            </Typography>
            <Tooltip title="Add to Vessel Records">
              <CardActions>
                <IconButton>
                  <BookmarkBorder
                    sx={(theme) => ({
                      fontSize: 'xlarge',
                      color: '#D1D1D1',
                      '&:hover': {
                        color: theme.palette.primary.contrastText
                      }
                    })}
                  />
                </IconButton>
              </CardActions>
            </Tooltip>
          </Box>

          <Typography height="1em" color="success.main" variant="subtitle2">
            Confidence:
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginBottom="0.5em"
          >
            <Box width="90%">
              <LinearProgress
                value={confidence}
                variant="determinate"
                color="success"
                sx={{
                  backgroundColor: 'black'
                }}
              />
            </Box>
            <Typography variant="body2" sx={{ml: 1, color: 'success.main'}}>
              {confidence.toFixed(2)}%
            </Typography>
          </Box>
          <CardMedia
            component="img"
            image={image}
            alt="Vessel Image"
            sx={{objectFit: 'contain', backgroundColor: 'black', height: 280}}
          />
          <Typography variant="subtitle1" color={(theme) => theme.palette.primary.main}>
            <strong>{formatDate(date, 'yyyy-MM-dd')}</strong>
          </Typography>
          <Box alignContent={'center'} sx={{display: 'flex', alignItems: 'flex-start'}}>
            <Place sx={{fontSize: '1.3em', color: 'GrayText', marginX: '1px'}} />
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography variant="body2" color="GrayText" height="15px">
                {latitude}°
              </Typography>
              <Typography variant="body2" color="GrayText" height="15px">
                {longitude}°
              </Typography>
            </Box>
          </Box>

          <div style={{marginTop: '0.5em'}} />

          <Typography variant="body1" component="div">
            Length: <strong>{length} m</strong>
          </Typography>

          <div style={{marginTop: '0.3em'}} />

          <Typography variant="body1" component="div">
            Width: <strong>{width} m</strong>
          </Typography>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};
