import React from 'react';
import {RFeature, RPopup} from 'rlayers';
import {Icon, Style} from 'ol/style';
import {Watchbox_Marker, Watchbox_Marker_Selected} from 'styles/alert_icons';
import {calculateScale} from 'utilities/map';
import {PopupLabel} from './PopupLabel';
import {Point} from 'ol/geom';
import {fromLonLat} from 'ol/proj';
import {WatchboxArea} from 'types/Alerts';
import {useDispatch} from 'react-redux';
import {toggleWatchbox} from 'redux/filters';
import {useAppSelector} from 'redux/hooks';
import {selectMapMensurationEnabled} from 'redux/mapMensuration';
import {selectDrawingTool} from 'redux/map';

const anchor = [0, 1];

interface WatchboxMarkerProps {
  watchbox: WatchboxArea;
}

export const WatchboxMarker: React.FC<WatchboxMarkerProps> = ({watchbox}) => {
  const dispatch = useDispatch();
  const mensurationEnabled = useAppSelector(selectMapMensurationEnabled);
  const isDrawing = useAppSelector(selectDrawingTool);
  const onPointClicked = () => {
    if (!mensurationEnabled && !isDrawing) {
      dispatch(toggleWatchbox(watchbox.name));
    }
  };
  return (
    <RFeature
      key={watchbox.key}
      geometry={new Point(fromLonLat(watchbox.markerLocation))}
      style={(item, resolution) => {
        const icon = new Icon({
          anchor,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          rotateWithView: true,
          src: watchbox.active ? Watchbox_Marker_Selected : Watchbox_Marker
        });
        if (resolution) {
          icon.setScale(calculateScale(resolution));
        }
        return new Style({
          image: icon
        });
      }}
      onClick={onPointClicked}
    >
      <RPopup
        className="watchbox-popup-label-feature"
        trigger="hover"
        delay={{show: 0, hide: 100}}
        offset={[-70, 70]}
        positioning={'bottom-left'}
      >
        <PopupLabel watchbox={watchbox} />
      </RPopup>
    </RFeature>
  );
};
