import React, {PropsWithChildren} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {AccordionDetails} from '@mui/material';
import {
  Accordion as MuiAccordian,
  AccordionSummary as MuiAccordianSummary
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DockMenu} from '../DockMenu';
/**
 * Styled Components
 */
export const accordionHeight = '36px !important';

export const Accordion = styled(MuiAccordian)(({theme}) => ({
  color: theme.palette.primary.main,
  backgroundColor: '#333231',
  boxShadow: 'none',
  margin: '0px !important'
}));

export const AccordionSummary = styled(MuiAccordianSummary)(({theme}) => ({
  color: theme.palette.primary.main,
  backgroundColor: '#4C4B48',
  minHeight: accordionHeight,
  height: accordionHeight,
  paddingLeft: '0px',
  paddingRight: '10px',
  margin: 0,
  marginTop: '1px',
  cursor: 'default'
}));

interface SidebarAccordianProps extends PropsWithChildren {
  title: string;
  icon: React.ReactNode;
  onUndock: () => void;
  onClose: () => void;
}

export const SidebarAccordian: React.FC<SidebarAccordianProps> = ({
  title,
  children,
  icon,
  onUndock,
  onClose
}) => {
  const [expanded, setExpanded] = React.useState(true);

  const togglePanel = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            position: 'relative'
          }}
        >
          <Box sx={{display: 'flex'}}>
            <ExpandMoreIcon
              onClick={togglePanel}
              sx={(theme) => ({
                pointerEvents: 'auto',
                color: theme.palette.primary.main,
                fontSize: '20px',
                margin: '4px 5px',
                rotate: expanded ? '0deg' : '270deg'
              })}
            />
            {icon}
            <Typography fontSize={16} style={{marginTop: 2, marginLeft: 5}}>
              {title}
            </Typography>
          </Box>
          <DockMenu
            onUndock={onUndock}
            onClose={onClose}
            onCloseText={expanded ? 'Close' : 'Open'}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{pt: 0, mt: 0, backgroundColor: '#333231', padding: '5px'}}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
