import React, {useEffect} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import {Stack, MenuItem, FormControl, CircularProgress} from '@mui/material';
import aisIcon from 'assets/timeline/Timeline_AIS_Symbol.svg';
import detectionIcon from 'assets/timeline/Timeline_Collection_Symbol.svg';
import timeIndicatorIcon from 'assets/timeline/TimeStampIndicator.svg';
import Selected_Vessel_Indicator_1x from 'assets/map/Selected_Vessel_Indicator_1x.png';
import {AISTimeLineContainer, AISTimelineMain} from 'components/TimelineContainer/Styled';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {setAisDatePeriod, selectAisDateRange, selectAisLoading} from 'redux/ais';
import {selectSelectedVessel, selectVesselHistory} from 'redux/vesselHistory';
import {formatDate} from 'utilities/date';
import {isBefore, isAfter, endOfHour, startOfHour} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';

export const AISTimeline = () => {
  const dispatch = useAppDispatch();
  const selectedVessel = useAppSelector(selectSelectedVessel);
  const vesselHistory = useAppSelector(selectVesselHistory);
  const aisTrackDateRange = useAppSelector(selectAisDateRange);
  const aisTrackLoading = useAppSelector(selectAisLoading);

  const chartRef = React.useRef<am4charts.XYChart | null>(null);
  const rangeRectangleRef = React.useRef<am4core.Sprite | null>(null);
  const chartDetailsRef = React.useRef<am4core.Label | null>(null);
  const dateAxisRef = React.useRef<am4charts.DateAxis | null>(null);
  const [period, setPeriod] = React.useState('1');

  const generateData = React.useCallback(() => {
    if (!selectedVessel) return [];
    const historyData: {date: Date; value: number; selected: boolean}[] = [];
    vesselHistory.forEach((v) => {
      if (
        isAfter(new Date(v.properties.meanDatetime), aisTrackDateRange.start) &&
        isBefore(new Date(v.properties.meanDatetime), aisTrackDateRange.end)
      ) {
        historyData.push({
          date: new Date(v.properties.meanDatetime),
          value: 10,
          selected: v.properties.vesselId === selectedVessel.properties.vesselId
        });
      }
    });
    return [
      {
        date: aisTrackDateRange.start,
        value: 0,
        selected: false
      },
      ...historyData,
      {
        date: aisTrackDateRange.end,
        value: 0,
        selected: false
      }
    ];
  }, [selectedVessel, vesselHistory, aisTrackDateRange.start, aisTrackDateRange.end]);

  React.useEffect(() => {
    if (!selectedVessel) return;

    setTimeout(() => {
      if (rangeRectangleRef.current && dateAxisRef.current && chartDetailsRef.current) {
        const start = dateAxisRef.current.positionToCoordinate(
          dateAxisRef.current.dateToPosition(
            startOfHour(utcToZonedTime(aisTrackDateRange.start, 'UTC'))
          )
        );
        const end = dateAxisRef.current.positionToCoordinate(
          dateAxisRef.current.dateToPosition(
            endOfHour(utcToZonedTime(aisTrackDateRange.end, 'UTC'))
          )
        );
        rangeRectangleRef.current.x = start;
        rangeRectangleRef.current.width = end - start;
        rangeRectangleRef.current.opacity = selectedVessel.properties.dark ? 0 : 1;

        chartDetailsRef.current.text = getChartDetailsText(
          new Date(selectedVessel.properties.meanDatetime)
        );
      }
    }, 1000);
    if (chartRef.current) {
      chartRef.current.data = generateData();
    }
  }, [aisTrackDateRange, selectedVessel, generateData]);

  React.useEffect(() => {
    dispatch(setAisDatePeriod(period));
  }, [period, dispatch]);

  React.useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = generateData();
    }
  }, [generateData]);

  const handlePeriodChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value);
  };

  const getChartDetailsText = (selectedDate: Date) => {
    return `${formatDate(selectedDate, 'yyyy-MM-dd')} | ${formatDate(
      selectedDate,
      "HH:mm'Z'"
    )}`.toUpperCase();
  };

  useEffect(() => {
    am4core.useTheme(am4themes_dark);
    // am4core.useTheme(am4themes_animated);
    am4core.addLicense('CH228165625');

    const timelineChart = am4core.create('ais-timeline', am4charts.XYChart);

    timelineChart.chartContainer.padding(0, 5, 0, 5);
    timelineChart.plotContainer.background.fill = am4core.color('#262626');
    timelineChart.plotContainer.background.fillOpacity = 1;
    timelineChart.plotContainer.height = 20;
    timelineChart.plotContainer.dy = 30;
    if (timelineChart.svgContainer) timelineChart.svgContainer.autoResize = false;

    const dateAxis = timelineChart.xAxes.push(new am4charts.DateAxis());
    dateAxis.keepSelection = true;
    dateAxis.timezone = 'UTC';
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.dateFormats.setKey('hour', "HH:mm'Z'");
    dateAxis.periodChangeDateFormats.setKey('hour', "HH:mm'Z'");
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.baseInterval = {count: 1, timeUnit: 'hour'};
    dateAxis.renderer.grid.template.strokeOpacity = 1;
    dateAxis.renderer.grid.template.stroke = am4core.color('#7F7D79');
    dateAxis.renderer.labels.template.dy = 15;

    const dateLabelTemplate = dateAxis.renderer.labels.template;
    dateLabelTemplate.fontSize = 11;
    dateLabelTemplate.fontFamily = 'Blender';
    dateLabelTemplate.fontWeight = '300';
    // dateLabelTemplate.dy = 8;

    const valueAxis = timelineChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minGridDistance = 50;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.disabled = true;
    chartRef.current = timelineChart;

    const series = timelineChart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'date';
    series.strokeWidth = 10;
    series.cursorTooltipEnabled = false;
    series.cursorHoverEnabled = false;
    series.connect = false;
    series.tensionX = 1;
    series.fillOpacity = 0.2;
    series.name = 'detect';

    const aisSeriesGradient = new am4core.LinearGradient();
    aisSeriesGradient.rotation = 0;
    aisSeriesGradient.addColor(am4core.color('#1B2B66'));
    aisSeriesGradient.addColor(am4core.color('#432F71'));
    aisSeriesGradient.addColor(am4core.color('#653177'));
    aisSeriesGradient.addColor(am4core.color('#853279'));
    aisSeriesGradient.addColor(am4core.color('#A43377'));
    aisSeriesGradient.addColor(am4core.color('#BF3871'));
    aisSeriesGradient.addColor(am4core.color('#D64267'));
    aisSeriesGradient.addColor(am4core.color('#E9525B'));
    aisSeriesGradient.addColor(am4core.color('#F6664D'));
    aisSeriesGradient.addColor(am4core.color('#FE7D3D'));
    aisSeriesGradient.addColor(am4core.color('#FF952B'));
    aisSeriesGradient.addColor(am4core.color('#FCAF17'));

    const aisSeries = timelineChart.series.push(new am4charts.ColumnSeries());
    aisSeries.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    aisSeries.dataFields.valueY = 'value';
    aisSeries.dataFields.dateX = 'date';
    aisSeries.name = 'AIS Data';
    aisSeries.strokeWidth = 0;
    aisSeries.fill = aisSeriesGradient;

    const rangeRectangle = timelineChart.plotContainer.createChild(am4core.Rectangle);
    rangeRectangle.fill = aisSeriesGradient;
    rangeRectangle.height = 8;
    rangeRectangle.dy = 0;
    rangeRectangle.draggable = false;
    rangeRectangle.minY = 6;
    rangeRectangle.maxY = 6;
    rangeRectangle.toBack();
    // rangeRectangle.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    rangeRectangle.opacity = 0;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.stroke = am4core.color('#D9D9D9');
    bullet.strokeWidth = 2;
    bullet.fillOpacity = 0;
    bullet.circle.radius = 5;
    bullet.dy = 10;

    bullet.adapter.add('opacity', (opacity, target) => {
      if (target.dataItem && target.dataItem.dataContext) {
        if ((target.dataItem.dataContext as any).value === 0) {
          return 0;
        }
      }
      return 1;
    });

    const targetBullet = series.bullets.push(new am4charts.Bullet());
    const targetImage = targetBullet.createChild(am4core.Image);
    targetImage.href = Selected_Vessel_Indicator_1x;
    targetImage.width = 18;
    targetImage.height = 18;
    targetImage.horizontalCenter = 'middle';
    targetImage.verticalCenter = 'middle';
    targetImage.dy = 10;

    targetBullet.adapter.add('opacity', (opacity, target) => {
      if (target.dataItem && target.dataItem.dataContext) {
        if ((target.dataItem.dataContext as any).selected) {
          return 1;
        }
      }
      return 0;
    });

    const detailsTitle = timelineChart.createChild(am4core.Label);
    detailsTitle.opacity = 1;
    detailsTitle.isMeasured = false;
    detailsTitle.width = 160;
    detailsTitle.y = -15;
    detailsTitle.dx = -80;
    detailsTitle.textAlign = 'middle';
    detailsTitle.x = am4core.percent(50);
    detailsTitle.fontSize = 14;
    detailsTitle.fontFamily = 'Blender';
    detailsTitle.fontWeight = '500';
    detailsTitle.background.fill = am4core.color('#333231');
    detailsTitle.padding(6, 0, 4, 0);
    const titleBorder = detailsTitle.createChild(am4core.Line);
    titleBorder.stroke = am4core.color('#FCAF17');
    titleBorder.width = am4core.percent(100);
    titleBorder.dy = 20;

    const timestampIndicator = detailsTitle.createChild(am4core.Image);
    timestampIndicator.href = timeIndicatorIcon;
    timestampIndicator.width = 20;
    timestampIndicator.height = 20;
    timestampIndicator.dx = 70;
    timestampIndicator.dy = 20;

    //timelineChart.data = [];

    rangeRectangleRef.current = rangeRectangle;
    dateAxisRef.current = dateAxis;
    chartDetailsRef.current = detailsTitle;
  }, []);

  return (
    <AISTimeLineContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        spacing={2}
        sx={{
          color: '#fff',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '15px',
          marginTop: '5px'
        }}
      >
        <div>
          <span style={{marginRight: 5}}>View by</span>
          <FormControl
            variant="standard"
            size="small"
            sx={{ml: 1, mr: 1, minWidth: 75, color: '#fff'}}
          >
            <Select
              size="small"
              sx={{color: '#fff'}}
              value={period}
              onChange={handlePeriodChange}
              label="View by"
            >
              <MenuItem value={'1'}>24 Hours</MenuItem>
              <MenuItem value={'3'}>3 Days</MenuItem>
              <MenuItem value={'7'}>7 Days</MenuItem>
              <MenuItem value={'14'}>14 Days</MenuItem>
              <MenuItem value={'30'}>30 Days</MenuItem>
              <MenuItem value={'90'}>3 Months</MenuItem>
            </Select>
          </FormControl>
        </div>
        {aisTrackLoading && (
          <div style={{color: '#74726f', marginTop: -10}}>
            <CircularProgress size={10} sx={{marginRight: '5px'}} />
            Loading AIS Track...
          </div>
        )}
        <div>
          <img
            style={{width: 30, marginBottom: 1, marginRight: 5, display: 'inline'}}
            src={aisIcon}
          />{' '}
          <span style={{fontSize: 12, marginRight: 15}}>AIS Track</span>{' '}
          <img style={{width: 12, marginTop: 1, display: 'inline'}} src={detectionIcon} />{' '}
          <span style={{fontSize: 12}}>Detection</span>
        </div>
      </Stack>
      <AISTimelineMain id="ais-timeline" />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        spacing={2}
        sx={{
          color: '#fff',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingBottom: '5px'
        }}
      >
        <div>{formatDate(aisTrackDateRange.start, 'yyyy-MM-dd')}</div>
        <div>{formatDate(aisTrackDateRange.end, 'yyyy-MM-dd')}</div>
      </Stack>
    </AISTimeLineContainer>
  );
};
