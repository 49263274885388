import React from 'react';
import {IconButton, Stack} from '@mui/material';
import SettingsEthernetSharpIcon from '@mui/icons-material/SettingsEthernetSharp';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import NearMeIcon from '@mui/icons-material/NearMe';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {
  clearMensurationDrawings,
  MapMensurationOptionLookup,
  MapMensurationToolOption,
  MapMensurationTypeOption,
  selectMapMensurationLabels,
  selectMapMensurationTool,
  selectMapMensurationUnit,
  setMensurationLabels,
  setMensurationTool
} from 'redux/mapMensuration';
import {AreaIcon} from './AreaIcon';
import {ControlSwitch} from '../components/ControlSwitch';
import {MeasurementSelect} from './MeasurementSelect';

export const MensurationPanelContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const tool = useAppSelector(selectMapMensurationTool);
  const unit = useAppSelector(selectMapMensurationUnit);
  const labels = useAppSelector(selectMapMensurationLabels);
  const clearMap = () => {
    dispatch(clearMensurationDrawings());
  };

  const setTool = (value: MapMensurationToolOption) => {
    dispatch(setMensurationTool(value));
  };

  const toggleLabels = (label: MapMensurationTypeOption, value: boolean) => {
    dispatch(setMensurationLabels({label, value}));
  };

  return (
    <div className="drawing-panel-content" style={{paddingLeft: 5}}>
      <Stack direction="row" justifyContent="space-between">
        <h2 className="fs-12">TOOL TYPE</h2>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <div className="menu-line" style={{marginBottom: 5}}>
          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            aria-label="line"
            size="small"
            style={{marginRight: 5}}
            data-tooltip="Distance"
            className={`drawing-icon-button with-hover ${
              tool === 'line' ? 'highlighted' : ''
            }`}
            onClick={() => setTool('line')}
          >
            <SettingsEthernetSharpIcon
              sx={{
                fontSize: '25px',
                marginBottom: '3px',
                color: tool === 'line' ? '#E5E1DA' : '#B2AFAA'
              }}
            />
          </IconButton>

          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            aria-label="polygon"
            size="small"
            style={{marginRight: 5}}
            data-tooltip="Area"
            className={`drawing-icon-button with-hover ${
              tool === 'polygon' ? 'highlighted' : ''
            }`}
            onClick={() => setTool('polygon')}
          >
            <AreaIcon selected={tool === 'polygon'} />
          </IconButton>
          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            aria-label="select"
            size="small"
            data-tooltip="Select"
            className={`drawing-icon-button with-hover ${
              tool === 'select' ? 'highlighted' : ''
            }`}
            onClick={() => setTool('select')}
          >
            <NearMeOutlinedIcon
              sx={{
                fontSize: '25px',
                marginBottom: '3px',
                color: tool === 'select' ? '#E5E1DA' : '#B2AFAA'
              }}
            />
          </IconButton>
        </div>
      </Stack>
      <Stack className="drawing-label-section" direction="row">
        <h2 className="fs-12">MAP LABELS</h2>
        <h2 className="fs-12" style={{marginLeft: 55}}>
          TOTALS{' '}
          <NearMeIcon
            id="selected-totals-indicator"
            style={{display: 'none'}}
            sx={{
              marginBottom: '-1px',
              fontSize: '10px',
              color: '#1B8282'
            }}
          />
        </h2>
      </Stack>
      <Stack>
        <div className="menu-line">
          <ControlSwitch
            size="small"
            checked={labels.distance}
            onChange={(e) => {
              toggleLabels('distance', e.target.checked);
            }}
          />
          <div className="distance-label">Distance</div>
          <div className="distance-total" id="mensuration-distance-total">
            --
          </div>
          <span className="unit-display">{MapMensurationOptionLookup[unit]}</span>
        </div>
        <div className="menu-line">
          <ControlSwitch
            size="small"
            checked={labels.area}
            onChange={(e) => {
              toggleLabels('area', e.target.checked);
            }}
          />
          <div className="distance-label">Area</div>
          <div className="distance-total" id="mensuration-area-total">
            --
          </div>
          <span className="unit-display">
            {MapMensurationOptionLookup[unit]}
            <sup className="fs-10">2</sup>
          </span>
        </div>
        <div style={{marginTop: 5}}>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <MeasurementSelect />
            </div>
            <button className="clear-button light-text" onClick={clearMap}>
              Clear All
            </button>
          </Stack>
        </div>
      </Stack>
    </div>
  );
};
