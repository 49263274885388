import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import {Countries} from 'components/ContentExplorer/ContentExplorerHeader/filterpanels/CountryList';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectFlags, selectFiltersDisabled, setFlags} from 'redux/filters';

const FlagFilterPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const flags = useAppSelector(selectFlags);
  const filtersDisabled = useAppSelector(selectFiltersDisabled);
  const [countryFlag, setCountryFlag] = React.useState<string[]>(flags);
  const handleChange = (event: SelectChangeEvent<typeof countryFlag>) => {
    const {
      target: {value}
    } = event;
    const flagValue = typeof value === 'string' ? value.split(',') : value;
    setCountryFlag(
      // On autofill we get a stringified value.
      flagValue
    );
    dispatch(setFlags([...flagValue, ...flags]));
  };

  const handleDelete = (value: string) => {
    const updatedFlags = flags.filter((flag) => flag !== value);
    dispatch(setFlags(updatedFlags));
    setCountryFlag(updatedFlags);
  };

  return (
    <Box sx={{paddingLeft: '13px'}}>
      <FormControl variant="standard" sx={{width: '100%', marginBottom: '16px'}}>
        <InputLabel id="flag-select-label">Country (Flag)</InputLabel>
        <Select
          labelId="flag-select-label"
          id="country-flag-filter-select"
          value={countryFlag}
          multiple
          onChange={handleChange}
          variant="standard"
          color="primary"
          sx={(theme) => ({
            width: '100%',
            border: '0px',
            color: theme.palette.primary.main
          })}
          inputProps={{IconComponent: () => null}}
          disabled={filtersDisabled}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
              {selected.map((value, index) => (
                <Chip
                  key={value}
                  label={value}
                  id={`${value}-${index}`}
                  sx={(theme) => ({
                    color: theme.palette.primary.main
                  })}
                  color="primary"
                  variant="outlined"
                  disabled={filtersDisabled}
                  onDelete={() => handleDelete(value)}
                  onMouseDown={(event: any) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={{PaperProps: {sx: {maxHeight: 300}}}}
        >
          {Countries.map((country) => (
            <MenuItem key={country.code} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FlagFilterPanel;
