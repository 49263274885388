import {
  addHours,
  addMinutes,
  endOfDay,
  setMinutes,
  setSeconds,
  startOfDay,
  subDays,
  subHours,
  subMinutes
} from 'date-fns';
import {FiltersInput} from 'types/Filters';
export const today = new Date();

export const defaultStartDate = startOfDay(subDays(today, 1));
export const defaultEndDate = endOfDay(today);

export const defaultSelectedTimeFrameStart = setMinutes(subHours(today, 1), 0);

export const defaultAisChartDateRangeStart = setMinutes(
  subHours(defaultSelectedTimeFrameStart, 1),
  0
);
export const defaultAisChartDateRangeEnd = setMinutes(
  addHours(defaultAisChartDateRangeStart, 2),
  60
);

export function calculateSelectedDates(d: Date) {
  const selectedValue = addMinutes(d, 30);
  const selected = setSeconds(selectedValue, 0);
  const start = subMinutes(selected, 30);
  const end = addMinutes(selected, 30);
  return {
    selected,
    start,
    end
  };
}

export const defaultSelectedTimeFrame = calculateSelectedDates(
  defaultSelectedTimeFrameStart
);

export const defaultCateogries = [
  {
    id: 'aircraft_carrier',
    label: 'Aircraft Carrier',
    term: 'AC-M',
    aisType: '35 (EO Refinement)',
    value: false
  },
  {
    id: 'noncombatant',
    label: 'Noncombatant',
    term: 'NC-A-59',
    aisType: '59',
    value: false
  },
  {
    id: 'anti_pollution',
    label: 'Anti-Pollution',
    term: 'AP-A-54',
    aisType: '54',
    value: false
  },
  {
    id: 'passenger',
    label: 'Passenger',
    term: 'P-A-6+60-69',
    aisType: '6, 60-69',
    value: false
  },
  {
    id: 'barge',
    label: 'Barge',
    term: 'B-M',
    aisType: 'N/A (EO Refinement)',
    value: false
  },
  {
    id: 'pilot_vessel',
    label: 'Pilot Vessel',
    term: 'PV-A-50',
    aisType: '50',
    value: false
  },
  {
    id: 'container',
    label: 'Container',
    term: 'CO-M',
    aisType: '7, 70-79 (EO Refinement)',
    value: false
  },
  {
    id: 'pleasure_craft',
    label: 'Pleasure Craft',
    term: 'PC-A-37',
    aisType: '37',
    value: false
  },
  {
    id: 'cargo',
    label: 'Cargo',
    term: 'CA-M',
    aisType: '7, 70-79',
    value: false
  },
  {
    id: 'port_tender',
    label: 'Port Tender',
    term: 'PT-A-53',
    aisType: '53',
    value: false
  },
  {
    id: 'diving',
    label: 'Diving Operations',
    term: 'DO-A-34',
    aisType: '34',
    value: false
  },
  {
    id: 'sailing',
    label: 'Sailing',
    term: 'S-M',
    aisType: '36',
    value: false
  },
  {
    id: 'dredging',
    label: 'Dredging',
    term: 'D-M',
    aisType: '33',
    value: false
  },
  {
    id: 'search',
    label: 'Search and Rescue',
    term: 'SR-A-51',
    aisType: '51',
    value: false
  },
  {
    id: 'fishing',
    label: 'Fishing',
    term: 'F-M',
    aisType: '30',
    value: false
  },
  {
    id: 'spare',
    label: 'Spare',
    term: 'S-A-56+57',
    aisType: '56-57',
    value: false
  },
  {
    id: 'government',
    label: 'Government',
    term: 'G-M',
    aisType: '35, 51, 55 (EO Refinement)',
    value: false
  },
  {
    id: 'tanker',
    label: 'Tanker',
    term: 'T-M',
    aisType: '8, 80-89',
    value: false
  },
  {
    id: 'hsc',
    label: 'High Speed Craft',
    term: 'HSC-A-40-49',
    aisType: '40-49',
    value: false
  },
  {
    id: 'towing',
    label: 'Towing',
    term: 'T-A-31+32',
    aisType: '31-32',
    value: false
  },
  {
    id: 'law',
    label: 'Law Enforcement',
    term: 'LE-A-55',
    aisType: '55',
    value: false
  },
  {
    id: 'tug',
    label: 'Tug',
    term: 'TU-M',
    aisType: '52',
    value: false
  },
  {
    id: 'medical',
    label: 'Medical Transport',
    term: 'M-A-58',
    aisType: '58',
    value: false
  },
  {
    id: 'wing',
    label: 'Wing In Ground',
    term: 'WIG-A-2+20-29',
    aisType: '2, 20-29',
    value: false
  },
  {
    id: 'military',
    label: 'Military Operations',
    term: 'MO-A-35',
    aisType: '35',
    value: false
  },
  {
    id: 'unknown',
    label: 'Unknown',
    term: 'U-A-9+90-99',
    aisType: '9, 90-99',
    value: false
  }
];

export const defaultMeasureValue = {
  speed: {
    from: '',
    to: ''
  },
  vesselLength: {
    from: '',
    to: ''
  },
  vesselWidth: {
    from: '',
    to: ''
  }
};

export const defaultMeasure = {
  '0': {
    ...defaultMeasureValue
  }
};

export const getDefaultFilters = () => {
  return JSON.parse(JSON.stringify(defaultFilters));
};
export const defaultFilters = {
  mmsis: [],
  alertIds: [],
  vesselId: null,
  flags: [],
  measurements: defaultMeasure,
  detectionTypes: {
    dark: false,
    correlated: false,
    noDetects: false
  },
  missions: [],
  sources: [
    {
      id: 'EO',
      label: 'EO',
      value: false,
      disabled: false
    },
    {
      id: 'SAR',
      label: 'SAR',
      value: false,
      disabled: false
    },
    {
      id: 'RF',
      label: 'RF',
      value: false,
      disabled: false
    },
    {
      id: 'ODS',
      label: 'ODS',
      value: false,
      disabled: false
    }
  ],
  watchboxes: [],
  drawnWatchbox: [],
  cachedDrawing: [],
  isDrawing: false,
  isGeoFiltering: false,
  geoClear: true,
  categories: defaultCateogries
} as FiltersInput;
