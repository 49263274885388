import React from 'react';
import {Vessel} from 'types/Alerts';
import VesselRowHistoryWrapper from 'components/VesselHistory/VesselHistoryRowItem';
import {useAppSelector} from 'redux/hooks';
import {selectSelectedVessel} from 'redux/vesselHistory';

interface VesselHistoryProps {
  vessels: Vessel[];
}

const VesselHistoryContainer: React.FC<VesselHistoryProps> = ({vessels}) => {
  const selectedVessel = useAppSelector(selectSelectedVessel);
  return (
    <>
      {vessels.map((vessel: Vessel) => {
        let selected = false;
        if (selectedVessel?.vesselId === vessel.vesselId) {
          selected = true;
        }
        return (
          <VesselRowHistoryWrapper vessel={vessel} key={vessel.id} selected={selected} />
        );
      })}
    </>
  );
};

export default VesselHistoryContainer;
