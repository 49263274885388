import React from 'react';
import {RFeature} from 'rlayers';
import {fromLonLat} from 'ol/proj';
import {Point} from 'ol/geom';
import {Tip} from 'types/Alerts';
import {Icon, Style} from 'ol/style';
import {calculateScale, getRotation, getTipIcon} from 'utilities/map';

interface TipFeatureProps {
  tip: Tip;
}

export const TipFeature: React.FC<TipFeatureProps> = ({tip}) => {
  const {headingDeg, observationType, containsHeadingAmbiguity} =
    tip.tipMetadata.observations[0];
  const rotation = headingDeg || 0;
  const iconPath = getTipIcon(
    observationType === 'dark',
    containsHeadingAmbiguity,
    rotation
  );
  return (
    <RFeature
      geometry={new Point(fromLonLat(tip.observation.geometry.coordinates))}
      style={(item, resolution) => {
        const icon = new Icon({
          rotation: getRotation(rotation),
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          rotateWithView: true,
          src: iconPath
        });
        if (resolution) {
          icon.setScale(calculateScale(resolution));
        }
        return new Style({
          image: icon
        });
      }}
    />
  );
};
