import React from 'react';
import {Vessel, CorrelatedVesselMetadata} from 'types/Alerts';
import {Box, Tooltip, styled} from '@mui/material';
import {Typography} from '@mui/material';
import {title} from 'radash';
interface VesselDetails {
  vessel: Vessel | CorrelatedVesselMetadata;
}

const VesselDetailText = styled(Typography)(() => ({
  lineHeight: 1,
  fontWeight: 400,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}));

// EO or AIS Vessel Data
const VesselDetailsEOAIS: React.FC<VesselDetails> = ({vessel}) => {
  if (!vessel) {
    return (
      <Box>
        <VesselDetailText>{'- -'}</VesselDetailText>
        <VesselDetailText>{'- -'}</VesselDetailText>
        <VesselDetailText>{'- -'} </VesselDetailText>
        <VesselDetailText>{'- -'}</VesselDetailText>
        <VesselDetailText> {'- -'}</VesselDetailText>
        <VesselDetailText>{'- -'}</VesselDetailText>
        <VesselDetailText> {'- -'}</VesselDetailText>
      </Box>
    );
  }
  let length;
  let width;
  let flag;
  let name;
  let vesselType;
  let callSign = '- -';
  let imo = '- -';

  const removeUnderscore = (vesselData: string | null) => {
    let correctedTitle;
    if (vesselData?.includes('_')) {
      correctedTitle = title(vesselData?.replace(' ', '_'));
      return correctedTitle;
    } else {
      correctedTitle = vesselData
        ? vesselData.charAt(0).toUpperCase() + vesselData.slice(1)
        : '- -';
      return correctedTitle;
    }
  };
  //eo data
  if ('properties' in vessel) {
    vesselType = vessel.properties.vesselType ?? '- -';
    vesselType = removeUnderscore(vessel.properties.vesselType);
    length = vessel.properties.length ?? '- -';
    width = vessel.properties.width ?? '- -';
    name = vessel.properties.name ?? '- -';
    flag = '- -';
  }
  //ao data
  else {
    vesselType = vessel.vesselType ?? '- -';
    length = vessel.length ?? '- -';
    name = vessel.vesselName ?? '- -';
    width = vessel.width ?? '- -';
    flag = vessel.flag ? vessel.flag : '- -';
    callSign = vessel.callSign ? vessel.callSign : '- -';
    imo = vessel.imo ? vessel.imo : '- -';
  }
  return (
    <Box>
      <Tooltip sx={{cursor: 'pointer'}} title={vesselType}>
        <Box component="span">
          <VesselDetailText>{vesselType}</VesselDetailText>
        </Box>
      </Tooltip>
      <VesselDetailText>{length} (m)</VesselDetailText>
      <VesselDetailText>{width} (m)</VesselDetailText>
      <VesselDetailText> {name}</VesselDetailText>
      <VesselDetailText>{callSign} </VesselDetailText>
      <VesselDetailText>{flag} </VesselDetailText>
      <VesselDetailText>{imo} </VesselDetailText>
    </Box>
  );
};

export default VesselDetailsEOAIS;
