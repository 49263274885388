import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError
} from '@reduxjs/toolkit';
import {RootState} from 'redux/store';
import {fetchAlerts, fetchInventory, fetchVessels} from 'api/alerts';
import {EOAlertResponse, Vessel, OilSpill} from 'types/Alerts';
import {selectSerializedFilters} from 'redux/filters';
import {buildQueries} from 'queries/AlertQueries';

interface AlertStates {
  loading: boolean;
  loadingInventory: boolean;
  selected: boolean;
  expanded: boolean;
  filtered: boolean;
}

export interface AlertState {
  alerts: EOAlertResponse[];
  alertsById: Record<string, EOAlertResponse>;
  alertStates: Record<string, AlertStates>;
  selectedAlertIds: string[];
  focusedVessel: Vessel | OilSpill | null;
  initialActionsComplete: boolean;
  status: 'idle' | 'loading' | 'failed';
  error: SerializedError | null;
}

const initialState: AlertState = {
  alerts: [],
  alertsById: {},
  alertStates: {},
  selectedAlertIds: [],
  focusedVessel: null,
  initialActionsComplete: false,
  status: 'loading',
  error: null
};

type LoadAlertArgs = {
  avoidLoadingState?: boolean;
};
export const loadAlerts = createAsyncThunk(
  'alerts/fetchAlerts',
  async (_: LoadAlertArgs, {getState}) => {
    const filters = selectSerializedFilters(getState() as RootState);
    const {alertQuery} = buildQueries(
      filters.selectedTimeFrame.start,
      filters.selectedTimeFrame.end,
      !filters.filtersEnabled,
      filters.hideZeroDetects,
      filters.filters,
      filters.filters.missions
    );
    return await fetchAlerts(alertQuery, 'contentExplorerAlerts');
  }
);

type LoadVesselArgs = {
  alertId: string;
  avoidLoadingState?: boolean;
};

export const loadVessels = createAsyncThunk(
  'alerts/fetchVessels',
  async ({alertId}: LoadVesselArgs, {getState}) => {
    const filters = selectSerializedFilters(getState() as RootState);
    const {vesselQuery, allAlertVesselsQuery} = buildQueries(
      filters.selectedTimeFrame.start,
      filters.selectedTimeFrame.end,
      !filters.filtersEnabled,
      filters.hideZeroDetects,
      filters.filters,
      filters.filters.missions,
      alertId
    );
    const vessels = await fetchVessels(allAlertVesselsQuery);
    const filteredVessels = await fetchVessels(vesselQuery);
    const inventory = await fetchInventory(alertId);
    return {
      vessels,
      inventory,
      filteredVessels,
      alertId
    };
  }
);

type LoadInventoryArgs = {
  alertId: string;
  avoidLoadingState?: boolean;
};
export const loadInventory = createAsyncThunk(
  'alerts/fetchInventory',
  async ({alertId}: LoadInventoryArgs) => {
    const inventory = await fetchInventory(alertId);
    return {
      inventory,
      alertId
    };
  }
);

export const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlerts: (state, action: PayloadAction<EOAlertResponse[]>) => {
      state.alerts = action.payload;
    },
    setFocusedVessel: (state, action: PayloadAction<Vessel | OilSpill>) => {
      state.focusedVessel = action.payload;
      state.selectedAlertIds = [...state.selectedAlertIds, action.payload.alertId];
      state.alertStates[action.payload.alertId] = {
        ...state.alertStates[action.payload.alertId],
        selected: true
      };
      selectAlert(action.payload.alertId);
    },
    setAlertFilteredVessels: (
      state,
      action: PayloadAction<{id: string; value: boolean}>
    ) => {
      state.alertStates[action.payload.id] = {
        ...state.alertStates[action.payload.id],
        filtered: action.payload.value
      };
    },
    selectAlert: (state, action: PayloadAction<string>) => {
      state.selectedAlertIds = [...state.selectedAlertIds, action.payload];
      state.alertStates[action.payload] = {
        ...state.alertStates[action.payload],
        selected: true,
        expanded: true
      };
    },
    expandAlert: (state, action: PayloadAction<string>) => {
      state.alertStates[action.payload] = {
        ...state.alertStates[action.payload],
        expanded: true
      };
    },
    collapseAlert: (state, action: PayloadAction<string>) => {
      state.alertStates[action.payload] = {
        ...state.alertStates[action.payload],
        expanded: false
      };
    },
    deselectAlert: (state, action: PayloadAction<string>) => {
      state.selectedAlertIds = state.selectedAlertIds.filter(
        (alertId) => alertId !== action.payload
      );
      state.alertStates[action.payload] = {
        ...state.alertStates[action.payload],
        selected: false
      };
      state.focusedVessel = null;
    },
    completeInitialActions: (state) => {
      state.initialActionsComplete = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAlerts.pending, (state, args) => {
        state.status = 'loading';
        if (!args.meta.arg.avoidLoadingState) {
          state.alerts = [];
          state.focusedVessel = null;
        }
      })
      .addCase(loadAlerts.fulfilled, (state, action) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const alertId = urlParams.get('alertId');
        const vesselId = urlParams.get('vesselId');

        //#region Maintain `vessels` and `inventoryItems` from previous cache
        const alertsWithVessels = state.alerts.filter(
          (alert) => alert.vessels.length > 0
        );
        const alertsWithInventoryItems = state.alerts.filter(
          (alert) => alert.inventoryItems.length > 0
        );

        const updatedAlerts = action.payload.map((incomingAlert) => {
          const existingAlertWithVessels = alertsWithVessels.find(
            (alert) => alert.id === incomingAlert.id
          );

          const existingAlertWithInventory = alertsWithInventoryItems.find(
            (alert) => alert.id === incomingAlert.id
          );

          return {
            ...incomingAlert,
            vessels: existingAlertWithVessels
              ? existingAlertWithVessels.vessels
              : incomingAlert.vessels,
            filteredVessels: existingAlertWithVessels
              ? existingAlertWithVessels.vessels
              : incomingAlert.vessels,
            inventoryItems: existingAlertWithInventory
              ? existingAlertWithInventory.inventoryItems
              : incomingAlert.inventoryItems
          };
        });
        //#endregion

        state.alerts = updatedAlerts;

        state.alertsById = updatedAlerts.reduce((acc, alert) => {
          return {
            ...acc,
            [alert.id]: alert
          };
        }, {});
        state.alertStates = updatedAlerts.reduce((acc, alert) => {
          const inSearchParams = alertId === alert.id;
          if (inSearchParams && !state.initialActionsComplete) {
            return {
              ...acc,
              [alert.id]: {
                selected: true,
                expanded: true,
                filtered: true // alert.filteredVessels.length !== alert.vessels.length
              }
            };
          }
          return {
            ...acc,
            [alert.id]: {
              selected: state.alertStates[alert.id]
                ? state.alertStates[alert.id].selected
                : false,
              expanded: state.alertStates[alert.id]
                ? state.alertStates[alert.id].expanded
                : false,
              filtered: true // alert.filteredVessels.length !== alert.vessels.length
            }
          };
        }, {});
        state.selectedAlertIds = Object.keys(state.alertStates).filter((key) => {
          const target = state.alertStates[key];
          if (target.selected) {
            return key;
          }
        });
        if (!vesselId) {
          state.initialActionsComplete = true;
        }
        state.status = 'idle';
      })
      .addCase(loadAlerts.rejected, (state, action) => {
        if (action.payload) {
          state.status = 'failed';
          state.alerts = [];
          state.selectedAlertIds = [];
          state.alertStates = {};
          state.alertsById = {};
          state.error = action.error;
        }
      })
      .addCase(loadVessels.pending, (state, action) => {
        if (!action.meta.arg.avoidLoadingState) {
          state.alertStates[action.meta.arg.alertId].loading = true;
        }
      })
      .addCase(loadVessels.fulfilled, (state, action) => {
        state.alertsById[action.payload.alertId] = {
          ...state.alertsById[action.payload.alertId],
          vessels: action.payload.vessels,
          filteredVessels: action.payload.filteredVessels
        };
        state.alerts = state.alerts.map((alert) => {
          if (alert.alertIdentifier === action.payload.alertId) {
            return {
              ...alert,
              vessels: action.payload.vessels,
              filteredVessels: action.payload.filteredVessels
            };
          }
          return alert;
        });
        state.alertStates[action.meta.arg.alertId].loading = false;
      })
      .addCase(loadInventory.pending, (state, action) => {
        if (!action.meta.arg.avoidLoadingState) {
          state.alertStates[action.meta.arg.alertId].loadingInventory = true;
        }
      })
      .addCase(loadInventory.fulfilled, (state, action) => {
        state.alertsById[action.payload.alertId] = {
          ...state.alertsById[action.payload.alertId],
          inventoryItems: action.payload.inventory
        };
        state.alerts = state.alerts.map((alert) => {
          if (alert.alertIdentifier === action.payload.alertId) {
            return {
              ...alert,
              inventoryItems: action.payload.inventory
            };
          }
          return alert;
        });
        state.alertStates[action.meta.arg.alertId].loadingInventory = false;
      })
      .addCase(loadInventory.rejected, (state, action) => {
        state.alertStates[action.meta.arg.alertId].loadingInventory = false;
      });
  }
});

export const {
  setAlerts,
  selectAlert,
  deselectAlert,
  expandAlert,
  collapseAlert,
  setFocusedVessel,
  setAlertFilteredVessels,
  completeInitialActions
} = alertSlice.actions;

export default alertSlice.reducer;
