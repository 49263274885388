import {Box, Tab, Tabs, styled, LinearProgress, Button} from '@mui/material';
import React, {useState} from 'react';
import {AlertChipsContainer} from 'components/ContentExplorer/ContentExplorerContent/AlertTabPanel/AlertChipsContainer';
import {AlertDetailsCard} from 'components/ContentExplorer/ContentExplorerContent/AlertTabPanel/AlertDetailsCard';
import {AlertStripCard} from 'components/ContentExplorer/ContentExplorerContent/AlertTabPanel/AlertStripCard';
import {EOAlertResponse} from 'types/Alerts';
import {useAppDispatch, useAppSelector} from '../../../../redux/hooks';
import {loadInventory, loadVessels, selectAlertStateById} from '../../../../redux/alerts';
import {createAccessHistoryLog, type AccessHistoryBody} from 'api/user-analytics';
import {useMutation} from 'react-query';
import {fetchAccessHistory} from 'redux/alertAccess';
import {downloadAlertJson} from 'utilities/debug';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabPanel: React.FC<TabPanelProps> = ({children, index, value, ...other}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 0}}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

interface AlertTabPanelProps {
  alert: EOAlertResponse;
}

const MTab = styled(Tab)({
  '&.Mui-disabled': {
    color: '#74726f'
  }
});

export const AlertTabPanel: React.FC<AlertTabPanelProps> = ({alert}) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);
  const [incomingDate, setIncomingDate] = React.useState(alert.ingestDate);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const alertState = useAppSelector((state) =>
    selectAlertStateById(state, alert.alertIdentifier)
  );

  const loadAlertVessels = React.useCallback(() => {
    if (alert.vessels.length === 0 && alert.counts.total > 0) {
      dispatch(loadVessels({alertId: alert.alertIdentifier, avoidLoadingState: false}));
    }
    if (alert.source === 'EO' && alert.inventoryItems.length === 0) {
      dispatch(loadInventory({alertId: alert.alertIdentifier, avoidLoadingState: false}));
    }
  }, [alert, dispatch]);

  React.useEffect(() => {
    loadAlertVessels();
    fetchAccessHistory();
  }, [loadAlertVessels]);

  // if ingestDate was updated, refetch loaded vessels and inventoryItems
  React.useEffect(() => {
    if (incomingDate !== alert.ingestDate) {
      setIncomingDate(alert.ingestDate);
      dispatch(loadInventory({alertId: alert.alertIdentifier, avoidLoadingState: false}));
      dispatch(loadVessels({alertId: alert.alertIdentifier, avoidLoadingState: false}));
    }
  }, [alert.ingestDate, alert.alertIdentifier, incomingDate, dispatch]);

  const {mutate: postHistoryLog} = useMutation({
    mutationFn: (newAlert: AccessHistoryBody) => {
      return createAccessHistoryLog(newAlert);
    }
  });

  // vessels need to be loaded for history log to occur,
  // hence why it cannot happen over the EyeToggleButton event
  React.useEffect(() => {
    if (alertState.expanded && (alert.vessels.length > 0 || alert.counts.total === 0)) {
      postHistoryLog({
        alertId: alert.id,
        vesselCount: alert.vessels.length.toString(),
        lastVesselId: alert?.vessels?.at(-1)?.id ?? ''
      });
    }
  }, [alert, alertState, postHistoryLog]);

  const dates = useAppSelector((store) => store.filters.selectedTimeFrame);

  if (alertState.loading || alertState.loadingInventory) {
    return (
      <LinearProgress
        sx={(theme) => ({backgroundColor: theme.palette.primary.contrastText})}
      />
    );
  }

  const disableImagery =
    alert.source === 'SAR' || alert.source === 'ODS' || alert.source === 'RF';

  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <Button onClick={() => downloadAlertJson(alert, dates)}>json</Button>
      )}
      <Tabs
        value={value}
        variant="fullWidth"
        sx={{minHeight: '36px', height: '36px'}}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            background: '#fcaf17',
            backgroundColor: '#fcaf17'
          }
        }}
        aria-label="secondary tabs example"
        color="inherit"
        textColor="primary"
      >
        <Tab
          sx={(theme) => ({
            color: theme.palette.primary.main,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: '36px'
          })}
          value={0}
          label="Vessel Detects"
          {...a11yProps(0)}
        />
        <Tab
          sx={(theme) => ({
            color: theme.palette.primary.main,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: '36px'
          })}
          value={1}
          label="Alert Summary"
          {...a11yProps(1)}
        />
        <MTab
          sx={(theme) => ({
            color: theme.palette.primary.main,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: '36px'
          })}
          value={2}
          label="Imagery"
          disabled={disableImagery}
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AlertChipsContainer
          alertId={alert.alertIdentifier}
          vessels={alert.vessels}
          spills={alert.spills}
          spillSources={alert.sources}
          filteredVessels={alert.filteredVessels}
          source={alert.source}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AlertDetailsCard alert={alert} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AlertStripCard alert={alert} />
      </TabPanel>
    </>
  );
};
