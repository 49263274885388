import {Filters, Mission} from 'types/Filters';
import {vesselTypeMap} from 'utilities/vesselTypes';
import {Coordinate} from 'ol/coordinate';

const lowercase = (value: string) => {
  return value.toLowerCase().replace(/\s/gi, '_');
};

export function vesselTypeLookup(shipType: string, query: number) {
  // query => 0 === 'alert query'
  // query => 1 === 'vessel query'
  const val = vesselTypeMap.get(shipType);
  if (Array.isArray(val)) {
    const arr = [];
    if (query === 0) {
      for (let i = 0; i < val.length; i++) {
        arr.push({
          'properties.numericVesselTypes': val[i]
        });
      }
    } else if (query === 1) {
      for (let i = 0; i < val.length; i++) {
        arr.push({
          'correlationMetadata.numericVesselTypes': val[i]
        });
      }
    } else {
      return [];
    }

    return arr;
  } else if (val) {
    if (query === 0) {
      return [
        {
          'properties.vesselTypes': lowercase(val)
        }
      ];
    } else if (query === 1) {
      return [
        {
          'properties.vesselType': lowercase(val)
        }
      ];
    } else {
      return [];
    }
  }
}

export function buildFinalQuery(filter: unknown[]) {
  return {
    size: 10000,
    query: {
      bool: {
        filter
      }
    },
    sort: [
      {
        'properties.meanDatetime': {
          order: 'desc'
        }
      }
    ]
  };
}

export function buildQueries(
  startDate: string,
  endDate: string,
  disableFilters: boolean | null,
  hideZeroFilters: boolean | null,
  filters: Filters | null,
  missions: Mission[] | null,
  alertId?: string
) {
  return {
    alertQuery: buildAlertQuery(
      startDate,
      endDate,
      disableFilters,
      hideZeroFilters,
      filters,
      missions
    ),
    vesselQuery: buildVesselQuery(
      startDate,
      endDate,
      disableFilters,
      hideZeroFilters,
      filters,
      missions,
      alertId
    ),
    allAlertVesselsQuery: buildVesselQuery(
      startDate,
      endDate,
      null,
      null,
      null,
      null,
      alertId
    )
  };
}

export function buildAlertQuery(
  startDate: string,
  endDate: string,
  disableFilters: boolean | null,
  hideZeroFilters: boolean | null,
  filters: Filters | null,
  missions: Mission[] | null
) {
  // const sDate = format(new Date(startDate), 'yyyy-MM-dd');
  // const eDate = format(new Date(endDate), 'yyyy-MM-dd');
  // console.log('alert dates: ', sDate, eDate);
  let sources: any[] = [];
  if (filters) {
    sources = filters?.sources.filter((c) => c.value).map((c) => c.label);
  }

  let shipTypes: any[] = [];
  if (filters) {
    shipTypes = filters?.categories.filter((c) => c.value).map((c) => c.term);
  }

  let missionFilters: any[] = [];
  if (missions) {
    missionFilters = missions
      ?.filter((mission) => mission.value)
      .map((mission) => mission.label);
  }

  const watchboxes = filters?.watchboxes.filter((wb) => wb.active);
  const drawnWatchbox = filters?.drawnWatchbox;

  const filter: unknown[] =
    filters && filters.alertIds?.length > 0
      ? []
      : [
          {
            range: {
              'properties.meanDatetime': {
                format: 'strict_date_optional_time',
                gte: startDate,
                lte: endDate
              }
            }
          }
        ];

  if (disableFilters) {
    const tmp: any = {
      bool: {
        must: []
      }
    };
    if (hideZeroFilters) {
      tmp.bool.must.push({
        bool: {
          should: [
            {
              range: {
                'counts.dark': {
                  gt: 0
                }
              }
            },
            {
              range: {
                'counts.correlated': {
                  gt: 0
                }
              }
            }
          ]
        }
      });
      filter.push(tmp);
    }
    return buildFinalQuery(filter);
  }
  if (!filters) {
    return buildFinalQuery(filter);
  }

  if (missions && missionFilters.length > 0) {
    const tmp = {
      bool: {
        should: missionFilters.map((mission) => {
          return {
            term: {
              'properties.mission': mission
            }
          };
        })
      }
    };
    filter.push(tmp);
  }

  if (sources.length > 0) {
    const tmp = {
      bool: {
        should: sources.map((source) => {
          return {
            term: {source: source.toLowerCase()}
          };
        })
      }
    };
    filter.push(tmp);
  }

  if (
    // filters.detectionTypes.dark ||
    // filters.detectionTypes.correlated ||
    hideZeroFilters
  ) {
    const tmp: any = {
      bool: {
        must: []
      }
    };

    if (hideZeroFilters && filters.detectionTypes.correlated) {
      tmp.bool.must.push({
        bool: {
          should: [
            {
              range: {
                'counts.correlated': {
                  gt: 0
                }
              }
            }
          ]
        }
      });
    }

    if (hideZeroFilters && filters.detectionTypes.dark) {
      if (tmp.bool.must.length > 0) {
        tmp.bool.must[0].bool.should.push({
          range: {
            'counts.dark': {
              gt: 0
            }
          }
        });
      } else {
        tmp.bool.must.push({
          bool: {
            should: [
              {
                range: {
                  'counts.dark': {
                    gt: 0
                  }
                }
              }
            ]
          }
        });
      }
    } else if (hideZeroFilters) {
      tmp.bool.must.push({
        bool: {
          should: [
            {
              range: {
                'counts.correlated': {
                  gt: 0
                }
              }
            },
            {
              range: {
                'counts.dark': {
                  gt: 0
                }
              }
            }
          ]
        }
      });
    }
    filter.push(tmp);
  }

  if (filters.mmsis.length > 0) {
    const tmp = {
      terms: {
        'properties.mmsis': filters.mmsis
      }
    };
    filter.push(tmp);
  }

  if (filters.alertIds.length > 0) {
    const tmp = {
      terms: {
        _id: filters.alertIds
      }
    };
    filter.push(tmp);
  }

  if (filters.flags.length > 0) {
    const tmp = {
      terms: {
        'properties.flags': filters.flags.map((fl) => lowercase(fl))
      }
    };
    filter.push(tmp);
  }
  // Working here
  if (filters.detectionTypes.dark || filters.detectionTypes.correlated) {
    if (filters.detectionTypes.dark) {
      const tmp = {
        range: {
          'counts.dark': {
            gt: 0
          }
        }
      };
      filter.push(tmp);
    }
    if (filters.detectionTypes.correlated) {
      const tmp = {
        range: {
          'counts.correlated': {
            gt: 0
          }
        }
      };
      filter.push(tmp);
    }
  }
  // Create the or blocks here
  const keys = Object.keys(filters.measurements);
  const arr = [] as Array<any>;
  for (let i = 0; i < keys.length; i++) {
    let lengthFrom = 0;
    let lengthTo = 0;
    let widthFrom = 0;
    let widthTo = 0;
    let speedFrom = 0;
    let speedTo = 0;
    const measurement = filters.measurements[keys[i]];
    // Validate input is number
    if (!Number.isNaN(+measurement.vesselLength.from)) {
      lengthFrom = +measurement.vesselLength.from;
    }
    if (!Number.isNaN(+measurement.vesselLength.to)) {
      lengthTo = +measurement.vesselLength.to;
    }
    if (!Number.isNaN(+measurement.vesselWidth.from)) {
      widthFrom = +measurement.vesselWidth.from;
    }
    if (!Number.isNaN(+measurement.vesselWidth.to)) {
      widthTo = +measurement.vesselWidth.to;
    }
    if (!Number.isNaN(+measurement.speed.from)) {
      speedFrom = +measurement.speed.from;
    }
    if (!Number.isNaN(+measurement.speed.to)) {
      speedTo = +measurement.speed.to;
    }
    if (
      lengthFrom > 0 ||
      lengthTo > 0 ||
      widthFrom > 0 ||
      widthTo > 0 ||
      speedFrom > 0 ||
      speedTo > 0
    ) {
      arr.push({
        vesselLength: {
          from: lengthFrom,
          to: lengthTo
        },
        vesselWidth: {from: widthFrom, to: widthTo},
        speed: {from: speedFrom, to: speedTo}
      });
    }
  }

  if (arr.length > 0) {
    const tmp: any = {
      bool: {
        should: []
      }
    };
    for (let i = 0; i < arr.length; i++) {
      tmp.bool.should.push({
        bool: {
          must: []
        }
      });
      if (arr[i].vesselLength.to > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.lengths': {
              lte: arr[i].vesselLength.to
            }
          }
        });
      }
      if (arr[i].vesselLength.from > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.lengths': {
              gte: arr[i].vesselLength.from
            }
          }
        });
      }
      if (arr[i].vesselWidth.to > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.widths': {
              lte: arr[i].vesselWidth.to
            }
          }
        });
      }
      if (arr[i].vesselWidth.from > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.widths': {
              gte: arr[i].vesselWidth.from
            }
          }
        });
      }
      if (arr[i].speed.to > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.speeds': {
              lte: arr[i].speed.to
            }
          }
        });
      }

      if (arr[i].speed.from > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.speeds': {
              gte: arr[i].speed.from
            }
          }
        });
      }
    }
    filter.push(tmp);
  }

  if (shipTypes.length > 0) {
    const tmp = {
      bool: {
        must: [
          {
            bool: {
              should: shipTypes.flatMap((shipType) => {
                const vesselTypes = vesselTypeLookup(shipType, 0);
                return vesselTypes?.map((queryTerm) => {
                  return {term: queryTerm};
                });
              })
            }
          }
        ]
      }
    };

    filter.push(tmp);
  }
  const polygons: Array<Coordinate[][]> = [];

  if (drawnWatchbox && drawnWatchbox.length > 0) {
    drawnWatchbox.forEach((wb) => {
      if (wb.geometry.type === 'Polygon') {
        polygons.push(wb.geometry.coordinates);
      }
    });

    const tmp: any = {
      nested: {
        path: 'watchboxPolygons',
        query: {
          bool: {
            should: []
          }
        }
      }
    };

    polygons.forEach((points) => {
      const nestedTmp = {
        bool: {
          must: [
            {
              geo_shape: {
                'watchboxPolygons.location': {
                  shape: {
                    type: 'polygon',
                    coordinates: points
                  }
                }
              }
            }
          ]
        }
      };
      tmp.nested.query.bool.should.push(nestedTmp);
    });
    filter.push(tmp);
  }

  if (watchboxes && watchboxes.length > 0) {
    watchboxes.forEach((wb) => {
      if (wb.geometry.type === 'Polygon') {
        polygons.push(wb.geometry.coordinates);
      }
      if (wb.geometry.type === 'MultiPolygon') {
        wb.geometry.coordinates.forEach((c) => {
          polygons.push(c);
        });
      }
    });

    const tmp: any = {
      nested: {
        path: 'watchboxPolygons',
        query: {
          bool: {
            should: []
          }
        }
      }
    };

    polygons.forEach((points) => {
      const nestedTmp = {
        bool: {
          must: [
            {
              geo_shape: {
                'watchboxPolygons.location': {
                  shape: {
                    type: 'polygon',
                    coordinates: points
                  }
                }
              }
            }
          ]
        }
      };
      tmp.nested.query.bool.should.push(nestedTmp);
    });
    filter.push(tmp);
  }

  return buildFinalQuery(filter);
}

export function buildVesselQuery(
  startDate: string,
  endDate: string,
  disableFilters: boolean | null,
  hideZeroFilters: boolean | null,
  filters: Filters | null,
  missions: Mission[] | null,
  alertId?: string
) {
  // const sDate = format(new Date(startDate), 'yyyy-MM-dd');
  // const eDate = format(new Date(endDate), 'yyyy-MM-dd');
  // console.log('vessel dates: ', sDate, eDate);

  let sources: any[] = [];
  if (filters) {
    sources = filters?.sources.filter((c) => c.value).map((c) => c.label);
  }

  let shipTypes: any[] = [];
  if (filters) {
    shipTypes = filters?.categories.filter((c) => c.value).map((c) => c.term);
  }

  let missionFilters: any[] = [];
  if (missions) {
    missionFilters = missions
      ?.filter((mission) => mission.value)
      .map((mission) => mission.label);
  }
  const filter: unknown[] = [];

  if (alertId) {
    const tmp = {
      bool: {
        should: {
          term: {'properties.alertId': alertId}
        }
      }
    };
    filter.push(tmp);
  }

  if (disableFilters) {
    return buildFinalQuery(filter);
  }
  if (!filters) {
    return buildFinalQuery(filter);
  }

  if (missions && missionFilters.length > 0) {
    const tmp = {
      bool: {
        should: missionFilters.map((mission) => {
          return {
            term: {
              'properties.mission': mission
            }
          };
        })
      }
    };
    filter.push(tmp);
  }

  if (sources.length > 0) {
    const tmp = {
      bool: {
        should: sources.map((source) => {
          return {
            term: {'properties.source': source.toLowerCase()}
          };
        })
      }
    };
    filter.push(tmp);
  }

  if (filters.detectionTypes.dark || filters.detectionTypes.correlated) {
    const tmp: any = {
      bool: {
        should: []
      }
    };
    if (filters.detectionTypes.dark) {
      tmp.bool.should.push({
        term: {
          'properties.dark': true
        }
      });
    }
    if (filters.detectionTypes.correlated) {
      tmp.bool.should.push({
        term: {
          'properties.correlated': true
        }
      });
    }
    filter.push(tmp);
  }

  if (filters.mmsis.length > 0) {
    const tmp = {
      terms: {
        'properties.mmsi': filters.mmsis
      }
    };
    filter.push(tmp);
  }

  if (filters.alertIds.length > 0) {
    const tmp = {
      terms: {
        'properties.alertId': filters.alertIds
      }
    };
    filter.push(tmp);
  }

  if (filters.flags.length > 0) {
    const tmp = {
      bool: {
        should: filters.flags.map((flag) => {
          return {
            term: {'properties.flag': lowercase(flag)}
          };
        })
      }
    };
    filter.push(tmp);
  }
  // Create the or blocks here
  const keys = Object.keys(filters.measurements);
  const arr = [] as Array<any>;
  for (let i = 0; i < keys.length; i++) {
    let lengthFrom = 0;
    let lengthTo = 0;
    let widthFrom = 0;
    let widthTo = 0;
    let speedFrom = 0;
    let speedTo = 0;
    const measurement = filters.measurements[keys[i]];
    // Validate input is number
    if (!Number.isNaN(+measurement.vesselLength.from)) {
      lengthFrom = +measurement.vesselLength.from;
    }
    if (!Number.isNaN(+measurement.vesselLength.to)) {
      lengthTo = +measurement.vesselLength.to;
    }
    if (!Number.isNaN(+measurement.vesselWidth.from)) {
      widthFrom = +measurement.vesselWidth.from;
    }
    if (!Number.isNaN(+measurement.vesselWidth.to)) {
      widthTo = +measurement.vesselWidth.to;
    }
    if (!Number.isNaN(+measurement.speed.from)) {
      speedFrom = +measurement.speed.from;
    }
    if (!Number.isNaN(+measurement.speed.to)) {
      speedTo = +measurement.speed.to;
    }
    if (
      lengthFrom > 0 ||
      lengthTo > 0 ||
      widthFrom > 0 ||
      widthTo > 0 ||
      speedFrom > 0 ||
      speedTo > 0
    ) {
      arr.push({
        vesselLength: {
          from: lengthFrom,
          to: lengthTo
        },
        vesselWidth: {from: widthFrom, to: widthTo},
        speed: {from: speedFrom, to: speedTo}
      });
    }
  }

  if (arr.length > 0) {
    const tmp: any = {
      bool: {
        should: []
      }
    };
    for (let i = 0; i < arr.length; i++) {
      tmp.bool.should.push({
        bool: {
          must: []
        }
      });
      if (arr[i].vesselLength.to > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.length': {
              lte: arr[i].vesselLength.to
            }
          }
        });
      }
      if (arr[i].vesselLength.from > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.length': {
              gte: arr[i].vesselLength.from
            }
          }
        });
      }
      if (arr[i].vesselWidth.to > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.width': {
              lte: arr[i].vesselWidth.to
            }
          }
        });
      }
      if (arr[i].vesselWidth.from > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.width': {
              gte: arr[i].vesselWidth.from
            }
          }
        });
      }
      if (arr[i].speed.to > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.speed': {
              lte: arr[i].speed.to
            }
          }
        });
      }
      if (arr[i].speed.from > 0) {
        tmp.bool.should[i].bool.must.push({
          range: {
            'properties.speed': {
              gte: arr[i].speed.from
            }
          }
        });
      }
    }
    filter.push(tmp);
  }

  if (shipTypes.length > 0) {
    const tmp = {
      bool: {
        must: [
          {
            bool: {
              should: shipTypes.flatMap((shipType) => {
                const vesselTypes = vesselTypeLookup(shipType, 1);
                const terms = vesselTypes?.map((queryTerm) => {
                  return {term: queryTerm};
                });
                return terms;
              })
            }
          }
        ]
      }
    };
    filter.push(tmp);
  }
  const watchboxes = filters?.watchboxes.filter((wb) => wb.active);
  const drawnWatchbox = filters?.drawnWatchbox;

  const polygons: Array<Coordinate[][]> = [];

  if (watchboxes && watchboxes.length > 0) {
    watchboxes.forEach((wb) => {
      if (wb.geometry.type === 'Polygon') {
        polygons.push(wb.geometry.coordinates);
      }
      if (wb.geometry.type === 'MultiPolygon') {
        wb.geometry.coordinates.forEach((c) => {
          polygons.push(c);
        });
      }
    });

    const tmp: any = {
      bool: {
        should: []
      }
    };

    polygons.forEach((points) => {
      tmp.bool.should.push({
        geo_polygon: {
          location: {
            points: points.flat()
          }
        }
      });
    });
    filter.push(tmp);
  }

  if (drawnWatchbox && drawnWatchbox.length > 0) {
    drawnWatchbox.forEach((wb) => {
      if (wb.geometry.type === 'Polygon') {
        polygons.push(wb.geometry.coordinates);
      }
    });

    const tmp: any = {
      bool: {
        should: []
      }
    };

    polygons.forEach((points) => {
      tmp.bool.should.push({
        geo_polygon: {
          location: {
            points: points.flat()
          }
        }
      });
    });
    filter.push(tmp);
  }
  return buildFinalQuery(filter);
}

export const buildMMSIQuery = (
  mmsi: string | null | undefined,
  vesselId: string | undefined
) => {
  if (!vesselId) return null;
  return {
    size: 10000,
    query: {
      bool: {
        filter: [
          {
            bool: {
              should: [
                {
                  term: {
                    'properties.mmsi': mmsi ?? ''
                  }
                },
                {
                  term: {
                    'properties.vesselId': vesselId
                  }
                }
              ]
            }
          }
        ]
      }
    },
    sort: [
      {
        'properties.meanDatetime': {
          order: 'desc'
        }
      }
    ]
  };
};

// export const buildGeometryQuery = ()
