import React from 'react';
import {RFeature} from 'rlayers';
import {RStyle, RStroke, RFill} from 'rlayers/style';
import {fromLonLat} from 'ol/proj';
import {Point, Polygon} from 'ol/geom';
import {Vessel} from 'types/Alerts';
import {
  transformPolygonCoords,
  calculateScale,
  getVesselPointIcon,
  getRotation,
  colorWithAlpha
} from 'utilities/map';
import {LIT_GREEN, DARK_RED, Lit_TipEstimate} from 'styles/alert_icons';
import {Icon, Style} from 'ol/style';

interface VesselFeatureProps {
  onClick?: (vessel: Vessel) => void;
  vessel: Vessel;
  hideFootprint?: boolean;
  hideEstimate?: boolean;
}

export const VesselFeature: React.FC<VesselFeatureProps> = ({
  vessel,
  onClick,
  hideFootprint = false,
  hideEstimate = false
}) => {
  const pointRef = React.useRef<RFeature | null>(null);
  const course = vessel.properties.course;
  const heading = vessel.properties.heading;
  const rotation = heading || course || 0;
  const color = vessel.properties.dark ? DARK_RED : LIT_GREEN;
  const onFeatureClick = () => {
    if (onClick) {
      onClick(vessel);
    }
  };
  return (
    <>
      <RFeature
        onClick={onFeatureClick}
        properties={{FeatureType: 'VesselDetect'}}
        geometry={new Point(fromLonLat([vessel.lon, vessel.lat]))}
        ref={pointRef}
        style={(item, resolution) => {
          const iconPath = getVesselPointIcon(vessel);
          const icon = new Icon({
            rotation: getRotation(rotation),
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            rotateWithView: true,
            src: iconPath
          });
          if (resolution) {
            icon.setScale(calculateScale(resolution));
          }
          return new Style({
            image: icon
          });
        }}
      />
      {vessel.properties.correlated && !hideEstimate && (
        <RFeature
          geometry={
            new Point(
              fromLonLat([
                vessel.correlationMetadata.estLongitudeAtCollectionTimestamp,
                vessel.correlationMetadata.estLatitudeAtCollectionTimestamp
              ])
            )
          }
          style={(item, resolution) => {
            const icon = new Icon({
              rotation: getRotation(
                vessel.correlationMetadata.course ||
                  vessel.correlationMetadata.headingDeg ||
                  0
              ),
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              rotateWithView: true,
              src: Lit_TipEstimate
            });
            if (resolution) {
              icon.setScale(calculateScale(resolution));
            }
            return new Style({
              image: icon
            });
          }}
        />
      )}
      {vessel.geometry && !hideFootprint && (
        <RFeature
          geometry={new Polygon(transformPolygonCoords(vessel.geometry.coordinates))}
        >
          <RStyle>
            <RStroke color={color} width={2} />
            <RFill color={colorWithAlpha(color, 0.2)} />
          </RStyle>
        </RFeature>
      )}
    </>
  );
};
