import React from 'react';
import {RFeature} from 'rlayers';
import {RStyle, RStroke, RFill} from 'rlayers/style';
import {Polygon, MultiPolygon} from 'ol/geom';
import {InventoryItem} from 'types/Alerts';
import {colorWithAlpha} from 'utilities/map';
import {transformMultiPolygonCoords, transformPolygonCoords} from 'utilities/map';
import {INVENTORY_YELLOW} from 'styles/alert_icons';

interface InventoryFeatureProps {
  inventoryItem: InventoryItem;
}

export const InventoryFeature: React.FC<InventoryFeatureProps> = ({inventoryItem}) => {
  return (
    <>
      <RFeature
        geometry={
          new Polygon(transformPolygonCoords(inventoryItem.stripGeometry.coordinates))
        }
      >
        <RStyle>
          <RStroke color={colorWithAlpha(INVENTORY_YELLOW, 0.4)} width={2} />
          <RFill color="transparent" />
        </RStyle>
      </RFeature>
      {inventoryItem.cloudCoverGeometry && (
        <RFeature
          geometry={
            new MultiPolygon(
              transformMultiPolygonCoords(inventoryItem.cloudCoverGeometry.coordinates)
            )
          }
        >
          <RStyle>
            <RFill color={colorWithAlpha(INVENTORY_YELLOW, 0.1)} />
          </RStyle>
        </RFeature>
      )}
    </>
  );
};
