import React from 'react';
import Map from 'ol/Map';
import {useAppSelector} from 'redux/hooks';
import {useHotkeys} from 'react-hotkeys-hook';
import ContextMenu from 'ol-contextmenu';
import {
  selectMapMensurationEnabled,
  selectMapMensurationTool,
  selectMapMensurationUnit,
  MapMensurationUnitOption,
  selectMapMensurationLabels,
  selectMapMensurationClearCount
} from 'redux/mapMensuration';
import {Draw} from 'ol/interaction';
import {segmentStyle, pointStyle, labelStyle, selectPointStyle} from './styles';
import {Fill, Icon, Style} from 'ol/style';
import crossHairIcon from 'assets/map/CrosshairIcon.svg';
import ContextMenuDelete from 'assets/map/ContextMenuDelete.svg';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {numberWithCommas} from 'utilities/map';
import {Geometry, LineString, Point} from 'ol/geom';
import {getArea as getOlArea, getLength as getOlLength} from 'ol/sphere';
import {Feature} from 'ol';
import {unByKey} from 'ol/Observable';
import {EventsKey} from 'ol/events';
import {Coordinate} from 'ol/coordinate';
import Select from 'ol/interaction/Select';
import {Item} from 'ol-contextmenu/dist/types';
import './context-menu.scss';
import {selectIsMapModeLight} from '../../../../redux/map';

interface InteractionsProps {
  map: Map;
}

export const convertValue = (
  valueInMeters: number,
  unit: MapMensurationUnitOption
): number => {
  if (unit === 'meter') {
    return valueInMeters;
  }
  if (unit === 'kilometer') {
    return Math.round((valueInMeters / 1000) * 100) / 100;
  }
  if (unit === 'mile') {
    return valueInMeters * 0.00062137;
  }
  if (unit === 'feet') {
    return valueInMeters * 3.28;
  }
  if (unit === 'nautical_mile') {
    return valueInMeters / 1852;
  }
  return 0;
};

function getAbbreviation(unit: MapMensurationUnitOption) {
  if (unit === 'meter') return 'm';
  if (unit === 'kilometer') return 'km';
  if (unit === 'mile') return 'mi';
  if (unit === 'feet') return 'ft';
  if (unit === 'nautical_mile') return 'nm';
}

export const Interactions: React.FC<InteractionsProps> = ({map}) => {
  const enabled = useAppSelector(selectMapMensurationEnabled);
  const tool = useAppSelector(selectMapMensurationTool);
  const unit = useAppSelector(selectMapMensurationUnit);
  const labels = useAppSelector(selectMapMensurationLabels);
  const clearCount = useAppSelector(selectMapMensurationClearCount);
  const isLightMode = useAppSelector(selectIsMapModeLight);
  const displayUnit = React.useRef(unit);
  const labelsSettings = React.useRef(labels);
  const lineInProgress = React.useRef(false);
  const polygonInProgress = React.useRef(false);
  const setupComplete = React.useRef(false);
  const grandTotalDistance = React.useRef<number>(0);
  const totalDistance = React.useRef<number>(0);
  const linePointHistory = React.useRef<Feature<Geometry>[]>([]);
  const polygonPointHistory = React.useRef<Feature<Geometry>[]>([]);
  const grandTotalArea = React.useRef<number>(0);
  const totalArea = React.useRef<number>(0);
  const hoverKey = React.useRef<EventsKey | null>(null);
  const isSelectToolEnabled = React.useRef(tool === 'select');

  const [lineLayer] = React.useState(
    new VectorLayer({
      source: new VectorSource(),
      updateWhileAnimating: false,
      updateWhileInteracting: false,
      style: (feature) => {
        return styleFunction(feature);
      }
    })
  );

  const [polygonLayer] = React.useState(
    new VectorLayer({
      source: new VectorSource(),
      updateWhileAnimating: false,
      updateWhileInteracting: false,
      style: (feature) => {
        return styleFunction(feature);
      }
    })
  );

  const removeSelectedItem = React.useCallback(() => {
    if (selectedFeature.current) {
      const featureType = selectedFeature.current.get('type');
      const isPolygon = featureType === 'Polygon';
      const lineSource = lineLayer.getSource();
      const polygonSource = polygonLayer.getSource();
      const geometry = selectedFeature.current?.getGeometry();
      if (!geometry) return;
      const distance = getLength(geometry);
      const area = getArea(geometry);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const coords: number[] = geometry?.flatCoordinates;
      if (lineSource && !isPolygon) {
        lineSource.getFeatures().forEach((f) => {
          const type = f.get('type');
          if (type === 'Point') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const pointCoords: number[] = f.getGeometry()?.flatCoordinates;
            const exists = coords.find((v) => v === pointCoords[0]);
            if (exists) {
              lineSource.removeFeature(f);
            }
          }
        });
        lineSource.removeFeature(selectedFeature.current);
      }

      if (polygonSource && isPolygon) {
        polygonSource.getFeatures().forEach((f) => {
          const type = f.get('type');
          if (type === 'Point') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const pointCoords: number[] = f.getGeometry()?.flatCoordinates;
            const exists = coords.find((v) => v === pointCoords[0]);
            if (exists) {
              polygonSource.removeFeature(f);
            }
          }
        });
        polygonSource.removeFeature(selectedFeature.current);
      }

      grandTotalArea.current = grandTotalArea.current - area;
      grandTotalDistance.current = grandTotalDistance.current - distance;
      updateAreaElement(grandTotalArea.current);
      updateDistanceElement(grandTotalDistance.current);
      selectedFeature.current = null;
      showHideSelectionIndicator(false);
    }
  }, [lineLayer, polygonLayer]);

  const deleteContextMenuItem: Item = React.useMemo(() => {
    return {
      text: 'Delete Selection',
      icon: ContextMenuDelete,
      callback: () => {
        removeSelectedItem();
      }
    };
  }, [removeSelectedItem]);

  const contextMenu = React.useRef<ContextMenu>(
    new ContextMenu({
      width: 150,
      defaultItems: true // defaultItems are (for now) Zoom In/Zoom Out
    })
  );

  const lineDraw = React.useRef<Draw>(
    new Draw({
      source: lineLayer.getSource() || undefined,
      type: 'LineString',
      stopClick: false,
      snapTolerance: 0.01,
      style: function (feature) {
        return styleFunction(feature);
      }
    })
  );

  const polygonDraw = React.useRef<Draw>(
    new Draw({
      source: polygonLayer.getSource() || undefined,
      type: 'Polygon',
      snapTolerance: 2,
      style: (feature) => {
        return styleFunction(feature);
      }
    })
  );

  const polygonPoint = React.useRef(
    new Draw({
      source: polygonLayer.getSource() || undefined,
      type: 'Point',
      style: new Style({
        fill: new Fill({
          color: isLightMode ? '#000' : '#fff'
        }),
        image: new Icon({
          opacity: 1,
          size: [34, 34],
          offset: [-4, -5],
          src: crossHairIcon,
          scale: 1
        })
      })
    })
  );
  const linePoint = React.useRef(
    new Draw({
      source: lineLayer.getSource() || undefined,
      type: 'Point',
      snapTolerance: 0.01,
      style: new Style({
        fill: new Fill({
          color: isLightMode ? '#000' : '#fff'
        }),
        image: new Icon({
          opacity: 1,
          size: [34, 34],
          offset: [-4, -5],
          src: crossHairIcon,
          scale: 1
        })
      })
    })
  );

  const segmentStyles = React.useRef([segmentStyle]);
  const selectedFeature = React.useRef<Feature | null>(null);
  const select = React.useRef<Select>(
    new Select({
      filter: function (feature) {
        const featureType = feature.get('type');
        return featureType === 'LineString' || featureType === 'Polygon';
      },
      hitTolerance: 5,
      style: (feature) => styleFunction(feature, selectPointStyle)
    })
  );

  useHotkeys('mod+z', () => {
    if (lineInProgress.current) {
      undoLine();
    }
    if (polygonInProgress.current) {
      undoPolygon();
    }
  });

  useHotkeys('backspace, delete', () => {
    removeSelectedItem();
  });

  const clearInProgressDrawing = React.useCallback(() => {
    const lineSource = lineLayer.getSource();
    linePointHistory.current.forEach((point) => {
      if (lineSource) {
        lineSource.removeFeature(point);
      }
    });
    linePointHistory.current = [];
    const polySource = polygonLayer.getSource();
    polygonPointHistory.current.forEach((point) => {
      if (polySource) {
        polySource.removeFeature(point);
      }
    });
    polygonPointHistory.current = [];
  }, [lineLayer, polygonLayer]);

  const undoLine = React.useCallback(() => {
    lineDraw.current.removeLastPoint();
    const source = lineLayer.getSource();
    const target = linePointHistory.current.pop();
    if (!source || !target) return;
    source.removeFeature(target);
  }, [lineLayer]);

  const undoPolygon = React.useCallback(() => {
    polygonDraw.current.removeLastPoint();
    const source = polygonLayer.getSource();
    const target = polygonPointHistory.current.pop();
    if (!source || !target) return;
    source.removeFeature(target);
  }, [polygonLayer]);

  React.useEffect(() => {
    displayUnit.current = unit;
  }, [unit]);

  React.useEffect(() => {
    labelsSettings.current = labels;
  }, [labels]);

  const onLineDrawEnd = React.useCallback(() => {
    linePointHistory.current = [];
    grandTotalDistance.current = grandTotalDistance.current + totalDistance.current;
    updateDistanceElement(grandTotalDistance.current);
  }, []);

  const onPolygonDrawEnd = React.useCallback(() => {
    polygonPointHistory.current = [];
    grandTotalDistance.current = grandTotalDistance.current + totalDistance.current;
    grandTotalArea.current = grandTotalArea.current + totalArea.current;
    updateAreaElement(grandTotalArea.current);
    updateDistanceElement(grandTotalDistance.current);
  }, []);

  const setupLineInteraction = React.useCallback(() => {
    lineLayer.set('name', 'lineSegments');
    polygonLayer.set('name', 'polygons');
    map.addLayer(lineLayer);
    map.addLayer(polygonLayer);
    lineLayer.setZIndex(100);
    polygonLayer.setZIndex(100);

    linePoint.current.on('drawend', (e) => {
      e.feature.set('type', 'Point');
      linePointHistory.current.push(e.feature);
    });

    polygonPoint.current.on('drawend', (e) => {
      e.feature.set('type', 'Point');
      polygonPointHistory.current.push(e.feature);
    });
    lineDraw.current.on('drawstart', () => {
      lineInProgress.current = true;
    });
    lineDraw.current.on('drawend', (e) => {
      lineInProgress.current = false;
      e.feature.set('type', 'LineString');
      setTimeout(onLineDrawEnd, 100);
    });
    polygonDraw.current.on('drawstart', () => {
      polygonInProgress.current = true;
    });
    polygonDraw.current.on('drawend', (e) => {
      polygonInProgress.current = false;
      e.feature.set('type', 'Polygon');
      setTimeout(onPolygonDrawEnd, 100);
    });

    contextMenu.current.on('beforeopen', function (evt) {
      const feature: any = map.forEachFeatureAtPixel(
        evt.pixel,
        function (ft) {
          return ft;
        },
        {hitTolerance: 3}
      );

      if (feature && isSelectToolEnabled.current) {
        // open only on features
        const featureType = feature.get('type');
        const shouldShowMenu = featureType === 'LineString' || featureType === 'Polygon';
        if (shouldShowMenu) {
          select.current.getFeatures().pop();
          select.current.getFeatures().push(feature);
          selectedFeature.current = feature;
          contextMenu.current.enable();
        } else {
          contextMenu.current.disable();
        }
      } else {
        contextMenu.current.disable();
      }
    });
    contextMenu.current.on('open', (evt) => {
      const feature: any = map.forEachFeatureAtPixel(
        evt.pixel,
        (ft) => {
          return ft;
        },
        {hitTolerance: 3}
      );

      if (feature) {
        contextMenu.current.clear();
        contextMenu.current.push(deleteContextMenuItem);
        // selectedFeature.current = feature;
      } else {
        contextMenu.current.clear();
      }
    });
    select.current.on('select', function (e) {
      if (e.selected.length === 1) {
        selectedFeature.current = e.selected[0];
        const featGeom = selectedFeature.current?.getGeometry();
        const d = featGeom ? getLength(featGeom) : 0;
        const a = featGeom ? getArea(featGeom) : 0;
        updateDistanceElement(d);
        updateAreaElement(a);
        showHideSelectionIndicator(true);
      } else {
        selectedFeature.current = null;
        updateDistanceElement(grandTotalDistance.current);
        updateAreaElement(grandTotalArea.current);
        showHideSelectionIndicator(false);
      }
    });

    map.addControl(contextMenu.current);
    map.addInteraction(lineDraw.current);
    map.addInteraction(linePoint.current);
    map.addInteraction(polygonPoint.current);
    map.addInteraction(polygonDraw.current);

    setupComplete.current = true;
  }, [
    lineLayer,
    polygonLayer,
    map,
    deleteContextMenuItem,
    onLineDrawEnd,
    onPolygonDrawEnd
  ]);

  React.useEffect(() => {
    if (!setupComplete.current && enabled) {
      setTimeout(setupLineInteraction, 100);
    }
  }, [enabled, setupLineInteraction]);

  const hoverInteraction = React.useCallback(
    (e: any) => {
      if (e.dragging) return;
      const hit = map.hasFeatureAtPixel(e.pixel, {
        hitTolerance: 3,
        layerFilter: function (layer) {
          const layerName = layer.get('name');
          return layerName === 'lineSegments' || layerName === 'polygons';
        }
      });
      map.getViewport().style.cursor = hit ? 'pointer' : '';
    },
    [map]
  );

  const removeHoverInteraction = React.useCallback(() => {
    if (hoverKey.current) {
      unByKey(hoverKey.current);
    }
  }, []);

  const enableSelectTool = React.useCallback(() => {
    map.getViewport().style.cursor = 'default';
    map.addInteraction(select.current);
    clearInProgressDrawing();
    hoverKey.current = map.on('pointermove', hoverInteraction);
    polygonDraw.current.setActive(false);
    polygonPoint.current.setActive(false);
    linePoint.current.setActive(false);
    lineDraw.current.setActive(false);
  }, [map, clearInProgressDrawing, hoverInteraction]);

  const enableLineTool = React.useCallback(() => {
    removeHoverInteraction();
    select.current.getFeatures().clear();
    clearInProgressDrawing();
    showHideSelectionIndicator(false);
    selectedFeature.current = null;
    map.removeInteraction(select.current);
    polygonDraw.current.setActive(false);
    polygonPoint.current.setActive(false);
    linePoint.current.setActive(true);
    lineDraw.current.setActive(true);
    updateAreaElement(grandTotalArea.current);
    updateDistanceElement(grandTotalDistance.current);
  }, [clearInProgressDrawing, removeHoverInteraction, map]);

  const enablePolygonTool = React.useCallback(() => {
    removeHoverInteraction();
    clearInProgressDrawing();
    select.current.getFeatures().clear();
    showHideSelectionIndicator(false);
    selectedFeature.current = null;
    map.removeInteraction(select.current);
    polygonDraw.current.setActive(true);
    polygonPoint.current.setActive(true);
    linePoint.current.setActive(false);
    lineDraw.current.setActive(false);
    updateAreaElement(grandTotalArea.current);
    updateDistanceElement(grandTotalDistance.current);
  }, [map, removeHoverInteraction, clearInProgressDrawing]);

  const setupTools = React.useCallback(() => {
    isSelectToolEnabled.current = tool === 'select';
    if (tool === 'line') {
      enableLineTool();
      contextMenu.current.disable();
    }
    if (tool === 'polygon') {
      enablePolygonTool();
      contextMenu.current.disable();
    }
    if (tool === 'select') {
      enableSelectTool();
      contextMenu.current.enable();
    }
  }, [enableLineTool, enablePolygonTool, enableSelectTool, tool]);

  const disableDrawingTools = React.useCallback(() => {
    selectedFeature.current = null;
    clearInProgressDrawing();
    map.removeInteraction(select.current);
    polygonDraw.current.setActive(false);
    polygonPoint.current.setActive(false);
    lineDraw.current.setActive(false);
    linePoint.current.setActive(false);
    showHideSelectionIndicator(false);
  }, [map, clearInProgressDrawing]);

  React.useEffect(() => {
    if (enabled) {
      setupTools();
      map.getViewport().style.cursor = 'none';
    } else {
      map.getViewport().style.cursor = 'default';
      removeHoverInteraction();
      clearInProgressDrawing();
      disableDrawingTools();
    }
  }, [
    enabled,
    clearInProgressDrawing,
    map,
    setupTools,
    removeHoverInteraction,
    disableDrawingTools
  ]);

  React.useEffect(() => {
    lineDraw.current.changed();
    lineLayer.getSource()?.changed();
    polygonDraw.current.changed();
    polygonLayer.getSource()?.changed();
  }, [labels, lineLayer, polygonLayer]);

  React.useEffect(() => {
    lineDraw.current.changed();
    lineLayer.getSource()?.changed();
    polygonDraw.current.changed();
    polygonLayer.getSource()?.changed();
    if (lineInProgress.current || polygonInProgress.current) {
      updateDistanceElement(totalDistance.current);
    } else {
      updateDistanceElement(grandTotalDistance.current);
    }
    if (polygonInProgress.current) {
      updateAreaElement(totalArea.current);
    } else {
      updateAreaElement(grandTotalArea.current);
    }
  }, [unit, lineLayer, polygonLayer]);

  React.useEffect(() => {
    setupTools();
  }, [tool, setupTools]);

  const updateDistanceElement = (value: number) => {
    const target = document.getElementById('mensuration-distance-total');
    if (target) {
      if (value === 0) {
        target.innerText = '--';
      } else {
        const convertedValue = convertValue(value, displayUnit.current);
        target.innerText = `${numberWithCommas(convertedValue < 0 ? 0 : convertedValue)}`;
      }
    }
  };
  const updateAreaElement = (value: number) => {
    const target = document.getElementById('mensuration-area-total');
    if (target) {
      if (value === 0) {
        target.innerText = '--';
      } else {
        const convertedValue = convertValue(value, displayUnit.current);
        target.innerText = `${numberWithCommas(convertedValue)}`;
      }
    }
  };

  const clearAll = React.useCallback(() => {
    lineLayer.getSource()?.clear();
    polygonLayer.getSource()?.clear();
    grandTotalDistance.current = 0;
    totalDistance.current = 0;
    grandTotalArea.current = 0;
    totalArea.current = 0;
    updateDistanceElement(0);
    updateAreaElement(0);
  }, [lineLayer, polygonLayer]);

  React.useEffect(() => {
    clearAll();
  }, [clearCount, clearAll]);

  const showHideSelectionIndicator = (show: boolean) => {
    const target = document.getElementById('selected-totals-indicator');
    if (target) {
      target.style.display = show ? 'inline-block' : 'none';
    }
  };

  const getLength = (feature: Geometry) => {
    const value = getOlLength(feature);
    return Number(value.toFixed(3));
  };

  const getArea = (feature: Geometry) => {
    const value = getOlArea(feature);
    return Number(value.toFixed(3));
  };

  const styleFunction = (feature: any, pStyle = pointStyle) => {
    const styles = [pStyle.clone()];
    const geometry = feature.getGeometry();
    if (!geometry) return;
    const type = geometry.getType();
    let areaLabel: string | null = null,
      line: any,
      point: any;

    if (type === 'Polygon') {
      point = geometry.getInteriorPoint();
      const area = getArea(geometry);
      if (area > 0 && labelsSettings.current.area) {
        areaLabel = `${numberWithCommas(
          convertValue(area, displayUnit.current)
        )} ${getAbbreviation(displayUnit.current)}\xB2`;
      }
      line = new LineString(geometry.getCoordinates()[0]);
    } else if (type === 'LineString') {
      line = geometry;
    }
    if (line) {
      let count = 0;
      let polyTotal = 0;
      line.forEachSegment((a: Coordinate, b: Coordinate) => {
        const segment = new LineString([a, b]);
        const distance = getLength(segment);
        if (type === 'Polygon') {
          polyTotal = polyTotal + distance;
        }

        const label = labelsSettings.current.distance
          ? `${numberWithCommas(
              convertValue(distance, displayUnit.current)
            )} ${getAbbreviation(displayUnit.current)}`
          : '';
        if (segmentStyles.current.length - 1 < count) {
          segmentStyles.current.push(segmentStyle.clone());
        }
        const segmentPoint = new Point(segment.getCoordinateAt(0.5));
        segmentStyles.current[count].setGeometry(segmentPoint);
        if (distance > 0) {
          segmentStyles.current[count].getText().setText(label);
        } else {
          segmentStyles.current[count].getText().setText('');
        }
        styles.push(segmentStyles.current[count]);
        count++;
      });
      if (type === 'LineString' && lineInProgress.current) {
        const d = getLength(line);
        totalDistance.current = d;
        updateDistanceElement(d);
      } else if (type === 'Polygon' && polygonInProgress.current) {
        const d = getLength(line);
        totalDistance.current = d;
        updateDistanceElement(d);
        const area = getArea(geometry);
        totalArea.current = area;
        updateAreaElement(area);
      }
    }
    if (areaLabel) {
      labelStyle.setGeometry(point);
      labelStyle.getText().setText(areaLabel);
      styles.push(labelStyle);
    }
    return styles;
  };
  return null;
};
