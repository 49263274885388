import React from 'react';
import {RFeature, RPopup} from 'rlayers';
import {formatDate} from 'utilities/date';
import {Point} from 'ol/geom';
import {fromLonLat} from 'ol/proj';
import {Coordinate} from 'ol/coordinate';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {calculateScale} from 'utilities/map';
interface PingFeatureProps {
  coordinate: Coordinate;
  color: string;
  timestamp: number;
}
export const PingFeature: React.FC<PingFeatureProps> = ({
  coordinate,
  color,
  timestamp
}) => {
  return (
    <RFeature
      geometry={new Point(fromLonLat(coordinate))}
      style={(item, resolution) => {
        const circle = new CircleStyle({
          radius: 3,
          stroke: new Stroke({
            color: '#fff',
            width: 0.25
          }),
          fill: new Fill({
            color
          })
        });
        if (resolution) {
          circle.setRadius(3 * calculateScale(resolution));
        }
        return new Style({
          image: circle
        });
      }}
    >
      <RPopup trigger={'hover'} className="ais-ping-info-popover">
        <p>{formatDate(new Date(timestamp), 'yyyy-MM-dd')}</p>
        <p>{formatDate(new Date(timestamp), 'HH:mm:ss')}Z</p>
      </RPopup>
    </RFeature>
  );
};
