import React from 'react';
import {RLayerVector} from 'rlayers';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {VesselFeature} from '../Alerts/VesselFeature';
import {SelectedVesselFeature} from './SelectedVesselFeature';
import {
  getHistoryTab,
  selectSelectedVessel,
  selectVesselHistory
} from 'redux/vesselHistory';
import {
  selectFingerprintVessels,
  selectFocusedFpVessel,
  setFocusedFpVessel
} from 'redux/vesselFingerprint';
import {FingerprintVesselFeature} from '../Alerts/FingerprintVesselFeature';
import {PartialFingerprintVessel} from 'types/Alerts';

export const VesselHistory: React.FC = () => {
  const dispatch = useAppDispatch();

  const vesselHistory = useAppSelector(selectVesselHistory);
  const fingerprintVessels = useAppSelector(selectFingerprintVessels);
  const selectedVessel = useAppSelector(selectSelectedVessel);
  const focusedFpVessel = useAppSelector(selectFocusedFpVessel);

  const currentHistoryTab = useAppSelector(getHistoryTab);

  const focusFingerprintVessel = (vessel: PartialFingerprintVessel) => {
    dispatch(setFocusedFpVessel(vessel));
  };

  if (!selectedVessel) return null;

  const renderVesselHistory = () => {
    return vesselHistory.map((vessel) => {
      return <VesselFeature key={vessel.id} vessel={vessel} hideFootprint hideEstimate />;
    });
  };

  const renderFingerprintVessels = () => {
    if (currentHistoryTab === 'similar') {
      return fingerprintVessels.map((vessel) => {
        return (
          <FingerprintVesselFeature
            key={vessel.id}
            vessel={vessel}
            onClick={focusFingerprintVessel}
          />
        );
      });
    }
  };

  return (
    <RLayerVector zIndex={10} wrapX={false}>
      {renderVesselHistory()}
      {renderFingerprintVessels()}
      <SelectedVesselFeature vessel={selectedVessel} />
      {focusedFpVessel && <SelectedVesselFeature vessel={focusedFpVessel} />}
    </RLayerVector>
  );
};
