import React from 'react';
import {CollectionSources, ImageryMethod} from 'types/Alerts';
import {EOTippedTimeline} from './EOTipped';
import {NonEOTimeline} from './NonEO';
import {EOWatchboxTimeline} from './EOWatchbox';

type LatencyTimelineProps = {
  observationDate: Date;
  captureDate?: Date | null;
  processingDate: Date | null;
  imageryMethod?: ImageryMethod;
  source: CollectionSources;
};

const LatencyTimeline: React.FC<LatencyTimelineProps> = ({
  source,
  observationDate,
  captureDate,
  processingDate,
  imageryMethod
}) => {
  const datetimeFormat = "HH:mm:ss'Z' | yyyy-MM-dd";

  if (source !== 'EO') {
    return (
      <NonEOTimeline
        observationDate={observationDate}
        processingDate={processingDate}
        dateFormat={datetimeFormat}
      />
    );
  }

  if (!imageryMethod) {
    throw new Error('An `imageryMethod` is required for `source` of type "EO"');
  }

  if (imageryMethod === 'tipped' && !captureDate) {
    throw new Error('A `captureDate` is required for `imageryMethod` of type "tipped"');
  }

  // render EO components
  switch (imageryMethod) {
    case 'tipped':
      return (
        <EOTippedTimeline
          observationDate={observationDate}
          captureDate={captureDate as Date}
          processingDate={processingDate}
          dateFormat={datetimeFormat}
        />
      );
    case 'watchbox':
      return (
        <EOWatchboxTimeline
          observationDate={observationDate}
          processingDate={processingDate}
          dateFormat={datetimeFormat}
        />
      );
    default:
      throw new Error('Invalid `imageryMethod` for `source` of type "EO"');
  }
};

export default LatencyTimeline;
