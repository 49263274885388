import React from 'react';
import {Container} from '@mui/material';

interface props {
  error: string;
}

// eslint-disable-next-line no-console
const consoleWarn = console.error;
// Redux does not like dates within the state as they are not serializable but technically it is fine.
// Ignoring the errors.
const SUPPRESSED_WARNINGS = ['A non-serializable value was detected'];

// eslint-disable-next-line no-console
console.error = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
    consoleWarn(msg, ...args);
  }
};

const App: React.FC<props> = ({error}): JSX.Element => {
  return (
    <Container>
      <div>Unauthorized Access | {error}</div>
    </Container>
  );
};

export default App;
