import * as React from 'react';

const BackButtonIcon: React.FC = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9.19995L3.8 7L14 7L14 5L3.8 5L6 2.79999L6 -3.49691e-07L-2.62268e-07 6L6 12L6 9.19995Z"
        fill="#B2AFAA"
      />
    </svg>
  );
};

export default BackButtonIcon;
