import {polygon} from '@turf/turf';
import {Coordinate} from 'ol/coordinate';
import {never} from 'ol/events/condition';
import {DrawEvent} from 'ol/interaction/Draw';
import React from 'react';
import {
  resetDrawnWatchbox,
  selectDrawingClear,
  selectDrawnWatchbox,
  selectGeoFiltering,
  setDrawnWatchbox,
  toggleGeoFiltering
} from 'redux/filters';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectDrawingTool} from 'redux/map';
import {RLayerVector, RStyle, RInteraction} from 'rlayers';
import {transformInversePolygonCoords} from 'utilities/map';
import {v4 as uuidv4} from 'uuid';

const GeoFilterLayer: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDrawing = useAppSelector(selectDrawingTool);
  const isGeoFiltering = useAppSelector(selectGeoFiltering);
  const geoCleared = useAppSelector(selectDrawingClear);
  const drawnWatchbox = useAppSelector(selectDrawnWatchbox);
  const handleDrawEnd = (e: DrawEvent) => {
    // TODO: upgrading openlayers version might fix the TS issue
    const eventGeometry: any = e.feature.getGeometry();

    if (eventGeometry) {
      const newPolygonCoordinates: Coordinate[][] = eventGeometry.getCoordinates();

      const transformedCoords = transformInversePolygonCoords(newPolygonCoordinates);

      const actualPolygon = polygon(transformedCoords).geometry;

      const polygonShape = {
        geometry: {
          type: 'Polygon',
          coordinates: actualPolygon.coordinates
        }
      };

      dispatch(setDrawnWatchbox(polygonShape));
      if (!isGeoFiltering) {
        dispatch(toggleGeoFiltering({value: true}));
      }
    }
  };
  function handleDrawStart(): void {
    if (drawnWatchbox.length > 0) {
      dispatch(resetDrawnWatchbox(true));
    }
  }

  const [resetKey, setResetKey] = React.useState(uuidv4());
  React.useEffect(() => {
    if (geoCleared) {
      setResetKey(uuidv4());
      dispatch(resetDrawnWatchbox(false));
    }
  }, [geoCleared, dispatch]);

  return (
    <RLayerVector visible={isGeoFiltering} key={resetKey}>
      <RStyle.RStyle>
        <RStyle.RStroke color="#1b8282" lineDash={[5]} width={2} />
        <RStyle.RFill color="rgba(255,255,255,0.1)" />
      </RStyle.RStyle>

      {isDrawing && (
        <RInteraction.RDraw
          type={'Polygon'}
          onDrawStart={handleDrawStart}
          onDrawEnd={handleDrawEnd}
          maxPoints={4}
          minPoints={4}
          freehandCondition={never}
        />
      )}
    </RLayerVector>
  );
};

export default GeoFilterLayer;
