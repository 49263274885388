import {Tooltip} from '@mui/material';
import React from 'react';
import BinocularIconLoader from 'components/Icons/BinocularIconLoader';
import CloudImage from 'assets/Cloud_2x.png';
import CompleteImage from 'assets/Complete_2x.png';
import DetectImage from 'assets/Detect_2x.png';
import ProcessingImage from 'assets/Processing_2x.png';
import {InventoryItem, Vessel} from 'types/Alerts';
import {
  ImageStripCardContainer,
  // StripRadioContainer,
  ImageStripAlertDetails,
  StripImageContainer,
  ResponsiveOverlay,
  StripImagePreview,
  StripImagePreviewTextDecorator,
  StripDetailCard,
  StripDetailCardBody,
  StripDetailCardTitle
} from 'components/ContentExplorer/ContentExplorerContent/AlertTabPanel/AlertStripCard/Styled';
import {downloadImageStrip} from '../../../../../api/alerts';

type ImageProps = {
  selected: string;
  item: InventoryItem;
  onChange: (value: string) => void;
  vessels: Vessel[];
};

const StripImage: React.FC<ImageProps> = ({selected, item, vessels = []}) => {
  // const handleImageStripsNoApply = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   onChange(event.target.value);
  // };
  const [base64Image, setBase64Image] = React.useState<string | null>(null);

  React.useEffect(() => {
    (async () => {
      const base64Result = await downloadImageStrip(item.inventoryId);
      setBase64Image(base64Result);
    })();
  }, [item.inventoryId]);

  return (
    <ImageStripCardContainer>
      {/* <StripRadioContainer>
        <Radio
          checked={item.inventoryId === selected}
          onChange={handleImageStripsNoApply}
          name="apply-imagery"
          value={item.inventoryId}
          aria-label="Apply Image Strip"
          sx={(theme) => ({
            color: theme.palette.primary.main,
            '&.Mui-checked': {
              color: theme.palette.primary.contrastText
            }
          })}
        />
      </StripRadioContainer> */}
      <ImageStripAlertDetails
        sx={(theme) => ({
          backgroundColor: item.inventoryId === selected ? '#4C4B48' : '#333231',
          borderTopColor:
            item.inventoryId === selected ? theme.palette.primary.contrastText : '#151414'
        })}
      >
        {/* <TargetIconImagery /> */}
        <StripImageContainer>
          <ResponsiveOverlay>
            {base64Image && (
              <img
                src={base64Image}
                style={{objectFit: 'contain', height: '150px', width: '150px'}}
              />
            )}

            <Tooltip
              placement="right"
              style={{margin: '0px !important'}}
              title={
                <React.Fragment>
                  {base64Image && <img src={base64Image} style={{height: '500px'}} />}
                </React.Fragment>
              }
            >
              <StripImagePreview>
                <BinocularIconLoader />
                <StripImagePreviewTextDecorator>Preview</StripImagePreviewTextDecorator>
              </StripImagePreview>
            </Tooltip>
          </ResponsiveOverlay>
        </StripImageContainer>
        <StripDetailCard>
          <StripDetailCardTitle>ID {item.inventoryId}</StripDetailCardTitle>
          <StripDetailCardBody>
            <div>
              Cloud Cover
              <img width="20" className="png-icon" src={CloudImage} />{' '}
              {Math.round(item.stripCloudCoverPct)}%
            </div>
          </StripDetailCardBody>
          <StripDetailCardBody>
            <div>
              Detects
              <img width="20" className="png-icon" src={DetectImage} /> {vessels.length}
            </div>
          </StripDetailCardBody>
          <StripDetailCardBody sx={{marginBottom: '40px'}}>
            <div>
              Status
              {item.vesselDetectStatus === 'Completed' ? (
                <img width="20" className="png-icon" src={CompleteImage} />
              ) : (
                <img width="20" className="png-icon" src={ProcessingImage} />
              )}
              {item.vesselDetectStatus}
            </div>
          </StripDetailCardBody>
        </StripDetailCard>
        {/*<Button*/}
        {/*  startIcon={<Download />}*/}
        {/*  className="download-button"*/}
        {/*  href={item.metadataZipDownloadUrl}*/}
        {/*>*/}
        {/*  Download*/}
        {/*</Button>*/}
      </ImageStripAlertDetails>
    </ImageStripCardContainer>
  );
};

export default StripImage;
