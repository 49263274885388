import React from 'react';
import {RFeature} from 'rlayers';
import {RStyle, RStroke, RFill} from 'rlayers/style';
import {Polygon} from 'ol/geom';
import {PolygonGeometry} from 'types/Alerts';
import {colorWithAlpha} from 'utilities/map';
import {transformPolygonCoords} from 'utilities/map';
import {INVENTORY_YELLOW} from 'styles/alert_icons';

interface SarSceneFeatureProps {
  geometry: PolygonGeometry;
}

export const SarSceneFeature: React.FC<SarSceneFeatureProps> = ({geometry}) => {
  return (
    <>
      <RFeature geometry={new Polygon(transformPolygonCoords(geometry.coordinates))}>
        <RStyle>
          <RStroke color={colorWithAlpha(INVENTORY_YELLOW, 0.4)} width={2} />
          <RFill color="transparent" />
        </RStyle>
      </RFeature>
    </>
  );
};
