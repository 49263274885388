import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'redux/store';
import {selectAisDateRange} from './selectors';
import {selectSelectedVessel} from 'redux/vesselHistory';
import {buildAISQuery} from '../../queries/AISQuery';
import {queryAis} from '../../api/ais';
import {AISVesselPol} from 'types/AIS';
export interface AisState {
  track: AISVesselPol[];
  aisPeriod: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AisState = {
  track: [],
  aisPeriod: '1',
  status: 'loading'
};

export const loadAisVesselTrack = createAsyncThunk(
  'alerts/vesselTrack',
  async (_, {getState}) => {
    const state = getState() as RootState;
    const selectedVessel = selectSelectedVessel(state);
    if (!selectedVessel) return [];
    const dateRange = selectAisDateRange(state);
    const vesselMMSIQuery = buildAISQuery(
      selectedVessel.properties.mmsi,
      dateRange.start,
      dateRange.end
    );
    return await queryAis('pol', vesselMMSIQuery);
  }
);

export const aisSlice = createSlice({
  name: 'ais',
  initialState,
  reducers: {
    setAisDatePeriod: (state, action: PayloadAction<any>) => {
      state.aisPeriod = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAisVesselTrack.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadAisVesselTrack.fulfilled, (state, action) => {
        state.track = action.payload;
        state.status = 'idle';
      })
      .addCase(loadAisVesselTrack.rejected, (state) => {
        // Ignore cancelled requests
        state.status = 'failed';
        state.track = [];
      });
  }
});

export const {setAisDatePeriod} = aisSlice.actions;

export default aisSlice.reducer;
