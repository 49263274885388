import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {startOfDay, endOfDay} from 'date-fns';

import {
  defaultAisChartDateRangeEnd,
  defaultAisChartDateRangeStart,
  defaultEndDate,
  defaultSelectedTimeFrame,
  defaultStartDate,
  getDefaultFilters
} from './defaults';
import {Detection, FiltersInput, FilterState, Measurements, Mission} from 'types/Filters';
import {getMissionList, getWatchboxList} from 'api/alerts';
import {utcToZonedTime} from 'date-fns-tz';
import {DrawnWatchboxArea, WatchboxArea} from 'types/Alerts';

const initialState: FilterState = {
  selectedTimeFrame: {
    start: defaultStartDate,
    end: defaultEndDate
  },
  aisChartDateRange: {
    start: defaultAisChartDateRangeStart,
    end: defaultAisChartDateRangeEnd
  },
  aisTrackDateRange: {
    selected: defaultSelectedTimeFrame.selected,
    start: defaultSelectedTimeFrame.start,
    end: defaultSelectedTimeFrame.end
  },
  loadingWatchboxes: true,
  filters: getDefaultFilters(),
  filtersEnabled: true,
  hideZeroDetects: false
};

const buildFilters = (
  currentFilters: FiltersInput,
  key: keyof FiltersInput,
  value: any
) => {
  return {
    ...currentFilters,
    [key]: value
  };
};

const toggleArrayValue = (
  arrayValue: {id: string; value: boolean}[],
  targetId: string
) => {
  return arrayValue.map((item) => {
    if (item.id === targetId) {
      return {
        ...item,
        value: !item.value
      };
    }
    return item;
  });
};

const toggleMissionValue = (missions: Mission[], targetValue: string) => {
  return missions.map((mission) => {
    if (mission.label === targetValue) {
      return {
        ...mission,
        value: !mission.value
      };
    }
    return mission;
  });
};

const toggleWatchboxValue = (watchboxes: WatchboxArea[], targetId: string) => {
  return watchboxes.map((watchbox) => {
    if (watchbox.name === targetId) {
      return {
        ...watchbox,
        active: !watchbox.active
      };
    }
    return watchbox;
  });
};

export const loadMissionOptions = createAsyncThunk(
  'filters/loadMissionOptions',
  async () => {
    return await getMissionList();
  }
);

export const loadWatchboxOptions = createAsyncThunk(
  'filters/loadWatchboxOptions',
  async () => {
    return await getWatchboxList();
  }
);

interface SearchParams {
  vesselId: string | null;
  alertId: string | null;
  alertDate: string | null;
}
export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFiltersEnabled: (state, action: PayloadAction<boolean>) => {
      state.filtersEnabled = action.payload;
    },
    setHideZeroDetects: (state, action: PayloadAction<boolean>) => {
      state.hideZeroDetects = action.payload;
    },
    setSelectedTimeFrame: (state, action: PayloadAction<{start: Date; end: Date}>) => {
      state.selectedTimeFrame = action.payload;
    },
    setInitialSearchParams: (state, action: PayloadAction<SearchParams>) => {
      if (action.payload.alertDate) {
        state.selectedTimeFrame = {
          start: startOfDay(utcToZonedTime(action.payload.alertDate, 'UTC')),
          end: endOfDay(utcToZonedTime(action.payload.alertDate, 'UTC'))
        };
      }

      if (action.payload.vesselId) {
        state.filters.vesselId = action.payload.vesselId;
      }

      state.filters = {
        ...state.filters,
        alertIds: action.payload.alertId
          ? [action.payload.alertId]
          : state.filters.alertIds
      };
    },

    toggleSource: (state, action: PayloadAction<'EO' | 'SAR' | 'RF' | 'ODS'>) => {
      const updatedSources = toggleArrayValue(state.filters.sources, action.payload);
      state.filters = buildFilters(state.filters, 'sources', updatedSources);
    },
    toggleMission: (state, action: PayloadAction<string>) => {
      const updatedMissions = toggleArrayValue(state.filters.missions, action.payload);
      state.filters = buildFilters(state.filters, 'missions', updatedMissions);
    },
    toggleMissionByValue: (state, action: PayloadAction<string>) => {
      const updatedMissions = toggleMissionValue(state.filters.missions, action.payload);
      state.filters = buildFilters(state.filters, 'missions', updatedMissions);
    },

    toggleWatchbox: (state, action: PayloadAction<string>) => {
      const updatedWatchboxes = toggleWatchboxValue(
        state.filters.watchboxes,
        action.payload
      );
      state.filters = buildFilters(state.filters, 'watchboxes', updatedWatchboxes);
    },
    toggleCategory: (state, action: PayloadAction<string>) => {
      const updatedCategories = toggleArrayValue(
        state.filters.categories,
        action.payload
      );
      state.filters = buildFilters(state.filters, 'categories', updatedCategories);
    },
    toggleDetectionType: (state, action: PayloadAction<keyof Detection>) => {
      state.filters.detectionTypes[action.payload] =
        !state.filters.detectionTypes[action.payload];
    },
    toggleGeoFiltering: (state, action: PayloadAction<{value: boolean}>) => {
      if (state.filters.drawnWatchbox.length > 0) {
        state.filters.cachedDrawing = state.filters.drawnWatchbox;
      }

      const isGeoFiltering = action.payload.value
        ? action.payload.value
        : !state.filters.isGeoFiltering;

      state.filters.isGeoFiltering = isGeoFiltering;

      isGeoFiltering
        ? (state.filters.drawnWatchbox = state.filters.cachedDrawing)
        : (state.filters.drawnWatchbox = []);
    },
    setAlertIds: (state, action: PayloadAction<string[]>) => {
      state.filters = buildFilters(state.filters, 'alertIds', action.payload);
    },
    setDetectionTypes: (state, action: PayloadAction<Detection>) => {
      state.filters = buildFilters(state.filters, 'detectionTypes', action.payload);
    },
    setMmsis: (state, action: PayloadAction<string[]>) => {
      state.filters = buildFilters(state.filters, 'mmsis', action.payload);
    },
    setFlags: (state, action: PayloadAction<string[]>) => {
      state.filters = buildFilters(state.filters, 'flags', action.payload);
    },
    setMeasurements: (state, action: PayloadAction<Measurements>) => {
      state.filters = buildFilters(state.filters, 'measurements', action.payload);
    },
    setDrawnWatchbox: (state, action: PayloadAction<DrawnWatchboxArea>) => {
      const prevBoxes = state.filters.drawnWatchbox;
      state.filters.drawnWatchbox = [...prevBoxes, action.payload];
    },
    resetFilters: (state) => {
      const newFilters = {
        ...getDefaultFilters(),
        watchboxes: state.filters.watchboxes,
        missions: state.filters.missions.map((m) => {
          return {
            ...m,
            value: false
          };
        })
      };
      state.filters = newFilters;
    },
    resetWatchboxes: (state) => {
      state.filters = {
        ...state.filters,
        watchboxes: state.filters.watchboxes.map((wb) => {
          return {
            ...wb,
            active: false
          };
        })
      };
    },
    resetDrawnWatchbox: (state, action: PayloadAction<boolean>) => {
      state.filters.geoClear = action.payload;
      state.filters.drawnWatchbox = [];
      state.filters.cachedDrawing = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadMissionOptions.fulfilled, (state, action) => {
      state.filters.missions = action.payload;
    });
    builder.addCase(loadWatchboxOptions.fulfilled, (state, action) => {
      state.filters.watchboxes = action.payload;
      state.loadingWatchboxes = false;
    });
  }
});

export const {
  setInitialSearchParams,
  setFiltersEnabled,
  setHideZeroDetects,
  setSelectedTimeFrame,
  toggleSource,
  toggleMission,
  toggleMissionByValue,
  toggleWatchbox,
  toggleDetectionType,
  toggleCategory,
  toggleGeoFiltering,
  setAlertIds,
  setDetectionTypes,
  setMmsis,
  setFlags,
  setMeasurements,
  setDrawnWatchbox,
  resetFilters,
  resetWatchboxes,
  resetDrawnWatchbox
} = filterSlice.actions;

export default filterSlice.reducer;
