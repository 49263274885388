import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {defaultLegendOptions, LegendControlOption} from '../../styles/legend_icons';

export interface LegendState {
  legendItems: LegendControlOption[];
  open: boolean;
  docked: boolean;
}

const initialState: LegendState = {
  open: true,
  docked: true,
  legendItems: defaultLegendOptions
};

export const legendSlice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    setLegendOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setLegendDocked: (state, action: PayloadAction<boolean>) => {
      state.docked = action.payload;
    }
  }
});

export const {setLegendOpen, setLegendDocked} = legendSlice.actions;

export default legendSlice.reducer;
