import React from 'react';
import {EOAlertResponse} from 'types/Alerts';
import {ODSSummary} from './components/ODSSummary';
import {EOSummary} from './components/EOSummary';
import {SARRFSummary} from './components/SARRFSummary';

interface AlertDetailsCardProps {
  alert: EOAlertResponse;
}

export const AlertDetailsCard: React.FC<AlertDetailsCardProps> = ({alert}) => {
  return (
    <>
      {alert.source === 'ODS' && <ODSSummary alert={alert} />}
      {alert.source === 'EO' && <EOSummary alert={alert} />}
      {(alert.source === 'SAR' || alert.source === 'RF') && (
        <SARRFSummary alert={alert} />
      )}
    </>
  );
};
