import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import {Check, FindReplace} from '@mui/icons-material';
import {formatDate} from 'utilities/date';
import {intervalToDuration, formatDuration} from 'date-fns';
import {TimelineDate, TotalDetectionContent} from './Styles';

export const NonEOTimeline = (props: {
  observationDate: Date;
  processingDate: Date | null;
  dateFormat: string;
}): React.ReactElement => {
  const {observationDate, processingDate, dateFormat} = props;

  //#region formatted dates
  const observedDate = formatDate(observationDate, dateFormat);

  let processedDate;
  if (processingDate) {
    processedDate = formatDate(processingDate, dateFormat);
  }
  //#endregion

  //#region durations
  let finalDuration;
  if (processingDate) {
    finalDuration = intervalToDuration({start: observationDate, end: processingDate});
  }

  const fmtCompletion = finalDuration ? formatDuration(finalDuration) : 'Processing...';
  //#endregion

  return (
    <>
      <TotalDetectionContent>
        <Typography variant="h6" component="span">
          Total Detection Cycle
        </Typography>
        <Typography sx={{fontWeight: 'bold'}}>{fmtCompletion}</Typography>
      </TotalDetectionContent>

      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{alignContent: 'center'}}
            align="right"
            variant="body2"
          >
            <Typography variant="h6" component="span">
              Capture Time
            </Typography>

            <TimelineDate>{observedDate}</TimelineDate>
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot variant="outlined">
              <FindReplace />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{py: '12px', px: 2, position: 'relative', top: '3em'}}>
            <div>
              <Typography variant="h6" component="span">
                Capture to Delivery
              </Typography>
              <Typography>{fmtCompletion}</Typography>
            </div>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{m: 'auto 0'}} variant="body2" />

          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot variant="outlined">
              <Check />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{alignContent: 'center'}}>
            <Typography variant="h6" component="span">
              Delivery Time
            </Typography>
            <TimelineDate>{processedDate ? processedDate : ''}</TimelineDate>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </>
  );
};
