import {Label, Line, Vertex} from 'types/Mensuration';
import {RootState} from '../store';
import {createSelector} from '@reduxjs/toolkit';

export const selectPresent = (state: RootState) => state.vesselChipMensuration.present;
export const selectFuture = (state: RootState) => state.vesselChipMensuration.future;
export const selectPast = (state: RootState) => state.vesselChipMensuration.past;
export const selectDeviceRatio = (state: RootState) =>
  state.vesselChipMensuration.deviceRatio;
export const selectCanvasHeight = (state: RootState) =>
  state.vesselChipMensuration.height;
export const selectCanvasWidth = (state: RootState) => state.vesselChipMensuration.width;
export const selectImageNaturalHeight = (state: RootState) =>
  state.vesselChipMensuration.imageNaturalDimensions.height;
export const selectImageNaturalWidth = (state: RootState) =>
  state.vesselChipMensuration.imageNaturalDimensions.width;
export const selectDisableRedoActionHistory = (state: RootState) =>
  state.vesselChipMensuration.disableRedoActionHistory;
export const selectDisableUndoActionHistory = (state: RootState) =>
  state.vesselChipMensuration.disableUndoActionHistory;
export const selectVesselMensurationActive = (state: RootState) =>
  state.vesselChipMensuration.active;
export const selectVesselMensurationEnlarged = (state: RootState) =>
  state.vesselChipMensuration.enlarged;
export const selectVesselChipRotationActive = (state: RootState) =>
  state.vesselChipMensuration.chipRotation;
export const selectVesselChipRotation = (state: RootState) =>
  state.vesselChipMensuration.rotationDegrees;
export const selectTabIndex = (state: RootState) =>
  state.vesselChipMensuration.active ? 0 : -1;
export const selectCollisionTolerance = (state: RootState) =>
  state.vesselChipMensuration.collisionTolerance;
export const selectCursorLine = (state: RootState) =>
  state.vesselChipMensuration.cursorLine;
export const selectDoubleClick = (state: RootState) =>
  state.vesselChipMensuration.dblClick;
export const selectRenderedLines = createSelector(
  [selectPast, selectPresent],
  (past, present) => {
    return [...past, present].filter((x) => x && x.type === 'Line').map((x) => x as Line);
  }
);

export const selectRenderedLabels = createSelector(
  [selectPast, selectPresent],
  (past, present) => {
    return [...past, present]
      .filter((x) => x && x.type === 'Label')
      .map((x) => x as Label);
  }
);

export const selectRenderedVertices = createSelector(
  [selectPast, selectPresent],
  (past, present) => {
    return [...past, present]
      .filter((x) => x && x.type === 'Vertex')
      .map((x) => x as Vertex);
  }
);

export const selectRenderedElements = createSelector(
  [selectPast, selectPresent],
  (past, present) => {
    return [...past, present].filter((x) => x !== null);
  }
);
