import React from 'react';
import {RFeature} from 'rlayers';
import {RStyle, RStroke, RFill} from 'rlayers/style';
import {MultiPolygon, Polygon} from 'ol/geom';
import {Feature} from 'types/Artifacts';
import {transformMultiPolygonCoords, transformPolygonCoords} from 'utilities/map';
import {EEZ_GRAY} from 'styles/alert_icons';
import {useAppSelector} from 'redux/hooks';
import {selectIsMapModeLight} from 'redux/map';

interface EEZFeatureProps {
  feature: Feature;
}

const EEZDarkStyle = (
  <RStyle>
    <RStroke color={EEZ_GRAY} width={0.5} lineDash={[2, 4, 6, 8, 6, 4]} />
    <RFill color="transparent" />
  </RStyle>
);

const EEZLightStyle = (
  <RStyle>
    <RStroke color={EEZ_GRAY} width={0.5} lineDash={[2, 4, 6, 8, 6, 4]} />
    <RFill color="transparent" />
  </RStyle>
);

export const EEZFeature: React.FC<EEZFeatureProps> = ({feature}) => {
  const isLightMode = useAppSelector(selectIsMapModeLight);
  if (feature.geometry.type === 'Polygon') {
    return (
      <RFeature
        geometry={new Polygon(transformPolygonCoords(feature.geometry.coordinates))}
      >
        {isLightMode ? EEZLightStyle : EEZDarkStyle}
      </RFeature>
    );
  }
  return (
    <>
      <RFeature
        geometry={
          new MultiPolygon(transformMultiPolygonCoords(feature.geometry.coordinates))
        }
      >
        {isLightMode ? EEZLightStyle : EEZDarkStyle}
      </RFeature>
    </>
  );
};
